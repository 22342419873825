import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'
import mitt from 'mitt'
import dayjs from 'dayjs'
const emitter = mitt();
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import '@/scss/custom.scss'

import animals from '@/store/animal-mock'

let baseURL = process.env.NODE_ENV == 'production' ? 'https://api-bc.safebeef.net/api-produtor/v1' : 'https://api-bc-dev.safebeef.net/api-produtor/v1'

const axiosInstance = axios.create({
    baseURL: baseURL,
    withCredentials: false
})

const app = createApp(App)

const httpMock = {
    get: (url) => {
        const id = url.split('/').pop()

        return Promise.resolve({ data: animals[id] })
    }
}

app.config.globalProperties.$http = httpMock
app.config.globalProperties.$emitter = emitter;
app.config.globalProperties.$dayjs = dayjs
app.config.productionTip = false

app.use(store)
app.use(router)
app.mount('#app')
