<template>
  <div class="container">
    <table class="table" v-if="animal.pesoHistorico">
      <thead>
        <tr>
          <th scope="col">Data</th>
          <th scope="col">Peso</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <template v-for="peso in animal.pesoHistorico">
          <tr>
            <td>{{formataData(peso.dtPesagem)}}</td>
            <td>{{peso.peso}} kg</td>
            <td>
              <img alt="+" src="../assets/plus.png" class="plusCollapse" data-bs-toggle="collapse" 
              :href="'#collapseExample'+peso.idPesagemBlockchain" role="button" aria-expanded="false" :aria-controls="'collapseExample'+peso.idPesagemBlockchain"></td>
          </tr>
          <tr>
            <td class="hiddenRow" colspan="3">
              <div class="collapse" :id="'collapseExample'+peso.idPesagemBlockchain">
                <div class="card card-body">
                  <div class="container">
                    <div class="row align-items-start">
                      <div class="col text-start">
                        <b>ID Pesagem:</b>
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        {{peso.idPesagemBlockchain}}
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        <b>Data Pesagem:</b>
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        {{formataData(peso.dtPesagem)}}
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        <b>Tipo:</b>
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        {{peso.tipo}}
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        <b>Peso:</b>
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        {{peso.peso}} kg
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        <b>Responsável:</b>
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        {{peso.nomeResponsavel}}
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        <b>Localidade:</b>
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        <iframe v-if="validaLocalizacao(peso.idPesagemBlockchain)"
                          width="100%"
                          height="100%"
                          style="border:0"
                          loading="lazy"
                          allowfullscreen
                          referrerpolicy="no-referrer-when-downgrade"
                          :src=getMapa(peso.idPesagemBlockchain)>
                        </iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>

let vm

export default {
  name: 'Pesagem',
  components: {
  },
  async created() {
    vm = this
    if(!vm.$route.params || !vm.$route.params.id)
      return vm.$router.push({ name: 'Index'})

    if(vm.$store.getters.getAnimal == null) {
      let response = null
      try {
        response = await vm.$http.get('/animal-aberto/'+vm.$route.params.id)
        if(response.data.error)
          throw response.data.message
      } catch(e) {
        return vm.$router.push({ name: 'Index'}) 
      }
      
      vm.$store.commit('setAnimal', response.data)
      vm.$emitter.emit('setAnimal', vm.$store.getters.getAnimal)
    }

    vm.animal = vm.$store.getters.getAnimal
  },
  data() {
    return {
      animal: {}
    }
  },
  methods: {    
    validaLocalizacao(idPesagem) {
      if (!vm.animal || !vm.animal.pesoHistorico) return false

      const pesagem = vm.animal.pesoHistorico.find(el => el.idPesagemBlockchain == idPesagem)

      if (!pesagem) return false

      return (pesagem.latitude && pesagem.longitude)
    },
    getMapa(idPesagem) {      
      const pesagem = vm.animal.pesoHistorico.find(el => el.idPesagemBlockchain == idPesagem)
      
      return `https://www.google.com/maps/embed/v1/view?key=AIzaSyBSrRxNTQJmCHcLTBv8IVoehzySq-atChk&center=\
      ${pesagem.latitude},${pesagem.longitude}&maptype=satellite&zoom=15`
    },
    formataData(data) {
      if(!data || data.length < 10) return '-'
      return vm.$dayjs(data).format('DD/MM/YYYY')
    }
  }
}

</script>