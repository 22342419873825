const animals = {
  "05bca239-ba00-4bd8-b79d-a8a9199c484e": {
    idLoteSync: "ec1f85a1-7f04-468d-ac28-2bfdf17d33b5",
    idBlockchain: "05bca239-ba00-4bd8-b79d-a8a9199c484e",
    docType: "ANIMAL",
    idSistemaGestao: "5e65d637-938e-4018-9c53-a6d127557fe0",
    idSisbov: "",
    idICar: "982000444276143",
    nome: "",
    dataNascimento: "2020-08-25",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "982000444276143",
    idAnimalFazenda: "1200112",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Santa Gertrudis", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:15:38",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      idSistemaGestao: "5e65d637-938e-4018-9c53-a6d127557fe0",
      idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
      nomeResponsavel: "Fazenda União do Brasil",
      latitude: "-23.6825667",
      longitude: "-48.5986721",
      tipo: "Saída",
      peso: 483,
      dtPesagem: "2023-05-11T11:31:50",
      idPesagemBlockchain: "f62ea4fd-4275-42c5-b16d-a05cf3f353ac",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "5e65d637-938e-4018-9c53-a6d127557fe0",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Nascimento",
        peso: 30,
        dtPesagem: "2020-08-25T03:00:00",
        idPesagemBlockchain: "61be042d-6e23-4fc6-b9f6-1e0e8e0caa21",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "5e65d637-938e-4018-9c53-a6d127557fe0",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Desmame",
        peso: 92.5,
        dtPesagem: "2021-04-27T17:40:10",
        idPesagemBlockchain: "fe467959-c84e-473e-9e35-af4b26851dbe",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "5e65d637-938e-4018-9c53-a6d127557fe0",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 298,
        dtPesagem: "2022-10-04T07:40:54",
        idPesagemBlockchain: "6aa37ce7-b000-43dd-9f4c-f4b4342bd1e6",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "5e65d637-938e-4018-9c53-a6d127557fe0",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 417,
        dtPesagem: "2023-02-17T13:41:34",
        idPesagemBlockchain: "4c75588a-b15c-436d-a880-707066efddeb",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "5e65d637-938e-4018-9c53-a6d127557fe0",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Saída",
        peso: 483,
        dtPesagem: "2023-05-11T11:31:50",
        idPesagemBlockchain: "f62ea4fd-4275-42c5-b16d-a05cf3f353ac",
      },
    ],
    produtorAtual: {
      idBlockchain: "25bc0b49-7cf7-4519-b70c-3001a1e4bfed",
      nome: "Fazenda União do Brasil",
      documento: "08090246000103",
    },
    status: "ABATIDO",
    dataCriacao: "2023-12-18T13:22:59",
    dataUltimaAtualizacao: "2024-01-16T18:18:30",
    vendaAtual: {
      idBlockchain: "0401071b-4fe8-4b0e-ba81-d94b5ab0b970",
      documentoProprietarioDestino: "30269671000113",
      tipo: "INDUSTRIA",
      proprietarioOrigem: {
        nome: "Fazenda União do Brasil",
        documento: "08090246000103",
      },
      proprietarioDestino: {
        nome: "Frigorífico Red Beef LTDA",
        documento: "30269671000113",
      },
      dataCriacao: "2023-12-18T18:33:01",
      dataUltimaAtualizacao: "2023-12-18T18:33:19",
      status: "EFETIVADA",
      qtdAnimais: 29,
    },
    vendaHistorico: [
      {
        idBlockchain: "eb66af4e-769b-4269-9378-dba27a1c0947",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:21:39",
        dataUltimaAtualizacao: "2023-12-18T18:24:08",
        status: "RECUSADA",
        qtdAnimais: 29,
      },
      {
        idBlockchain: "0401071b-4fe8-4b0e-ba81-d94b5ab0b970",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:33:01",
        dataUltimaAtualizacao: "2023-12-18T18:33:19",
        status: "EFETIVADA",
        qtdAnimais: 29,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [
      {
        dtCriacao: "2023-12-18T14:16:17",
        dtAlteracao: "2023-12-18T14:16:17",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "irancho-mvp",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipoExame: "ULTRASSOM_CARCACA",
        dtExame: "2023-05-11T11:31:50",
        vrResultado: "",
        vrResultadoAol: "73.01",
        vrResultadoEgs: "5.18",
        vrResultadoEgg: "0",
        vrResultadoMar: "3.71",
        vrResultadoRatio: "0",
        qtDiasPrenhe: null,
        icApresentouCio: null,
        idExameBlockchain: "227bd58d-a718-4a68-9c24-33f861ae5793",
      },
    ],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:16:02",
      dtAlteracao: "2023-12-18T14:16:02",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      fazenda: {
        nome: "Fazenda União do Brasil ",
        dataEntrada: "2022-10-04T00:00:00",
        dataSaida: "2023-05-11T00:00:00",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
      },
      subdivisao: {
        nome: "Confinamento",
        dataEntrada: "2022-10-04T00:00:00",
        dataSaida: "2023-05-11T00:00:00",
      },
      idSistemaGestao: "5e65d637-938e-4018-9c53-a6d127557fe0",
      idLocalizacaoBlockchain: "2cef8f58-c885-4a4a-b7e7-741287643ed0",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Vale do Sol ",
          dataEntrada: "2021-04-27T00:00:00",
          dataSaida: "2022-10-04T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "Desmama",
          dataEntrada: "2021-04-27T00:00:00",
          dataSaida: "2022-10-04T00:00:00",
        },
        idSistemaGestao: "5e65d637-938e-4018-9c53-a6d127557fe0",
        idLocalizacaoBlockchain: "2373432c-11f9-466e-bd6c-52b65eb00b2a",
      },
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Fazenda União do Brasil ",
          dataEntrada: "2022-10-04T00:00:00",
          dataSaida: "2023-05-11T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "Confinamento",
          dataEntrada: "2022-10-04T00:00:00",
          dataSaida: "2023-05-11T00:00:00",
        },
        idSistemaGestao: "5e65d637-938e-4018-9c53-a6d127557fe0",
        idLocalizacaoBlockchain: "2cef8f58-c885-4a4a-b7e7-741287643ed0",
      },
    ],
    loteHistorico: [],
    idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
    nomeResponsavel: "Fazenda União do Brasil",
    frigorifico: {
      nome: "fub-frigorifico",
      maturidade: "2 dentes",
      acabamento: "Uniforme",
      pesoBanda1: "",
      pesoBanda2: "",
      obs1: "",
      obs2: "",
      dtMorte: "2023-09-05",
      porcentagemCarcaca: "",
      dtCriacao: "2024-01-16T18:18:29",
      dtAlteracao: "2024-01-16T18:18:29",
    },
  },
  "0e5ef379-4be9-40d0-979a-bd607dc11756": {
    idLoteSync: "ec1f85a1-7f04-468d-ac28-2bfdf17d33b5",
    idBlockchain: "0e5ef379-4be9-40d0-979a-bd607dc11756",
    docType: "ANIMAL",
    idSistemaGestao: "cfd83496-02f5-4fcf-a345-4a8ea1d8aab6",
    idSisbov: "",
    idICar: "982000444275852",
    nome: "",
    dataNascimento: "2022-06-01",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "982000444275852",
    idAnimalFazenda: "4275852",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Santa Gertrudis", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:15:38",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      idSistemaGestao: "65bc67b9-293a-449f-b3b0-ce0177403ab7",
      idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
      nomeResponsavel: "Fazenda União do Brasil",
      latitude: "",
      longitude: "",
      tipo: "Saída",
      peso: 492,
      dtPesagem: "2023-11-07",
      idPesagemBlockchain: "56c5d1a4-ec16-4a6a-b5b1-bd92c4d4c960",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "6b91bc72-73f5-4775-8f4d-d91a07d72a43",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Entrada",
        peso: 306,
        dtPesagem: "2023-06-01",
        idPesagemBlockchain: "eefd4735-7fff-4397-8c12-464976d14e2e",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "3c58e149-a160-451b-b485-146efd756704",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 287,
        dtPesagem: "2023-06-27",
        idPesagemBlockchain: "aa47dae4-80d5-4afb-8895-90007bfe89c6",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "1a25b98f-2286-4303-9152-264f3b1d5af0",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 403,
        dtPesagem: "2023-09-04",
        idPesagemBlockchain: "5bfc30a0-2911-46bf-8ba6-9853fc96534b",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "65bc67b9-293a-449f-b3b0-ce0177403ab7",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Saída",
        peso: 492,
        dtPesagem: "2023-11-07",
        idPesagemBlockchain: "56c5d1a4-ec16-4a6a-b5b1-bd92c4d4c960",
      },
    ],
    produtorAtual: {
      idBlockchain: "25bc0b49-7cf7-4519-b70c-3001a1e4bfed",
      nome: "Fazenda União do Brasil",
      documento: "08090246000103",
    },
    status: "ABATIDO",
    dataCriacao: "2023-12-18T13:22:59",
    dataUltimaAtualizacao: "2023-12-18T18:46:48",
    vendaAtual: {
      idBlockchain: "e0130d89-b68f-423b-ad3d-a429637fb5a3",
      documentoProprietarioDestino: "18293459000196",
      tipo: "INDUSTRIA",
      proprietarioOrigem: {
        nome: "Fazenda União do Brasil",
        documento: "08090246000103",
      },
      proprietarioDestino: {
        nome: "Guidara Indústria e Comércio de Alimentos Eireli",
        documento: "18293459000196",
      },
      dataCriacao: "2023-12-18T18:27:49",
      dataUltimaAtualizacao: "2023-12-18T18:28:02",
      status: "EFETIVADA",
      qtdAnimais: 25,
    },
    vendaHistorico: [
      {
        idBlockchain: "f10b303c-ab60-4d04-b8b0-0f217cda4abb",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:22:09",
        dataUltimaAtualizacao: "2023-12-18T18:24:42",
        status: "RECUSADA",
        qtdAnimais: 25,
      },
      {
        idBlockchain: "f1392d6d-fc67-43f4-918a-1da3da56a459",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:26:52",
        dataUltimaAtualizacao: "2023-12-18T18:27:14",
        status: "RECUSADA",
        qtdAnimais: 25,
      },
      {
        idBlockchain: "e0130d89-b68f-423b-ad3d-a429637fb5a3",
        documentoProprietarioDestino: "18293459000196",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Guidara Indústria e Comércio de Alimentos Eireli",
          documento: "18293459000196",
        },
        dataCriacao: "2023-12-18T18:27:49",
        dataUltimaAtualizacao: "2023-12-18T18:28:02",
        status: "EFETIVADA",
        qtdAnimais: 25,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [
      {
        dtCriacao: "2023-12-18T14:16:17",
        dtAlteracao: "2023-12-18T14:16:17",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "e4abc219-fc2c-4438-a7b3-b1f6da878a7f",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipoExame: "ULTRASSOM_CARCACA",
        dtExame: "2023-09-04",
        vrResultado: "",
        vrResultadoAol: "78.41",
        vrResultadoEgs: "4.82",
        vrResultadoEgg: "0.00",
        vrResultadoMar: "3.34",
        vrResultadoRatio: "0.51",
        qtDiasPrenhe: null,
        icApresentouCio: null,
        idExameBlockchain: "c7f7f53f-dc6f-461d-989a-cd23d9583dd4",
      },
    ],
    medicamentosAplicados: [
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "04c39066-2a2f-4f3d-90e5-31925eb5a5cf",
        idOriginalMedicamentoBlockchain: "ba97487b-264d-4640-887a-1740557e1093",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        dtMedicacao: "2023-06-01",
        unidadeMedida: "MILILITRO",
        quantidade: 5,
        idMedicamentoBlockchain: "b831a8f6-96ec-4775-b9ae-ef30beb4dfd2",
        medicamento: {
          idBlockchain: "ba97487b-264d-4640-887a-1740557e1093",
          nome: "Iver-vet 3.5 injetável 1000 mL",
          categoria: "FARMACIA",
          subCategoria: "VERMIFUGO",
          fabricante: "Biovet",
          unidade: "LITRO",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "78f77d19-1968-4cc2-8973-477156413657",
        idOriginalMedicamentoBlockchain: "03a88948-7528-45c1-873a-b8d6012f9c15",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        dtMedicacao: "2023-06-27",
        unidadeMedida: "UNIDADE",
        quantidade: 2,
        idMedicamentoBlockchain: "df27c977-1df5-45dc-af40-828db33f98e5",
        medicamento: {
          idBlockchain: "03a88948-7528-45c1-873a-b8d6012f9c15",
          nome: "Vacina Bovi-Shield",
          categoria: "FARMACIA",
          subCategoria: "VACINA",
          fabricante: "Zoetis",
          unidade: "UNIDADE",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
    ],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:16:02",
      dtAlteracao: "2023-12-18T14:16:02",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      fazenda: {
        nome: "Fazenda União do Brasil ",
        dataEntrada: "2023-06-27",
        dataSaida: "2023-11-07",
        latitude: "-23.68423843",
        longitude: "-48.59685500",
      },
      subdivisao: {
        nome: "Confinamento",
        dataEntrada: "2023-06-27",
        dataSaida: "2023-11-07",
      },
      idSistemaGestao: "5015377",
      idLocalizacaoBlockchain: "cebb506e-769f-433c-9061-ec5ad78aba8a",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Fazenda União do Brasil ",
          dataEntrada: "2023-06-27",
          dataSaida: "2023-11-07",
          latitude: "-23.68423843",
          longitude: "-48.59685500",
        },
        subdivisao: {
          nome: "Confinamento",
          dataEntrada: "2023-06-27",
          dataSaida: "2023-11-07",
        },
        idSistemaGestao: "5015377",
        idLocalizacaoBlockchain: "cebb506e-769f-433c-9061-ec5ad78aba8a",
      },
    ],
    loteHistorico: [],
    idResponsavel: "fdd249be-f2a0-402a-9213-c40dff9f7c9f",
    nomeResponsavel: "BARTIRA AGROPECUARIA S/A",
    frigorifico: {
      nome: "Guidara Industria",
      maturidade: "2 dentes",
      acabamento: "Uniforme",
      pesoBanda1: "134",
      pesoBanda2: "134",
      obs1: "",
      obs2: "",
      dtMorte: "2023-11-08T00:00:00",
      porcentagemCarcaca: "56.40",
      dtCriacao: "2023-12-18T18:46:48",
      dtAlteracao: "2023-12-18T18:46:48",
    },
  },
  "0ee5db1b-9fa8-4d8f-b233-fba243588632": {
    idLoteSync: "ec1f85a1-7f04-468d-ac28-2bfdf17d33b5",
    idBlockchain: "0ee5db1b-9fa8-4d8f-b233-fba243588632",
    docType: "ANIMAL",
    idSistemaGestao: "6c7a1fc5-a453-420b-bc04-07594a608f2f",
    idSisbov: "",
    idICar: "982000444281839",
    nome: "",
    dataNascimento: "2022-06-01",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "982000444281839",
    idAnimalFazenda: "4281839",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Santa Gertrudis", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:15:38",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      idSistemaGestao: "52a9025c-9ffd-4cd7-a832-270415246838",
      idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
      nomeResponsavel: "Fazenda União do Brasil",
      latitude: "",
      longitude: "",
      tipo: "Saída",
      peso: 463,
      dtPesagem: "2023-11-07",
      idPesagemBlockchain: "aa6972ef-c225-4c8c-abe5-11262a422e58",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "1597c1e1-9c4c-47d7-8080-64355a6dab79",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Entrada",
        peso: 286,
        dtPesagem: "2023-06-01",
        idPesagemBlockchain: "9ef7d74a-5115-4f32-9d80-8e775e0dcc1e",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "6eeb2973-2f88-4027-9dd6-4cd58717a8b3",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 280,
        dtPesagem: "2023-06-27",
        idPesagemBlockchain: "406ae62e-5fba-4736-91de-82f3aa3aa1d0",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "9e5d530e-5cb5-4ac4-9ce6-e4e38c619c4a",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 379,
        dtPesagem: "2023-09-04",
        idPesagemBlockchain: "aed7d768-e96d-47c7-81ca-684d6077d70c",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "52a9025c-9ffd-4cd7-a832-270415246838",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Saída",
        peso: 463,
        dtPesagem: "2023-11-07",
        idPesagemBlockchain: "aa6972ef-c225-4c8c-abe5-11262a422e58",
      },
    ],
    produtorAtual: {
      idBlockchain: "25bc0b49-7cf7-4519-b70c-3001a1e4bfed",
      nome: "Fazenda União do Brasil",
      documento: "08090246000103",
    },
    status: "ABATIDO",
    dataCriacao: "2023-12-18T13:22:59",
    dataUltimaAtualizacao: "2023-12-18T18:46:38",
    vendaAtual: {
      idBlockchain: "e0130d89-b68f-423b-ad3d-a429637fb5a3",
      documentoProprietarioDestino: "18293459000196",
      tipo: "INDUSTRIA",
      proprietarioOrigem: {
        nome: "Fazenda União do Brasil",
        documento: "08090246000103",
      },
      proprietarioDestino: {
        nome: "Guidara Indústria e Comércio de Alimentos Eireli",
        documento: "18293459000196",
      },
      dataCriacao: "2023-12-18T18:27:49",
      dataUltimaAtualizacao: "2023-12-18T18:28:02",
      status: "EFETIVADA",
      qtdAnimais: 25,
    },
    vendaHistorico: [
      {
        idBlockchain: "f10b303c-ab60-4d04-b8b0-0f217cda4abb",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:22:09",
        dataUltimaAtualizacao: "2023-12-18T18:24:42",
        status: "RECUSADA",
        qtdAnimais: 25,
      },
      {
        idBlockchain: "f1392d6d-fc67-43f4-918a-1da3da56a459",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:26:52",
        dataUltimaAtualizacao: "2023-12-18T18:27:14",
        status: "RECUSADA",
        qtdAnimais: 25,
      },
      {
        idBlockchain: "e0130d89-b68f-423b-ad3d-a429637fb5a3",
        documentoProprietarioDestino: "18293459000196",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Guidara Indústria e Comércio de Alimentos Eireli",
          documento: "18293459000196",
        },
        dataCriacao: "2023-12-18T18:27:49",
        dataUltimaAtualizacao: "2023-12-18T18:28:02",
        status: "EFETIVADA",
        qtdAnimais: 25,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [
      {
        dtCriacao: "2023-12-18T14:16:17",
        dtAlteracao: "2023-12-18T14:16:17",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "c6a2b282-59eb-47d8-b0e6-c21ded41bcee",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipoExame: "ULTRASSOM_CARCACA",
        dtExame: "2023-09-04",
        vrResultado: "",
        vrResultadoAol: "69.92",
        vrResultadoEgs: "4.71",
        vrResultadoEgg: "0.00",
        vrResultadoMar: "3.51",
        vrResultadoRatio: "0.53",
        qtDiasPrenhe: null,
        icApresentouCio: null,
        idExameBlockchain: "d19681de-3696-48db-b4dc-be379302b5ed",
      },
    ],
    medicamentosAplicados: [
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "49c82d25-fd73-430f-bb25-9758823698c9",
        idOriginalMedicamentoBlockchain: "03a88948-7528-45c1-873a-b8d6012f9c15",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        dtMedicacao: "2023-06-27",
        unidadeMedida: "UNIDADE",
        quantidade: 2,
        idMedicamentoBlockchain: "4a13f707-29f6-49f6-a4bf-70d146fcbec9",
        medicamento: {
          idBlockchain: "03a88948-7528-45c1-873a-b8d6012f9c15",
          nome: "Vacina Bovi-Shield",
          categoria: "FARMACIA",
          subCategoria: "VACINA",
          fabricante: "Zoetis",
          unidade: "UNIDADE",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "ed771506-6ee5-4def-b83b-111145290951",
        idOriginalMedicamentoBlockchain: "ba97487b-264d-4640-887a-1740557e1093",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        dtMedicacao: "2023-06-01",
        unidadeMedida: "MILILITRO",
        quantidade: 5,
        idMedicamentoBlockchain: "48dac9cc-4a03-4dcf-b60c-3457e5d79049",
        medicamento: {
          idBlockchain: "ba97487b-264d-4640-887a-1740557e1093",
          nome: "Iver-vet 3.5 injetável 1000 mL",
          categoria: "FARMACIA",
          subCategoria: "VERMIFUGO",
          fabricante: "Biovet",
          unidade: "LITRO",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
    ],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:16:02",
      dtAlteracao: "2023-12-18T14:16:02",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      fazenda: {
        nome: "Fazenda União do Brasil ",
        dataEntrada: "2023-06-27",
        dataSaida: "2023-11-07",
        latitude: "-23.68423843",
        longitude: "-48.59685500",
      },
      subdivisao: {
        nome: "Confinamento",
        dataEntrada: "2023-06-27",
        dataSaida: "2023-11-07",
      },
      idSistemaGestao: "5015453",
      idLocalizacaoBlockchain: "9fb7be3c-71ce-4e82-88dd-706852e8303a",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Fazenda União do Brasil ",
          dataEntrada: "2023-06-27",
          dataSaida: "2023-11-07",
          latitude: "-23.68423843",
          longitude: "-48.59685500",
        },
        subdivisao: {
          nome: "Confinamento",
          dataEntrada: "2023-06-27",
          dataSaida: "2023-11-07",
        },
        idSistemaGestao: "5015453",
        idLocalizacaoBlockchain: "9fb7be3c-71ce-4e82-88dd-706852e8303a",
      },
    ],
    loteHistorico: [],
    idResponsavel: "fdd249be-f2a0-402a-9213-c40dff9f7c9f",
    nomeResponsavel: "BARTIRA AGROPECUARIA S/A",
    frigorifico: {
      nome: "Guidara Industria",
      maturidade: "2 dentes",
      acabamento: "Uniforme",
      pesoBanda1: "127",
      pesoBanda2: "127",
      obs1: "",
      obs2: "",
      dtMorte: "2023-11-08T00:00:00",
      porcentagemCarcaca: "56.30",
      dtCriacao: "2023-12-18T18:46:37",
      dtAlteracao: "2023-12-18T18:46:37",
    },
  },
  "12560ff6-e831-465e-8978-b65bc4b2dd8b": {
    idLoteSync: "42de72df-9185-43ff-b22e-f1f9899ba0fc",
    idBlockchain: "12560ff6-e831-465e-8978-b65bc4b2dd8b",
    docType: "ANIMAL",
    idSistemaGestao: "irancho-mvpteste4",
    idSisbov: "345",
    idICar: "",
    nome: "boi teste lote4",
    dataNascimento: "2024-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "0123",
    idAnimalFazenda: "teste4",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "25bc0b49-7cf7-4519-b70c-3001a1e4bfed",
      nome: "Fazenda União do Brasil",
      documento: "08090246000103",
    },
    status: "VIVO",
    dataCriacao: "2024-01-16T16:26:39",
    dataUltimaAtualizacao: "2024-01-16T16:31:55",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [
      {
        dtCriacao: "2024-01-16T16:27:59",
        dtAlteracao: "2024-01-16T16:30:55",
        deletado: true,
        editado: true,
        idLoteSync: "42de72df-9185-43ff-b22e-f1f9899ba0fc",
        idSistemaGestao: "23131",
        idResponsavel: "111111",
        nomeResponsavel: "Mauricio",
        latitude: "",
        longitude: "",
        tipoExame: "ANDROLOGICO",
        dtExame: "2021-11-01",
        vrResultado: "APTO",
        vrResultadoAol: "",
        vrResultadoEgs: "",
        vrResultadoEgg: "",
        vrResultadoMar: "",
        vrResultadoRatio: "",
        qtDiasPrenhe: 1231312,
        icApresentouCio: true,
        idExameBlockchain: "4bf4431d-721f-4222-8d28-1ab09b2a7c4d",
      },
    ],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "09af30c7-3ae2-4016-8659-0da0fb248818",
    nomeResponsavel: "Natalino Cavalli Junior",
    frigorifico: null,
  },
  "12baaf14-fce6-4da6-b257-87f34bdcd26d": {
    idLoteSync: "ec1f85a1-7f04-468d-ac28-2bfdf17d33b5",
    idBlockchain: "12baaf14-fce6-4da6-b257-87f34bdcd26d",
    docType: "ANIMAL",
    idSistemaGestao: "56082f3d-89de-4c78-9144-be7ce375ad37",
    idSisbov: "",
    idICar: "982000449378957",
    nome: "F 008041210098",
    dataNascimento: "2021-08-20",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "982000449378957",
    idAnimalFazenda: "F 008041210098",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Santa Gertrudis", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:15:38",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      idSistemaGestao: "56082f3d-89de-4c78-9144-be7ce375ad37",
      idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
      nomeResponsavel: "Fazenda União do Brasil",
      latitude: "-23.6825667",
      longitude: "-48.5986721",
      tipo: "Saída",
      peso: 568,
      dtPesagem: "2023-05-11T11:31:50",
      idPesagemBlockchain: "12e00c29-9b75-44c0-b74d-8704619d998f",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "56082f3d-89de-4c78-9144-be7ce375ad37",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Nascimento",
        peso: 39,
        dtPesagem: "2021-08-20T03:00:00",
        idPesagemBlockchain: "ae73e926-5eb2-4e1a-b935-9342be1e4e01",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "56082f3d-89de-4c78-9144-be7ce375ad37",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Desmame",
        peso: 183,
        dtPesagem: "2022-04-19T22:49:44",
        idPesagemBlockchain: "bd674612-92c7-41c6-8016-350010d6baf1",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "56082f3d-89de-4c78-9144-be7ce375ad37",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 298,
        dtPesagem: "2022-10-04T07:40:54",
        idPesagemBlockchain: "53a0756a-1f3d-4ce1-8c11-7fbd73de5654",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "56082f3d-89de-4c78-9144-be7ce375ad37",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 456,
        dtPesagem: "2023-02-17T13:41:34",
        idPesagemBlockchain: "ed409f1c-675e-4832-9d27-b9de472e61e3",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "56082f3d-89de-4c78-9144-be7ce375ad37",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Saída",
        peso: 568,
        dtPesagem: "2023-05-11T11:31:50",
        idPesagemBlockchain: "12e00c29-9b75-44c0-b74d-8704619d998f",
      },
    ],
    produtorAtual: {
      idBlockchain: "25bc0b49-7cf7-4519-b70c-3001a1e4bfed",
      nome: "Fazenda União do Brasil",
      documento: "08090246000103",
    },
    status: "ABATIDO",
    dataCriacao: "2023-12-18T13:22:59",
    dataUltimaAtualizacao: "2023-12-18T18:57:37",
    vendaAtual: {
      idBlockchain: "0401071b-4fe8-4b0e-ba81-d94b5ab0b970",
      documentoProprietarioDestino: "30269671000113",
      tipo: "INDUSTRIA",
      proprietarioOrigem: {
        nome: "Fazenda União do Brasil",
        documento: "08090246000103",
      },
      proprietarioDestino: {
        nome: "Frigorífico Red Beef LTDA",
        documento: "30269671000113",
      },
      dataCriacao: "2023-12-18T18:33:01",
      dataUltimaAtualizacao: "2023-12-18T18:33:19",
      status: "EFETIVADA",
      qtdAnimais: 29,
    },
    vendaHistorico: [
      {
        idBlockchain: "eb66af4e-769b-4269-9378-dba27a1c0947",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:21:39",
        dataUltimaAtualizacao: "2023-12-18T18:24:08",
        status: "RECUSADA",
        qtdAnimais: 29,
      },
      {
        idBlockchain: "0401071b-4fe8-4b0e-ba81-d94b5ab0b970",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:33:01",
        dataUltimaAtualizacao: "2023-12-18T18:33:19",
        status: "EFETIVADA",
        qtdAnimais: 29,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [
      {
        dtCriacao: "2023-12-18T14:16:17",
        dtAlteracao: "2023-12-18T14:16:17",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "irancho-mvp",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipoExame: "ULTRASSOM_CARCACA",
        dtExame: "2023-05-11T11:31:50",
        vrResultado: "",
        vrResultadoAol: "73.52",
        vrResultadoEgs: "5.65",
        vrResultadoEgg: "0",
        vrResultadoMar: "3.74",
        vrResultadoRatio: "0",
        qtDiasPrenhe: null,
        icApresentouCio: null,
        idExameBlockchain: "15168de5-7658-4e64-b1aa-66260ee74375",
      },
    ],
    medicamentosAplicados: [
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "irancho-mvp",
        idOriginalMedicamentoBlockchain: "40aa0e8c-5ceb-4b79-bd44-253139e57a21",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        dtMedicacao: "2022-04-19T15:09:38",
        unidadeMedida: "MILILITRO",
        quantidade: 2,
        idMedicamentoBlockchain: "9debc14d-8297-4641-852a-57df986b1659",
        medicamento: {
          idBlockchain: "40aa0e8c-5ceb-4b79-bd44-253139e57a21",
          nome: "Aborvac Brucelose",
          categoria: "FARMACIA",
          subCategoria: "VACINA",
          fabricante: "Zoetis",
          unidade: "MILILITRO",
          sexo: "FEMEA",
          idadeMaxima: 8,
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
    ],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:16:02",
      dtAlteracao: "2023-12-18T14:16:02",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      fazenda: {
        nome: "Fazenda União do Brasil ",
        dataEntrada: "2022-10-04T00:00:00",
        dataSaida: "2023-05-11T00:00:00",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
      },
      subdivisao: {
        nome: "Confinamento",
        dataEntrada: "2022-10-04T00:00:00",
        dataSaida: "2023-05-11T00:00:00",
      },
      idSistemaGestao: "56082f3d-89de-4c78-9144-be7ce375ad37",
      idLocalizacaoBlockchain: "0dac6883-b13f-41cf-a1a1-0cbbccdb12e6",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Fazenda Santo Antonio ",
          dataEntrada: "2022-04-19T00:00:00",
          dataSaida: "2022-04-23T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "pasto da frente",
          dataEntrada: "2022-04-19T00:00:00",
          dataSaida: "2022-04-23T00:00:00",
        },
        idSistemaGestao: "56082f3d-89de-4c78-9144-be7ce375ad37",
        idLocalizacaoBlockchain: "8391f01d-eade-4700-88cd-d79a03e529ac",
      },
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Vale do Sol ",
          dataEntrada: "2022-04-23T00:00:00",
          dataSaida: "2022-10-04T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "Desmama",
          dataEntrada: "2022-04-23T00:00:00",
          dataSaida: "2022-10-04T00:00:00",
        },
        idSistemaGestao: "56082f3d-89de-4c78-9144-be7ce375ad37",
        idLocalizacaoBlockchain: "2375da4b-0d4b-43cb-999e-6a3444fc2b77",
      },
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Fazenda União do Brasil ",
          dataEntrada: "2022-10-04T00:00:00",
          dataSaida: "2023-05-11T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "Confinamento",
          dataEntrada: "2022-10-04T00:00:00",
          dataSaida: "2023-05-11T00:00:00",
        },
        idSistemaGestao: "56082f3d-89de-4c78-9144-be7ce375ad37",
        idLocalizacaoBlockchain: "0dac6883-b13f-41cf-a1a1-0cbbccdb12e6",
      },
    ],
    loteHistorico: [],
    idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
    nomeResponsavel: "Fazenda União do Brasil",
    frigorifico: {
      nome: "Frigorífico Red Beef LTDA",
      maturidade: "0 dentes",
      acabamento: "Uniforme",
      pesoBanda1: "140.25 Kg",
      pesoBanda2: "140.25 Kg",
      obs1: "",
      obs2: "",
      dtMorte: "2023-05-11",
      porcentagemCarcaca: "49%",
      dtCriacao: "2023-12-18T18:57:37",
      dtAlteracao: "2023-12-18T18:57:37",
    },
  },
  "149d4382-9d78-407a-b1e0-e2361a09dac3": {
    idLoteSync: "ec1f85a1-7f04-468d-ac28-2bfdf17d33b5",
    idBlockchain: "149d4382-9d78-407a-b1e0-e2361a09dac3",
    docType: "ANIMAL",
    idSistemaGestao: "60aa4ff5-8361-44ee-9532-2154e3e4d72c",
    idSisbov: "",
    idICar: "982000444275983",
    nome: "",
    dataNascimento: "2022-04-02",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "982000444275983",
    idAnimalFazenda: "4275983",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Santa Gertrudis", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:15:38",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      idSistemaGestao: "2b2ff416-77e4-44dc-8dcb-e8ffbf5239cf",
      idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
      nomeResponsavel: "Fazenda União do Brasil",
      latitude: "",
      longitude: "",
      tipo: "Saída",
      peso: 466,
      dtPesagem: "2023-11-07",
      idPesagemBlockchain: "6a0655b6-6c4e-4409-a115-11d4dc32d346",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "fd194131-bd84-42a9-b57b-1c79449938a5",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Entrada",
        peso: 262,
        dtPesagem: "2023-06-01",
        idPesagemBlockchain: "973e6710-1997-4411-a97e-b93ff9304ddb",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "7390a912-7d85-4a98-ab0b-e8e05fe06993",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 250,
        dtPesagem: "2023-06-27",
        idPesagemBlockchain: "84594110-e811-4111-acee-d2f97c39fa6f",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "71a88b89-0285-4a78-bd4a-71e84a4873c1",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 380,
        dtPesagem: "2023-09-04",
        idPesagemBlockchain: "b79983c2-5529-4ac0-95b7-6792f644dc43",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "2b2ff416-77e4-44dc-8dcb-e8ffbf5239cf",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Saída",
        peso: 466,
        dtPesagem: "2023-11-07",
        idPesagemBlockchain: "6a0655b6-6c4e-4409-a115-11d4dc32d346",
      },
    ],
    produtorAtual: {
      idBlockchain: "25bc0b49-7cf7-4519-b70c-3001a1e4bfed",
      nome: "Fazenda União do Brasil",
      documento: "08090246000103",
    },
    status: "ABATIDO",
    dataCriacao: "2023-12-18T13:22:59",
    dataUltimaAtualizacao: "2023-12-18T18:49:43",
    vendaAtual: {
      idBlockchain: "e0130d89-b68f-423b-ad3d-a429637fb5a3",
      documentoProprietarioDestino: "18293459000196",
      tipo: "INDUSTRIA",
      proprietarioOrigem: {
        nome: "Fazenda União do Brasil",
        documento: "08090246000103",
      },
      proprietarioDestino: {
        nome: "Guidara Indústria e Comércio de Alimentos Eireli",
        documento: "18293459000196",
      },
      dataCriacao: "2023-12-18T18:27:49",
      dataUltimaAtualizacao: "2023-12-18T18:28:02",
      status: "EFETIVADA",
      qtdAnimais: 25,
    },
    vendaHistorico: [
      {
        idBlockchain: "f10b303c-ab60-4d04-b8b0-0f217cda4abb",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:22:09",
        dataUltimaAtualizacao: "2023-12-18T18:24:42",
        status: "RECUSADA",
        qtdAnimais: 25,
      },
      {
        idBlockchain: "f1392d6d-fc67-43f4-918a-1da3da56a459",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:26:52",
        dataUltimaAtualizacao: "2023-12-18T18:27:14",
        status: "RECUSADA",
        qtdAnimais: 25,
      },
      {
        idBlockchain: "e0130d89-b68f-423b-ad3d-a429637fb5a3",
        documentoProprietarioDestino: "18293459000196",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Guidara Indústria e Comércio de Alimentos Eireli",
          documento: "18293459000196",
        },
        dataCriacao: "2023-12-18T18:27:49",
        dataUltimaAtualizacao: "2023-12-18T18:28:02",
        status: "EFETIVADA",
        qtdAnimais: 25,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [
      {
        dtCriacao: "2023-12-18T14:16:17",
        dtAlteracao: "2023-12-18T14:16:17",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "d552bb29-65f0-488a-961c-baa18639c9e0",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipoExame: "ULTRASSOM_CARCACA",
        dtExame: "2023-09-04",
        vrResultado: "",
        vrResultadoAol: "81.58",
        vrResultadoEgs: "5.18",
        vrResultadoEgg: "0.00",
        vrResultadoMar: "4.04",
        vrResultadoRatio: "0.51",
        qtDiasPrenhe: null,
        icApresentouCio: null,
        idExameBlockchain: "7b3dbb31-a755-4b70-bab3-8ec95d681190",
      },
    ],
    medicamentosAplicados: [
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "b729f1ce-b31d-44cc-93fe-8410d083a202",
        idOriginalMedicamentoBlockchain: "03a88948-7528-45c1-873a-b8d6012f9c15",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        dtMedicacao: "2023-06-27",
        unidadeMedida: "UNIDADE",
        quantidade: 2,
        idMedicamentoBlockchain: "ace3da09-1efa-4a04-9f72-deb8a53499d6",
        medicamento: {
          idBlockchain: "03a88948-7528-45c1-873a-b8d6012f9c15",
          nome: "Vacina Bovi-Shield",
          categoria: "FARMACIA",
          subCategoria: "VACINA",
          fabricante: "Zoetis",
          unidade: "UNIDADE",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "df8e686d-76a0-4a2c-9b16-785bce0226ec",
        idOriginalMedicamentoBlockchain: "ba97487b-264d-4640-887a-1740557e1093",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        dtMedicacao: "2023-06-01",
        unidadeMedida: "MILILITRO",
        quantidade: 5,
        idMedicamentoBlockchain: "65cce9f2-79de-4b9f-b9dd-df02c0e53dc7",
        medicamento: {
          idBlockchain: "ba97487b-264d-4640-887a-1740557e1093",
          nome: "Iver-vet 3.5 injetável 1000 mL",
          categoria: "FARMACIA",
          subCategoria: "VERMIFUGO",
          fabricante: "Biovet",
          unidade: "LITRO",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
    ],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:16:02",
      dtAlteracao: "2023-12-18T14:16:02",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      fazenda: {
        nome: "Fazenda União do Brasil ",
        dataEntrada: "2023-06-27",
        dataSaida: "2023-11-07",
        latitude: "-23.68423843",
        longitude: "-48.59685500",
      },
      subdivisao: {
        nome: "Confinamento",
        dataEntrada: "2023-06-27",
        dataSaida: "2023-11-07",
      },
      idSistemaGestao: "5015338",
      idLocalizacaoBlockchain: "715afa63-1e0e-4186-b244-62f7fff7493f",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Fazenda União do Brasil ",
          dataEntrada: "2023-06-27",
          dataSaida: "2023-11-07",
          latitude: "-23.68423843",
          longitude: "-48.59685500",
        },
        subdivisao: {
          nome: "Confinamento",
          dataEntrada: "2023-06-27",
          dataSaida: "2023-11-07",
        },
        idSistemaGestao: "5015338",
        idLocalizacaoBlockchain: "715afa63-1e0e-4186-b244-62f7fff7493f",
      },
    ],
    loteHistorico: [],
    idResponsavel: "fdd249be-f2a0-402a-9213-c40dff9f7c9f",
    nomeResponsavel: "BARTIRA AGROPECUARIA S/A",
    frigorifico: {
      nome: "Guidara Industria",
      maturidade: "2 dentes",
      acabamento: "Uniforme",
      pesoBanda1: "122.25",
      pesoBanda2: "122.25",
      obs1: "",
      obs2: "",
      dtMorte: "2023-11-08T00:00:00",
      porcentagemCarcaca: "54.10",
      dtCriacao: "2023-12-18T18:49:43",
      dtAlteracao: "2023-12-18T18:49:43",
    },
  },
  "1b798276-dfc3-417b-9245-3f83dc1ee2be": {
    idLoteSync: "ec1f85a1-7f04-468d-ac28-2bfdf17d33b5",
    idBlockchain: "1b798276-dfc3-417b-9245-3f83dc1ee2be",
    docType: "ANIMAL",
    idSistemaGestao: "1f402c32-9549-40cb-a599-b2eb168f8d0c",
    idSisbov: "105350162278507",
    idICar: "982000444277425",
    nome: "",
    dataNascimento: "2020-10-18",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "982000444277425",
    idAnimalFazenda: "F 008044253",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Santa Gertrudis", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:15:38",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      idSistemaGestao: "1f402c32-9549-40cb-a599-b2eb168f8d0c",
      idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
      nomeResponsavel: "Fazenda União do Brasil",
      latitude: "-23.6825667",
      longitude: "-48.5986721",
      tipo: "Saída",
      peso: 532,
      dtPesagem: "2023-05-11T11:31:50",
      idPesagemBlockchain: "999e0ea3-580c-47ac-821a-70d1d7ca98c0",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "1f402c32-9549-40cb-a599-b2eb168f8d0c",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Nascimento",
        peso: 37,
        dtPesagem: "2020-10-18T00:00:00",
        idPesagemBlockchain: "b68ac53a-7c16-42df-81b2-b6d45b7c3672",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "1f402c32-9549-40cb-a599-b2eb168f8d0c",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 136,
        dtPesagem: "2021-04-30T19:20:47",
        idPesagemBlockchain: "6e13876d-89c3-463c-9b2e-430727b29811",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "1f402c32-9549-40cb-a599-b2eb168f8d0c",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 136,
        dtPesagem: "2021-05-19T17:27:49",
        idPesagemBlockchain: "6d2129f6-cad0-410b-a149-9993417d02f9",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "1f402c32-9549-40cb-a599-b2eb168f8d0c",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 305,
        dtPesagem: "2022-10-04T07:40:54",
        idPesagemBlockchain: "ae64bbfa-da86-4218-b01a-5713ae82413a",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "1f402c32-9549-40cb-a599-b2eb168f8d0c",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 416,
        dtPesagem: "2023-02-17T13:41:34",
        idPesagemBlockchain: "6dc9b790-ce0a-48b0-b9ce-ddbf4118c92b",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "1f402c32-9549-40cb-a599-b2eb168f8d0c",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Saída",
        peso: 532,
        dtPesagem: "2023-05-11T11:31:50",
        idPesagemBlockchain: "999e0ea3-580c-47ac-821a-70d1d7ca98c0",
      },
    ],
    produtorAtual: {
      idBlockchain: "25bc0b49-7cf7-4519-b70c-3001a1e4bfed",
      nome: "Fazenda União do Brasil",
      documento: "08090246000103",
    },
    status: "ABATIDO",
    dataCriacao: "2023-12-18T13:22:59",
    dataUltimaAtualizacao: "2023-12-18T18:54:53",
    vendaAtual: {
      idBlockchain: "0401071b-4fe8-4b0e-ba81-d94b5ab0b970",
      documentoProprietarioDestino: "30269671000113",
      tipo: "INDUSTRIA",
      proprietarioOrigem: {
        nome: "Fazenda União do Brasil",
        documento: "08090246000103",
      },
      proprietarioDestino: {
        nome: "Frigorífico Red Beef LTDA",
        documento: "30269671000113",
      },
      dataCriacao: "2023-12-18T18:33:01",
      dataUltimaAtualizacao: "2023-12-18T18:33:19",
      status: "EFETIVADA",
      qtdAnimais: 29,
    },
    vendaHistorico: [
      {
        idBlockchain: "eb66af4e-769b-4269-9378-dba27a1c0947",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:21:39",
        dataUltimaAtualizacao: "2023-12-18T18:24:08",
        status: "RECUSADA",
        qtdAnimais: 29,
      },
      {
        idBlockchain: "0401071b-4fe8-4b0e-ba81-d94b5ab0b970",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:33:01",
        dataUltimaAtualizacao: "2023-12-18T18:33:19",
        status: "EFETIVADA",
        qtdAnimais: 29,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [
      {
        dtCriacao: "2023-12-18T14:16:17",
        dtAlteracao: "2023-12-18T14:16:17",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "irancho-mvp",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipoExame: "ULTRASSOM_CARCACA",
        dtExame: "2023-05-11T11:31:50",
        vrResultado: "",
        vrResultadoAol: "68.73",
        vrResultadoEgs: "5.65",
        vrResultadoEgg: "0",
        vrResultadoMar: "5.38",
        vrResultadoRatio: "0",
        qtDiasPrenhe: null,
        icApresentouCio: null,
        idExameBlockchain: "2be7ca2c-6b7b-4639-ae14-483201b927e4",
      },
    ],
    medicamentosAplicados: [
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "irancho-mvp",
        idOriginalMedicamentoBlockchain: "7838ec4f-23ec-49fc-87b1-3f6fdcc0ba70",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        dtMedicacao: "2021-05-19T14:27:49",
        unidadeMedida: "MILILITRO",
        quantidade: 2,
        idMedicamentoBlockchain: "48b25c19-6993-4a6a-9662-25df94520ec1",
        medicamento: {
          idBlockchain: "7838ec4f-23ec-49fc-87b1-3f6fdcc0ba70",
          nome: "Aftosa ourovac 50 doses ",
          categoria: "FARMACIA",
          subCategoria: "VACINA",
          fabricante: "Ouro fino",
          unidade: "MILILITRO",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "irancho-mvp",
        idOriginalMedicamentoBlockchain: "a98e882b-a392-4bfd-bb22-057e47b66cad",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        dtMedicacao: "2021-05-19T14:27:49",
        unidadeMedida: "MILILITRO",
        quantidade: 2.72,
        idMedicamentoBlockchain: "ec531b4a-7977-4eff-9c40-7ea0d10602a7",
        medicamento: {
          idBlockchain: "a98e882b-a392-4bfd-bb22-057e47b66cad",
          nome: "Iver-vet ADE 1000 mL",
          categoria: "FARMACIA",
          subCategoria: "VERMIFUGO",
          fabricante: "Biovet",
          unidade: "MILILITRO",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
    ],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:16:02",
      dtAlteracao: "2023-12-18T14:16:02",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      fazenda: {
        nome: "Fazenda União do Brasil ",
        dataEntrada: "2022-10-04T00:00:00",
        dataSaida: "2023-05-11T00:00:00",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
      },
      subdivisao: {
        nome: "Confinamento",
        dataEntrada: "2022-10-04T00:00:00",
        dataSaida: "2023-05-11T00:00:00",
      },
      idSistemaGestao: "1f402c32-9549-40cb-a599-b2eb168f8d0c",
      idLocalizacaoBlockchain: "aeb5499d-4767-4078-8abd-a355d58788d1",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Vale do Sol ",
          dataEntrada: "2021-06-16T00:00:00",
          dataSaida: "2022-10-04T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "Compra desmama",
          dataEntrada: "2021-06-16T00:00:00",
          dataSaida: "2022-10-04T00:00:00",
        },
        idSistemaGestao: "1f402c32-9549-40cb-a599-b2eb168f8d0c",
        idLocalizacaoBlockchain: "c850ff5f-7e7d-4e08-bcc9-b94683c850a9",
      },
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Fazenda União do Brasil ",
          dataEntrada: "2022-10-04T00:00:00",
          dataSaida: "2023-05-11T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "Confinamento",
          dataEntrada: "2022-10-04T00:00:00",
          dataSaida: "2023-05-11T00:00:00",
        },
        idSistemaGestao: "1f402c32-9549-40cb-a599-b2eb168f8d0c",
        idLocalizacaoBlockchain: "aeb5499d-4767-4078-8abd-a355d58788d1",
      },
    ],
    loteHistorico: [],
    idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
    nomeResponsavel: "Fazenda União do Brasil",
    frigorifico: {
      nome: "Frigorífico Red Beef LTDA",
      maturidade: "2 dentes",
      acabamento: "Uniforme",
      pesoBanda1: "142.75 Kg",
      pesoBanda2: "142.75 Kg",
      obs1: "",
      obs2: "",
      dtMorte: "2023-05-11",
      porcentagemCarcaca: "54%",
      dtCriacao: "2023-12-18T18:54:53",
      dtAlteracao: "2023-12-18T18:54:53",
    },
  },
  "1bf4a789-80cc-494f-88d1-c180646fdb80": {
    idLoteSync: "ec1f85a1-7f04-468d-ac28-2bfdf17d33b5",
    idBlockchain: "1bf4a789-80cc-494f-88d1-c180646fdb80",
    docType: "ANIMAL",
    idSistemaGestao: "ba2375c3-0117-453b-a581-2123027edccc",
    idSisbov: "105350162261418",
    idICar: "982000444277562",
    nome: "",
    dataNascimento: "2020-01-11",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "982000444277562",
    idAnimalFazenda: "226142",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Santa Gertrudis", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:15:38",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      idSistemaGestao: "ba2375c3-0117-453b-a581-2123027edccc",
      idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
      nomeResponsavel: "Fazenda União do Brasil",
      latitude: "-23.6825667",
      longitude: "-48.5986721",
      tipo: "Pesagem Simples",
      peso: 546,
      dtPesagem: "2023-02-17T13:42:07",
      idPesagemBlockchain: "f9fb6ca5-1428-4f21-a69c-d62a97ab5d11",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "ba2375c3-0117-453b-a581-2123027edccc",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 546,
        dtPesagem: "2023-02-17T13:42:07",
        idPesagemBlockchain: "f9fb6ca5-1428-4f21-a69c-d62a97ab5d11",
      },
    ],
    produtorAtual: {
      idBlockchain: "25bc0b49-7cf7-4519-b70c-3001a1e4bfed",
      nome: "Fazenda União do Brasil",
      documento: "08090246000103",
    },
    status: "ABATIDO",
    dataCriacao: "2023-12-18T13:22:59",
    dataUltimaAtualizacao: "2023-12-18T18:58:04",
    vendaAtual: {
      idBlockchain: "0401071b-4fe8-4b0e-ba81-d94b5ab0b970",
      documentoProprietarioDestino: "30269671000113",
      tipo: "INDUSTRIA",
      proprietarioOrigem: {
        nome: "Fazenda União do Brasil",
        documento: "08090246000103",
      },
      proprietarioDestino: {
        nome: "Frigorífico Red Beef LTDA",
        documento: "30269671000113",
      },
      dataCriacao: "2023-12-18T18:33:01",
      dataUltimaAtualizacao: "2023-12-18T18:33:19",
      status: "EFETIVADA",
      qtdAnimais: 29,
    },
    vendaHistorico: [
      {
        idBlockchain: "eb66af4e-769b-4269-9378-dba27a1c0947",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:21:39",
        dataUltimaAtualizacao: "2023-12-18T18:24:08",
        status: "RECUSADA",
        qtdAnimais: 29,
      },
      {
        idBlockchain: "0401071b-4fe8-4b0e-ba81-d94b5ab0b970",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:33:01",
        dataUltimaAtualizacao: "2023-12-18T18:33:19",
        status: "EFETIVADA",
        qtdAnimais: 29,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "irancho-mvp",
        idOriginalMedicamentoBlockchain: "3367926c-ceac-46be-8628-6774c88536a5",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        dtMedicacao: "2022-11-30T14:43:19",
        unidadeMedida: "MILILITRO",
        quantidade: 2,
        idMedicamentoBlockchain: "35993f4f-9f6c-49d5-b07f-ca7655608d07",
        medicamento: {
          idBlockchain: "3367926c-ceac-46be-8628-6774c88536a5",
          nome: "Aftosa ourovac 15 doses ",
          categoria: "FARMACIA",
          subCategoria: "VACINA",
          fabricante: "Ouro fino",
          unidade: "MILILITRO",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:30",
          dataUltimaAtualizacao: "2023-12-13T13:25:30",
        },
      },
    ],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:16:02",
      dtAlteracao: "2023-12-18T14:16:02",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      fazenda: {
        nome: "Vale do Sol ",
        dataEntrada: "2021-05-18T00:00:00",
        dataSaida: "2022-11-30T00:00:00",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
      },
      subdivisao: {
        nome: "Desmama",
        dataEntrada: "2021-05-18T00:00:00",
        dataSaida: "2022-11-30T00:00:00",
      },
      idSistemaGestao: "ba2375c3-0117-453b-a581-2123027edccc",
      idLocalizacaoBlockchain: "16d8d9fc-cb1e-40d9-8c4f-21d5e3d81e8f",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Vale do Sol ",
          dataEntrada: "2021-05-18T00:00:00",
          dataSaida: "2022-11-30T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "Desmama",
          dataEntrada: "2021-05-18T00:00:00",
          dataSaida: "2022-11-30T00:00:00",
        },
        idSistemaGestao: "ba2375c3-0117-453b-a581-2123027edccc",
        idLocalizacaoBlockchain: "16d8d9fc-cb1e-40d9-8c4f-21d5e3d81e8f",
      },
    ],
    loteHistorico: [],
    idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
    nomeResponsavel: "Fazenda União do Brasil",
    frigorifico: {
      nome: "Frigorífico Red Beef LTDA",
      maturidade: "50%",
      acabamento: "",
      pesoBanda1: "",
      pesoBanda2: "",
      obs1: "",
      obs2: "",
      dtMorte: "2023-05-11",
      porcentagemCarcaca: "54.00",
      dtCriacao: "2023-12-18T18:58:04",
      dtAlteracao: "2023-12-18T18:58:04",
    },
  },
  "274f75a5-ba08-4f5e-8695-bcacc7b49278": {
    idLoteSync: "77646ba3-1936-4db0-9d95-c46547c7d1ce",
    idBlockchain: "274f75a5-ba08-4f5e-8695-bcacc7b49278",
    docType: "ANIMAL",
    idSistemaGestao: "irancho-mvpteste2",
    idSisbov: "0123",
    idICar: "",
    nome: "boi teste lote2",
    dataNascimento: "2024-01-01",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "0123",
    idAnimalFazenda: "boi1",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [
      {
        dtCriacao: "2024-01-16T13:16:31",
        dtAlteracao: "2024-01-16T13:27:44",
        deletado: true,
        editado: true,
        idLoteSync: "77646ba3-1936-4db0-9d95-c46547c7d1ce",
        idSistemaGestao: "irancho-mvpteste2   ",
        idResponsavel: "09af30c7-3ae2-4016-8659-0da0fb248818",
        nomeResponsavel: "Natalino Cavalli Junior",
        latitude: "",
        longitude: "",
        tipo: "PESO 2",
        peso: 250,
        dtPesagem: "2024-01-12T17:48:04",
        idPesagemBlockchain: "4dd43194-2c99-4ecd-af5e-83afbfaedc11",
      },
    ],
    produtorAtual: {
      idBlockchain: "25bc0b49-7cf7-4519-b70c-3001a1e4bfed",
      nome: "Fazenda União do Brasil",
      documento: "08090246000103",
    },
    status: "VIVO",
    dataCriacao: "2024-01-16T13:15:15",
    dataUltimaAtualizacao: "2024-01-16T16:07:05",
    vendaAtual: {
      idBlockchain: "4f6abacd-f1d4-4bc1-97db-583db863a934",
      documentoProprietarioDestino: "123456789",
      tipo: "INDUSTRIA",
      proprietarioOrigem: {
        nome: "Fazenda União do Brasil",
        documento: "08090246000103",
      },
      proprietarioDestino: null,
      dataCriacao: "2024-01-16T16:07:05",
      dataUltimaAtualizacao: "2024-01-16T16:07:05",
      status: "NAO_CONFIRMADA",
      qtdAnimais: 1,
    },
    vendaHistorico: [],
    reproducoes: [
      {
        dtCriacao: "2024-01-16T15:34:36",
        dtAlteracao: "2024-01-16T15:35:38",
        deletado: true,
        editado: true,
        idLoteSync: "77646ba3-1936-4db0-9d95-c46547c7d1ce",
        idSistemaGestao: "1231321",
        idResponsavel: "111111",
        nomeResponsavel: "Mauricio",
        latitude: "",
        longitude: "",
        tipo: "MONTA_NATURAL",
        descricao: "Monta dificultosa devido problemas na vaca 22222",
        dtReproducao: "2021-01-01",
        dtFimReproducao: "2021-01-02",
        idReproducaoBlockchain: "e2a03805-f593-4350-a032-45e369854d75",
      },
    ],
    acoes: [
      {
        dtCriacao: "2024-01-16T13:41:40",
        dtAlteracao: "2024-01-16T13:48:16",
        deletado: true,
        editado: true,
        idLoteSync: "77646ba3-1936-4db0-9d95-c46547c7d1ce",
        idSistemaGestao: "irancho-mvpteste2",
        idResponsavel: "09af30c7-3ae2-4016-8659-0da0fb248818",
        nomeResponsavel: "Natalino Cavalli Junior",
        dtAcao: "2024-01-04",
        nome: "Castração - atualizada",
        latitude: "",
        longitude: "",
        idAcaoBlockchain: "d783c62e-c3df-4801-9d64-5768ab06b42b",
      },
    ],
    exames: [],
    medicamentosAplicados: [
      {
        idLoteSync: "77646ba3-1936-4db0-9d95-c46547c7d1ce",
        dtCriacao: "2024-01-16T15:12:48",
        dtAlteracao: "2024-01-16T15:16:36",
        deletado: true,
        editado: true,
        idSistemaGestao: "0ce642e0-4543-4b44-b4cb-9dcf98e6171b",
        idOriginalMedicamentoBlockchain: "00beaebc-009e-4f4d-952c-6edcc5598fa3",
        idResponsavel: "11111",
        nomeResponsavel: "Gilberto",
        descricao: "Medicamento",
        latitude: "-44.2131231",
        longitude: "-11.2132333",
        dtMedicacao: "2021-11-10",
        idMedicamentoBlockchain: "2c62798f-40e9-44a5-ba3d-98fdffdbf936",
        medicamento: {
          idBlockchain: "00beaebc-009e-4f4d-952c-6edcc5598fa3",
          nome: "A-D-E Injetável Emulsificável",
          categoria: "FARMACIA",
          subCategoria: "VITAMINA",
          fabricante: "Zoetis",
          unidade: "MILILITRO",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
    ],
    nutricoesAplicadas: [
      {
        idLoteSync: "77646ba3-1936-4db0-9d95-c46547c7d1ce",
        dtCriacao: "2024-01-16T15:26:55",
        dtAlteracao: "2024-01-16T15:27:58",
        deletado: true,
        editado: true,
        idSistemaGestao: "23131",
        idOriginalNutricaoBlockchain: "00724573-9cbb-43d1-a555-3b3d438f38f7",
        idResponsavel: "11111",
        nomeResponsavel: "Gilberto",
        descricao: "Nutricao 222222222222xxx",
        latitude: "-44.2131231",
        longitude: "-11.2132333",
        dtNutricao: "2021-11-10",
        idNutricaoBlockchain: "fefbdef1-c784-4d97-aee4-7fa2c73cc314",
        nutricao: {
          idBlockchain: "00724573-9cbb-43d1-a555-3b3d438f38f7",
          nome: "Nutroeste Uréia 10",
          categoria: "NUTRICIONAL",
          subCategoria: "SUPLEMENTO_MINERAL_COM_UREIA",
          fabricante: "Nutroeste nutrição animal",
          unidade: "QUILOGRAMA",
          dataCriacao: "2023-12-13T13:26:37",
          dataUltimaAtualizacao: "2023-12-13T13:26:37",
        },
      },
    ],
    localizacaoAtual: {
      dtCriacao: "2024-01-16T14:29:38",
      dtAlteracao: "2024-01-16T14:29:38",
      idLoteSync: "77646ba3-1936-4db0-9d95-c46547c7d1ce",
      fazenda: {
        nome: "FAZENDA SAFEBEEF",
        dataEntrada: "2024-01-01",
        dataSaida: "",
        latitude: "-16.63736200",
        longitude: "-49.12905200",
      },
      subdivisao: { nome: "PIQ 08", dataEntrada: "2024-01-01", dataSaida: "" },
      idSistemaGestao: "irancho-mvpteste2",
      idLocalizacaoBlockchain: "c19edbdf-5156-4442-b4eb-9c957ff29c20",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2024-01-16T14:08:11",
        dtAlteracao: "2024-01-16T14:11:00",
        deletado: true,
        editado: true,
        idLoteSync: "77646ba3-1936-4db0-9d95-c46547c7d1ce",
        fazenda: { nome: "MyFazenda 1zzzzz12", dataEntrada: "2021-09-09" },
        subdivisao: { nome: "MySub zzzzz", dataEntrada: "2021-09-09" },
        idSistemaGestao: "irancho-mvpteste2",
        idLocalizacaoBlockchain: "5377398f-eb2e-400a-9444-a9876d9d002d",
      },
      {
        dtCriacao: "2024-01-16T14:29:38",
        dtAlteracao: "2024-01-16T14:29:38",
        idLoteSync: "77646ba3-1936-4db0-9d95-c46547c7d1ce",
        fazenda: {
          nome: "FAZENDA SAFEBEEF",
          dataEntrada: "2024-01-01",
          dataSaida: "",
          latitude: "-16.63736200",
          longitude: "-49.12905200",
        },
        subdivisao: {
          nome: "PIQ 08",
          dataEntrada: "2024-01-01",
          dataSaida: "",
        },
        idSistemaGestao: "irancho-mvpteste2",
        idLocalizacaoBlockchain: "c19edbdf-5156-4442-b4eb-9c957ff29c20",
      },
    ],
    loteHistorico: [
      {
        dtCriacao: "2024-01-16T14:31:42",
        dtAlteracao: "2024-01-16T14:35:49",
        deletado: true,
        editado: true,
        idLoteSync: "77646ba3-1936-4db0-9d95-c46547c7d1ce",
        nome: "MyLote 3",
        dataEntrada: "2024-09-09",
        dataSaida: null,
        idSistemaGestao: null,
        idLoteBlockchain: "5669f3a7-df3c-4e3f-93df-63f68360cce6",
      },
    ],
    idResponsavel: "09af30c7-3ae2-4016-8659-0da0fb248818",
    nomeResponsavel: "Natalino Cavalli Junior",
    frigorifico: null,
  },
  "2b3c2bf4-f9f8-4d40-abc5-de144800738a": {
    idLoteSync: "ec1f85a1-7f04-468d-ac28-2bfdf17d33b5",
    idBlockchain: "2b3c2bf4-f9f8-4d40-abc5-de144800738a",
    docType: "ANIMAL",
    idSistemaGestao: "672fb3e3-bc53-4e54-9cf3-8b0fa4ff5af3",
    idSisbov: "",
    idICar: "982000444280519",
    nome: "",
    dataNascimento: "2022-04-02",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "982000444280519",
    idAnimalFazenda: "4280519",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Santa Gertrudis", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:15:38",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      idSistemaGestao: "00476217-04ac-4d37-801d-4d771ed03ed6",
      idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
      nomeResponsavel: "Fazenda União do Brasil",
      latitude: "",
      longitude: "",
      tipo: "Saída",
      peso: 532,
      dtPesagem: "2023-11-07",
      idPesagemBlockchain: "651d2547-628f-442f-845b-dfd9c9aa3da0",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "00f8e1ba-e4e0-4abe-be3d-0d14ae2acf62",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Entrada",
        peso: 286,
        dtPesagem: "2023-06-01",
        idPesagemBlockchain: "cf4e54af-83ae-4e49-9e22-7b8869167054",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "92ea7bdc-8b7c-4e12-8b69-3af12bda26c6",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 281,
        dtPesagem: "2023-06-27",
        idPesagemBlockchain: "554e6e49-500a-4768-a843-aab9b9e210ab",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "d9ad54ac-6f26-4e3d-9a03-54d1d74e8414",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 415,
        dtPesagem: "2023-09-04",
        idPesagemBlockchain: "0ba6c2ff-443a-47c5-ae92-8654548f624b",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "00476217-04ac-4d37-801d-4d771ed03ed6",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Saída",
        peso: 532,
        dtPesagem: "2023-11-07",
        idPesagemBlockchain: "651d2547-628f-442f-845b-dfd9c9aa3da0",
      },
    ],
    produtorAtual: {
      idBlockchain: "25bc0b49-7cf7-4519-b70c-3001a1e4bfed",
      nome: "Fazenda União do Brasil",
      documento: "08090246000103",
    },
    status: "ABATIDO",
    dataCriacao: "2023-12-18T13:22:59",
    dataUltimaAtualizacao: "2023-12-18T18:46:30",
    vendaAtual: {
      idBlockchain: "e0130d89-b68f-423b-ad3d-a429637fb5a3",
      documentoProprietarioDestino: "18293459000196",
      tipo: "INDUSTRIA",
      proprietarioOrigem: {
        nome: "Fazenda União do Brasil",
        documento: "08090246000103",
      },
      proprietarioDestino: {
        nome: "Guidara Indústria e Comércio de Alimentos Eireli",
        documento: "18293459000196",
      },
      dataCriacao: "2023-12-18T18:27:49",
      dataUltimaAtualizacao: "2023-12-18T18:28:02",
      status: "EFETIVADA",
      qtdAnimais: 25,
    },
    vendaHistorico: [
      {
        idBlockchain: "f10b303c-ab60-4d04-b8b0-0f217cda4abb",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:22:09",
        dataUltimaAtualizacao: "2023-12-18T18:24:42",
        status: "RECUSADA",
        qtdAnimais: 25,
      },
      {
        idBlockchain: "f1392d6d-fc67-43f4-918a-1da3da56a459",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:26:52",
        dataUltimaAtualizacao: "2023-12-18T18:27:14",
        status: "RECUSADA",
        qtdAnimais: 25,
      },
      {
        idBlockchain: "e0130d89-b68f-423b-ad3d-a429637fb5a3",
        documentoProprietarioDestino: "18293459000196",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Guidara Indústria e Comércio de Alimentos Eireli",
          documento: "18293459000196",
        },
        dataCriacao: "2023-12-18T18:27:49",
        dataUltimaAtualizacao: "2023-12-18T18:28:02",
        status: "EFETIVADA",
        qtdAnimais: 25,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [
      {
        dtCriacao: "2023-12-18T14:16:17",
        dtAlteracao: "2023-12-18T14:16:17",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "2b2e4e2a-cbed-487c-ac46-d4d2a8f80c23",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipoExame: "ULTRASSOM_CARCACA",
        dtExame: "2023-09-04",
        vrResultado: "",
        vrResultadoAol: "80.20",
        vrResultadoEgs: "4.71",
        vrResultadoEgg: "0.00",
        vrResultadoMar: "3.18",
        vrResultadoRatio: "0.48",
        qtDiasPrenhe: null,
        icApresentouCio: null,
        idExameBlockchain: "7c9e756d-a9a6-4294-af3b-66c7800a5354",
      },
    ],
    medicamentosAplicados: [
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "3687197a-137a-4b0b-97ff-0135290f9426",
        idOriginalMedicamentoBlockchain: "03a88948-7528-45c1-873a-b8d6012f9c15",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        dtMedicacao: "2023-06-27",
        unidadeMedida: "UNIDADE",
        quantidade: 2,
        idMedicamentoBlockchain: "9b576be4-e725-4a9c-a5a7-1b16ad3cb9fd",
        medicamento: {
          idBlockchain: "03a88948-7528-45c1-873a-b8d6012f9c15",
          nome: "Vacina Bovi-Shield",
          categoria: "FARMACIA",
          subCategoria: "VACINA",
          fabricante: "Zoetis",
          unidade: "UNIDADE",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "752c5b6c-be5c-4724-a71a-83d14694cc06",
        idOriginalMedicamentoBlockchain: "ba97487b-264d-4640-887a-1740557e1093",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        dtMedicacao: "2023-06-01",
        unidadeMedida: "MILILITRO",
        quantidade: 5,
        idMedicamentoBlockchain: "c1c06da2-e5a5-4857-9d41-aa755dc0e5d8",
        medicamento: {
          idBlockchain: "ba97487b-264d-4640-887a-1740557e1093",
          nome: "Iver-vet 3.5 injetável 1000 mL",
          categoria: "FARMACIA",
          subCategoria: "VERMIFUGO",
          fabricante: "Biovet",
          unidade: "LITRO",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
    ],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:16:02",
      dtAlteracao: "2023-12-18T14:16:02",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      fazenda: {
        nome: "Fazenda União do Brasil ",
        dataEntrada: "2023-06-27",
        dataSaida: "2023-11-07",
        latitude: "-23.68423843",
        longitude: "-48.59685500",
      },
      subdivisao: {
        nome: "Confinamento",
        dataEntrada: "2023-06-27",
        dataSaida: "2023-11-07",
      },
      idSistemaGestao: "5015360",
      idLocalizacaoBlockchain: "4edc03c3-ce73-4860-bed3-9bbe543502d0",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Fazenda União do Brasil ",
          dataEntrada: "2023-06-27",
          dataSaida: "2023-11-07",
          latitude: "-23.68423843",
          longitude: "-48.59685500",
        },
        subdivisao: {
          nome: "Confinamento",
          dataEntrada: "2023-06-27",
          dataSaida: "2023-11-07",
        },
        idSistemaGestao: "5015360",
        idLocalizacaoBlockchain: "4edc03c3-ce73-4860-bed3-9bbe543502d0",
      },
    ],
    loteHistorico: [],
    idResponsavel: "fdd249be-f2a0-402a-9213-c40dff9f7c9f",
    nomeResponsavel: "BARTIRA AGROPECUARIA S/A",
    frigorifico: {
      nome: "Guidara Industria",
      maturidade: "2 dentes",
      acabamento: "Uniforme",
      pesoBanda1: "146",
      pesoBanda2: "146",
      obs1: "",
      obs2: "",
      dtMorte: "2023-11-08T00:00:00",
      porcentagemCarcaca: "57.60",
      dtCriacao: "2023-12-18T18:46:30",
      dtAlteracao: "2023-12-18T18:46:30",
    },
  },
  "337f8c53-dc95-4d29-9cfb-aba5b1c90f1e": {
    idLoteSync: "ec1f85a1-7f04-468d-ac28-2bfdf17d33b5",
    idBlockchain: "337f8c53-dc95-4d29-9cfb-aba5b1c90f1e",
    docType: "ANIMAL",
    idSistemaGestao: "d7aa7e60-5e76-4927-984f-36bb3663795b",
    idSisbov: "",
    idICar: "982000444281123",
    nome: "",
    dataNascimento: "2021-12-30",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "982000444281123",
    idAnimalFazenda: "3211144",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Santa Gertrudis", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:15:38",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      idSistemaGestao: "d7aa7e60-5e76-4927-984f-36bb3663795b",
      idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
      nomeResponsavel: "Fazenda União do Brasil",
      latitude: "-23.6825667",
      longitude: "-48.5986721",
      tipo: "Saída",
      peso: 512,
      dtPesagem: "2023-05-11T11:31:50",
      idPesagemBlockchain: "fc534eb2-4470-485e-a51a-1a2da6260a77",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "d7aa7e60-5e76-4927-984f-36bb3663795b",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Nascimento",
        peso: 34,
        dtPesagem: "2021-12-30T03:00:00",
        idPesagemBlockchain: "a14bd602-0d0e-4050-8979-625a9a2eca31",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "d7aa7e60-5e76-4927-984f-36bb3663795b",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 196,
        dtPesagem: "2022-06-03T10:22:14",
        idPesagemBlockchain: "a860c5e4-7c9a-4597-96b2-908385970469",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "d7aa7e60-5e76-4927-984f-36bb3663795b",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Desmame",
        peso: 196,
        dtPesagem: "2022-06-06T19:07:37",
        idPesagemBlockchain: "7a15f03e-13e8-488b-bfae-012a20a22ce2",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "d7aa7e60-5e76-4927-984f-36bb3663795b",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 244,
        dtPesagem: "2022-10-04T07:40:54",
        idPesagemBlockchain: "d77e556c-9289-4be1-97f1-449a9521d873",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "d7aa7e60-5e76-4927-984f-36bb3663795b",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 407,
        dtPesagem: "2023-02-17T13:41:34",
        idPesagemBlockchain: "3030e246-5bb4-4bb8-a0ee-b402187bb02f",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "d7aa7e60-5e76-4927-984f-36bb3663795b",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Saída",
        peso: 512,
        dtPesagem: "2023-05-11T11:31:50",
        idPesagemBlockchain: "fc534eb2-4470-485e-a51a-1a2da6260a77",
      },
    ],
    produtorAtual: {
      idBlockchain: "25bc0b49-7cf7-4519-b70c-3001a1e4bfed",
      nome: "Fazenda União do Brasil",
      documento: "08090246000103",
    },
    status: "ABATIDO",
    dataCriacao: "2023-12-18T13:22:59",
    dataUltimaAtualizacao: "2023-12-18T18:55:16",
    vendaAtual: {
      idBlockchain: "0401071b-4fe8-4b0e-ba81-d94b5ab0b970",
      documentoProprietarioDestino: "30269671000113",
      tipo: "INDUSTRIA",
      proprietarioOrigem: {
        nome: "Fazenda União do Brasil",
        documento: "08090246000103",
      },
      proprietarioDestino: {
        nome: "Frigorífico Red Beef LTDA",
        documento: "30269671000113",
      },
      dataCriacao: "2023-12-18T18:33:01",
      dataUltimaAtualizacao: "2023-12-18T18:33:19",
      status: "EFETIVADA",
      qtdAnimais: 29,
    },
    vendaHistorico: [
      {
        idBlockchain: "eb66af4e-769b-4269-9378-dba27a1c0947",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:21:39",
        dataUltimaAtualizacao: "2023-12-18T18:24:08",
        status: "RECUSADA",
        qtdAnimais: 29,
      },
      {
        idBlockchain: "0401071b-4fe8-4b0e-ba81-d94b5ab0b970",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:33:01",
        dataUltimaAtualizacao: "2023-12-18T18:33:19",
        status: "EFETIVADA",
        qtdAnimais: 29,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [
      {
        dtCriacao: "2023-12-18T14:16:17",
        dtAlteracao: "2023-12-18T14:16:17",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "irancho-mvp",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipoExame: "ULTRASSOM_CARCACA",
        dtExame: "2023-05-11T11:31:50",
        vrResultado: "",
        vrResultadoAol: "73.57",
        vrResultadoEgs: "7.53",
        vrResultadoEgg: "0",
        vrResultadoMar: "3.78",
        vrResultadoRatio: "0",
        qtDiasPrenhe: null,
        icApresentouCio: null,
        idExameBlockchain: "a9a413a0-26e9-4bf2-8d0d-012c044d54bf",
      },
    ],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:16:02",
      dtAlteracao: "2023-12-18T14:16:02",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      fazenda: {
        nome: "Fazenda União do Brasil ",
        dataEntrada: "2022-10-04T00:00:00",
        dataSaida: "2023-05-11T00:00:00",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
      },
      subdivisao: {
        nome: "Confinamento",
        dataEntrada: "2022-10-04T00:00:00",
        dataSaida: "2023-05-11T00:00:00",
      },
      idSistemaGestao: "d7aa7e60-5e76-4927-984f-36bb3663795b",
      idLocalizacaoBlockchain: "97c11ead-773c-499b-bfe0-dbe41f1d1af8",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Vale do Sol ",
          dataEntrada: "2022-07-05T00:00:00",
          dataSaida: "2022-10-04T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "Desmama",
          dataEntrada: "2022-07-05T00:00:00",
          dataSaida: "2022-10-04T00:00:00",
        },
        idSistemaGestao: "d7aa7e60-5e76-4927-984f-36bb3663795b",
        idLocalizacaoBlockchain: "d6bc982c-3848-434c-82c1-e9399bc44ed3",
      },
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Fazenda União do Brasil ",
          dataEntrada: "2022-10-04T00:00:00",
          dataSaida: "2023-05-11T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "Confinamento",
          dataEntrada: "2022-10-04T00:00:00",
          dataSaida: "2023-05-11T00:00:00",
        },
        idSistemaGestao: "d7aa7e60-5e76-4927-984f-36bb3663795b",
        idLocalizacaoBlockchain: "97c11ead-773c-499b-bfe0-dbe41f1d1af8",
      },
    ],
    loteHistorico: [],
    idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
    nomeResponsavel: "Fazenda União do Brasil",
    frigorifico: {
      nome: "Frigorífico Red Beef LTDA",
      maturidade: "0 dentes",
      acabamento: "Uniforme",
      pesoBanda1: "141.00 Kg",
      pesoBanda2: "141.00 Kg",
      obs1: "",
      obs2: "",
      dtMorte: "2023-05-11",
      porcentagemCarcaca: "55%",
      dtCriacao: "2023-12-18T18:55:16",
      dtAlteracao: "2023-12-18T18:55:16",
    },
  },
  "34185eeb-e1d6-4206-808e-8f3b083eef3e": {
    idLoteSync: "ec1f85a1-7f04-468d-ac28-2bfdf17d33b5",
    idBlockchain: "34185eeb-e1d6-4206-808e-8f3b083eef3e",
    docType: "ANIMAL",
    idSistemaGestao: "22b19aae-4f90-46eb-a760-559d089705c5",
    idSisbov: "",
    idICar: "982000444283299",
    nome: "",
    dataNascimento: "2020-10-20",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "982000444283299",
    idAnimalFazenda: "2200206",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Santa Gertrudis", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:15:38",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      idSistemaGestao: "22b19aae-4f90-46eb-a760-559d089705c5",
      idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
      nomeResponsavel: "Fazenda União do Brasil",
      latitude: "-23.6825667",
      longitude: "-48.5986721",
      tipo: "Saída",
      peso: 584,
      dtPesagem: "2023-05-11T11:31:50",
      idPesagemBlockchain: "e787d59e-6c22-4deb-b399-26824ef01863",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "22b19aae-4f90-46eb-a760-559d089705c5",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Nascimento",
        peso: 32,
        dtPesagem: "2020-10-20T03:00:00",
        idPesagemBlockchain: "5e358c7e-1651-4c8e-81cd-b6ec8a6a6785",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "22b19aae-4f90-46eb-a760-559d089705c5",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Desmame",
        peso: 139,
        dtPesagem: "2021-07-23T17:55:44",
        idPesagemBlockchain: "ef1f2f55-fb41-408a-826a-fbd80b8cdaf3",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "22b19aae-4f90-46eb-a760-559d089705c5",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 304,
        dtPesagem: "2022-10-04T07:40:54",
        idPesagemBlockchain: "b29cd380-01dd-4994-9c88-5b3afcef4cbb",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "22b19aae-4f90-46eb-a760-559d089705c5",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 455,
        dtPesagem: "2023-02-17T13:41:34",
        idPesagemBlockchain: "2e523fda-fe4b-47e2-bc56-790d2118c4a7",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "22b19aae-4f90-46eb-a760-559d089705c5",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Saída",
        peso: 584,
        dtPesagem: "2023-05-11T11:31:50",
        idPesagemBlockchain: "e787d59e-6c22-4deb-b399-26824ef01863",
      },
    ],
    produtorAtual: {
      idBlockchain: "25bc0b49-7cf7-4519-b70c-3001a1e4bfed",
      nome: "Fazenda União do Brasil",
      documento: "08090246000103",
    },
    status: "ABATIDO",
    dataCriacao: "2023-12-18T13:22:59",
    dataUltimaAtualizacao: "2023-12-18T18:56:20",
    vendaAtual: {
      idBlockchain: "0401071b-4fe8-4b0e-ba81-d94b5ab0b970",
      documentoProprietarioDestino: "30269671000113",
      tipo: "INDUSTRIA",
      proprietarioOrigem: {
        nome: "Fazenda União do Brasil",
        documento: "08090246000103",
      },
      proprietarioDestino: {
        nome: "Frigorífico Red Beef LTDA",
        documento: "30269671000113",
      },
      dataCriacao: "2023-12-18T18:33:01",
      dataUltimaAtualizacao: "2023-12-18T18:33:19",
      status: "EFETIVADA",
      qtdAnimais: 29,
    },
    vendaHistorico: [
      {
        idBlockchain: "eb66af4e-769b-4269-9378-dba27a1c0947",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:21:39",
        dataUltimaAtualizacao: "2023-12-18T18:24:08",
        status: "RECUSADA",
        qtdAnimais: 29,
      },
      {
        idBlockchain: "0401071b-4fe8-4b0e-ba81-d94b5ab0b970",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:33:01",
        dataUltimaAtualizacao: "2023-12-18T18:33:19",
        status: "EFETIVADA",
        qtdAnimais: 29,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [
      {
        dtCriacao: "2023-12-18T14:16:17",
        dtAlteracao: "2023-12-18T14:16:17",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "irancho-mvp",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipoExame: "ULTRASSOM_CARCACA",
        dtExame: "2023-05-11T11:31:50",
        vrResultado: "",
        vrResultadoAol: "64.69",
        vrResultadoEgs: "6.12",
        vrResultadoEgg: "0",
        vrResultadoMar: "4.06",
        vrResultadoRatio: "0",
        qtDiasPrenhe: null,
        icApresentouCio: null,
        idExameBlockchain: "fc41f25b-10b9-468a-be2f-09ec4c2e94f7",
      },
    ],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:16:02",
      dtAlteracao: "2023-12-18T14:16:02",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      fazenda: {
        nome: "Fazenda União do Brasil ",
        dataEntrada: "2022-10-04T00:00:00",
        dataSaida: "2023-05-11T00:00:00",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
      },
      subdivisao: {
        nome: "Confinamento",
        dataEntrada: "2022-10-04T00:00:00",
        dataSaida: "2023-05-11T00:00:00",
      },
      idSistemaGestao: "22b19aae-4f90-46eb-a760-559d089705c5",
      idLocalizacaoBlockchain: "790c9b1d-15a3-4ee1-b35e-6568749b32b5",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Vale do Sol ",
          dataEntrada: "2021-07-23T00:00:00",
          dataSaida: "2022-10-04T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "Desmama",
          dataEntrada: "2021-07-23T00:00:00",
          dataSaida: "2022-10-04T00:00:00",
        },
        idSistemaGestao: "22b19aae-4f90-46eb-a760-559d089705c5",
        idLocalizacaoBlockchain: "86cdb635-3677-4677-9c02-01cd58d726a8",
      },
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Fazenda União do Brasil ",
          dataEntrada: "2022-10-04T00:00:00",
          dataSaida: "2023-05-11T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "Confinamento",
          dataEntrada: "2022-10-04T00:00:00",
          dataSaida: "2023-05-11T00:00:00",
        },
        idSistemaGestao: "22b19aae-4f90-46eb-a760-559d089705c5",
        idLocalizacaoBlockchain: "790c9b1d-15a3-4ee1-b35e-6568749b32b5",
      },
    ],
    loteHistorico: [],
    idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
    nomeResponsavel: "Fazenda União do Brasil",
    frigorifico: {
      nome: "Frigorífico Red Beef LTDA",
      maturidade: "2 dentes",
      acabamento: "Uniforme",
      pesoBanda1: "147.25 Kg",
      pesoBanda2: "147.25 Kg",
      obs1: "",
      obs2: "",
      dtMorte: "2023-05-11",
      porcentagemCarcaca: "50%",
      dtCriacao: "2023-12-18T18:56:20",
      dtAlteracao: "2023-12-18T18:56:20",
    },
  },
  "37fcada5-3a3f-4a54-bc5f-33cc7433a99b": {
    idLoteSync: "ec1f85a1-7f04-468d-ac28-2bfdf17d33b5",
    idBlockchain: "37fcada5-3a3f-4a54-bc5f-33cc7433a99b",
    docType: "ANIMAL",
    idSistemaGestao: "fe1392fd-2164-4ad1-ad43-df4876d51c48",
    idSisbov: "",
    idICar: "982000444280166",
    nome: "",
    dataNascimento: "2021-01-11",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "982000444280166",
    idAnimalFazenda: "3210085",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Santa Gertrudis", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:15:38",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      idSistemaGestao: "fe1392fd-2164-4ad1-ad43-df4876d51c48",
      idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
      nomeResponsavel: "Fazenda União do Brasil",
      latitude: "-23.6825667",
      longitude: "-48.5986721",
      tipo: "Saída",
      peso: 524,
      dtPesagem: "2023-05-11T11:31:50",
      idPesagemBlockchain: "811af322-40c9-4cf4-99e0-f70cb7815568",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "fe1392fd-2164-4ad1-ad43-df4876d51c48",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Nascimento",
        peso: 30,
        dtPesagem: "2021-01-11T03:00:00",
        idPesagemBlockchain: "e2494c69-b4a1-4daa-808a-983929726731",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "fe1392fd-2164-4ad1-ad43-df4876d51c48",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Desmame",
        peso: 131,
        dtPesagem: "2021-09-23T13:06:24",
        idPesagemBlockchain: "a5d68bc8-8f33-4617-a851-bc7ee8e1fdc8",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "fe1392fd-2164-4ad1-ad43-df4876d51c48",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 312,
        dtPesagem: "2022-10-04T07:40:54",
        idPesagemBlockchain: "d3d0e3fb-1b81-4db0-8f4d-0c68bcb15dd8",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "fe1392fd-2164-4ad1-ad43-df4876d51c48",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 443,
        dtPesagem: "2023-02-17T13:41:34",
        idPesagemBlockchain: "3f4e7726-5dc7-456e-915c-e00bbdf6595a",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "fe1392fd-2164-4ad1-ad43-df4876d51c48",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Saída",
        peso: 524,
        dtPesagem: "2023-05-11T11:31:50",
        idPesagemBlockchain: "811af322-40c9-4cf4-99e0-f70cb7815568",
      },
    ],
    produtorAtual: {
      idBlockchain: "25bc0b49-7cf7-4519-b70c-3001a1e4bfed",
      nome: "Fazenda União do Brasil",
      documento: "08090246000103",
    },
    status: "ABATIDO",
    dataCriacao: "2023-12-18T13:22:59",
    dataUltimaAtualizacao: "2023-12-18T18:54:40",
    vendaAtual: {
      idBlockchain: "0401071b-4fe8-4b0e-ba81-d94b5ab0b970",
      documentoProprietarioDestino: "30269671000113",
      tipo: "INDUSTRIA",
      proprietarioOrigem: {
        nome: "Fazenda União do Brasil",
        documento: "08090246000103",
      },
      proprietarioDestino: {
        nome: "Frigorífico Red Beef LTDA",
        documento: "30269671000113",
      },
      dataCriacao: "2023-12-18T18:33:01",
      dataUltimaAtualizacao: "2023-12-18T18:33:19",
      status: "EFETIVADA",
      qtdAnimais: 29,
    },
    vendaHistorico: [
      {
        idBlockchain: "eb66af4e-769b-4269-9378-dba27a1c0947",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:21:39",
        dataUltimaAtualizacao: "2023-12-18T18:24:08",
        status: "RECUSADA",
        qtdAnimais: 29,
      },
      {
        idBlockchain: "0401071b-4fe8-4b0e-ba81-d94b5ab0b970",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:33:01",
        dataUltimaAtualizacao: "2023-12-18T18:33:19",
        status: "EFETIVADA",
        qtdAnimais: 29,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [
      {
        dtCriacao: "2023-12-18T14:16:17",
        dtAlteracao: "2023-12-18T14:16:17",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "irancho-mvp",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipoExame: "ULTRASSOM_CARCACA",
        dtExame: "2023-05-11T11:31:50",
        vrResultado: "",
        vrResultadoAol: "89.14",
        vrResultadoEgs: "5.2",
        vrResultadoEgg: "0",
        vrResultadoMar: "4.21",
        vrResultadoRatio: "0",
        qtDiasPrenhe: null,
        icApresentouCio: null,
        idExameBlockchain: "4eb15957-b029-4f3b-9344-c143703941d5",
      },
    ],
    medicamentosAplicados: [
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "irancho-mvp",
        idOriginalMedicamentoBlockchain: "40aa0e8c-5ceb-4b79-bd44-253139e57a21",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        dtMedicacao: "2021-06-07T12:00:40",
        unidadeMedida: "MILILITRO",
        quantidade: 2,
        idMedicamentoBlockchain: "818329fa-2ae3-40c5-b7ab-0a5ab86c5e00",
        medicamento: {
          idBlockchain: "40aa0e8c-5ceb-4b79-bd44-253139e57a21",
          nome: "Aborvac Brucelose",
          categoria: "FARMACIA",
          subCategoria: "VACINA",
          fabricante: "Zoetis",
          unidade: "MILILITRO",
          sexo: "FEMEA",
          idadeMaxima: 8,
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
    ],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:16:02",
      dtAlteracao: "2023-12-18T14:16:02",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      fazenda: {
        nome: "Fazenda União do Brasil ",
        dataEntrada: "2022-10-04T00:00:00",
        dataSaida: "2023-05-11T00:00:00",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
      },
      subdivisao: {
        nome: "Confinamento",
        dataEntrada: "2022-10-04T00:00:00",
        dataSaida: "2023-05-11T00:00:00",
      },
      idSistemaGestao: "fe1392fd-2164-4ad1-ad43-df4876d51c48",
      idLocalizacaoBlockchain: "41d96c6f-18d8-4d00-beca-db1438afc501",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Fazenda União do Brasil ",
          dataEntrada: "2021-12-16T00:00:00",
          dataSaida: "2021-12-29T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "Confinamento",
          dataEntrada: "2021-12-16T00:00:00",
          dataSaida: "2021-12-29T00:00:00",
        },
        idSistemaGestao: "fe1392fd-2164-4ad1-ad43-df4876d51c48",
        idLocalizacaoBlockchain: "68f97fcf-637a-4a74-a353-96c3d0311dc4",
      },
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Vale do Sol ",
          dataEntrada: "2021-12-29T00:00:00",
          dataSaida: "2022-10-04T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "Desmama",
          dataEntrada: "2021-12-29T00:00:00",
          dataSaida: "2022-10-04T00:00:00",
        },
        idSistemaGestao: "fe1392fd-2164-4ad1-ad43-df4876d51c48",
        idLocalizacaoBlockchain: "ea70253a-2a5a-4eaf-a341-c49b3085065e",
      },
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Fazenda União do Brasil ",
          dataEntrada: "2022-10-04T00:00:00",
          dataSaida: "2023-05-11T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "Confinamento",
          dataEntrada: "2022-10-04T00:00:00",
          dataSaida: "2023-05-11T00:00:00",
        },
        idSistemaGestao: "fe1392fd-2164-4ad1-ad43-df4876d51c48",
        idLocalizacaoBlockchain: "41d96c6f-18d8-4d00-beca-db1438afc501",
      },
    ],
    loteHistorico: [],
    idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
    nomeResponsavel: "Fazenda União do Brasil",
    frigorifico: {
      nome: "Frigorífico Red Beef LTDA",
      maturidade: "2 dentes",
      acabamento: "Uniforme",
      pesoBanda1: "148.50 Kg",
      pesoBanda2: "148.50 Kg",
      obs1: "",
      obs2: "",
      dtMorte: "2023-05-11",
      porcentagemCarcaca: "57%",
      dtCriacao: "2023-12-18T18:54:40",
      dtAlteracao: "2023-12-18T18:54:40",
    },
  },
  "3d5d2d4e-09bc-4717-8211-9915d660e2ee": {
    idLoteSync: "ec1f85a1-7f04-468d-ac28-2bfdf17d33b5",
    idBlockchain: "3d5d2d4e-09bc-4717-8211-9915d660e2ee",
    docType: "ANIMAL",
    idSistemaGestao: "7021adf2-9230-48a9-8dbe-e79d39439e1b",
    idSisbov: "",
    idICar: "982000444280287",
    nome: "",
    dataNascimento: "2021-09-10",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "982000444280287",
    idAnimalFazenda: "3210613",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Santa Gertrudis", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:15:38",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      idSistemaGestao: "7021adf2-9230-48a9-8dbe-e79d39439e1b",
      idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
      nomeResponsavel: "Fazenda União do Brasil",
      latitude: "-23.6825667",
      longitude: "-48.5986721",
      tipo: "Saída",
      peso: 435,
      dtPesagem: "2023-05-11T11:31:50",
      idPesagemBlockchain: "55fde794-44f5-49c7-ad96-0d392816d52f",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "7021adf2-9230-48a9-8dbe-e79d39439e1b",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Nascimento",
        peso: 23,
        dtPesagem: "2021-09-10T03:00:00",
        idPesagemBlockchain: "34388a94-693e-4bd3-a238-c19ed43da523",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "7021adf2-9230-48a9-8dbe-e79d39439e1b",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Desmame",
        peso: 160,
        dtPesagem: "2022-06-06T19:07:37",
        idPesagemBlockchain: "19960031-18ac-4ee1-891d-534b637ecab4",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "7021adf2-9230-48a9-8dbe-e79d39439e1b",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 365,
        dtPesagem: "2023-02-17T13:42:07",
        idPesagemBlockchain: "e6810b52-3d39-4a28-965f-0daae433ca52",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "7021adf2-9230-48a9-8dbe-e79d39439e1b",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Saída",
        peso: 435,
        dtPesagem: "2023-05-11T11:31:50",
        idPesagemBlockchain: "55fde794-44f5-49c7-ad96-0d392816d52f",
      },
    ],
    produtorAtual: {
      idBlockchain: "25bc0b49-7cf7-4519-b70c-3001a1e4bfed",
      nome: "Fazenda União do Brasil",
      documento: "08090246000103",
    },
    status: "ABATIDO",
    dataCriacao: "2023-12-18T13:22:59",
    dataUltimaAtualizacao: "2023-12-18T18:57:43",
    vendaAtual: {
      idBlockchain: "0401071b-4fe8-4b0e-ba81-d94b5ab0b970",
      documentoProprietarioDestino: "30269671000113",
      tipo: "INDUSTRIA",
      proprietarioOrigem: {
        nome: "Fazenda União do Brasil",
        documento: "08090246000103",
      },
      proprietarioDestino: {
        nome: "Frigorífico Red Beef LTDA",
        documento: "30269671000113",
      },
      dataCriacao: "2023-12-18T18:33:01",
      dataUltimaAtualizacao: "2023-12-18T18:33:19",
      status: "EFETIVADA",
      qtdAnimais: 29,
    },
    vendaHistorico: [
      {
        idBlockchain: "eb66af4e-769b-4269-9378-dba27a1c0947",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:21:39",
        dataUltimaAtualizacao: "2023-12-18T18:24:08",
        status: "RECUSADA",
        qtdAnimais: 29,
      },
      {
        idBlockchain: "0401071b-4fe8-4b0e-ba81-d94b5ab0b970",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:33:01",
        dataUltimaAtualizacao: "2023-12-18T18:33:19",
        status: "EFETIVADA",
        qtdAnimais: 29,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [
      {
        dtCriacao: "2023-12-18T14:16:17",
        dtAlteracao: "2023-12-18T14:16:17",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "irancho-mvp",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipoExame: "ULTRASSOM_CARCACA",
        dtExame: "2023-05-11T11:31:50",
        vrResultado: "",
        vrResultadoAol: "62.17",
        vrResultadoEgs: "4.71",
        vrResultadoEgg: "0",
        vrResultadoMar: "3.6",
        vrResultadoRatio: "0",
        qtDiasPrenhe: null,
        icApresentouCio: null,
        idExameBlockchain: "a2a2f7c4-c4ef-40a9-bdaa-7939a7df18fe",
      },
    ],
    medicamentosAplicados: [
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "irancho-mvp",
        idOriginalMedicamentoBlockchain: "3367926c-ceac-46be-8628-6774c88536a5",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        dtMedicacao: "2022-11-30T14:43:19",
        unidadeMedida: "MILILITRO",
        quantidade: 2,
        idMedicamentoBlockchain: "97c3c70d-71f3-4fe4-bf6b-03af65c18667",
        medicamento: {
          idBlockchain: "3367926c-ceac-46be-8628-6774c88536a5",
          nome: "Aftosa ourovac 15 doses ",
          categoria: "FARMACIA",
          subCategoria: "VACINA",
          fabricante: "Ouro fino",
          unidade: "MILILITRO",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:30",
          dataUltimaAtualizacao: "2023-12-13T13:25:30",
        },
      },
    ],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:16:02",
      dtAlteracao: "2023-12-18T14:16:02",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      fazenda: {
        nome: "Fazenda União do Brasil ",
        dataEntrada: "2022-11-30T00:00:00",
        dataSaida: "2023-05-11T00:00:00",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
      },
      subdivisao: {
        nome: "Confinamento",
        dataEntrada: "2022-11-30T00:00:00",
        dataSaida: "2023-05-11T00:00:00",
      },
      idSistemaGestao: "7021adf2-9230-48a9-8dbe-e79d39439e1b",
      idLocalizacaoBlockchain: "f95ae8c6-959d-4c79-a294-ea4cfbc28b17",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Vale do Sol ",
          dataEntrada: "2022-07-05T00:00:00",
          dataSaida: "2022-11-30T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "Desmama",
          dataEntrada: "2022-07-05T00:00:00",
          dataSaida: "2022-11-30T00:00:00",
        },
        idSistemaGestao: "7021adf2-9230-48a9-8dbe-e79d39439e1b",
        idLocalizacaoBlockchain: "e2f63702-3831-4291-bae4-f13e19c3227f",
      },
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Fazenda União do Brasil ",
          dataEntrada: "2022-11-30T00:00:00",
          dataSaida: "2023-05-11T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "Confinamento",
          dataEntrada: "2022-11-30T00:00:00",
          dataSaida: "2023-05-11T00:00:00",
        },
        idSistemaGestao: "7021adf2-9230-48a9-8dbe-e79d39439e1b",
        idLocalizacaoBlockchain: "f95ae8c6-959d-4c79-a294-ea4cfbc28b17",
      },
    ],
    loteHistorico: [],
    idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
    nomeResponsavel: "Fazenda União do Brasil",
    frigorifico: {
      nome: "Frigorífico Red Beef LTDA",
      maturidade: "0 dentes",
      acabamento: "Uniforme",
      pesoBanda1: "117.25 Kg",
      pesoBanda2: "117.25 Kg",
      obs1: "",
      obs2: "",
      dtMorte: "2023-05-11",
      porcentagemCarcaca: "54%",
      dtCriacao: "2023-12-18T18:57:43",
      dtAlteracao: "2023-12-18T18:57:43",
    },
  },
  "3f21c72e-97d3-4415-a04d-223ac6f8f225": {
    idLoteSync: "ec1f85a1-7f04-468d-ac28-2bfdf17d33b5",
    idBlockchain: "3f21c72e-97d3-4415-a04d-223ac6f8f225",
    docType: "ANIMAL",
    idSistemaGestao: "cb6fe37c-f77f-11ea-9af0-4201ac122013",
    idSisbov: "105350155814423",
    idICar: "982000444275811",
    nome: "4158 UB",
    dataNascimento: "2020-04-04",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "982000444275811",
    idAnimalFazenda: "F 008044158",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Santa Gertrudis", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:15:38",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      idSistemaGestao: "cb6fe37c-f77f-11ea-9af0-4201ac122013",
      idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
      nomeResponsavel: "Fazenda União do Brasil",
      latitude: "-23.6825667",
      longitude: "-48.5986721",
      tipo: "Saída",
      peso: 526,
      dtPesagem: "2023-05-11T11:31:50",
      idPesagemBlockchain: "36f03043-9010-4ffd-9ba0-ea1f9e27a21c",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "cb6fe37c-f77f-11ea-9af0-4201ac122013",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Desmame",
        peso: 217,
        dtPesagem: "2021-10-27T10:04:10",
        idPesagemBlockchain: "c97dbd2b-999e-45d7-9bc0-e29deaf0e841",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "cb6fe37c-f77f-11ea-9af0-4201ac122013",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 100,
        dtPesagem: "2022-02-18T18:29:04",
        idPesagemBlockchain: "48ed914d-f4e5-4d29-9052-7a70081031a7",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "cb6fe37c-f77f-11ea-9af0-4201ac122013",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Entrada",
        peso: 268,
        dtPesagem: "2022-11-28T09:07:37",
        idPesagemBlockchain: "c6ab5edd-1f31-4a4c-abd1-07ccd637a7d6",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "cb6fe37c-f77f-11ea-9af0-4201ac122013",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 390,
        dtPesagem: "2023-02-17T13:42:07",
        idPesagemBlockchain: "f8e300e6-2b73-42fc-bab1-bd0250c2d279",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "cb6fe37c-f77f-11ea-9af0-4201ac122013",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Saída",
        peso: 526,
        dtPesagem: "2023-05-11T11:31:50",
        idPesagemBlockchain: "36f03043-9010-4ffd-9ba0-ea1f9e27a21c",
      },
    ],
    produtorAtual: {
      idBlockchain: "25bc0b49-7cf7-4519-b70c-3001a1e4bfed",
      nome: "Fazenda União do Brasil",
      documento: "08090246000103",
    },
    status: "ABATIDO",
    dataCriacao: "2023-12-18T13:22:59",
    dataUltimaAtualizacao: "2023-12-18T18:58:22",
    vendaAtual: {
      idBlockchain: "0401071b-4fe8-4b0e-ba81-d94b5ab0b970",
      documentoProprietarioDestino: "30269671000113",
      tipo: "INDUSTRIA",
      proprietarioOrigem: {
        nome: "Fazenda União do Brasil",
        documento: "08090246000103",
      },
      proprietarioDestino: {
        nome: "Frigorífico Red Beef LTDA",
        documento: "30269671000113",
      },
      dataCriacao: "2023-12-18T18:33:01",
      dataUltimaAtualizacao: "2023-12-18T18:33:19",
      status: "EFETIVADA",
      qtdAnimais: 29,
    },
    vendaHistorico: [
      {
        idBlockchain: "eb66af4e-769b-4269-9378-dba27a1c0947",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:21:39",
        dataUltimaAtualizacao: "2023-12-18T18:24:08",
        status: "RECUSADA",
        qtdAnimais: 29,
      },
      {
        idBlockchain: "0401071b-4fe8-4b0e-ba81-d94b5ab0b970",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:33:01",
        dataUltimaAtualizacao: "2023-12-18T18:33:19",
        status: "EFETIVADA",
        qtdAnimais: 29,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [
      {
        dtCriacao: "2023-12-18T14:16:17",
        dtAlteracao: "2023-12-18T14:16:17",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "irancho-mvp",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipoExame: "DIAGNOSTICO_GESTACAO",
        dtExame: "2021-10-27T07:04:10",
        vrResultado: "VAZIA",
        vrResultadoAol: "",
        vrResultadoEgs: "",
        vrResultadoEgg: "",
        vrResultadoMar: "",
        vrResultadoRatio: "",
        qtDiasPrenhe: null,
        icApresentouCio: null,
        idExameBlockchain: "37e2a68b-4831-4a01-8b5e-23a65d3ba6b7",
      },
      {
        dtCriacao: "2023-12-18T14:16:17",
        dtAlteracao: "2023-12-18T14:16:17",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "irancho-mvp",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipoExame: "ULTRASSOM_CARCACA",
        dtExame: "2023-05-11T11:31:50",
        vrResultado: "",
        vrResultadoAol: "58",
        vrResultadoEgs: "5.65",
        vrResultadoEgg: "0",
        vrResultadoMar: "3.59",
        vrResultadoRatio: "0",
        qtDiasPrenhe: null,
        icApresentouCio: null,
        idExameBlockchain: "4c546a94-036c-494f-9a43-02b240580c99",
      },
    ],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:16:02",
      dtAlteracao: "2023-12-18T14:16:02",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      fazenda: {
        nome: "Fazenda União do Brasil ",
        dataEntrada: "2022-11-28T00:00:00",
        dataSaida: "2023-05-11T00:00:00",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
      },
      subdivisao: {
        nome: "Confinamento",
        dataEntrada: "2022-11-28T00:00:00",
        dataSaida: "2023-05-11T00:00:00",
      },
      idSistemaGestao: "cb6fe37c-f77f-11ea-9af0-4201ac122013",
      idLocalizacaoBlockchain: "98840afc-4118-4cbb-b058-7243d631803b",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Vale do Sol ",
          dataEntrada: "2021-05-18T00:00:00",
          dataSaida: "2021-10-27T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "Desmama",
          dataEntrada: "2021-05-18T00:00:00",
          dataSaida: "2021-10-27T00:00:00",
        },
        idSistemaGestao: "cb6fe37c-f77f-11ea-9af0-4201ac122013",
        idLocalizacaoBlockchain: "be5bdef4-d93d-4e12-b966-6da41275da41",
      },
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Fazenda União do Brasil ",
          dataEntrada: "2021-10-27T00:00:00",
          dataSaida: "2022-02-18T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "Confinamento",
          dataEntrada: "2021-10-27T00:00:00",
          dataSaida: "2022-02-18T00:00:00",
        },
        idSistemaGestao: "cb6fe37c-f77f-11ea-9af0-4201ac122013",
        idLocalizacaoBlockchain: "1786e610-6fae-4465-87fb-6146550e0e0a",
      },
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Fazenda União do Brasil ",
          dataEntrada: "2022-02-18T00:00:00",
          dataSaida: "2022-11-28T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "EUCALIPTO",
          dataEntrada: "2022-02-18T00:00:00",
          dataSaida: "2022-11-28T00:00:00",
        },
        idSistemaGestao: "cb6fe37c-f77f-11ea-9af0-4201ac122013",
        idLocalizacaoBlockchain: "81193638-3501-4ac8-9322-2efae19ad75e",
      },
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Fazenda União do Brasil ",
          dataEntrada: "2022-11-28T00:00:00",
          dataSaida: "2023-05-11T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "Confinamento",
          dataEntrada: "2022-11-28T00:00:00",
          dataSaida: "2023-05-11T00:00:00",
        },
        idSistemaGestao: "cb6fe37c-f77f-11ea-9af0-4201ac122013",
        idLocalizacaoBlockchain: "98840afc-4118-4cbb-b058-7243d631803b",
      },
    ],
    loteHistorico: [],
    idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
    nomeResponsavel: "Fazenda União do Brasil",
    frigorifico: {
      nome: "Frigorífico Red Beef LTDA",
      maturidade: "6 dentes",
      acabamento: "Mediano",
      pesoBanda1: "131.50 Kg",
      pesoBanda2: "131.50 Kg",
      obs1: "",
      obs2: "",
      dtMorte: "2023-05-11",
      porcentagemCarcaca: "50%",
      dtCriacao: "2023-12-18T18:58:22",
      dtAlteracao: "2023-12-18T18:58:22",
    },
  },
  "3f75e6a9-72f6-47ed-bdd7-529314e223b7": {
    idLoteSync: "ec1f85a1-7f04-468d-ac28-2bfdf17d33b5",
    idBlockchain: "3f75e6a9-72f6-47ed-bdd7-529314e223b7",
    docType: "ANIMAL",
    idSistemaGestao: "7b223981-63b6-4625-b40c-30d1921b682e",
    idSisbov: "",
    idICar: "982000444277980",
    nome: "",
    dataNascimento: "2022-04-02",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "982000444277980",
    idAnimalFazenda: "4277980",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Santa Gertrudis", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:15:38",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      idSistemaGestao: "e5470c85-7068-4ef0-bd29-f117d6468cbb",
      idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
      nomeResponsavel: "Fazenda União do Brasil",
      latitude: "",
      longitude: "",
      tipo: "Saída",
      peso: 497,
      dtPesagem: "2023-11-07",
      idPesagemBlockchain: "453453f2-fafa-4850-ab7f-481cac6d7ef8",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "6f39161d-e63f-4837-b85b-5f5f126d348b",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Entrada",
        peso: 272,
        dtPesagem: "2023-06-01",
        idPesagemBlockchain: "35a98187-fb7c-46f6-9e8e-20812fd4554e",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "240bb0c2-8ae2-40d1-a9ff-fc115320ed18",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 269,
        dtPesagem: "2023-06-27",
        idPesagemBlockchain: "7629772b-4260-44ce-aae0-47bcd6d050b2",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "47470954-345c-4f1b-a484-7590b2c52efb",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 393,
        dtPesagem: "2023-09-04",
        idPesagemBlockchain: "2169b57c-1953-4cce-aeda-a2478c573fde",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "e5470c85-7068-4ef0-bd29-f117d6468cbb",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Saída",
        peso: 497,
        dtPesagem: "2023-11-07",
        idPesagemBlockchain: "453453f2-fafa-4850-ab7f-481cac6d7ef8",
      },
    ],
    produtorAtual: {
      idBlockchain: "25bc0b49-7cf7-4519-b70c-3001a1e4bfed",
      nome: "Fazenda União do Brasil",
      documento: "08090246000103",
    },
    status: "ABATIDO",
    dataCriacao: "2023-12-18T13:22:59",
    dataUltimaAtualizacao: "2023-12-18T18:48:41",
    vendaAtual: {
      idBlockchain: "e0130d89-b68f-423b-ad3d-a429637fb5a3",
      documentoProprietarioDestino: "18293459000196",
      tipo: "INDUSTRIA",
      proprietarioOrigem: {
        nome: "Fazenda União do Brasil",
        documento: "08090246000103",
      },
      proprietarioDestino: {
        nome: "Guidara Indústria e Comércio de Alimentos Eireli",
        documento: "18293459000196",
      },
      dataCriacao: "2023-12-18T18:27:49",
      dataUltimaAtualizacao: "2023-12-18T18:28:02",
      status: "EFETIVADA",
      qtdAnimais: 25,
    },
    vendaHistorico: [
      {
        idBlockchain: "f10b303c-ab60-4d04-b8b0-0f217cda4abb",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:22:09",
        dataUltimaAtualizacao: "2023-12-18T18:24:42",
        status: "RECUSADA",
        qtdAnimais: 25,
      },
      {
        idBlockchain: "f1392d6d-fc67-43f4-918a-1da3da56a459",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:26:52",
        dataUltimaAtualizacao: "2023-12-18T18:27:14",
        status: "RECUSADA",
        qtdAnimais: 25,
      },
      {
        idBlockchain: "e0130d89-b68f-423b-ad3d-a429637fb5a3",
        documentoProprietarioDestino: "18293459000196",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Guidara Indústria e Comércio de Alimentos Eireli",
          documento: "18293459000196",
        },
        dataCriacao: "2023-12-18T18:27:49",
        dataUltimaAtualizacao: "2023-12-18T18:28:02",
        status: "EFETIVADA",
        qtdAnimais: 25,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [
      {
        dtCriacao: "2023-12-18T14:16:17",
        dtAlteracao: "2023-12-18T14:16:17",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "cabb035f-d9c4-4226-8c92-8c9ef10d925b",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipoExame: "ULTRASSOM_CARCACA",
        dtExame: "2023-09-04",
        vrResultado: "",
        vrResultadoAol: "74.85",
        vrResultadoEgs: "4.24",
        vrResultadoEgg: "0.00",
        vrResultadoMar: "3.63",
        vrResultadoRatio: "0.50",
        qtDiasPrenhe: null,
        icApresentouCio: null,
        idExameBlockchain: "4cda8f9e-4167-497d-b24e-f35191f31d4b",
      },
    ],
    medicamentosAplicados: [
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "45ac3650-0186-46b0-80e8-e3ade58cf56b",
        idOriginalMedicamentoBlockchain: "03a88948-7528-45c1-873a-b8d6012f9c15",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        dtMedicacao: "2023-06-27",
        unidadeMedida: "UNIDADE",
        quantidade: 2,
        idMedicamentoBlockchain: "7479448f-64fd-49b5-a2d8-ca220c2d48d2",
        medicamento: {
          idBlockchain: "03a88948-7528-45c1-873a-b8d6012f9c15",
          nome: "Vacina Bovi-Shield",
          categoria: "FARMACIA",
          subCategoria: "VACINA",
          fabricante: "Zoetis",
          unidade: "UNIDADE",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "a25d0e41-f5a9-4209-9867-0702da90e4d6",
        idOriginalMedicamentoBlockchain: "ba97487b-264d-4640-887a-1740557e1093",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        dtMedicacao: "2023-06-01",
        unidadeMedida: "MILILITRO",
        quantidade: 5,
        idMedicamentoBlockchain: "b18c9c7d-be48-4ea2-a4da-15496610e996",
        medicamento: {
          idBlockchain: "ba97487b-264d-4640-887a-1740557e1093",
          nome: "Iver-vet 3.5 injetável 1000 mL",
          categoria: "FARMACIA",
          subCategoria: "VERMIFUGO",
          fabricante: "Biovet",
          unidade: "LITRO",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
    ],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:16:02",
      dtAlteracao: "2023-12-18T14:16:02",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      fazenda: {
        nome: "Fazenda União do Brasil ",
        dataEntrada: "2023-06-27",
        dataSaida: "2023-11-07",
        latitude: "-23.68423843",
        longitude: "-48.59685500",
      },
      subdivisao: {
        nome: "Confinamento",
        dataEntrada: "2023-06-27",
        dataSaida: "2023-11-07",
      },
      idSistemaGestao: "5015370",
      idLocalizacaoBlockchain: "ad4473b0-c658-4bcc-83a2-a74a6468a0fe",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Fazenda União do Brasil ",
          dataEntrada: "2023-06-27",
          dataSaida: "2023-11-07",
          latitude: "-23.68423843",
          longitude: "-48.59685500",
        },
        subdivisao: {
          nome: "Confinamento",
          dataEntrada: "2023-06-27",
          dataSaida: "2023-11-07",
        },
        idSistemaGestao: "5015370",
        idLocalizacaoBlockchain: "ad4473b0-c658-4bcc-83a2-a74a6468a0fe",
      },
    ],
    loteHistorico: [],
    idResponsavel: "fdd249be-f2a0-402a-9213-c40dff9f7c9f",
    nomeResponsavel: "BARTIRA AGROPECUARIA S/A",
    frigorifico: {
      nome: "Guidara Industria",
      maturidade: "2 dentes",
      acabamento: "Uniforme",
      pesoBanda1: "135.25",
      pesoBanda2: "135.25",
      obs1: "",
      obs2: "",
      dtMorte: "2023-11-08T00:00:00",
      porcentagemCarcaca: "56.50",
      dtCriacao: "2023-12-18T18:48:41",
      dtAlteracao: "2023-12-18T18:48:41",
    },
  },
  "452fcac8-5e54-4aff-8d10-7d6cb8131a1a": {
    idLoteSync: "ec1f85a1-7f04-468d-ac28-2bfdf17d33b5",
    idBlockchain: "452fcac8-5e54-4aff-8d10-7d6cb8131a1a",
    docType: "ANIMAL",
    idSistemaGestao: "8218b36d-4c3c-49f4-8db9-834f08690641",
    idSisbov: "",
    idICar: "982000444276156",
    nome: "",
    dataNascimento: "2020-07-16",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "982000444276156",
    idAnimalFazenda: "1200041",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Santa Gertrudis", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:15:38",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      idSistemaGestao: "8218b36d-4c3c-49f4-8db9-834f08690641",
      idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
      nomeResponsavel: "Fazenda União do Brasil",
      latitude: "-23.6825667",
      longitude: "-48.5986721",
      tipo: "Saída",
      peso: 568,
      dtPesagem: "2023-05-11T11:31:50",
      idPesagemBlockchain: "1f9ee0bd-c978-4a0b-865d-859d00b71cf2",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "8218b36d-4c3c-49f4-8db9-834f08690641",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Nascimento",
        peso: 29,
        dtPesagem: "2020-07-16T03:00:00",
        idPesagemBlockchain: "4ed10a0a-37cc-4687-808a-fae5acc03627",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "8218b36d-4c3c-49f4-8db9-834f08690641",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Desmame",
        peso: 206,
        dtPesagem: "2021-04-27T17:40:10",
        idPesagemBlockchain: "c1238813-7cdd-47ec-bdf1-e521584a8009",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "8218b36d-4c3c-49f4-8db9-834f08690641",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 451,
        dtPesagem: "2023-02-17T13:42:07",
        idPesagemBlockchain: "3cf30810-3c79-4356-88ec-e121c59356a0",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "8218b36d-4c3c-49f4-8db9-834f08690641",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Saída",
        peso: 568,
        dtPesagem: "2023-05-11T11:31:50",
        idPesagemBlockchain: "1f9ee0bd-c978-4a0b-865d-859d00b71cf2",
      },
    ],
    produtorAtual: {
      idBlockchain: "25bc0b49-7cf7-4519-b70c-3001a1e4bfed",
      nome: "Fazenda União do Brasil",
      documento: "08090246000103",
    },
    status: "ABATIDO",
    dataCriacao: "2023-12-18T13:22:59",
    dataUltimaAtualizacao: "2023-12-18T18:58:17",
    vendaAtual: {
      idBlockchain: "0401071b-4fe8-4b0e-ba81-d94b5ab0b970",
      documentoProprietarioDestino: "30269671000113",
      tipo: "INDUSTRIA",
      proprietarioOrigem: {
        nome: "Fazenda União do Brasil",
        documento: "08090246000103",
      },
      proprietarioDestino: {
        nome: "Frigorífico Red Beef LTDA",
        documento: "30269671000113",
      },
      dataCriacao: "2023-12-18T18:33:01",
      dataUltimaAtualizacao: "2023-12-18T18:33:19",
      status: "EFETIVADA",
      qtdAnimais: 29,
    },
    vendaHistorico: [
      {
        idBlockchain: "eb66af4e-769b-4269-9378-dba27a1c0947",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:21:39",
        dataUltimaAtualizacao: "2023-12-18T18:24:08",
        status: "RECUSADA",
        qtdAnimais: 29,
      },
      {
        idBlockchain: "0401071b-4fe8-4b0e-ba81-d94b5ab0b970",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:33:01",
        dataUltimaAtualizacao: "2023-12-18T18:33:19",
        status: "EFETIVADA",
        qtdAnimais: 29,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [
      {
        dtCriacao: "2023-12-18T14:16:17",
        dtAlteracao: "2023-12-18T14:16:17",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "irancho-mvp",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipoExame: "ULTRASSOM_CARCACA",
        dtExame: "2023-05-11T11:31:50",
        vrResultado: "",
        vrResultadoAol: "62.47",
        vrResultadoEgs: "5.18",
        vrResultadoEgg: "0",
        vrResultadoMar: "4.53",
        vrResultadoRatio: "0",
        qtDiasPrenhe: null,
        icApresentouCio: null,
        idExameBlockchain: "e0a30f2b-ef86-45fa-ada5-2fd2164a0525",
      },
    ],
    medicamentosAplicados: [
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "irancho-mvp",
        idOriginalMedicamentoBlockchain: "3367926c-ceac-46be-8628-6774c88536a5",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        dtMedicacao: "2022-11-30T14:43:19",
        unidadeMedida: "MILILITRO",
        quantidade: 2,
        idMedicamentoBlockchain: "3dd6b49a-c608-4050-934e-98d667f2bb18",
        medicamento: {
          idBlockchain: "3367926c-ceac-46be-8628-6774c88536a5",
          nome: "Aftosa ourovac 15 doses ",
          categoria: "FARMACIA",
          subCategoria: "VACINA",
          fabricante: "Ouro fino",
          unidade: "MILILITRO",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:30",
          dataUltimaAtualizacao: "2023-12-13T13:25:30",
        },
      },
    ],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:16:02",
      dtAlteracao: "2023-12-18T14:16:02",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      fazenda: {
        nome: "Fazenda União do Brasil ",
        dataEntrada: "2022-11-30T00:00:00",
        dataSaida: "2023-05-11T00:00:00",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
      },
      subdivisao: {
        nome: "Confinamento",
        dataEntrada: "2022-11-30T00:00:00",
        dataSaida: "2023-05-11T00:00:00",
      },
      idSistemaGestao: "8218b36d-4c3c-49f4-8db9-834f08690641",
      idLocalizacaoBlockchain: "6e88fb62-b1df-4f68-a6da-fd4efe17cfb6",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Vale do Sol ",
          dataEntrada: "2021-04-27T00:00:00",
          dataSaida: "2022-11-30T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "Desmama",
          dataEntrada: "2021-04-27T00:00:00",
          dataSaida: "2022-11-30T00:00:00",
        },
        idSistemaGestao: "8218b36d-4c3c-49f4-8db9-834f08690641",
        idLocalizacaoBlockchain: "70ff890c-fc66-4d6b-8d5e-dbe08fd3a71c",
      },
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Fazenda União do Brasil ",
          dataEntrada: "2022-11-30T00:00:00",
          dataSaida: "2023-05-11T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "Confinamento",
          dataEntrada: "2022-11-30T00:00:00",
          dataSaida: "2023-05-11T00:00:00",
        },
        idSistemaGestao: "8218b36d-4c3c-49f4-8db9-834f08690641",
        idLocalizacaoBlockchain: "6e88fb62-b1df-4f68-a6da-fd4efe17cfb6",
      },
    ],
    loteHistorico: [],
    idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
    nomeResponsavel: "Fazenda União do Brasil",
    frigorifico: {
      nome: "Frigorífico Red Beef LTDA",
      maturidade: "2 dentes",
      acabamento: "Uniforme",
      pesoBanda1: "151.25 Kg",
      pesoBanda2: "151.25 Kg",
      obs1: "",
      obs2: "",
      dtMorte: "2023-05-11",
      porcentagemCarcaca: "53%",
      dtCriacao: "2023-12-18T18:58:17",
      dtAlteracao: "2023-12-18T18:58:17",
    },
  },
  "49d77153-4910-4190-843e-1879f39c894f": {
    idLoteSync: "ec1f85a1-7f04-468d-ac28-2bfdf17d33b5",
    idBlockchain: "49d77153-4910-4190-843e-1879f39c894f",
    docType: "ANIMAL",
    idSistemaGestao: "9ee39625-08a6-4313-a596-5c44797e019d",
    idSisbov: "",
    idICar: "982000444273003",
    nome: "",
    dataNascimento: "2020-12-12",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "982000444273003",
    idAnimalFazenda: "3201373",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Santa Gertrudis", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:15:38",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      idSistemaGestao: "9ee39625-08a6-4313-a596-5c44797e019d",
      idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
      nomeResponsavel: "Fazenda União do Brasil",
      latitude: "-23.6825667",
      longitude: "-48.5986721",
      tipo: "Saída",
      peso: 536,
      dtPesagem: "2023-05-11T11:31:50",
      idPesagemBlockchain: "5f602e6d-ca53-4ff7-8cd2-9448050588da",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "9ee39625-08a6-4313-a596-5c44797e019d",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Nascimento",
        peso: 28,
        dtPesagem: "2020-12-12T03:00:00",
        idPesagemBlockchain: "dc66b39a-33d0-491b-9cad-1272e94feaf9",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "9ee39625-08a6-4313-a596-5c44797e019d",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Desmame",
        peso: 147,
        dtPesagem: "2021-09-28T18:32:46",
        idPesagemBlockchain: "2586a8ed-373a-4c09-83be-34d14917dc83",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "9ee39625-08a6-4313-a596-5c44797e019d",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 327,
        dtPesagem: "2022-10-04T07:40:54",
        idPesagemBlockchain: "ae4e9933-5e18-4c1a-8515-521ba1a15623",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "9ee39625-08a6-4313-a596-5c44797e019d",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 466,
        dtPesagem: "2023-02-17T13:41:34",
        idPesagemBlockchain: "694a0629-3a7b-4182-8cc2-3166278bdcef",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "9ee39625-08a6-4313-a596-5c44797e019d",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Saída",
        peso: 536,
        dtPesagem: "2023-05-11T11:31:50",
        idPesagemBlockchain: "5f602e6d-ca53-4ff7-8cd2-9448050588da",
      },
    ],
    produtorAtual: {
      idBlockchain: "25bc0b49-7cf7-4519-b70c-3001a1e4bfed",
      nome: "Fazenda União do Brasil",
      documento: "08090246000103",
    },
    status: "ABATIDO",
    dataCriacao: "2023-12-18T13:22:59",
    dataUltimaAtualizacao: "2023-12-18T18:55:30",
    vendaAtual: {
      idBlockchain: "0401071b-4fe8-4b0e-ba81-d94b5ab0b970",
      documentoProprietarioDestino: "30269671000113",
      tipo: "INDUSTRIA",
      proprietarioOrigem: {
        nome: "Fazenda União do Brasil",
        documento: "08090246000103",
      },
      proprietarioDestino: {
        nome: "Frigorífico Red Beef LTDA",
        documento: "30269671000113",
      },
      dataCriacao: "2023-12-18T18:33:01",
      dataUltimaAtualizacao: "2023-12-18T18:33:19",
      status: "EFETIVADA",
      qtdAnimais: 29,
    },
    vendaHistorico: [
      {
        idBlockchain: "eb66af4e-769b-4269-9378-dba27a1c0947",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:21:39",
        dataUltimaAtualizacao: "2023-12-18T18:24:08",
        status: "RECUSADA",
        qtdAnimais: 29,
      },
      {
        idBlockchain: "0401071b-4fe8-4b0e-ba81-d94b5ab0b970",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:33:01",
        dataUltimaAtualizacao: "2023-12-18T18:33:19",
        status: "EFETIVADA",
        qtdAnimais: 29,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [
      {
        dtCriacao: "2023-12-18T14:16:17",
        dtAlteracao: "2023-12-18T14:16:17",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "irancho-mvp",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipoExame: "ULTRASSOM_CARCACA",
        dtExame: "2023-05-11T11:31:50",
        vrResultado: "",
        vrResultadoAol: "97.24",
        vrResultadoEgs: "4.24",
        vrResultadoEgg: "0",
        vrResultadoMar: "3.73",
        vrResultadoRatio: "0",
        qtDiasPrenhe: null,
        icApresentouCio: null,
        idExameBlockchain: "9989758d-7737-4923-b4f5-fe4e63ff7c0c",
      },
    ],
    medicamentosAplicados: [
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "irancho-mvp",
        idOriginalMedicamentoBlockchain: "40aa0e8c-5ceb-4b79-bd44-253139e57a21",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        dtMedicacao: "2021-06-08T10:30:45",
        unidadeMedida: "MILILITRO",
        quantidade: 2,
        idMedicamentoBlockchain: "287f689c-ff0e-473b-b8fb-fba54d54356c",
        medicamento: {
          idBlockchain: "40aa0e8c-5ceb-4b79-bd44-253139e57a21",
          nome: "Aborvac Brucelose",
          categoria: "FARMACIA",
          subCategoria: "VACINA",
          fabricante: "Zoetis",
          unidade: "MILILITRO",
          sexo: "FEMEA",
          idadeMaxima: 8,
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
    ],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:16:02",
      dtAlteracao: "2023-12-18T14:16:02",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      fazenda: {
        nome: "Fazenda União do Brasil ",
        dataEntrada: "2022-10-04T00:00:00",
        dataSaida: "2023-05-11T00:00:00",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
      },
      subdivisao: {
        nome: "Confinamento",
        dataEntrada: "2022-10-04T00:00:00",
        dataSaida: "2023-05-11T00:00:00",
      },
      idSistemaGestao: "9ee39625-08a6-4313-a596-5c44797e019d",
      idLocalizacaoBlockchain: "09461ee5-5844-45ab-b611-5f5c59881743",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Vale do Sol ",
          dataEntrada: "2021-09-28T00:00:00",
          dataSaida: "2022-10-04T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "transferencia de animais",
          dataEntrada: "2021-09-28T00:00:00",
          dataSaida: "2022-10-04T00:00:00",
        },
        idSistemaGestao: "9ee39625-08a6-4313-a596-5c44797e019d",
        idLocalizacaoBlockchain: "af272780-0ef3-4177-bcc3-cea414e416e4",
      },
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Fazenda União do Brasil ",
          dataEntrada: "2022-10-04T00:00:00",
          dataSaida: "2023-05-11T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "Confinamento",
          dataEntrada: "2022-10-04T00:00:00",
          dataSaida: "2023-05-11T00:00:00",
        },
        idSistemaGestao: "9ee39625-08a6-4313-a596-5c44797e019d",
        idLocalizacaoBlockchain: "09461ee5-5844-45ab-b611-5f5c59881743",
      },
    ],
    loteHistorico: [],
    idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
    nomeResponsavel: "Fazenda União do Brasil",
    frigorifico: {
      nome: "Frigorífico Red Beef LTDA",
      maturidade: "2 dentes",
      acabamento: "Mediano",
      pesoBanda1: "149.75 Kg",
      pesoBanda2: "149.75 Kg",
      obs1: "",
      obs2: "",
      dtMorte: "2023-05-11",
      porcentagemCarcaca: "56%",
      dtCriacao: "2023-12-18T18:55:30",
      dtAlteracao: "2023-12-18T18:55:30",
    },
  },
  "4aa0b6ab-f18c-4b0a-824f-a03f18b7a544": {
    idLoteSync: "ec1f85a1-7f04-468d-ac28-2bfdf17d33b5",
    idBlockchain: "4aa0b6ab-f18c-4b0a-824f-a03f18b7a544",
    docType: "ANIMAL",
    idSistemaGestao: "4ece484f-f514-46ce-a8dc-d7a7c327e95b",
    idSisbov: "",
    idICar: "982000444278711",
    nome: "",
    dataNascimento: "2021-02-23",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "982000444278711",
    idAnimalFazenda: "3210233",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Santa Gertrudis", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:15:38",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      idSistemaGestao: "4ece484f-f514-46ce-a8dc-d7a7c327e95b",
      idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
      nomeResponsavel: "Fazenda União do Brasil",
      latitude: "-23.6825667",
      longitude: "-48.5986721",
      tipo: "Saída",
      peso: 546,
      dtPesagem: "2023-05-11T11:31:50",
      idPesagemBlockchain: "fa3df6b6-506d-4a49-99d9-3dcce3a2021e",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "4ece484f-f514-46ce-a8dc-d7a7c327e95b",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Nascimento",
        peso: 27,
        dtPesagem: "2021-02-23T03:00:00",
        idPesagemBlockchain: "882937c2-0ba1-41f5-8916-f3df444603a8",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "4ece484f-f514-46ce-a8dc-d7a7c327e95b",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 170,
        dtPesagem: "2021-11-23T19:49:06",
        idPesagemBlockchain: "1ff145e6-53a0-46e1-bda4-0aa2bad351a9",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "4ece484f-f514-46ce-a8dc-d7a7c327e95b",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Desmame",
        peso: 167,
        dtPesagem: "2021-11-29T13:02:31",
        idPesagemBlockchain: "b0cc3265-dba3-48ba-8c3e-d5423f200428",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "4ece484f-f514-46ce-a8dc-d7a7c327e95b",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 305,
        dtPesagem: "2022-10-04T07:40:54",
        idPesagemBlockchain: "89be2d67-4889-46b0-9f1d-296640299fed",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "4ece484f-f514-46ce-a8dc-d7a7c327e95b",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 462,
        dtPesagem: "2023-02-17T13:41:34",
        idPesagemBlockchain: "341b7c18-820b-4294-8142-ab7dc59ae37f",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "4ece484f-f514-46ce-a8dc-d7a7c327e95b",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Saída",
        peso: 546,
        dtPesagem: "2023-05-11T11:31:50",
        idPesagemBlockchain: "fa3df6b6-506d-4a49-99d9-3dcce3a2021e",
      },
    ],
    produtorAtual: {
      idBlockchain: "25bc0b49-7cf7-4519-b70c-3001a1e4bfed",
      nome: "Fazenda União do Brasil",
      documento: "08090246000103",
    },
    status: "ABATIDO",
    dataCriacao: "2023-12-18T13:22:59",
    dataUltimaAtualizacao: "2023-12-18T18:54:26",
    vendaAtual: {
      idBlockchain: "0401071b-4fe8-4b0e-ba81-d94b5ab0b970",
      documentoProprietarioDestino: "30269671000113",
      tipo: "INDUSTRIA",
      proprietarioOrigem: {
        nome: "Fazenda União do Brasil",
        documento: "08090246000103",
      },
      proprietarioDestino: {
        nome: "Frigorífico Red Beef LTDA",
        documento: "30269671000113",
      },
      dataCriacao: "2023-12-18T18:33:01",
      dataUltimaAtualizacao: "2023-12-18T18:33:19",
      status: "EFETIVADA",
      qtdAnimais: 29,
    },
    vendaHistorico: [
      {
        idBlockchain: "eb66af4e-769b-4269-9378-dba27a1c0947",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:21:39",
        dataUltimaAtualizacao: "2023-12-18T18:24:08",
        status: "RECUSADA",
        qtdAnimais: 29,
      },
      {
        idBlockchain: "0401071b-4fe8-4b0e-ba81-d94b5ab0b970",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:33:01",
        dataUltimaAtualizacao: "2023-12-18T18:33:19",
        status: "EFETIVADA",
        qtdAnimais: 29,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [
      {
        dtCriacao: "2023-12-18T14:16:17",
        dtAlteracao: "2023-12-18T14:16:17",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "irancho-mvp",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipoExame: "ULTRASSOM_CARCACA",
        dtExame: "2023-05-11T11:31:50",
        vrResultado: "",
        vrResultadoAol: "85.15",
        vrResultadoEgs: "9.41",
        vrResultadoEgg: "0",
        vrResultadoMar: "4.45",
        vrResultadoRatio: "0",
        qtDiasPrenhe: null,
        icApresentouCio: null,
        idExameBlockchain: "e989907f-5529-4bd2-838d-28099c773e83",
      },
    ],
    medicamentosAplicados: [
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "irancho-mvp",
        idOriginalMedicamentoBlockchain: "7838ec4f-23ec-49fc-87b1-3f6fdcc0ba70",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        dtMedicacao: "2021-05-12T15:05:36",
        unidadeMedida: "MILILITRO",
        quantidade: 2,
        idMedicamentoBlockchain: "5bcf6ee6-f082-49ac-a808-9a46aa5607dc",
        medicamento: {
          idBlockchain: "7838ec4f-23ec-49fc-87b1-3f6fdcc0ba70",
          nome: "Aftosa ourovac 50 doses ",
          categoria: "FARMACIA",
          subCategoria: "VACINA",
          fabricante: "Ouro fino",
          unidade: "MILILITRO",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "irancho-mvp",
        idOriginalMedicamentoBlockchain: "6986cc08-57a1-45d1-83a9-13b7a09a0ba5",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        dtMedicacao: "2021-05-12T15:05:36",
        unidadeMedida: "MILILITRO",
        quantidade: 5,
        idMedicamentoBlockchain: "b60559e0-552b-4a23-8f2e-98fb9e30f97f",
        medicamento: {
          idBlockchain: "6986cc08-57a1-45d1-83a9-13b7a09a0ba5",
          nome: "Resguard Multi",
          categoria: "FARMACIA",
          subCategoria: "VACINA",
          fabricante: "Biovet",
          unidade: "MILILITRO",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "irancho-mvp",
        idOriginalMedicamentoBlockchain: "40aa0e8c-5ceb-4b79-bd44-253139e57a21",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        dtMedicacao: "2021-06-07T10:06:08",
        unidadeMedida: "MILILITRO",
        quantidade: 2,
        idMedicamentoBlockchain: "d1bd3f45-3999-4278-85d2-acbda6254226",
        medicamento: {
          idBlockchain: "40aa0e8c-5ceb-4b79-bd44-253139e57a21",
          nome: "Aborvac Brucelose",
          categoria: "FARMACIA",
          subCategoria: "VACINA",
          fabricante: "Zoetis",
          unidade: "MILILITRO",
          sexo: "FEMEA",
          idadeMaxima: 8,
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
    ],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:16:02",
      dtAlteracao: "2023-12-18T14:16:02",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      fazenda: {
        nome: "Fazenda União do Brasil ",
        dataEntrada: "2022-10-04T00:00:00",
        dataSaida: "2023-05-11T00:00:00",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
      },
      subdivisao: {
        nome: "Confinamento",
        dataEntrada: "2022-10-04T00:00:00",
        dataSaida: "2023-05-11T00:00:00",
      },
      idSistemaGestao: "4ece484f-f514-46ce-a8dc-d7a7c327e95b",
      idLocalizacaoBlockchain: "38340a85-dba5-4c08-b80f-7ed261cc95db",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Fazenda União do Brasil ",
          dataEntrada: "2021-08-31T00:00:00",
          dataSaida: "2021-11-29T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "Confinamento",
          dataEntrada: "2021-08-31T00:00:00",
          dataSaida: "2021-11-29T00:00:00",
        },
        idSistemaGestao: "4ece484f-f514-46ce-a8dc-d7a7c327e95b",
        idLocalizacaoBlockchain: "cf695b49-3541-44a7-b0c9-6aec8d54b90e",
      },
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Vale do Sol ",
          dataEntrada: "2021-11-29T00:00:00",
          dataSaida: "2022-10-04T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "Desmama",
          dataEntrada: "2021-11-29T00:00:00",
          dataSaida: "2022-10-04T00:00:00",
        },
        idSistemaGestao: "4ece484f-f514-46ce-a8dc-d7a7c327e95b",
        idLocalizacaoBlockchain: "244c2c70-7a05-43dc-8ec0-ddfa09f4c9fb",
      },
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Fazenda União do Brasil ",
          dataEntrada: "2022-10-04T00:00:00",
          dataSaida: "2023-05-11T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "Confinamento",
          dataEntrada: "2022-10-04T00:00:00",
          dataSaida: "2023-05-11T00:00:00",
        },
        idSistemaGestao: "4ece484f-f514-46ce-a8dc-d7a7c327e95b",
        idLocalizacaoBlockchain: "38340a85-dba5-4c08-b80f-7ed261cc95db",
      },
    ],
    loteHistorico: [],
    idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
    nomeResponsavel: "Fazenda União do Brasil",
    frigorifico: {
      nome: "Frigorífico Red Beef LTDA",
      maturidade: "2 dentes",
      acabamento: "Uniforme",
      pesoBanda1: "147.00 Kg",
      pesoBanda2: "147.00 Kg",
      obs1: "",
      obs2: "",
      dtMorte: "2023-05-11",
      porcentagemCarcaca: "54%",
      dtCriacao: "2023-12-18T18:54:26",
      dtAlteracao: "2023-12-18T18:54:26",
    },
  },
  "4c9923f8-06b7-4fac-9935-f576b62beb79": {
    idLoteSync: "ec1f85a1-7f04-468d-ac28-2bfdf17d33b5",
    idBlockchain: "4c9923f8-06b7-4fac-9935-f576b62beb79",
    docType: "ANIMAL",
    idSistemaGestao: "e1f4cb20-a345-42b5-9168-9955fdad56e9",
    idSisbov: "",
    idICar: "982000444277828",
    nome: "",
    dataNascimento: "2022-04-02",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "982000444277828",
    idAnimalFazenda: "4277828",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Santa Gertrudis", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:15:38",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      idSistemaGestao: "1b19be31-08ef-48e9-bd0b-0e1f503c4936",
      idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
      nomeResponsavel: "Fazenda União do Brasil",
      latitude: "",
      longitude: "",
      tipo: "Saída",
      peso: 493,
      dtPesagem: "2023-11-07",
      idPesagemBlockchain: "4b20e0c5-8118-4040-b0e6-242734c12867",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "7d5bc73d-5347-44f2-a02e-13be587107b3",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Entrada",
        peso: 278,
        dtPesagem: "2023-06-01",
        idPesagemBlockchain: "53737b5b-414a-46e1-a1b3-c5cdc4bbd7c9",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "396f2b63-dee0-4392-976d-b590a5633b31",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 274,
        dtPesagem: "2023-06-27",
        idPesagemBlockchain: "e6eb0839-ae19-48e5-9796-232dd2d8ff93",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "c409e19c-5d14-4d80-bb4d-43ac6dcfdfda",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 395,
        dtPesagem: "2023-09-04",
        idPesagemBlockchain: "40ef7ab7-9f0a-44d0-96c4-88957dcc9af7",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "1b19be31-08ef-48e9-bd0b-0e1f503c4936",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Saída",
        peso: 493,
        dtPesagem: "2023-11-07",
        idPesagemBlockchain: "4b20e0c5-8118-4040-b0e6-242734c12867",
      },
    ],
    produtorAtual: {
      idBlockchain: "25bc0b49-7cf7-4519-b70c-3001a1e4bfed",
      nome: "Fazenda União do Brasil",
      documento: "08090246000103",
    },
    status: "ABATIDO",
    dataCriacao: "2023-12-18T13:22:59",
    dataUltimaAtualizacao: "2023-12-18T18:49:20",
    vendaAtual: {
      idBlockchain: "e0130d89-b68f-423b-ad3d-a429637fb5a3",
      documentoProprietarioDestino: "18293459000196",
      tipo: "INDUSTRIA",
      proprietarioOrigem: {
        nome: "Fazenda União do Brasil",
        documento: "08090246000103",
      },
      proprietarioDestino: {
        nome: "Guidara Indústria e Comércio de Alimentos Eireli",
        documento: "18293459000196",
      },
      dataCriacao: "2023-12-18T18:27:49",
      dataUltimaAtualizacao: "2023-12-18T18:28:02",
      status: "EFETIVADA",
      qtdAnimais: 25,
    },
    vendaHistorico: [
      {
        idBlockchain: "f10b303c-ab60-4d04-b8b0-0f217cda4abb",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:22:09",
        dataUltimaAtualizacao: "2023-12-18T18:24:42",
        status: "RECUSADA",
        qtdAnimais: 25,
      },
      {
        idBlockchain: "f1392d6d-fc67-43f4-918a-1da3da56a459",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:26:52",
        dataUltimaAtualizacao: "2023-12-18T18:27:14",
        status: "RECUSADA",
        qtdAnimais: 25,
      },
      {
        idBlockchain: "e0130d89-b68f-423b-ad3d-a429637fb5a3",
        documentoProprietarioDestino: "18293459000196",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Guidara Indústria e Comércio de Alimentos Eireli",
          documento: "18293459000196",
        },
        dataCriacao: "2023-12-18T18:27:49",
        dataUltimaAtualizacao: "2023-12-18T18:28:02",
        status: "EFETIVADA",
        qtdAnimais: 25,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [
      {
        dtCriacao: "2023-12-18T14:16:17",
        dtAlteracao: "2023-12-18T14:16:17",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "0678d4b1-e457-46f3-b10d-ba2dc26db46b",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipoExame: "ULTRASSOM_CARCACA",
        dtExame: "2023-09-04",
        vrResultado: "",
        vrResultadoAol: "83.84",
        vrResultadoEgs: "4.94",
        vrResultadoEgg: "0.00",
        vrResultadoMar: "3.22",
        vrResultadoRatio: "0.52",
        qtDiasPrenhe: null,
        icApresentouCio: null,
        idExameBlockchain: "d44d4284-8510-42d4-bc24-32b7790ec1b2",
      },
    ],
    medicamentosAplicados: [
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "02e0c0d0-261c-4f22-a833-75b8683a6982",
        idOriginalMedicamentoBlockchain: "ba97487b-264d-4640-887a-1740557e1093",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        dtMedicacao: "2023-06-01",
        unidadeMedida: "MILILITRO",
        quantidade: 5,
        idMedicamentoBlockchain: "095f8ff8-70d2-4bba-b7cf-14d3f38c1f36",
        medicamento: {
          idBlockchain: "ba97487b-264d-4640-887a-1740557e1093",
          nome: "Iver-vet 3.5 injetável 1000 mL",
          categoria: "FARMACIA",
          subCategoria: "VERMIFUGO",
          fabricante: "Biovet",
          unidade: "LITRO",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "78d5c44a-07a1-414a-87d2-0ac936d05752",
        idOriginalMedicamentoBlockchain: "03a88948-7528-45c1-873a-b8d6012f9c15",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        dtMedicacao: "2023-06-27",
        unidadeMedida: "UNIDADE",
        quantidade: 2,
        idMedicamentoBlockchain: "2d0ca1d7-e882-4a5b-822c-a3a9725ac5f5",
        medicamento: {
          idBlockchain: "03a88948-7528-45c1-873a-b8d6012f9c15",
          nome: "Vacina Bovi-Shield",
          categoria: "FARMACIA",
          subCategoria: "VACINA",
          fabricante: "Zoetis",
          unidade: "UNIDADE",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
    ],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:16:02",
      dtAlteracao: "2023-12-18T14:16:02",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      fazenda: {
        nome: "Fazenda União do Brasil ",
        dataEntrada: "2023-06-27",
        dataSaida: "2023-11-07",
        latitude: "-23.68423843",
        longitude: "-48.59685500",
      },
      subdivisao: {
        nome: "Confinamento",
        dataEntrada: "2023-06-27",
        dataSaida: "2023-11-07",
      },
      idSistemaGestao: "5015356",
      idLocalizacaoBlockchain: "bb1ae71a-450a-472a-a972-ade27700617f",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Fazenda União do Brasil ",
          dataEntrada: "2023-06-27",
          dataSaida: "2023-11-07",
          latitude: "-23.68423843",
          longitude: "-48.59685500",
        },
        subdivisao: {
          nome: "Confinamento",
          dataEntrada: "2023-06-27",
          dataSaida: "2023-11-07",
        },
        idSistemaGestao: "5015356",
        idLocalizacaoBlockchain: "bb1ae71a-450a-472a-a972-ade27700617f",
      },
    ],
    loteHistorico: [],
    idResponsavel: "fdd249be-f2a0-402a-9213-c40dff9f7c9f",
    nomeResponsavel: "BARTIRA AGROPECUARIA S/A",
    frigorifico: {
      nome: "Guidara Industria",
      maturidade: "2 dentes",
      acabamento: "Uniforme",
      pesoBanda1: "135.25",
      pesoBanda2: "135.25",
      obs1: "",
      obs2: "",
      dtMorte: "2023-11-08T00:00:00",
      porcentagemCarcaca: "56.50",
      dtCriacao: "2023-12-18T18:49:20",
      dtAlteracao: "2023-12-18T18:49:20",
    },
  },
  "4e731494-0772-43f0-9881-dff39ab5a3c6": {
    idLoteSync: "ec1f85a1-7f04-468d-ac28-2bfdf17d33b5",
    idBlockchain: "4e731494-0772-43f0-9881-dff39ab5a3c6",
    docType: "ANIMAL",
    idSistemaGestao: "45abf918-d781-45fa-8dba-d6566c7c83f5",
    idSisbov: "",
    idICar: "982000444279016",
    nome: "",
    dataNascimento: "2021-01-15",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "982000444279016",
    idAnimalFazenda: "3210122",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Santa Gertrudis", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:15:38",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      idSistemaGestao: "45abf918-d781-45fa-8dba-d6566c7c83f5",
      idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
      nomeResponsavel: "Fazenda União do Brasil",
      latitude: "-23.6825667",
      longitude: "-48.5986721",
      tipo: "Saída",
      peso: 439,
      dtPesagem: "2023-05-11T11:31:50",
      idPesagemBlockchain: "825200a2-ed87-4515-aec6-204a5f504de9",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "45abf918-d781-45fa-8dba-d6566c7c83f5",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Nascimento",
        peso: 32,
        dtPesagem: "2021-01-15T03:00:00",
        idPesagemBlockchain: "b1527dcb-177c-46b3-b74d-b379c1bedaa4",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "45abf918-d781-45fa-8dba-d6566c7c83f5",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Desmame",
        peso: 122,
        dtPesagem: "2021-09-23T13:06:24",
        idPesagemBlockchain: "5999dec4-44ec-446d-b7b8-03326cc58dba",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "45abf918-d781-45fa-8dba-d6566c7c83f5",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Entrada",
        peso: 269,
        dtPesagem: "2022-11-28T09:07:37",
        idPesagemBlockchain: "8faef96b-119b-49d2-aa9b-cff11271a704",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "45abf918-d781-45fa-8dba-d6566c7c83f5",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 351,
        dtPesagem: "2023-02-17T13:42:07",
        idPesagemBlockchain: "80dac49f-eda9-4101-b605-0e72371b5c92",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "45abf918-d781-45fa-8dba-d6566c7c83f5",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Saída",
        peso: 439,
        dtPesagem: "2023-05-11T11:31:50",
        idPesagemBlockchain: "825200a2-ed87-4515-aec6-204a5f504de9",
      },
    ],
    produtorAtual: {
      idBlockchain: "25bc0b49-7cf7-4519-b70c-3001a1e4bfed",
      nome: "Fazenda União do Brasil",
      documento: "08090246000103",
    },
    status: "ABATIDO",
    dataCriacao: "2023-12-18T13:22:59",
    dataUltimaAtualizacao: "2023-12-18T18:56:10",
    vendaAtual: {
      idBlockchain: "0401071b-4fe8-4b0e-ba81-d94b5ab0b970",
      documentoProprietarioDestino: "30269671000113",
      tipo: "INDUSTRIA",
      proprietarioOrigem: {
        nome: "Fazenda União do Brasil",
        documento: "08090246000103",
      },
      proprietarioDestino: {
        nome: "Frigorífico Red Beef LTDA",
        documento: "30269671000113",
      },
      dataCriacao: "2023-12-18T18:33:01",
      dataUltimaAtualizacao: "2023-12-18T18:33:19",
      status: "EFETIVADA",
      qtdAnimais: 29,
    },
    vendaHistorico: [
      {
        idBlockchain: "eb66af4e-769b-4269-9378-dba27a1c0947",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:21:39",
        dataUltimaAtualizacao: "2023-12-18T18:24:08",
        status: "RECUSADA",
        qtdAnimais: 29,
      },
      {
        idBlockchain: "0401071b-4fe8-4b0e-ba81-d94b5ab0b970",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:33:01",
        dataUltimaAtualizacao: "2023-12-18T18:33:19",
        status: "EFETIVADA",
        qtdAnimais: 29,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [
      {
        dtCriacao: "2023-12-18T14:16:17",
        dtAlteracao: "2023-12-18T14:16:17",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "irancho-mvp",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipoExame: "ULTRASSOM_CARCACA",
        dtExame: "2023-05-11T11:31:50",
        vrResultado: "",
        vrResultadoAol: "70.78",
        vrResultadoEgs: "4.24",
        vrResultadoEgg: "0",
        vrResultadoMar: "3.53",
        vrResultadoRatio: "0",
        qtDiasPrenhe: null,
        icApresentouCio: null,
        idExameBlockchain: "a052d8a1-ccd3-4fce-9361-d68cdeab5586",
      },
    ],
    medicamentosAplicados: [
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "irancho-mvp",
        idOriginalMedicamentoBlockchain: "40aa0e8c-5ceb-4b79-bd44-253139e57a21",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        dtMedicacao: "2021-06-07T12:00:40",
        unidadeMedida: "MILILITRO",
        quantidade: 2,
        idMedicamentoBlockchain: "404f4b56-d1ac-4463-88e9-d59f6e53d2e2",
        medicamento: {
          idBlockchain: "40aa0e8c-5ceb-4b79-bd44-253139e57a21",
          nome: "Aborvac Brucelose",
          categoria: "FARMACIA",
          subCategoria: "VACINA",
          fabricante: "Zoetis",
          unidade: "MILILITRO",
          sexo: "FEMEA",
          idadeMaxima: 8,
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
    ],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:16:02",
      dtAlteracao: "2023-12-18T14:16:02",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      fazenda: {
        nome: "Fazenda União do Brasil ",
        dataEntrada: "2022-11-28T00:00:00",
        dataSaida: "2023-05-11T00:00:00",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
      },
      subdivisao: {
        nome: "Confinamento",
        dataEntrada: "2022-11-28T00:00:00",
        dataSaida: "2023-05-11T00:00:00",
      },
      idSistemaGestao: "45abf918-d781-45fa-8dba-d6566c7c83f5",
      idLocalizacaoBlockchain: "e55c5526-ab38-4bb3-b129-2bf36858923f",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Vale do Sol ",
          dataEntrada: "2021-10-26T00:00:00",
          dataSaida: "2022-11-28T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "transferencia de animais",
          dataEntrada: "2021-10-26T00:00:00",
          dataSaida: "2022-11-28T00:00:00",
        },
        idSistemaGestao: "45abf918-d781-45fa-8dba-d6566c7c83f5",
        idLocalizacaoBlockchain: "c4f3ef36-9aaf-4113-8e32-0626be09f16e",
      },
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Fazenda União do Brasil ",
          dataEntrada: "2022-11-28T00:00:00",
          dataSaida: "2023-05-11T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "Confinamento",
          dataEntrada: "2022-11-28T00:00:00",
          dataSaida: "2023-05-11T00:00:00",
        },
        idSistemaGestao: "45abf918-d781-45fa-8dba-d6566c7c83f5",
        idLocalizacaoBlockchain: "e55c5526-ab38-4bb3-b129-2bf36858923f",
      },
    ],
    loteHistorico: [],
    idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
    nomeResponsavel: "Fazenda União do Brasil",
    frigorifico: {
      nome: "Frigorífico Red Beef LTDA",
      maturidade: "2 dentes",
      acabamento: "Mediano",
      pesoBanda1: "117.25 Kg",
      pesoBanda2: "117.25 Kg",
      obs1: "",
      obs2: "",
      dtMorte: "2023-05-11",
      porcentagemCarcaca: "53%",
      dtCriacao: "2023-12-18T18:56:10",
      dtAlteracao: "2023-12-18T18:56:10",
    },
  },
  "4f607cfb-101c-47cd-a922-c660a05059ee": {
    idLoteSync: "537d020d-17ec-4059-96af-a3e194917000",
    idBlockchain: "4f607cfb-101c-47cd-a922-c660a05059ee",
    docType: "ANIMAL",
    idSistemaGestao: "irancho-mvpteste3",
    idSisbov: "123",
    idICar: "",
    nome: "boi teste lote3",
    dataNascimento: "2024-01-01",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "0123",
    idAnimalFazenda: "teste3",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "25bc0b49-7cf7-4519-b70c-3001a1e4bfed",
      nome: "Fazenda União do Brasil",
      documento: "08090246000103",
    },
    status: "VIVO",
    dataCriacao: "2024-01-16T16:21:56",
    dataUltimaAtualizacao: "2024-01-16T16:21:56",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "09af30c7-3ae2-4016-8659-0da0fb248818",
    nomeResponsavel: "Natalino Cavalli Junior",
    frigorifico: null,
  },
  "5246b9ed-9a63-485b-9817-dc3b50ea23a3": {
    idLoteSync: "ec1f85a1-7f04-468d-ac28-2bfdf17d33b5",
    idBlockchain: "5246b9ed-9a63-485b-9817-dc3b50ea23a3",
    docType: "ANIMAL",
    idSistemaGestao: "f47fbbec-189f-4a0a-bbd9-f8fb212c352f",
    idSisbov: "",
    idICar: "982000444281261",
    nome: "",
    dataNascimento: "2020-11-02",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "982000444281261",
    idAnimalFazenda: "1200270",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Santa Gertrudis", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:15:38",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      idSistemaGestao: "f47fbbec-189f-4a0a-bbd9-f8fb212c352f",
      idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
      nomeResponsavel: "Fazenda União do Brasil",
      latitude: "-23.6825667",
      longitude: "-48.5986721",
      tipo: "Saída",
      peso: 504,
      dtPesagem: "2023-05-11T11:31:50",
      idPesagemBlockchain: "ff9638c4-0a55-41e7-833b-9d060172976e",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "f47fbbec-189f-4a0a-bbd9-f8fb212c352f",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Nascimento",
        peso: 37,
        dtPesagem: "2020-11-02T03:00:00",
        idPesagemBlockchain: "156dd418-1649-4110-8a27-0824c8e4e7b9",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "f47fbbec-189f-4a0a-bbd9-f8fb212c352f",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Desmame",
        peso: 115,
        dtPesagem: "2021-08-17T19:38:45",
        idPesagemBlockchain: "51891fb7-b588-49bc-a13d-369fa71b1798",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "f47fbbec-189f-4a0a-bbd9-f8fb212c352f",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 292,
        dtPesagem: "2022-10-04T07:40:54",
        idPesagemBlockchain: "b41f666c-dce6-41ed-a62d-9ed5792e07b6",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "f47fbbec-189f-4a0a-bbd9-f8fb212c352f",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 427,
        dtPesagem: "2023-02-17T13:41:34",
        idPesagemBlockchain: "faf12c2e-0c0d-405a-8032-182fc10d909a",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "f47fbbec-189f-4a0a-bbd9-f8fb212c352f",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Saída",
        peso: 504,
        dtPesagem: "2023-05-11T11:31:50",
        idPesagemBlockchain: "ff9638c4-0a55-41e7-833b-9d060172976e",
      },
    ],
    produtorAtual: {
      idBlockchain: "25bc0b49-7cf7-4519-b70c-3001a1e4bfed",
      nome: "Fazenda União do Brasil",
      documento: "08090246000103",
    },
    status: "ABATIDO",
    dataCriacao: "2023-12-18T13:22:59",
    dataUltimaAtualizacao: "2023-12-18T18:57:12",
    vendaAtual: {
      idBlockchain: "0401071b-4fe8-4b0e-ba81-d94b5ab0b970",
      documentoProprietarioDestino: "30269671000113",
      tipo: "INDUSTRIA",
      proprietarioOrigem: {
        nome: "Fazenda União do Brasil",
        documento: "08090246000103",
      },
      proprietarioDestino: {
        nome: "Frigorífico Red Beef LTDA",
        documento: "30269671000113",
      },
      dataCriacao: "2023-12-18T18:33:01",
      dataUltimaAtualizacao: "2023-12-18T18:33:19",
      status: "EFETIVADA",
      qtdAnimais: 29,
    },
    vendaHistorico: [
      {
        idBlockchain: "eb66af4e-769b-4269-9378-dba27a1c0947",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:21:39",
        dataUltimaAtualizacao: "2023-12-18T18:24:08",
        status: "RECUSADA",
        qtdAnimais: 29,
      },
      {
        idBlockchain: "0401071b-4fe8-4b0e-ba81-d94b5ab0b970",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:33:01",
        dataUltimaAtualizacao: "2023-12-18T18:33:19",
        status: "EFETIVADA",
        qtdAnimais: 29,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [
      {
        dtCriacao: "2023-12-18T14:16:17",
        dtAlteracao: "2023-12-18T14:16:17",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "irancho-mvp",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipoExame: "ULTRASSOM_CARCACA",
        dtExame: "2023-05-11T11:31:50",
        vrResultado: "",
        vrResultadoAol: "57.01",
        vrResultadoEgs: "10.35",
        vrResultadoEgg: "0",
        vrResultadoMar: "4.17",
        vrResultadoRatio: "0",
        qtDiasPrenhe: null,
        icApresentouCio: null,
        idExameBlockchain: "c7e84347-7c85-4747-b200-8aaa9bad7114",
      },
    ],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
    nomeResponsavel: "Fazenda União do Brasil",
    frigorifico: {
      nome: "Frigorífico Red Beef LTDA",
      maturidade: "2 dentes",
      acabamento: "Uniforme",
      pesoBanda1: "138.50 Kg",
      pesoBanda2: "138.50 Kg",
      obs1: "",
      obs2: "",
      dtMorte: "2023-05-11",
      porcentagemCarcaca: "55%",
      dtCriacao: "2023-12-18T18:57:12",
      dtAlteracao: "2023-12-18T18:57:12",
    },
  },
  "553594ec-90f8-4776-9f4a-56d5d9f6aa0e": {
    idLoteSync: "ec1f85a1-7f04-468d-ac28-2bfdf17d33b5",
    idBlockchain: "553594ec-90f8-4776-9f4a-56d5d9f6aa0e",
    docType: "ANIMAL",
    idSistemaGestao: "5e39b16a-85cd-4aaf-b6ab-ffe2f89f15ed",
    idSisbov: "105350174100937",
    idICar: "982000444280710",
    nome: "",
    dataNascimento: "2021-06-17",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "982000444280710",
    idAnimalFazenda: "F 008044334",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Santa Gertrudis", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:15:38",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      idSistemaGestao: "5e39b16a-85cd-4aaf-b6ab-ffe2f89f15ed",
      idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
      nomeResponsavel: "Fazenda União do Brasil",
      latitude: "-23.6825667",
      longitude: "-48.5986721",
      tipo: "Saída",
      peso: 508,
      dtPesagem: "2023-05-11T11:31:50",
      idPesagemBlockchain: "ba29d44e-27ea-4302-bf93-3323d0b92a90",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "5e39b16a-85cd-4aaf-b6ab-ffe2f89f15ed",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 144,
        dtPesagem: "2021-11-23T19:49:06",
        idPesagemBlockchain: "ef0b72fc-6d51-45b5-9f3d-30e08480750e",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "5e39b16a-85cd-4aaf-b6ab-ffe2f89f15ed",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 223,
        dtPesagem: "2022-04-26T17:56:58",
        idPesagemBlockchain: "c169941d-765e-411b-bc50-3dae4512ecb1",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "5e39b16a-85cd-4aaf-b6ab-ffe2f89f15ed",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Entrada",
        peso: 330,
        dtPesagem: "2022-11-28T09:07:37",
        idPesagemBlockchain: "5e11077e-e0d6-4dec-b93d-220cf1ae1910",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "5e39b16a-85cd-4aaf-b6ab-ffe2f89f15ed",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 431,
        dtPesagem: "2023-02-17T13:42:07",
        idPesagemBlockchain: "8dc8fdc2-9a8f-47e8-88a5-5b432edf443b",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "5e39b16a-85cd-4aaf-b6ab-ffe2f89f15ed",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Saída",
        peso: 508,
        dtPesagem: "2023-05-11T11:31:50",
        idPesagemBlockchain: "ba29d44e-27ea-4302-bf93-3323d0b92a90",
      },
    ],
    produtorAtual: {
      idBlockchain: "25bc0b49-7cf7-4519-b70c-3001a1e4bfed",
      nome: "Fazenda União do Brasil",
      documento: "08090246000103",
    },
    status: "ABATIDO",
    dataCriacao: "2023-12-18T13:22:59",
    dataUltimaAtualizacao: "2023-12-18T18:57:50",
    vendaAtual: {
      idBlockchain: "0401071b-4fe8-4b0e-ba81-d94b5ab0b970",
      documentoProprietarioDestino: "30269671000113",
      tipo: "INDUSTRIA",
      proprietarioOrigem: {
        nome: "Fazenda União do Brasil",
        documento: "08090246000103",
      },
      proprietarioDestino: {
        nome: "Frigorífico Red Beef LTDA",
        documento: "30269671000113",
      },
      dataCriacao: "2023-12-18T18:33:01",
      dataUltimaAtualizacao: "2023-12-18T18:33:19",
      status: "EFETIVADA",
      qtdAnimais: 29,
    },
    vendaHistorico: [
      {
        idBlockchain: "eb66af4e-769b-4269-9378-dba27a1c0947",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:21:39",
        dataUltimaAtualizacao: "2023-12-18T18:24:08",
        status: "RECUSADA",
        qtdAnimais: 29,
      },
      {
        idBlockchain: "0401071b-4fe8-4b0e-ba81-d94b5ab0b970",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:33:01",
        dataUltimaAtualizacao: "2023-12-18T18:33:19",
        status: "EFETIVADA",
        qtdAnimais: 29,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [
      {
        dtCriacao: "2023-12-18T14:16:17",
        dtAlteracao: "2023-12-18T14:16:17",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "irancho-mvp",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipoExame: "ULTRASSOM_CARCACA",
        dtExame: "2023-05-11T11:31:50",
        vrResultado: "",
        vrResultadoAol: "77.81",
        vrResultadoEgs: "9.88",
        vrResultadoEgg: "0",
        vrResultadoMar: "3.74",
        vrResultadoRatio: "0",
        qtDiasPrenhe: null,
        icApresentouCio: null,
        idExameBlockchain: "bd7336f7-1186-40f8-9271-311244de84ef",
      },
    ],
    medicamentosAplicados: [
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "irancho-mvp",
        idOriginalMedicamentoBlockchain: "a98e882b-a392-4bfd-bb22-057e47b66cad",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        dtMedicacao: "2022-04-26T14:56:58",
        unidadeMedida: "MILILITRO",
        quantidade: 4.46,
        idMedicamentoBlockchain: "1524c2fa-b75a-4037-86fe-efb1708effb2",
        medicamento: {
          idBlockchain: "a98e882b-a392-4bfd-bb22-057e47b66cad",
          nome: "Iver-vet ADE 1000 mL",
          categoria: "FARMACIA",
          subCategoria: "VERMIFUGO",
          fabricante: "Biovet",
          unidade: "MILILITRO",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "irancho-mvp",
        idOriginalMedicamentoBlockchain: "7838ec4f-23ec-49fc-87b1-3f6fdcc0ba70",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        dtMedicacao: "2022-04-26T14:56:58",
        unidadeMedida: "MILILITRO",
        quantidade: 2,
        idMedicamentoBlockchain: "3be2b71d-e8cc-4e90-9150-be08959bd087",
        medicamento: {
          idBlockchain: "7838ec4f-23ec-49fc-87b1-3f6fdcc0ba70",
          nome: "Aftosa ourovac 50 doses ",
          categoria: "FARMACIA",
          subCategoria: "VACINA",
          fabricante: "Ouro fino",
          unidade: "MILILITRO",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
    ],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:16:02",
      dtAlteracao: "2023-12-18T14:16:02",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      fazenda: {
        nome: "Fazenda União do Brasil ",
        dataEntrada: "2022-11-28T00:00:00",
        dataSaida: "2023-05-11T00:00:00",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
      },
      subdivisao: {
        nome: "Confinamento",
        dataEntrada: "2022-11-28T00:00:00",
        dataSaida: "2023-05-11T00:00:00",
      },
      idSistemaGestao: "5e39b16a-85cd-4aaf-b6ab-ffe2f89f15ed",
      idLocalizacaoBlockchain: "85c4bf3c-e750-429f-b93c-48eae6845916",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Vale do Sol ",
          dataEntrada: "2022-05-02T00:00:00",
          dataSaida: "2022-11-28T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "Desmama",
          dataEntrada: "2022-05-02T00:00:00",
          dataSaida: "2022-11-28T00:00:00",
        },
        idSistemaGestao: "5e39b16a-85cd-4aaf-b6ab-ffe2f89f15ed",
        idLocalizacaoBlockchain: "7755fd78-c2d9-4d5a-ba14-2f1204322306",
      },
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Fazenda União do Brasil ",
          dataEntrada: "2022-11-28T00:00:00",
          dataSaida: "2023-05-11T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "Confinamento",
          dataEntrada: "2022-11-28T00:00:00",
          dataSaida: "2023-05-11T00:00:00",
        },
        idSistemaGestao: "5e39b16a-85cd-4aaf-b6ab-ffe2f89f15ed",
        idLocalizacaoBlockchain: "85c4bf3c-e750-429f-b93c-48eae6845916",
      },
    ],
    loteHistorico: [],
    idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
    nomeResponsavel: "Fazenda União do Brasil",
    frigorifico: {
      nome: "Frigorífico Red Beef LTDA",
      maturidade: "0 dentes",
      acabamento: "Uniforme",
      pesoBanda1: "135.75 Kg",
      pesoBanda2: "135.75 Kg",
      obs1: "",
      obs2: "",
      dtMorte: "2023-05-11",
      porcentagemCarcaca: "53%",
      dtCriacao: "2023-12-18T18:57:50",
      dtAlteracao: "2023-12-18T18:57:50",
    },
  },
  "57d568b7-ef94-488b-af00-630928766af6": {
    idLoteSync: "ec1f85a1-7f04-468d-ac28-2bfdf17d33b5",
    idBlockchain: "57d568b7-ef94-488b-af00-630928766af6",
    docType: "ANIMAL",
    idSistemaGestao: "7ad94015-24b9-4c80-86ea-f90f84c73d14",
    idSisbov: "",
    idICar: "982000444275752",
    nome: "",
    dataNascimento: "2022-04-02",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "982000444275752",
    idAnimalFazenda: "4275752",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Santa Gertrudis", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:15:38",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      idSistemaGestao: "1b031001-bf05-44f9-aade-862fd33c5b47",
      idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
      nomeResponsavel: "Fazenda União do Brasil",
      latitude: "",
      longitude: "",
      tipo: "Saída",
      peso: 447,
      dtPesagem: "2023-11-07",
      idPesagemBlockchain: "f9de39bf-3179-4a57-aff2-e6f83baac913",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "cee2e250-ead8-4e2b-841d-f518868ddb77",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Entrada",
        peso: 276,
        dtPesagem: "2023-06-01",
        idPesagemBlockchain: "b74abd1c-dfb8-4cb0-a13e-753879e96707",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "f8a3f771-fa62-420b-824f-d911f3763138",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 282,
        dtPesagem: "2023-06-27",
        idPesagemBlockchain: "644da828-ff25-47f6-b851-c75afa1aee51",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "687920b1-84ab-4297-b0ca-6e588e449003",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 357,
        dtPesagem: "2023-09-04",
        idPesagemBlockchain: "fb84d626-115e-4f26-bd5d-f039fe3de2e4",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "1b031001-bf05-44f9-aade-862fd33c5b47",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Saída",
        peso: 447,
        dtPesagem: "2023-11-07",
        idPesagemBlockchain: "f9de39bf-3179-4a57-aff2-e6f83baac913",
      },
    ],
    produtorAtual: {
      idBlockchain: "25bc0b49-7cf7-4519-b70c-3001a1e4bfed",
      nome: "Fazenda União do Brasil",
      documento: "08090246000103",
    },
    status: "ABATIDO",
    dataCriacao: "2023-12-18T13:22:59",
    dataUltimaAtualizacao: "2023-12-18T18:49:37",
    vendaAtual: {
      idBlockchain: "e0130d89-b68f-423b-ad3d-a429637fb5a3",
      documentoProprietarioDestino: "18293459000196",
      tipo: "INDUSTRIA",
      proprietarioOrigem: {
        nome: "Fazenda União do Brasil",
        documento: "08090246000103",
      },
      proprietarioDestino: {
        nome: "Guidara Indústria e Comércio de Alimentos Eireli",
        documento: "18293459000196",
      },
      dataCriacao: "2023-12-18T18:27:49",
      dataUltimaAtualizacao: "2023-12-18T18:28:02",
      status: "EFETIVADA",
      qtdAnimais: 25,
    },
    vendaHistorico: [
      {
        idBlockchain: "f10b303c-ab60-4d04-b8b0-0f217cda4abb",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:22:09",
        dataUltimaAtualizacao: "2023-12-18T18:24:42",
        status: "RECUSADA",
        qtdAnimais: 25,
      },
      {
        idBlockchain: "f1392d6d-fc67-43f4-918a-1da3da56a459",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:26:52",
        dataUltimaAtualizacao: "2023-12-18T18:27:14",
        status: "RECUSADA",
        qtdAnimais: 25,
      },
      {
        idBlockchain: "e0130d89-b68f-423b-ad3d-a429637fb5a3",
        documentoProprietarioDestino: "18293459000196",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Guidara Indústria e Comércio de Alimentos Eireli",
          documento: "18293459000196",
        },
        dataCriacao: "2023-12-18T18:27:49",
        dataUltimaAtualizacao: "2023-12-18T18:28:02",
        status: "EFETIVADA",
        qtdAnimais: 25,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [
      {
        dtCriacao: "2023-12-18T14:16:17",
        dtAlteracao: "2023-12-18T14:16:17",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "be3778c7-8b51-4a62-b778-c5e0d89162e9",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipoExame: "ULTRASSOM_CARCACA",
        dtExame: "2023-09-04",
        vrResultado: "",
        vrResultadoAol: "80.15",
        vrResultadoEgs: "7.53",
        vrResultadoEgg: "0.00",
        vrResultadoMar: "3.35",
        vrResultadoRatio: "0.48",
        qtDiasPrenhe: null,
        icApresentouCio: null,
        idExameBlockchain: "cc0d6c10-5ff3-4a27-bb92-84ffa0f56f69",
      },
    ],
    medicamentosAplicados: [
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "1e151a84-227a-452b-a14c-6a020489da48",
        idOriginalMedicamentoBlockchain: "ba97487b-264d-4640-887a-1740557e1093",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        dtMedicacao: "2023-06-01",
        unidadeMedida: "MILILITRO",
        quantidade: 5,
        idMedicamentoBlockchain: "23a3e383-9dc4-46db-add9-3ef5432efe3a",
        medicamento: {
          idBlockchain: "ba97487b-264d-4640-887a-1740557e1093",
          nome: "Iver-vet 3.5 injetável 1000 mL",
          categoria: "FARMACIA",
          subCategoria: "VERMIFUGO",
          fabricante: "Biovet",
          unidade: "LITRO",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "ade8b455-979e-4da7-bc3d-1e2713c554e7",
        idOriginalMedicamentoBlockchain: "03a88948-7528-45c1-873a-b8d6012f9c15",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        dtMedicacao: "2023-06-27",
        unidadeMedida: "UNIDADE",
        quantidade: 2,
        idMedicamentoBlockchain: "acd8e4be-0097-4f76-a286-a341026a9343",
        medicamento: {
          idBlockchain: "03a88948-7528-45c1-873a-b8d6012f9c15",
          nome: "Vacina Bovi-Shield",
          categoria: "FARMACIA",
          subCategoria: "VACINA",
          fabricante: "Zoetis",
          unidade: "UNIDADE",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
    ],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:16:02",
      dtAlteracao: "2023-12-18T14:16:02",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      fazenda: {
        nome: "Fazenda União do Brasil ",
        dataEntrada: "2023-06-27",
        dataSaida: "2023-11-07",
        latitude: "-23.68423843",
        longitude: "-48.59685500",
      },
      subdivisao: {
        nome: "Confinamento",
        dataEntrada: "2023-06-27",
        dataSaida: "2023-11-07",
      },
      idSistemaGestao: "5015334",
      idLocalizacaoBlockchain: "1c3c2cc7-743a-4afe-b0b6-754ada56aded",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Fazenda União do Brasil ",
          dataEntrada: "2023-06-27",
          dataSaida: "2023-11-07",
          latitude: "-23.68423843",
          longitude: "-48.59685500",
        },
        subdivisao: {
          nome: "Confinamento",
          dataEntrada: "2023-06-27",
          dataSaida: "2023-11-07",
        },
        idSistemaGestao: "5015334",
        idLocalizacaoBlockchain: "1c3c2cc7-743a-4afe-b0b6-754ada56aded",
      },
    ],
    loteHistorico: [],
    idResponsavel: "fdd249be-f2a0-402a-9213-c40dff9f7c9f",
    nomeResponsavel: "BARTIRA AGROPECUARIA S/A",
    frigorifico: {
      nome: "Guidara Industria",
      maturidade: "2 dentes",
      acabamento: "Uniforme",
      pesoBanda1: "120.25",
      pesoBanda2: "120.25",
      obs1: "",
      obs2: "",
      dtMorte: "2023-11-08T00:00:00",
      porcentagemCarcaca: "54.90",
      dtCriacao: "2023-12-18T18:49:37",
      dtAlteracao: "2023-12-18T18:49:37",
    },
  },
  "5bb925b0-8609-495f-84ae-70e76c4c5254": {
    idLoteSync: "ec1f85a1-7f04-468d-ac28-2bfdf17d33b5",
    idBlockchain: "5bb925b0-8609-495f-84ae-70e76c4c5254",
    docType: "ANIMAL",
    idSistemaGestao: "2adfbdda-b25a-4768-be0a-feb49915da4e",
    idSisbov: "",
    idICar: "982000444278719",
    nome: "",
    dataNascimento: "2021-02-27",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "982000444278719",
    idAnimalFazenda: "3210237",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Santa Gertrudis", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:15:38",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      idSistemaGestao: "2adfbdda-b25a-4768-be0a-feb49915da4e",
      idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
      nomeResponsavel: "Fazenda União do Brasil",
      latitude: "-23.6825667",
      longitude: "-48.5986721",
      tipo: "Saída",
      peso: 564,
      dtPesagem: "2023-05-11T11:31:50",
      idPesagemBlockchain: "c80103aa-3fd1-47b5-a5ca-3c1949612f88",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "2adfbdda-b25a-4768-be0a-feb49915da4e",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Nascimento",
        peso: 27,
        dtPesagem: "2021-02-27T03:00:00",
        idPesagemBlockchain: "f1dbb01e-547f-412e-942b-2e8b87a13814",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "2adfbdda-b25a-4768-be0a-feb49915da4e",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 175,
        dtPesagem: "2021-11-23T19:49:06",
        idPesagemBlockchain: "3ba09a33-24eb-4822-9606-ec431cd1b2ef",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "2adfbdda-b25a-4768-be0a-feb49915da4e",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Desmame",
        peso: 172,
        dtPesagem: "2021-11-29T13:02:31",
        idPesagemBlockchain: "c39cbd4c-0561-4557-a8a3-64e66ec39113",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "2adfbdda-b25a-4768-be0a-feb49915da4e",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 334,
        dtPesagem: "2022-10-04T07:40:54",
        idPesagemBlockchain: "504f060a-9edb-4502-bf4f-9af01ac53e42",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "2adfbdda-b25a-4768-be0a-feb49915da4e",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 437,
        dtPesagem: "2023-02-17T13:41:34",
        idPesagemBlockchain: "fb62a21f-4797-4ca1-8b0a-c2a5c2f6f575",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "2adfbdda-b25a-4768-be0a-feb49915da4e",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Saída",
        peso: 564,
        dtPesagem: "2023-05-11T11:31:50",
        idPesagemBlockchain: "c80103aa-3fd1-47b5-a5ca-3c1949612f88",
      },
    ],
    produtorAtual: {
      idBlockchain: "25bc0b49-7cf7-4519-b70c-3001a1e4bfed",
      nome: "Fazenda União do Brasil",
      documento: "08090246000103",
    },
    status: "ABATIDO",
    dataCriacao: "2023-12-18T13:22:59",
    dataUltimaAtualizacao: "2023-12-18T18:55:51",
    vendaAtual: {
      idBlockchain: "0401071b-4fe8-4b0e-ba81-d94b5ab0b970",
      documentoProprietarioDestino: "30269671000113",
      tipo: "INDUSTRIA",
      proprietarioOrigem: {
        nome: "Fazenda União do Brasil",
        documento: "08090246000103",
      },
      proprietarioDestino: {
        nome: "Frigorífico Red Beef LTDA",
        documento: "30269671000113",
      },
      dataCriacao: "2023-12-18T18:33:01",
      dataUltimaAtualizacao: "2023-12-18T18:33:19",
      status: "EFETIVADA",
      qtdAnimais: 29,
    },
    vendaHistorico: [
      {
        idBlockchain: "eb66af4e-769b-4269-9378-dba27a1c0947",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:21:39",
        dataUltimaAtualizacao: "2023-12-18T18:24:08",
        status: "RECUSADA",
        qtdAnimais: 29,
      },
      {
        idBlockchain: "0401071b-4fe8-4b0e-ba81-d94b5ab0b970",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:33:01",
        dataUltimaAtualizacao: "2023-12-18T18:33:19",
        status: "EFETIVADA",
        qtdAnimais: 29,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [
      {
        dtCriacao: "2023-12-18T14:16:17",
        dtAlteracao: "2023-12-18T14:16:17",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "irancho-mvp",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipoExame: "ULTRASSOM_CARCACA",
        dtExame: "2023-05-11T11:31:50",
        vrResultado: "",
        vrResultadoAol: "102.78",
        vrResultadoEgs: "9.88",
        vrResultadoEgg: "0",
        vrResultadoMar: "4.49",
        vrResultadoRatio: "0",
        qtDiasPrenhe: null,
        icApresentouCio: null,
        idExameBlockchain: "c93f6e57-9a2e-4643-aaa3-cb3f63247da8",
      },
    ],
    medicamentosAplicados: [
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "irancho-mvp",
        idOriginalMedicamentoBlockchain: "40aa0e8c-5ceb-4b79-bd44-253139e57a21",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        dtMedicacao: "2021-06-07T12:00:40",
        unidadeMedida: "MILILITRO",
        quantidade: 2,
        idMedicamentoBlockchain: "64cc37be-08b8-45db-b74d-30245aa534e3",
        medicamento: {
          idBlockchain: "40aa0e8c-5ceb-4b79-bd44-253139e57a21",
          nome: "Aborvac Brucelose",
          categoria: "FARMACIA",
          subCategoria: "VACINA",
          fabricante: "Zoetis",
          unidade: "MILILITRO",
          sexo: "FEMEA",
          idadeMaxima: 8,
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
    ],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:16:02",
      dtAlteracao: "2023-12-18T14:16:02",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      fazenda: {
        nome: "Fazenda União do Brasil ",
        dataEntrada: "2022-10-04T00:00:00",
        dataSaida: "2023-05-11T00:00:00",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
      },
      subdivisao: {
        nome: "Confinamento",
        dataEntrada: "2022-10-04T00:00:00",
        dataSaida: "2023-05-11T00:00:00",
      },
      idSistemaGestao: "2adfbdda-b25a-4768-be0a-feb49915da4e",
      idLocalizacaoBlockchain: "825bd709-d938-4d3e-a3a2-467e4cecc08f",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Fazenda União do Brasil ",
          dataEntrada: "2021-08-31T00:00:00",
          dataSaida: "2021-11-29T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "Confinamento",
          dataEntrada: "2021-08-31T00:00:00",
          dataSaida: "2021-11-29T00:00:00",
        },
        idSistemaGestao: "2adfbdda-b25a-4768-be0a-feb49915da4e",
        idLocalizacaoBlockchain: "035e13b4-8122-46b2-9864-113710c31ad4",
      },
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Vale do Sol ",
          dataEntrada: "2021-11-29T00:00:00",
          dataSaida: "2022-10-04T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "Desmama",
          dataEntrada: "2021-11-29T00:00:00",
          dataSaida: "2022-10-04T00:00:00",
        },
        idSistemaGestao: "2adfbdda-b25a-4768-be0a-feb49915da4e",
        idLocalizacaoBlockchain: "196540bc-ffda-4739-94cd-572dbd3f30c3",
      },
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Fazenda União do Brasil ",
          dataEntrada: "2022-10-04T00:00:00",
          dataSaida: "2023-05-11T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "Confinamento",
          dataEntrada: "2022-10-04T00:00:00",
          dataSaida: "2023-05-11T00:00:00",
        },
        idSistemaGestao: "2adfbdda-b25a-4768-be0a-feb49915da4e",
        idLocalizacaoBlockchain: "825bd709-d938-4d3e-a3a2-467e4cecc08f",
      },
    ],
    loteHistorico: [],
    idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
    nomeResponsavel: "Fazenda União do Brasil",
    frigorifico: {
      nome: "Frigorífico Red Beef LTDA",
      maturidade: "2 dentes",
      acabamento: "Uniforme",
      pesoBanda1: "155.00 Kg",
      pesoBanda2: "155.00 Kg",
      obs1: "",
      obs2: "",
      dtMorte: "2023-05-11",
      porcentagemCarcaca: "55%",
      dtCriacao: "2023-12-18T18:55:51",
      dtAlteracao: "2023-12-18T18:55:51",
    },
  },
  "5c56b3b3-2dc0-43b6-b768-d5b566e39345": {
    idLoteSync: "6ec5a0cc-80ab-4bb2-82d0-2138b92adcd3",
    idBlockchain: "5c56b3b3-2dc0-43b6-b768-d5b566e39345",
    docType: "ANIMAL",
    idSistemaGestao: "irancho-mvp",
    idSisbov: "123456789123456",
    idICar: "",
    nome: "Teste Renomeando",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "9000442443813",
    idAnimalFazenda: "A05",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "25bc0b49-7cf7-4519-b70c-3001a1e4bfed",
      nome: "Fazenda União do Brasil",
      documento: "08090246000103",
    },
    status: "VIVO",
    dataCriacao: "2024-01-16T11:57:18",
    dataUltimaAtualizacao: "2024-01-16T12:33:46",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: null,
    nomeResponsavel: null,
    frigorifico: null,
  },
  "6c452caa-9051-4e87-b0dc-05080e168127": {
    idLoteSync: "ec1f85a1-7f04-468d-ac28-2bfdf17d33b5",
    idBlockchain: "6c452caa-9051-4e87-b0dc-05080e168127",
    docType: "ANIMAL",
    idSistemaGestao: "363d9c2f-67f2-4e7e-a26c-990546b84722",
    idSisbov: "",
    idICar: "982000444273570",
    nome: "",
    dataNascimento: "2022-04-02",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "982000444273570",
    idAnimalFazenda: "4273570",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Santa Gertrudis", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:15:38",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      idSistemaGestao: "79424cff-8bbf-4f8e-b124-b1853db2fc53",
      idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
      nomeResponsavel: "Fazenda União do Brasil",
      latitude: "",
      longitude: "",
      tipo: "Saída",
      peso: 468,
      dtPesagem: "2023-11-07",
      idPesagemBlockchain: "ad089360-eb13-4bd4-9a54-016fd7b1fc62",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "8fb62eaf-77d2-4c2c-b28d-c7cd78b390cd",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Entrada",
        peso: 260,
        dtPesagem: "2023-06-01",
        idPesagemBlockchain: "d9574410-8be8-447d-80d0-932548166c59",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "698a067c-47c8-407e-9062-5f8769c603ee",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 251,
        dtPesagem: "2023-06-27",
        idPesagemBlockchain: "f6eda50d-2c35-42c4-8f90-c0b98bec1737",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "f4232d47-92ab-4711-914d-31c1e41b3c7b",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 354,
        dtPesagem: "2023-09-04",
        idPesagemBlockchain: "237ed536-23dc-4762-98f0-eb928f4edff1",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "79424cff-8bbf-4f8e-b124-b1853db2fc53",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Saída",
        peso: 468,
        dtPesagem: "2023-11-07",
        idPesagemBlockchain: "ad089360-eb13-4bd4-9a54-016fd7b1fc62",
      },
    ],
    produtorAtual: {
      idBlockchain: "25bc0b49-7cf7-4519-b70c-3001a1e4bfed",
      nome: "Fazenda União do Brasil",
      documento: "08090246000103",
    },
    status: "ABATIDO",
    dataCriacao: "2023-12-18T13:22:59",
    dataUltimaAtualizacao: "2023-12-18T18:48:13",
    vendaAtual: {
      idBlockchain: "e0130d89-b68f-423b-ad3d-a429637fb5a3",
      documentoProprietarioDestino: "18293459000196",
      tipo: "INDUSTRIA",
      proprietarioOrigem: {
        nome: "Fazenda União do Brasil",
        documento: "08090246000103",
      },
      proprietarioDestino: {
        nome: "Guidara Indústria e Comércio de Alimentos Eireli",
        documento: "18293459000196",
      },
      dataCriacao: "2023-12-18T18:27:49",
      dataUltimaAtualizacao: "2023-12-18T18:28:02",
      status: "EFETIVADA",
      qtdAnimais: 25,
    },
    vendaHistorico: [
      {
        idBlockchain: "f10b303c-ab60-4d04-b8b0-0f217cda4abb",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:22:09",
        dataUltimaAtualizacao: "2023-12-18T18:24:42",
        status: "RECUSADA",
        qtdAnimais: 25,
      },
      {
        idBlockchain: "f1392d6d-fc67-43f4-918a-1da3da56a459",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:26:52",
        dataUltimaAtualizacao: "2023-12-18T18:27:14",
        status: "RECUSADA",
        qtdAnimais: 25,
      },
      {
        idBlockchain: "e0130d89-b68f-423b-ad3d-a429637fb5a3",
        documentoProprietarioDestino: "18293459000196",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Guidara Indústria e Comércio de Alimentos Eireli",
          documento: "18293459000196",
        },
        dataCriacao: "2023-12-18T18:27:49",
        dataUltimaAtualizacao: "2023-12-18T18:28:02",
        status: "EFETIVADA",
        qtdAnimais: 25,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [
      {
        dtCriacao: "2023-12-18T14:16:17",
        dtAlteracao: "2023-12-18T14:16:17",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "0c1a6427-8121-44c3-847f-92885addd0d6",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipoExame: "ULTRASSOM_CARCACA",
        dtExame: "2023-09-04",
        vrResultado: "",
        vrResultadoAol: "67.13",
        vrResultadoEgs: "3.76",
        vrResultadoEgg: "0.00",
        vrResultadoMar: "3.59",
        vrResultadoRatio: "0.50",
        qtDiasPrenhe: null,
        icApresentouCio: null,
        idExameBlockchain: "ca1ebe8e-3bb5-49b0-a9e8-48c743431ba5",
      },
    ],
    medicamentosAplicados: [
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "7bcf142f-4349-40e5-97af-156f6a00971b",
        idOriginalMedicamentoBlockchain: "03a88948-7528-45c1-873a-b8d6012f9c15",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        dtMedicacao: "2023-06-27",
        unidadeMedida: "UNIDADE",
        quantidade: 2,
        idMedicamentoBlockchain: "cddb4d38-437e-4f91-943b-5057847f4ee6",
        medicamento: {
          idBlockchain: "03a88948-7528-45c1-873a-b8d6012f9c15",
          nome: "Vacina Bovi-Shield",
          categoria: "FARMACIA",
          subCategoria: "VACINA",
          fabricante: "Zoetis",
          unidade: "UNIDADE",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "e236a960-c19e-43e7-98d9-f134189df8b6",
        idOriginalMedicamentoBlockchain: "ba97487b-264d-4640-887a-1740557e1093",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        dtMedicacao: "2023-06-01",
        unidadeMedida: "MILILITRO",
        quantidade: 5,
        idMedicamentoBlockchain: "29c3eb33-1cda-4bf1-b122-62f046c0c8eb",
        medicamento: {
          idBlockchain: "ba97487b-264d-4640-887a-1740557e1093",
          nome: "Iver-vet 3.5 injetável 1000 mL",
          categoria: "FARMACIA",
          subCategoria: "VERMIFUGO",
          fabricante: "Biovet",
          unidade: "LITRO",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
    ],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:16:02",
      dtAlteracao: "2023-12-18T14:16:02",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      fazenda: {
        nome: "Fazenda União do Brasil ",
        dataEntrada: "2023-06-27",
        dataSaida: "2023-11-07",
        latitude: "-23.68423843",
        longitude: "-48.59685500",
      },
      subdivisao: {
        nome: "Confinamento",
        dataEntrada: "2023-06-27",
        dataSaida: "2023-11-07",
      },
      idSistemaGestao: "5015340",
      idLocalizacaoBlockchain: "5912b0d6-10d6-41a6-8a49-23d8611be6a7",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Fazenda União do Brasil ",
          dataEntrada: "2023-06-27",
          dataSaida: "2023-11-07",
          latitude: "-23.68423843",
          longitude: "-48.59685500",
        },
        subdivisao: {
          nome: "Confinamento",
          dataEntrada: "2023-06-27",
          dataSaida: "2023-11-07",
        },
        idSistemaGestao: "5015340",
        idLocalizacaoBlockchain: "5912b0d6-10d6-41a6-8a49-23d8611be6a7",
      },
    ],
    loteHistorico: [],
    idResponsavel: "fdd249be-f2a0-402a-9213-c40dff9f7c9f",
    nomeResponsavel: "BARTIRA AGROPECUARIA S/A",
    frigorifico: {
      nome: "Guidara Industria",
      maturidade: "2 dentes",
      acabamento: "Uniforme",
      pesoBanda1: "125.75",
      pesoBanda2: "125.75",
      obs1: "",
      obs2: "",
      dtMorte: "2023-11-08T00:00:00",
      porcentagemCarcaca: "55.50",
      dtCriacao: "2023-12-18T18:48:13",
      dtAlteracao: "2023-12-18T18:48:13",
    },
  },
  "7177c5e3-59fd-497c-85f7-20a0f28a9d0e": {
    idLoteSync: "ec1f85a1-7f04-468d-ac28-2bfdf17d33b5",
    idBlockchain: "7177c5e3-59fd-497c-85f7-20a0f28a9d0e",
    docType: "ANIMAL",
    idSistemaGestao: "6bd8b81a-f2e8-4f62-897e-296e56f3fde6",
    idSisbov: "",
    idICar: "982000444275765",
    nome: "",
    dataNascimento: "2022-04-02",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "982000444275765",
    idAnimalFazenda: "4275765",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Santa Gertrudis", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:15:38",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      idSistemaGestao: "c52841a2-2542-4adc-8659-5a518add3d58",
      idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
      nomeResponsavel: "Fazenda União do Brasil",
      latitude: "",
      longitude: "",
      tipo: "Saída",
      peso: 475,
      dtPesagem: "2023-11-07",
      idPesagemBlockchain: "82b03c10-1854-4b9e-a00a-ae636d698e16",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "eed2dca8-f036-4d87-85d4-f316b522e14e",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Entrada",
        peso: 260,
        dtPesagem: "2023-06-01",
        idPesagemBlockchain: "799206c9-6a4a-4cfc-96e5-6936f5851f93",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "21e51be1-6284-47d5-98a9-9fbfcc57dc02",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 259,
        dtPesagem: "2023-06-27",
        idPesagemBlockchain: "1ce359c2-2ff7-4927-ac7c-3050f76c0629",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "641f36ce-a8e6-439e-88ed-feeec1cb54ad",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 378,
        dtPesagem: "2023-09-04",
        idPesagemBlockchain: "4ce37b60-835f-46e7-a61b-65dce17ef051",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "c52841a2-2542-4adc-8659-5a518add3d58",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Saída",
        peso: 475,
        dtPesagem: "2023-11-07",
        idPesagemBlockchain: "82b03c10-1854-4b9e-a00a-ae636d698e16",
      },
    ],
    produtorAtual: {
      idBlockchain: "25bc0b49-7cf7-4519-b70c-3001a1e4bfed",
      nome: "Fazenda União do Brasil",
      documento: "08090246000103",
    },
    status: "ABATIDO",
    dataCriacao: "2023-12-18T13:22:59",
    dataUltimaAtualizacao: "2023-12-18T18:46:11",
    vendaAtual: {
      idBlockchain: "e0130d89-b68f-423b-ad3d-a429637fb5a3",
      documentoProprietarioDestino: "18293459000196",
      tipo: "INDUSTRIA",
      proprietarioOrigem: {
        nome: "Fazenda União do Brasil",
        documento: "08090246000103",
      },
      proprietarioDestino: {
        nome: "Guidara Indústria e Comércio de Alimentos Eireli",
        documento: "18293459000196",
      },
      dataCriacao: "2023-12-18T18:27:49",
      dataUltimaAtualizacao: "2023-12-18T18:28:02",
      status: "EFETIVADA",
      qtdAnimais: 25,
    },
    vendaHistorico: [
      {
        idBlockchain: "f10b303c-ab60-4d04-b8b0-0f217cda4abb",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:22:09",
        dataUltimaAtualizacao: "2023-12-18T18:24:42",
        status: "RECUSADA",
        qtdAnimais: 25,
      },
      {
        idBlockchain: "f1392d6d-fc67-43f4-918a-1da3da56a459",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:26:52",
        dataUltimaAtualizacao: "2023-12-18T18:27:14",
        status: "RECUSADA",
        qtdAnimais: 25,
      },
      {
        idBlockchain: "e0130d89-b68f-423b-ad3d-a429637fb5a3",
        documentoProprietarioDestino: "18293459000196",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Guidara Indústria e Comércio de Alimentos Eireli",
          documento: "18293459000196",
        },
        dataCriacao: "2023-12-18T18:27:49",
        dataUltimaAtualizacao: "2023-12-18T18:28:02",
        status: "EFETIVADA",
        qtdAnimais: 25,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [
      {
        dtCriacao: "2023-12-18T14:16:17",
        dtAlteracao: "2023-12-18T14:16:17",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "89747e86-b833-4773-96ca-7c32d4c05b0f",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipoExame: "ULTRASSOM_CARCACA",
        dtExame: "2023-09-04",
        vrResultado: "",
        vrResultadoAol: "80.16",
        vrResultadoEgs: "4.70",
        vrResultadoEgg: "0.00",
        vrResultadoMar: "3.34",
        vrResultadoRatio: "0.49",
        qtDiasPrenhe: null,
        icApresentouCio: null,
        idExameBlockchain: "176776a7-2859-4fea-af7b-226c72a70aff",
      },
    ],
    medicamentosAplicados: [
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "3d2b6ba5-2a3e-4d00-aaf7-71e938123b7a",
        idOriginalMedicamentoBlockchain: "ba97487b-264d-4640-887a-1740557e1093",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        dtMedicacao: "2023-06-01",
        unidadeMedida: "MILILITRO",
        quantidade: 5,
        idMedicamentoBlockchain: "63ac1253-9b3a-41cc-ad02-bd21aec7aa11",
        medicamento: {
          idBlockchain: "ba97487b-264d-4640-887a-1740557e1093",
          nome: "Iver-vet 3.5 injetável 1000 mL",
          categoria: "FARMACIA",
          subCategoria: "VERMIFUGO",
          fabricante: "Biovet",
          unidade: "LITRO",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "4a8d1d6c-dad6-449d-ad55-1a78782ecf9a",
        idOriginalMedicamentoBlockchain: "03a88948-7528-45c1-873a-b8d6012f9c15",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        dtMedicacao: "2023-06-27",
        unidadeMedida: "UNIDADE",
        quantidade: 2,
        idMedicamentoBlockchain: "5dc3cfb0-4dfd-40d2-93c5-e6748b73eec4",
        medicamento: {
          idBlockchain: "03a88948-7528-45c1-873a-b8d6012f9c15",
          nome: "Vacina Bovi-Shield",
          categoria: "FARMACIA",
          subCategoria: "VACINA",
          fabricante: "Zoetis",
          unidade: "UNIDADE",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
    ],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:16:02",
      dtAlteracao: "2023-12-18T14:16:02",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      fazenda: {
        nome: "Fazenda União do Brasil ",
        dataEntrada: "2023-06-27",
        dataSaida: "2023-11-07",
        latitude: "-23.68423843",
        longitude: "-48.59685500",
      },
      subdivisao: {
        nome: "Confinamento",
        dataEntrada: "2023-06-27",
        dataSaida: "2023-11-07",
      },
      idSistemaGestao: "5015420",
      idLocalizacaoBlockchain: "769c1468-1462-48c5-b593-9c7c385d727d",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Fazenda União do Brasil ",
          dataEntrada: "2023-06-27",
          dataSaida: "2023-11-07",
          latitude: "-23.68423843",
          longitude: "-48.59685500",
        },
        subdivisao: {
          nome: "Confinamento",
          dataEntrada: "2023-06-27",
          dataSaida: "2023-11-07",
        },
        idSistemaGestao: "5015420",
        idLocalizacaoBlockchain: "769c1468-1462-48c5-b593-9c7c385d727d",
      },
    ],
    loteHistorico: [],
    idResponsavel: "fdd249be-f2a0-402a-9213-c40dff9f7c9f",
    nomeResponsavel: "BARTIRA AGROPECUARIA S/A",
    frigorifico: {
      nome: "Guidara Industria",
      maturidade: "2 dentes",
      acabamento: "Uniforme",
      pesoBanda1: "134",
      pesoBanda2: "134",
      obs1: "",
      obs2: "",
      dtMorte: "2023-11-08T00:00:00",
      porcentagemCarcaca: "57.30",
      dtCriacao: "2023-12-18T18:46:11",
      dtAlteracao: "2023-12-18T18:46:11",
    },
  },
  "765feed0-a2d7-4c9d-a265-49490263ce7c": {
    idLoteSync: "ec1f85a1-7f04-468d-ac28-2bfdf17d33b5",
    idBlockchain: "765feed0-a2d7-4c9d-a265-49490263ce7c",
    docType: "ANIMAL",
    idSistemaGestao: "76b34fd2-a71a-4d22-9466-6f468808e0ff",
    idSisbov: "",
    idICar: "982000444274877",
    nome: "",
    dataNascimento: "2022-04-02",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "982000444274877",
    idAnimalFazenda: "4274877",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Santa Gertrudis", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:15:38",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      idSistemaGestao: "9792042b-44d6-489b-963e-3854923f1d10",
      idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
      nomeResponsavel: "Fazenda União do Brasil",
      latitude: "",
      longitude: "",
      tipo: "Saída",
      peso: 487,
      dtPesagem: "2023-11-07",
      idPesagemBlockchain: "4ab4b631-b56f-4764-8a4d-dc1f5bf11ff6",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "579d2359-d857-4f86-8274-ca081d11fab2",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Entrada",
        peso: 268,
        dtPesagem: "2023-06-01",
        idPesagemBlockchain: "c5003415-93e7-4663-9232-06edbc43e2fc",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "c5beffb7-7b3f-443f-9ff3-1a66ba5bf600",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 270,
        dtPesagem: "2023-06-27",
        idPesagemBlockchain: "9bdebda9-db62-4955-98aa-1058e22c859c",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "33d67f66-50cb-4c0c-85aa-09964e119827",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 389,
        dtPesagem: "2023-09-04",
        idPesagemBlockchain: "fad4e806-7066-49e1-8a22-5258be11ada0",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "9792042b-44d6-489b-963e-3854923f1d10",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Saída",
        peso: 487,
        dtPesagem: "2023-11-07",
        idPesagemBlockchain: "4ab4b631-b56f-4764-8a4d-dc1f5bf11ff6",
      },
    ],
    produtorAtual: {
      idBlockchain: "25bc0b49-7cf7-4519-b70c-3001a1e4bfed",
      nome: "Fazenda União do Brasil",
      documento: "08090246000103",
    },
    status: "ABATIDO",
    dataCriacao: "2023-12-18T13:22:59",
    dataUltimaAtualizacao: "2023-12-18T18:45:58",
    vendaAtual: {
      idBlockchain: "e0130d89-b68f-423b-ad3d-a429637fb5a3",
      documentoProprietarioDestino: "18293459000196",
      tipo: "INDUSTRIA",
      proprietarioOrigem: {
        nome: "Fazenda União do Brasil",
        documento: "08090246000103",
      },
      proprietarioDestino: {
        nome: "Guidara Indústria e Comércio de Alimentos Eireli",
        documento: "18293459000196",
      },
      dataCriacao: "2023-12-18T18:27:49",
      dataUltimaAtualizacao: "2023-12-18T18:28:02",
      status: "EFETIVADA",
      qtdAnimais: 25,
    },
    vendaHistorico: [
      {
        idBlockchain: "f10b303c-ab60-4d04-b8b0-0f217cda4abb",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:22:09",
        dataUltimaAtualizacao: "2023-12-18T18:24:42",
        status: "RECUSADA",
        qtdAnimais: 25,
      },
      {
        idBlockchain: "f1392d6d-fc67-43f4-918a-1da3da56a459",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:26:52",
        dataUltimaAtualizacao: "2023-12-18T18:27:14",
        status: "RECUSADA",
        qtdAnimais: 25,
      },
      {
        idBlockchain: "e0130d89-b68f-423b-ad3d-a429637fb5a3",
        documentoProprietarioDestino: "18293459000196",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Guidara Indústria e Comércio de Alimentos Eireli",
          documento: "18293459000196",
        },
        dataCriacao: "2023-12-18T18:27:49",
        dataUltimaAtualizacao: "2023-12-18T18:28:02",
        status: "EFETIVADA",
        qtdAnimais: 25,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [
      {
        dtCriacao: "2023-12-18T14:16:17",
        dtAlteracao: "2023-12-18T14:16:17",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "b2531b3a-73a0-4322-b2f9-7a0bd15c3cab",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipoExame: "ULTRASSOM_CARCACA",
        dtExame: "2023-09-04",
        vrResultado: "",
        vrResultadoAol: "90.99",
        vrResultadoEgs: "7.06",
        vrResultadoEgg: "0.00",
        vrResultadoMar: "3.28",
        vrResultadoRatio: "0.55",
        qtDiasPrenhe: null,
        icApresentouCio: null,
        idExameBlockchain: "c27e16f1-1716-46ef-a7dd-d21279be8f86",
      },
    ],
    medicamentosAplicados: [
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "4e9c871b-8eaa-491f-a47f-a96507817a7c",
        idOriginalMedicamentoBlockchain: "ba97487b-264d-4640-887a-1740557e1093",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        dtMedicacao: "2023-06-01",
        unidadeMedida: "MILILITRO",
        quantidade: 5,
        idMedicamentoBlockchain: "069968d6-6a60-40dd-ac29-4146f8a9daa0",
        medicamento: {
          idBlockchain: "ba97487b-264d-4640-887a-1740557e1093",
          nome: "Iver-vet 3.5 injetável 1000 mL",
          categoria: "FARMACIA",
          subCategoria: "VERMIFUGO",
          fabricante: "Biovet",
          unidade: "LITRO",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "c727b780-fddd-4546-b94e-376da88a27cc",
        idOriginalMedicamentoBlockchain: "03a88948-7528-45c1-873a-b8d6012f9c15",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        dtMedicacao: "2023-06-27",
        unidadeMedida: "UNIDADE",
        quantidade: 2,
        idMedicamentoBlockchain: "567b08ea-6f8f-4544-8b8c-ec6d7a1c0621",
        medicamento: {
          idBlockchain: "03a88948-7528-45c1-873a-b8d6012f9c15",
          nome: "Vacina Bovi-Shield",
          categoria: "FARMACIA",
          subCategoria: "VACINA",
          fabricante: "Zoetis",
          unidade: "UNIDADE",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
    ],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:16:02",
      dtAlteracao: "2023-12-18T14:16:02",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      fazenda: {
        nome: "Fazenda União do Brasil ",
        dataEntrada: "2023-06-27",
        dataSaida: "2023-11-07",
        latitude: "-23.68423843",
        longitude: "-48.59685500",
      },
      subdivisao: {
        nome: "Confinamento",
        dataEntrada: "2023-06-27",
        dataSaida: "2023-11-07",
      },
      idSistemaGestao: "5015351",
      idLocalizacaoBlockchain: "15a4f0a7-ee02-4960-b911-5aa76492ac89",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Fazenda União do Brasil ",
          dataEntrada: "2023-06-27",
          dataSaida: "2023-11-07",
          latitude: "-23.68423843",
          longitude: "-48.59685500",
        },
        subdivisao: {
          nome: "Confinamento",
          dataEntrada: "2023-06-27",
          dataSaida: "2023-11-07",
        },
        idSistemaGestao: "5015351",
        idLocalizacaoBlockchain: "15a4f0a7-ee02-4960-b911-5aa76492ac89",
      },
    ],
    loteHistorico: [],
    idResponsavel: "fdd249be-f2a0-402a-9213-c40dff9f7c9f",
    nomeResponsavel: "BARTIRA AGROPECUARIA S/A",
    frigorifico: {
      nome: "Guidara Industria",
      maturidade: "2 dentes",
      acabamento: "Uniforme",
      pesoBanda1: "136.5",
      pesoBanda2: "136.5",
      obs1: "",
      obs2: "",
      dtMorte: "2023-11-08T00:00:00",
      porcentagemCarcaca: "58.30",
      dtCriacao: "2023-12-18T18:45:58",
      dtAlteracao: "2023-12-18T18:45:58",
    },
  },
  "76c2b6bb-094b-4b86-b013-2aef64877a6d": {
    idLoteSync: "ec1f85a1-7f04-468d-ac28-2bfdf17d33b5",
    idBlockchain: "76c2b6bb-094b-4b86-b013-2aef64877a6d",
    docType: "ANIMAL",
    idSistemaGestao: "77d94169-ee81-45e7-a3b7-9ec3ade46e54",
    idSisbov: "",
    idICar: "982000444283239",
    nome: "",
    dataNascimento: "2022-04-02",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "982000444283239",
    idAnimalFazenda: "4283239",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Santa Gertrudis", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:15:38",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      idSistemaGestao: "95d2b299-01e2-452c-8959-d30192961e74",
      idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
      nomeResponsavel: "Fazenda União do Brasil",
      latitude: "",
      longitude: "",
      tipo: "Saída",
      peso: 424,
      dtPesagem: "2023-11-07",
      idPesagemBlockchain: "178d5d77-0985-41b6-90c6-4dc721246134",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "0de9e7a3-43de-4b37-8b2a-dd91b7d4000a",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Entrada",
        peso: 280,
        dtPesagem: "2023-06-01",
        idPesagemBlockchain: "87a0a0c6-f882-41f8-abfd-2622404568b6",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "bb271e07-5ee2-4165-a905-effdce592c15",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 274,
        dtPesagem: "2023-06-27",
        idPesagemBlockchain: "cd2af2c3-49a3-4424-bd07-327e226e2214",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "38444554-f02a-4c5e-831a-c54d3c3e58b9",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 364,
        dtPesagem: "2023-09-04",
        idPesagemBlockchain: "c4161212-323e-46e3-a490-aea098d4cfac",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "95d2b299-01e2-452c-8959-d30192961e74",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Saída",
        peso: 424,
        dtPesagem: "2023-11-07",
        idPesagemBlockchain: "178d5d77-0985-41b6-90c6-4dc721246134",
      },
    ],
    produtorAtual: {
      idBlockchain: "25bc0b49-7cf7-4519-b70c-3001a1e4bfed",
      nome: "Fazenda União do Brasil",
      documento: "08090246000103",
    },
    status: "ABATIDO",
    dataCriacao: "2023-12-18T13:22:59",
    dataUltimaAtualizacao: "2023-12-18T18:49:32",
    vendaAtual: {
      idBlockchain: "e0130d89-b68f-423b-ad3d-a429637fb5a3",
      documentoProprietarioDestino: "18293459000196",
      tipo: "INDUSTRIA",
      proprietarioOrigem: {
        nome: "Fazenda União do Brasil",
        documento: "08090246000103",
      },
      proprietarioDestino: {
        nome: "Guidara Indústria e Comércio de Alimentos Eireli",
        documento: "18293459000196",
      },
      dataCriacao: "2023-12-18T18:27:49",
      dataUltimaAtualizacao: "2023-12-18T18:28:02",
      status: "EFETIVADA",
      qtdAnimais: 25,
    },
    vendaHistorico: [
      {
        idBlockchain: "f10b303c-ab60-4d04-b8b0-0f217cda4abb",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:22:09",
        dataUltimaAtualizacao: "2023-12-18T18:24:42",
        status: "RECUSADA",
        qtdAnimais: 25,
      },
      {
        idBlockchain: "f1392d6d-fc67-43f4-918a-1da3da56a459",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:26:52",
        dataUltimaAtualizacao: "2023-12-18T18:27:14",
        status: "RECUSADA",
        qtdAnimais: 25,
      },
      {
        idBlockchain: "e0130d89-b68f-423b-ad3d-a429637fb5a3",
        documentoProprietarioDestino: "18293459000196",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Guidara Indústria e Comércio de Alimentos Eireli",
          documento: "18293459000196",
        },
        dataCriacao: "2023-12-18T18:27:49",
        dataUltimaAtualizacao: "2023-12-18T18:28:02",
        status: "EFETIVADA",
        qtdAnimais: 25,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [
      {
        dtCriacao: "2023-12-18T14:16:17",
        dtAlteracao: "2023-12-18T14:16:17",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "8450f026-cb94-4c74-b604-03669cbfb32a",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipoExame: "ULTRASSOM_CARCACA",
        dtExame: "2023-09-04",
        vrResultado: "",
        vrResultadoAol: "78.06",
        vrResultadoEgs: "9.88",
        vrResultadoEgg: "0.00",
        vrResultadoMar: "3.70",
        vrResultadoRatio: "0.50",
        qtDiasPrenhe: null,
        icApresentouCio: null,
        idExameBlockchain: "eae7aa64-0c72-46c0-adf5-de0c044c5064",
      },
    ],
    medicamentosAplicados: [
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "1eeddd38-927f-40d3-a1eb-de39b6bf126c",
        idOriginalMedicamentoBlockchain: "03a88948-7528-45c1-873a-b8d6012f9c15",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        dtMedicacao: "2023-06-27",
        unidadeMedida: "UNIDADE",
        quantidade: 2,
        idMedicamentoBlockchain: "0bdec35f-6c80-4a37-bcbf-1cd407bb3934",
        medicamento: {
          idBlockchain: "03a88948-7528-45c1-873a-b8d6012f9c15",
          nome: "Vacina Bovi-Shield",
          categoria: "FARMACIA",
          subCategoria: "VACINA",
          fabricante: "Zoetis",
          unidade: "UNIDADE",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "a351d1d3-790b-4b3c-86d9-c29b5f8e9f26",
        idOriginalMedicamentoBlockchain: "ba97487b-264d-4640-887a-1740557e1093",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        dtMedicacao: "2023-06-01",
        unidadeMedida: "MILILITRO",
        quantidade: 5,
        idMedicamentoBlockchain: "f7f5d9c2-8e4c-4c34-9af3-019b4bec7d3f",
        medicamento: {
          idBlockchain: "ba97487b-264d-4640-887a-1740557e1093",
          nome: "Iver-vet 3.5 injetável 1000 mL",
          categoria: "FARMACIA",
          subCategoria: "VERMIFUGO",
          fabricante: "Biovet",
          unidade: "LITRO",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
    ],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:16:02",
      dtAlteracao: "2023-12-18T14:16:02",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      fazenda: {
        nome: "Fazenda União do Brasil ",
        dataEntrada: "2023-06-27",
        dataSaida: "2023-11-07",
        latitude: "-23.68423843",
        longitude: "-48.59685500",
      },
      subdivisao: {
        nome: "Confinamento",
        dataEntrada: "2023-06-27",
        dataSaida: "2023-11-07",
      },
      idSistemaGestao: "5015326",
      idLocalizacaoBlockchain: "1f1bdfba-e0e1-4b24-802f-2259bf69fe58",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Fazenda União do Brasil ",
          dataEntrada: "2023-06-27",
          dataSaida: "2023-11-07",
          latitude: "-23.68423843",
          longitude: "-48.59685500",
        },
        subdivisao: {
          nome: "Confinamento",
          dataEntrada: "2023-06-27",
          dataSaida: "2023-11-07",
        },
        idSistemaGestao: "5015326",
        idLocalizacaoBlockchain: "1f1bdfba-e0e1-4b24-802f-2259bf69fe58",
      },
    ],
    loteHistorico: [],
    idResponsavel: "fdd249be-f2a0-402a-9213-c40dff9f7c9f",
    nomeResponsavel: "BARTIRA AGROPECUARIA S/A",
    frigorifico: {
      nome: "Guidara Industria",
      maturidade: "2 dentes",
      acabamento: "Uniforme",
      pesoBanda1: "120.75",
      pesoBanda2: "120.75",
      obs1: "",
      obs2: "",
      dtMorte: "2023-11-08T00:00:00",
      porcentagemCarcaca: "58.50",
      dtCriacao: "2023-12-18T18:49:32",
      dtAlteracao: "2023-12-18T18:49:32",
    },
  },
  "7ab7a7ae-b55c-46e2-b0b8-c25dcaa9c51d": {
    idLoteSync: "ec1f85a1-7f04-468d-ac28-2bfdf17d33b5",
    idBlockchain: "7ab7a7ae-b55c-46e2-b0b8-c25dcaa9c51d",
    docType: "ANIMAL",
    idSistemaGestao: "57beb664-cb8c-4cc4-8f68-baa7cde19b04",
    idSisbov: "",
    idICar: "982000444280170",
    nome: "",
    dataNascimento: "2021-01-15",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "982000444280170",
    idAnimalFazenda: "3210103",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Santa Gertrudis", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:15:38",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      idSistemaGestao: "57beb664-cb8c-4cc4-8f68-baa7cde19b04",
      idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
      nomeResponsavel: "Fazenda União do Brasil",
      latitude: "-23.6825667",
      longitude: "-48.5986721",
      tipo: "Saída",
      peso: 590,
      dtPesagem: "2023-05-11T11:31:50",
      idPesagemBlockchain: "9aacaca1-beb1-41c3-8466-89d45fa0e5da",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "57beb664-cb8c-4cc4-8f68-baa7cde19b04",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Nascimento",
        peso: 39,
        dtPesagem: "2021-01-15T03:00:00",
        idPesagemBlockchain: "d01145af-dc79-4629-8c71-f3ac016ee5d6",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "57beb664-cb8c-4cc4-8f68-baa7cde19b04",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Desmame",
        peso: 132,
        dtPesagem: "2021-09-23T13:06:24",
        idPesagemBlockchain: "2a60dfba-cfd8-4f03-b106-ff7e6f757c3c",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "57beb664-cb8c-4cc4-8f68-baa7cde19b04",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 307,
        dtPesagem: "2022-10-04T07:40:54",
        idPesagemBlockchain: "05cb0fa9-05b2-4d09-ac62-24b9d99a9403",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "57beb664-cb8c-4cc4-8f68-baa7cde19b04",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 476,
        dtPesagem: "2023-02-17T13:41:34",
        idPesagemBlockchain: "e59c8970-e79f-4119-849f-b2881c100ba3",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "57beb664-cb8c-4cc4-8f68-baa7cde19b04",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Saída",
        peso: 590,
        dtPesagem: "2023-05-11T11:31:50",
        idPesagemBlockchain: "9aacaca1-beb1-41c3-8466-89d45fa0e5da",
      },
    ],
    produtorAtual: {
      idBlockchain: "25bc0b49-7cf7-4519-b70c-3001a1e4bfed",
      nome: "Fazenda União do Brasil",
      documento: "08090246000103",
    },
    status: "ABATIDO",
    dataCriacao: "2023-12-18T13:22:59",
    dataUltimaAtualizacao: "2023-12-18T18:57:18",
    vendaAtual: {
      idBlockchain: "0401071b-4fe8-4b0e-ba81-d94b5ab0b970",
      documentoProprietarioDestino: "30269671000113",
      tipo: "INDUSTRIA",
      proprietarioOrigem: {
        nome: "Fazenda União do Brasil",
        documento: "08090246000103",
      },
      proprietarioDestino: {
        nome: "Frigorífico Red Beef LTDA",
        documento: "30269671000113",
      },
      dataCriacao: "2023-12-18T18:33:01",
      dataUltimaAtualizacao: "2023-12-18T18:33:19",
      status: "EFETIVADA",
      qtdAnimais: 29,
    },
    vendaHistorico: [
      {
        idBlockchain: "eb66af4e-769b-4269-9378-dba27a1c0947",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:21:39",
        dataUltimaAtualizacao: "2023-12-18T18:24:08",
        status: "RECUSADA",
        qtdAnimais: 29,
      },
      {
        idBlockchain: "0401071b-4fe8-4b0e-ba81-d94b5ab0b970",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:33:01",
        dataUltimaAtualizacao: "2023-12-18T18:33:19",
        status: "EFETIVADA",
        qtdAnimais: 29,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [
      {
        dtCriacao: "2023-12-18T14:16:17",
        dtAlteracao: "2023-12-18T14:16:17",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "irancho-mvp",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipoExame: "ULTRASSOM_CARCACA",
        dtExame: "2023-05-11T11:31:50",
        vrResultado: "",
        vrResultadoAol: "75.93",
        vrResultadoEgs: "8.94",
        vrResultadoEgg: "0",
        vrResultadoMar: "3.65",
        vrResultadoRatio: "0",
        qtDiasPrenhe: null,
        icApresentouCio: null,
        idExameBlockchain: "969650a9-dd2b-44ac-886b-bad1e962bc5b",
      },
    ],
    medicamentosAplicados: [
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "irancho-mvp",
        idOriginalMedicamentoBlockchain: "40aa0e8c-5ceb-4b79-bd44-253139e57a21",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        dtMedicacao: "2021-06-07T12:00:40",
        unidadeMedida: "MILILITRO",
        quantidade: 2,
        idMedicamentoBlockchain: "becaff13-7058-4df8-991b-218a32e514e0",
        medicamento: {
          idBlockchain: "40aa0e8c-5ceb-4b79-bd44-253139e57a21",
          nome: "Aborvac Brucelose",
          categoria: "FARMACIA",
          subCategoria: "VACINA",
          fabricante: "Zoetis",
          unidade: "MILILITRO",
          sexo: "FEMEA",
          idadeMaxima: 8,
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
    ],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:16:02",
      dtAlteracao: "2023-12-18T14:16:02",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      fazenda: {
        nome: "Fazenda União do Brasil ",
        dataEntrada: "2022-10-04T00:00:00",
        dataSaida: "2023-05-11T00:00:00",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
      },
      subdivisao: {
        nome: "Confinamento",
        dataEntrada: "2022-10-04T00:00:00",
        dataSaida: "2023-05-11T00:00:00",
      },
      idSistemaGestao: "57beb664-cb8c-4cc4-8f68-baa7cde19b04",
      idLocalizacaoBlockchain: "177096b7-d478-4ad7-ad70-4a1d7a91651e",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Vale do Sol ",
          dataEntrada: "2021-10-26T00:00:00",
          dataSaida: "2022-10-04T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "transferencia de animais",
          dataEntrada: "2021-10-26T00:00:00",
          dataSaida: "2022-10-04T00:00:00",
        },
        idSistemaGestao: "57beb664-cb8c-4cc4-8f68-baa7cde19b04",
        idLocalizacaoBlockchain: "a319257b-69c6-4b64-99ec-aaa5298e491b",
      },
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Fazenda União do Brasil ",
          dataEntrada: "2022-10-04T00:00:00",
          dataSaida: "2023-05-11T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "Confinamento",
          dataEntrada: "2022-10-04T00:00:00",
          dataSaida: "2023-05-11T00:00:00",
        },
        idSistemaGestao: "57beb664-cb8c-4cc4-8f68-baa7cde19b04",
        idLocalizacaoBlockchain: "177096b7-d478-4ad7-ad70-4a1d7a91651e",
      },
    ],
    loteHistorico: [],
    idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
    nomeResponsavel: "Fazenda União do Brasil",
    frigorifico: {
      nome: "Frigorífico Red Beef LTDA",
      maturidade: "0 dentes",
      acabamento: "Uniforme",
      pesoBanda1: "155.75 Kg",
      pesoBanda2: "155.75 Kg",
      obs1: "",
      obs2: "",
      dtMorte: "2023-05-11",
      porcentagemCarcaca: "53%",
      dtCriacao: "2023-12-18T18:57:18",
      dtAlteracao: "2023-12-18T18:57:18",
    },
  },
  "81e47114-01e5-4a9d-a447-ecbca69f20cd": {
    idLoteSync: "ec1f85a1-7f04-468d-ac28-2bfdf17d33b5",
    idBlockchain: "81e47114-01e5-4a9d-a447-ecbca69f20cd",
    docType: "ANIMAL",
    idSistemaGestao: "5a66326a-7c7c-4e58-a060-86e28a44563e",
    idSisbov: "",
    idICar: "982000444276252",
    nome: "",
    dataNascimento: "2022-04-02",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "982000444276252",
    idAnimalFazenda: "4276252",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Santa Gertrudis", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:15:38",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      idSistemaGestao: "0c2e768a-7b96-4aac-8e59-cb9bad7f9f0e",
      idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
      nomeResponsavel: "Fazenda União do Brasil",
      latitude: "",
      longitude: "",
      tipo: "Saída",
      peso: 456,
      dtPesagem: "2023-11-07",
      idPesagemBlockchain: "d2b78d2c-cddb-4134-a452-89d517f90114",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "c7be2086-6751-4556-9c4d-66187d83ca1c",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Entrada",
        peso: 248,
        dtPesagem: "2023-06-01",
        idPesagemBlockchain: "fc72e5a5-f399-445e-b9b4-c7aa1ff3f539",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "83db2a12-1a15-4141-8adb-cd7ff5c5af69",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 251,
        dtPesagem: "2023-06-27",
        idPesagemBlockchain: "dfe1edd9-d901-4d87-be5e-1bcae0e0eded",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "810bb366-e24b-497c-8b9c-78fd6fabb7c1",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 355,
        dtPesagem: "2023-09-04",
        idPesagemBlockchain: "d930719f-101c-4df5-aca8-2aec193c3517",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "0c2e768a-7b96-4aac-8e59-cb9bad7f9f0e",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Saída",
        peso: 456,
        dtPesagem: "2023-11-07",
        idPesagemBlockchain: "d2b78d2c-cddb-4134-a452-89d517f90114",
      },
    ],
    produtorAtual: {
      idBlockchain: "25bc0b49-7cf7-4519-b70c-3001a1e4bfed",
      nome: "Fazenda União do Brasil",
      documento: "08090246000103",
    },
    status: "ABATIDO",
    dataCriacao: "2023-12-18T13:22:59",
    dataUltimaAtualizacao: "2023-12-18T18:49:09",
    vendaAtual: {
      idBlockchain: "e0130d89-b68f-423b-ad3d-a429637fb5a3",
      documentoProprietarioDestino: "18293459000196",
      tipo: "INDUSTRIA",
      proprietarioOrigem: {
        nome: "Fazenda União do Brasil",
        documento: "08090246000103",
      },
      proprietarioDestino: {
        nome: "Guidara Indústria e Comércio de Alimentos Eireli",
        documento: "18293459000196",
      },
      dataCriacao: "2023-12-18T18:27:49",
      dataUltimaAtualizacao: "2023-12-18T18:28:02",
      status: "EFETIVADA",
      qtdAnimais: 25,
    },
    vendaHistorico: [
      {
        idBlockchain: "f10b303c-ab60-4d04-b8b0-0f217cda4abb",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:22:09",
        dataUltimaAtualizacao: "2023-12-18T18:24:42",
        status: "RECUSADA",
        qtdAnimais: 25,
      },
      {
        idBlockchain: "f1392d6d-fc67-43f4-918a-1da3da56a459",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:26:52",
        dataUltimaAtualizacao: "2023-12-18T18:27:14",
        status: "RECUSADA",
        qtdAnimais: 25,
      },
      {
        idBlockchain: "e0130d89-b68f-423b-ad3d-a429637fb5a3",
        documentoProprietarioDestino: "18293459000196",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Guidara Indústria e Comércio de Alimentos Eireli",
          documento: "18293459000196",
        },
        dataCriacao: "2023-12-18T18:27:49",
        dataUltimaAtualizacao: "2023-12-18T18:28:02",
        status: "EFETIVADA",
        qtdAnimais: 25,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [
      {
        dtCriacao: "2023-12-18T14:16:17",
        dtAlteracao: "2023-12-18T14:16:17",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "09a0c826-3a1e-4d4b-96bf-a7bba44c1be4",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipoExame: "ULTRASSOM_CARCACA",
        dtExame: "2023-09-04",
        vrResultado: "",
        vrResultadoAol: "80.08",
        vrResultadoEgs: "5.18",
        vrResultadoEgg: "0.00",
        vrResultadoMar: "3.22",
        vrResultadoRatio: "0.53",
        qtDiasPrenhe: null,
        icApresentouCio: null,
        idExameBlockchain: "d7c918b5-e70e-42c3-bc1e-dc972b1a5c82",
      },
    ],
    medicamentosAplicados: [
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "1d6af25c-8a67-4a42-9415-d9ff94740de8",
        idOriginalMedicamentoBlockchain: "03a88948-7528-45c1-873a-b8d6012f9c15",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        dtMedicacao: "2023-06-27",
        unidadeMedida: "UNIDADE",
        quantidade: 2,
        idMedicamentoBlockchain: "5c14d353-dbfa-4d89-8c7f-40c9edb60b8b",
        medicamento: {
          idBlockchain: "03a88948-7528-45c1-873a-b8d6012f9c15",
          nome: "Vacina Bovi-Shield",
          categoria: "FARMACIA",
          subCategoria: "VACINA",
          fabricante: "Zoetis",
          unidade: "UNIDADE",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "bd30ce74-4d3e-41db-b182-e450ffd3bfd6",
        idOriginalMedicamentoBlockchain: "ba97487b-264d-4640-887a-1740557e1093",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        dtMedicacao: "2023-06-01",
        unidadeMedida: "MILILITRO",
        quantidade: 5,
        idMedicamentoBlockchain: "51f3d4b7-9ddc-4469-8a7b-7fb310177ab4",
        medicamento: {
          idBlockchain: "ba97487b-264d-4640-887a-1740557e1093",
          nome: "Iver-vet 3.5 injetável 1000 mL",
          categoria: "FARMACIA",
          subCategoria: "VERMIFUGO",
          fabricante: "Biovet",
          unidade: "LITRO",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
    ],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:16:02",
      dtAlteracao: "2023-12-18T14:16:02",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      fazenda: {
        nome: "Fazenda União do Brasil ",
        dataEntrada: "2023-06-27",
        dataSaida: "2023-11-07",
        latitude: "-23.68423843",
        longitude: "-48.59685500",
      },
      subdivisao: {
        nome: "Confinamento",
        dataEntrada: "2023-06-27",
        dataSaida: "2023-11-07",
      },
      idSistemaGestao: "5015325",
      idLocalizacaoBlockchain: "035f2079-ea3d-41b4-89c4-3b5e7467a077",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Fazenda União do Brasil ",
          dataEntrada: "2023-06-27",
          dataSaida: "2023-11-07",
          latitude: "-23.68423843",
          longitude: "-48.59685500",
        },
        subdivisao: {
          nome: "Confinamento",
          dataEntrada: "2023-06-27",
          dataSaida: "2023-11-07",
        },
        idSistemaGestao: "5015325",
        idLocalizacaoBlockchain: "035f2079-ea3d-41b4-89c4-3b5e7467a077",
      },
    ],
    loteHistorico: [],
    idResponsavel: "fdd249be-f2a0-402a-9213-c40dff9f7c9f",
    nomeResponsavel: "BARTIRA AGROPECUARIA S/A",
    frigorifico: {
      nome: "Guidara Industria",
      maturidade: "2 dentes",
      acabamento: "Uniforme",
      pesoBanda1: "124",
      pesoBanda2: "124",
      obs1: "",
      obs2: "",
      dtMorte: "2023-11-08T00:00:00",
      porcentagemCarcaca: "56.50",
      dtCriacao: "2023-12-18T18:49:09",
      dtAlteracao: "2023-12-18T18:49:09",
    },
  },
  "846d4d19-78ba-4076-9438-00abd8369402": {
    idLoteSync: "ec1f85a1-7f04-468d-ac28-2bfdf17d33b5",
    idBlockchain: "846d4d19-78ba-4076-9438-00abd8369402",
    docType: "ANIMAL",
    idSistemaGestao: "431d0e73-64ea-42e9-9393-a4aedf6e56e2",
    idSisbov: "",
    idICar: "982000444281593",
    nome: "",
    dataNascimento: "2021-01-26",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "982000444281593",
    idAnimalFazenda: "1210013",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Santa Gertrudis", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:15:38",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      idSistemaGestao: "431d0e73-64ea-42e9-9393-a4aedf6e56e2",
      idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
      nomeResponsavel: "Fazenda União do Brasil",
      latitude: "-23.6825667",
      longitude: "-48.5986721",
      tipo: "Saída",
      peso: 453,
      dtPesagem: "2023-05-11T11:31:50",
      idPesagemBlockchain: "8e65ad79-ad27-437d-b51e-5347e55e76ae",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "431d0e73-64ea-42e9-9393-a4aedf6e56e2",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Nascimento",
        peso: 37,
        dtPesagem: "2021-01-26T03:00:00",
        idPesagemBlockchain: "87d5ce8e-3fb8-464a-b8c7-090788f88e63",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "431d0e73-64ea-42e9-9393-a4aedf6e56e2",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Desmame",
        peso: 120,
        dtPesagem: "2021-09-24T19:25:21",
        idPesagemBlockchain: "e0fe67d2-0e74-4eb3-8146-ed1a1d934aae",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "431d0e73-64ea-42e9-9393-a4aedf6e56e2",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 268,
        dtPesagem: "2022-10-04T07:40:54",
        idPesagemBlockchain: "514295df-dafa-443d-995d-0025ce6cfded",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "431d0e73-64ea-42e9-9393-a4aedf6e56e2",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 368,
        dtPesagem: "2023-02-17T13:41:34",
        idPesagemBlockchain: "0de1b62a-0c51-4a81-b960-cc0ec7121509",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "431d0e73-64ea-42e9-9393-a4aedf6e56e2",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Saída",
        peso: 453,
        dtPesagem: "2023-05-11T11:31:50",
        idPesagemBlockchain: "8e65ad79-ad27-437d-b51e-5347e55e76ae",
      },
    ],
    produtorAtual: {
      idBlockchain: "25bc0b49-7cf7-4519-b70c-3001a1e4bfed",
      nome: "Fazenda União do Brasil",
      documento: "08090246000103",
    },
    status: "ABATIDO",
    dataCriacao: "2023-12-18T13:22:59",
    dataUltimaAtualizacao: "2023-12-18T18:54:10",
    vendaAtual: {
      idBlockchain: "0401071b-4fe8-4b0e-ba81-d94b5ab0b970",
      documentoProprietarioDestino: "30269671000113",
      tipo: "INDUSTRIA",
      proprietarioOrigem: {
        nome: "Fazenda União do Brasil",
        documento: "08090246000103",
      },
      proprietarioDestino: {
        nome: "Frigorífico Red Beef LTDA",
        documento: "30269671000113",
      },
      dataCriacao: "2023-12-18T18:33:01",
      dataUltimaAtualizacao: "2023-12-18T18:33:19",
      status: "EFETIVADA",
      qtdAnimais: 29,
    },
    vendaHistorico: [
      {
        idBlockchain: "eb66af4e-769b-4269-9378-dba27a1c0947",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:21:39",
        dataUltimaAtualizacao: "2023-12-18T18:24:08",
        status: "RECUSADA",
        qtdAnimais: 29,
      },
      {
        idBlockchain: "0401071b-4fe8-4b0e-ba81-d94b5ab0b970",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:33:01",
        dataUltimaAtualizacao: "2023-12-18T18:33:19",
        status: "EFETIVADA",
        qtdAnimais: 29,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [
      {
        dtCriacao: "2023-12-18T14:16:17",
        dtAlteracao: "2023-12-18T14:16:17",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "irancho-mvp",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipoExame: "ULTRASSOM_CARCACA",
        dtExame: "2023-05-11T11:31:50",
        vrResultado: "",
        vrResultadoAol: "75.81",
        vrResultadoEgs: "8",
        vrResultadoEgg: "0",
        vrResultadoMar: "3.75",
        vrResultadoRatio: "0",
        qtDiasPrenhe: null,
        icApresentouCio: null,
        idExameBlockchain: "3ad05379-1634-45fa-833a-7f6a9b7a6789",
      },
    ],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:16:02",
      dtAlteracao: "2023-12-18T14:16:02",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      fazenda: {
        nome: "Fazenda União do Brasil ",
        dataEntrada: "2022-10-04T00:00:00",
        dataSaida: "2023-05-11T00:00:00",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
      },
      subdivisao: {
        nome: "Confinamento",
        dataEntrada: "2022-10-04T00:00:00",
        dataSaida: "2023-05-11T00:00:00",
      },
      idSistemaGestao: "431d0e73-64ea-42e9-9393-a4aedf6e56e2",
      idLocalizacaoBlockchain: "ece10342-1f65-4670-9f15-68f49e6acca1",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Fazenda Santo Antonio ",
          dataEntrada: "2021-01-26T00:00:00",
          dataSaida: "2021-09-24T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "pasto da frente",
          dataEntrada: "2021-01-26T00:00:00",
          dataSaida: "2021-09-24T00:00:00",
        },
        idSistemaGestao: "431d0e73-64ea-42e9-9393-a4aedf6e56e2",
        idLocalizacaoBlockchain: "c9fce289-a16d-4177-a959-99c98970f81e",
      },
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Vale do Sol ",
          dataEntrada: "2021-09-24T00:00:00",
          dataSaida: "2022-10-04T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "Desmama",
          dataEntrada: "2021-09-24T00:00:00",
          dataSaida: "2022-10-04T00:00:00",
        },
        idSistemaGestao: "431d0e73-64ea-42e9-9393-a4aedf6e56e2",
        idLocalizacaoBlockchain: "bece1043-722a-4b56-9bfc-bc5e9e8445f7",
      },
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Fazenda União do Brasil ",
          dataEntrada: "2022-10-04T00:00:00",
          dataSaida: "2023-05-11T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "Confinamento",
          dataEntrada: "2022-10-04T00:00:00",
          dataSaida: "2023-05-11T00:00:00",
        },
        idSistemaGestao: "431d0e73-64ea-42e9-9393-a4aedf6e56e2",
        idLocalizacaoBlockchain: "ece10342-1f65-4670-9f15-68f49e6acca1",
      },
    ],
    loteHistorico: [],
    idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
    nomeResponsavel: "Fazenda União do Brasil",
    frigorifico: {
      nome: "Frigorífico Red Beef LTDA",
      maturidade: "4 dentes",
      acabamento: "Mediano",
      pesoBanda1: "120.00 Kg",
      pesoBanda2: "120.00 Kg",
      obs1: "",
      obs2: "",
      dtMorte: "2023-05-11",
      porcentagemCarcaca: "53%",
      dtCriacao: "2023-12-18T18:54:10",
      dtAlteracao: "2023-12-18T18:54:10",
    },
  },
  "87b848c7-a5bb-4357-bdaa-8cd3c3252d0f": {
    idLoteSync: "ec1f85a1-7f04-468d-ac28-2bfdf17d33b5",
    idBlockchain: "87b848c7-a5bb-4357-bdaa-8cd3c3252d0f",
    docType: "ANIMAL",
    idSistemaGestao: "4d89fd45-2d58-43a3-934b-955f704e455b",
    idSisbov: "",
    idICar: "982000444277915",
    nome: "",
    dataNascimento: "2022-04-02",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "982000444277915",
    idAnimalFazenda: "4277915",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Santa Gertrudis", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:15:38",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      idSistemaGestao: "64e62350-3f03-482b-a453-353e8863ac00",
      idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
      nomeResponsavel: "Fazenda União do Brasil",
      latitude: "",
      longitude: "",
      tipo: "Saída",
      peso: 488,
      dtPesagem: "2023-11-07",
      idPesagemBlockchain: "8c662dd4-f3bf-488a-a88a-c1f1c4b57d1e",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "018bab21-7f2e-4937-a9fa-8bb65db29f03",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Entrada",
        peso: 244,
        dtPesagem: "2023-06-01",
        idPesagemBlockchain: "fae7555d-2b23-4875-a7d6-54b4a1649b0b",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "cf04355d-9775-4caf-86b6-074a208474ea",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 246,
        dtPesagem: "2023-06-27",
        idPesagemBlockchain: "9bcd4e3d-6f80-4ea5-9b20-2661f34360a1",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "cb3010e9-527d-4fde-b839-0e0b3c8479e1",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 372,
        dtPesagem: "2023-09-04",
        idPesagemBlockchain: "7a6b63c1-a163-4869-98b5-c9259a225631",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "64e62350-3f03-482b-a453-353e8863ac00",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Saída",
        peso: 488,
        dtPesagem: "2023-11-07",
        idPesagemBlockchain: "8c662dd4-f3bf-488a-a88a-c1f1c4b57d1e",
      },
    ],
    produtorAtual: {
      idBlockchain: "25bc0b49-7cf7-4519-b70c-3001a1e4bfed",
      nome: "Fazenda União do Brasil",
      documento: "08090246000103",
    },
    status: "ABATIDO",
    dataCriacao: "2023-12-18T13:22:59",
    dataUltimaAtualizacao: "2023-12-18T18:48:36",
    vendaAtual: {
      idBlockchain: "e0130d89-b68f-423b-ad3d-a429637fb5a3",
      documentoProprietarioDestino: "18293459000196",
      tipo: "INDUSTRIA",
      proprietarioOrigem: {
        nome: "Fazenda União do Brasil",
        documento: "08090246000103",
      },
      proprietarioDestino: {
        nome: "Guidara Indústria e Comércio de Alimentos Eireli",
        documento: "18293459000196",
      },
      dataCriacao: "2023-12-18T18:27:49",
      dataUltimaAtualizacao: "2023-12-18T18:28:02",
      status: "EFETIVADA",
      qtdAnimais: 25,
    },
    vendaHistorico: [
      {
        idBlockchain: "f10b303c-ab60-4d04-b8b0-0f217cda4abb",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:22:09",
        dataUltimaAtualizacao: "2023-12-18T18:24:42",
        status: "RECUSADA",
        qtdAnimais: 25,
      },
      {
        idBlockchain: "f1392d6d-fc67-43f4-918a-1da3da56a459",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:26:52",
        dataUltimaAtualizacao: "2023-12-18T18:27:14",
        status: "RECUSADA",
        qtdAnimais: 25,
      },
      {
        idBlockchain: "e0130d89-b68f-423b-ad3d-a429637fb5a3",
        documentoProprietarioDestino: "18293459000196",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Guidara Indústria e Comércio de Alimentos Eireli",
          documento: "18293459000196",
        },
        dataCriacao: "2023-12-18T18:27:49",
        dataUltimaAtualizacao: "2023-12-18T18:28:02",
        status: "EFETIVADA",
        qtdAnimais: 25,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [
      {
        dtCriacao: "2023-12-18T14:16:17",
        dtAlteracao: "2023-12-18T14:16:17",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "70afa8ba-ee36-41de-a1e3-dd3501fe2196",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipoExame: "ULTRASSOM_CARCACA",
        dtExame: "2023-09-04",
        vrResultado: "",
        vrResultadoAol: "78.69",
        vrResultadoEgs: "5.17",
        vrResultadoEgg: "0.00",
        vrResultadoMar: "4.03",
        vrResultadoRatio: "0.47",
        qtDiasPrenhe: null,
        icApresentouCio: null,
        idExameBlockchain: "1e16fda5-fc90-4e4d-8d96-11fafb0f8da8",
      },
    ],
    medicamentosAplicados: [
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "e0b58d7c-28d5-4b02-ae6f-00232c4aa279",
        idOriginalMedicamentoBlockchain: "ba97487b-264d-4640-887a-1740557e1093",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        dtMedicacao: "2023-06-01",
        unidadeMedida: "MILILITRO",
        quantidade: 5,
        idMedicamentoBlockchain: "3adec5e4-9ff7-46fc-909b-2e60df620ea2",
        medicamento: {
          idBlockchain: "ba97487b-264d-4640-887a-1740557e1093",
          nome: "Iver-vet 3.5 injetável 1000 mL",
          categoria: "FARMACIA",
          subCategoria: "VERMIFUGO",
          fabricante: "Biovet",
          unidade: "LITRO",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "ee4ca57c-5d5a-44c6-b181-439489c61fa8",
        idOriginalMedicamentoBlockchain: "03a88948-7528-45c1-873a-b8d6012f9c15",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        dtMedicacao: "2023-06-27",
        unidadeMedida: "UNIDADE",
        quantidade: 2,
        idMedicamentoBlockchain: "5ae414fe-a49a-46ab-bfbf-047ce459d6f2",
        medicamento: {
          idBlockchain: "03a88948-7528-45c1-873a-b8d6012f9c15",
          nome: "Vacina Bovi-Shield",
          categoria: "FARMACIA",
          subCategoria: "VACINA",
          fabricante: "Zoetis",
          unidade: "UNIDADE",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
    ],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:16:02",
      dtAlteracao: "2023-12-18T14:16:02",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      fazenda: {
        nome: "Fazenda União do Brasil ",
        dataEntrada: "2023-06-27",
        dataSaida: "2023-11-07",
        latitude: "-23.68423843",
        longitude: "-48.59685500",
      },
      subdivisao: {
        nome: "Confinamento",
        dataEntrada: "2023-06-27",
        dataSaida: "2023-11-07",
      },
      idSistemaGestao: "5015385",
      idLocalizacaoBlockchain: "9d638baa-7152-4bf7-bdcc-ffcaada0fd54",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Fazenda União do Brasil ",
          dataEntrada: "2023-06-27",
          dataSaida: "2023-11-07",
          latitude: "-23.68423843",
          longitude: "-48.59685500",
        },
        subdivisao: {
          nome: "Confinamento",
          dataEntrada: "2023-06-27",
          dataSaida: "2023-11-07",
        },
        idSistemaGestao: "5015385",
        idLocalizacaoBlockchain: "9d638baa-7152-4bf7-bdcc-ffcaada0fd54",
      },
    ],
    loteHistorico: [],
    idResponsavel: "fdd249be-f2a0-402a-9213-c40dff9f7c9f",
    nomeResponsavel: "BARTIRA AGROPECUARIA S/A",
    frigorifico: {
      nome: "Guidara Industria",
      maturidade: "2 dentes",
      acabamento: "Uniforme",
      pesoBanda1: "133.25",
      pesoBanda2: "133.25",
      obs1: "",
      obs2: "",
      dtMorte: "2023-11-08T00:00:00",
      porcentagemCarcaca: "56.20",
      dtCriacao: "2023-12-18T18:48:36",
      dtAlteracao: "2023-12-18T18:48:36",
    },
  },
  "8a1da836-72f6-44f2-b832-0700bd9b5551": {
    idLoteSync: "ec1f85a1-7f04-468d-ac28-2bfdf17d33b5",
    idBlockchain: "8a1da836-72f6-44f2-b832-0700bd9b5551",
    docType: "ANIMAL",
    idSistemaGestao: "bef154d4-4263-4b28-b528-853f8f415380",
    idSisbov: "",
    idICar: "982000444282965",
    nome: "",
    dataNascimento: "2020-10-30",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "982000444282965",
    idAnimalFazenda: "1200253",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Santa Gertrudis", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:15:38",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      idSistemaGestao: "bef154d4-4263-4b28-b528-853f8f415380",
      idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
      nomeResponsavel: "Fazenda União do Brasil",
      latitude: "-23.6825667",
      longitude: "-48.5986721",
      tipo: "Saída",
      peso: 436,
      dtPesagem: "2023-05-11T11:31:50",
      idPesagemBlockchain: "779fdccc-a234-43f3-915a-63809301e1cb",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "bef154d4-4263-4b28-b528-853f8f415380",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Nascimento",
        peso: 30,
        dtPesagem: "2020-10-30T03:00:00",
        idPesagemBlockchain: "971326f7-6550-4cb0-9eb2-668da6b3781d",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "bef154d4-4263-4b28-b528-853f8f415380",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Desmame",
        peso: 114,
        dtPesagem: "2021-06-22T20:34:05",
        idPesagemBlockchain: "d305571e-1d08-4e66-b168-97149251b75e",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "bef154d4-4263-4b28-b528-853f8f415380",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 343,
        dtPesagem: "2023-02-17T13:42:07",
        idPesagemBlockchain: "a5158dee-a447-4c24-a34c-2ee8145bb322",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "bef154d4-4263-4b28-b528-853f8f415380",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Saída",
        peso: 436,
        dtPesagem: "2023-05-11T11:31:50",
        idPesagemBlockchain: "779fdccc-a234-43f3-915a-63809301e1cb",
      },
    ],
    produtorAtual: {
      idBlockchain: "25bc0b49-7cf7-4519-b70c-3001a1e4bfed",
      nome: "Fazenda União do Brasil",
      documento: "08090246000103",
    },
    status: "ABATIDO",
    dataCriacao: "2023-12-18T13:22:59",
    dataUltimaAtualizacao: "2023-12-18T18:57:30",
    vendaAtual: {
      idBlockchain: "0401071b-4fe8-4b0e-ba81-d94b5ab0b970",
      documentoProprietarioDestino: "30269671000113",
      tipo: "INDUSTRIA",
      proprietarioOrigem: {
        nome: "Fazenda União do Brasil",
        documento: "08090246000103",
      },
      proprietarioDestino: {
        nome: "Frigorífico Red Beef LTDA",
        documento: "30269671000113",
      },
      dataCriacao: "2023-12-18T18:33:01",
      dataUltimaAtualizacao: "2023-12-18T18:33:19",
      status: "EFETIVADA",
      qtdAnimais: 29,
    },
    vendaHistorico: [
      {
        idBlockchain: "eb66af4e-769b-4269-9378-dba27a1c0947",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:21:39",
        dataUltimaAtualizacao: "2023-12-18T18:24:08",
        status: "RECUSADA",
        qtdAnimais: 29,
      },
      {
        idBlockchain: "0401071b-4fe8-4b0e-ba81-d94b5ab0b970",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:33:01",
        dataUltimaAtualizacao: "2023-12-18T18:33:19",
        status: "EFETIVADA",
        qtdAnimais: 29,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [
      {
        dtCriacao: "2023-12-18T14:16:17",
        dtAlteracao: "2023-12-18T14:16:17",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "irancho-mvp",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipoExame: "ULTRASSOM_CARCACA",
        dtExame: "2023-05-11T11:31:50",
        vrResultado: "",
        vrResultadoAol: "53.65",
        vrResultadoEgs: "4.24",
        vrResultadoEgg: "0",
        vrResultadoMar: "4.22",
        vrResultadoRatio: "0",
        qtDiasPrenhe: null,
        icApresentouCio: null,
        idExameBlockchain: "65eace36-75aa-436a-8ae1-4e64ae512dfb",
      },
    ],
    medicamentosAplicados: [
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "irancho-mvp",
        idOriginalMedicamentoBlockchain: "3367926c-ceac-46be-8628-6774c88536a5",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        dtMedicacao: "2022-11-30T14:43:19",
        unidadeMedida: "MILILITRO",
        quantidade: 2,
        idMedicamentoBlockchain: "928f6fe8-37ae-41e6-8f90-8aa958e4e38e",
        medicamento: {
          idBlockchain: "3367926c-ceac-46be-8628-6774c88536a5",
          nome: "Aftosa ourovac 15 doses ",
          categoria: "FARMACIA",
          subCategoria: "VACINA",
          fabricante: "Ouro fino",
          unidade: "MILILITRO",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:30",
          dataUltimaAtualizacao: "2023-12-13T13:25:30",
        },
      },
    ],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:16:02",
      dtAlteracao: "2023-12-18T14:16:02",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      fazenda: {
        nome: "Fazenda União do Brasil ",
        dataEntrada: "2022-11-30T00:00:00",
        dataSaida: "2023-05-11T00:00:00",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
      },
      subdivisao: {
        nome: "Confinamento",
        dataEntrada: "2022-11-30T00:00:00",
        dataSaida: "2023-05-11T00:00:00",
      },
      idSistemaGestao: "bef154d4-4263-4b28-b528-853f8f415380",
      idLocalizacaoBlockchain: "91191024-e21f-4943-90f8-8de30b63724c",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Vale do Sol ",
          dataEntrada: "2021-06-22T00:00:00",
          dataSaida: "2022-11-30T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "Desmama",
          dataEntrada: "2021-06-22T00:00:00",
          dataSaida: "2022-11-30T00:00:00",
        },
        idSistemaGestao: "bef154d4-4263-4b28-b528-853f8f415380",
        idLocalizacaoBlockchain: "5bce65a0-f8a0-42e8-be6f-131706532b2f",
      },
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Fazenda União do Brasil ",
          dataEntrada: "2022-11-30T00:00:00",
          dataSaida: "2023-05-11T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "Confinamento",
          dataEntrada: "2022-11-30T00:00:00",
          dataSaida: "2023-05-11T00:00:00",
        },
        idSistemaGestao: "bef154d4-4263-4b28-b528-853f8f415380",
        idLocalizacaoBlockchain: "91191024-e21f-4943-90f8-8de30b63724c",
      },
    ],
    loteHistorico: [],
    idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
    nomeResponsavel: "Fazenda União do Brasil",
    frigorifico: {
      nome: "Frigorífico Red Beef LTDA",
      maturidade: "50%",
      acabamento: "",
      pesoBanda1: "",
      pesoBanda2: "",
      obs1: "",
      obs2: "",
      dtMorte: "2023-05-11",
      porcentagemCarcaca: "54.00",
      dtCriacao: "2023-12-18T18:57:30",
      dtAlteracao: "2023-12-18T18:57:30",
    },
  },
  "8a45d228-96de-4ead-b59a-2125760b6f78": {
    idLoteSync: "ec1f85a1-7f04-468d-ac28-2bfdf17d33b5",
    idBlockchain: "8a45d228-96de-4ead-b59a-2125760b6f78",
    docType: "ANIMAL",
    idSistemaGestao: "619e1cbc-2bad-4330-aec8-7880432eea91",
    idSisbov: "",
    idICar: "982000444279141",
    nome: "",
    dataNascimento: "2021-01-05",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "982000444279141",
    idAnimalFazenda: "3210023",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Santa Gertrudis", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:15:38",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      idSistemaGestao: "619e1cbc-2bad-4330-aec8-7880432eea91",
      idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
      nomeResponsavel: "Fazenda União do Brasil",
      latitude: "-23.6825667",
      longitude: "-48.5986721",
      tipo: "Saída",
      peso: 552,
      dtPesagem: "2023-05-11T11:31:50",
      idPesagemBlockchain: "f3ab0f88-3ec6-44d4-9dff-a0419c880b41",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "619e1cbc-2bad-4330-aec8-7880432eea91",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Nascimento",
        peso: 32,
        dtPesagem: "2021-01-05T03:00:00",
        idPesagemBlockchain: "101a748b-6458-4f1b-8870-79357bc80d41",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "619e1cbc-2bad-4330-aec8-7880432eea91",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Desmame",
        peso: 163,
        dtPesagem: "2021-09-23T13:06:24",
        idPesagemBlockchain: "1549bca3-3cff-4dde-846c-f7ede3c38bf2",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "619e1cbc-2bad-4330-aec8-7880432eea91",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 418,
        dtPesagem: "2023-02-17T13:42:07",
        idPesagemBlockchain: "d870a9b7-020d-46ee-9ef8-9276aa90123a",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "619e1cbc-2bad-4330-aec8-7880432eea91",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Saída",
        peso: 552,
        dtPesagem: "2023-05-11T11:31:50",
        idPesagemBlockchain: "f3ab0f88-3ec6-44d4-9dff-a0419c880b41",
      },
    ],
    produtorAtual: {
      idBlockchain: "25bc0b49-7cf7-4519-b70c-3001a1e4bfed",
      nome: "Fazenda União do Brasil",
      documento: "08090246000103",
    },
    status: "ABATIDO",
    dataCriacao: "2023-12-18T13:22:59",
    dataUltimaAtualizacao: "2023-12-18T18:58:10",
    vendaAtual: {
      idBlockchain: "0401071b-4fe8-4b0e-ba81-d94b5ab0b970",
      documentoProprietarioDestino: "30269671000113",
      tipo: "INDUSTRIA",
      proprietarioOrigem: {
        nome: "Fazenda União do Brasil",
        documento: "08090246000103",
      },
      proprietarioDestino: {
        nome: "Frigorífico Red Beef LTDA",
        documento: "30269671000113",
      },
      dataCriacao: "2023-12-18T18:33:01",
      dataUltimaAtualizacao: "2023-12-18T18:33:19",
      status: "EFETIVADA",
      qtdAnimais: 29,
    },
    vendaHistorico: [
      {
        idBlockchain: "eb66af4e-769b-4269-9378-dba27a1c0947",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:21:39",
        dataUltimaAtualizacao: "2023-12-18T18:24:08",
        status: "RECUSADA",
        qtdAnimais: 29,
      },
      {
        idBlockchain: "0401071b-4fe8-4b0e-ba81-d94b5ab0b970",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:33:01",
        dataUltimaAtualizacao: "2023-12-18T18:33:19",
        status: "EFETIVADA",
        qtdAnimais: 29,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [
      {
        dtCriacao: "2023-12-18T14:16:17",
        dtAlteracao: "2023-12-18T14:16:17",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "irancho-mvp",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipoExame: "ULTRASSOM_CARCACA",
        dtExame: "2023-05-11T11:31:50",
        vrResultado: "",
        vrResultadoAol: "63.11",
        vrResultadoEgs: "3.29",
        vrResultadoEgg: "0",
        vrResultadoMar: "5.69",
        vrResultadoRatio: "0",
        qtDiasPrenhe: null,
        icApresentouCio: null,
        idExameBlockchain: "d0bec8bd-8021-44f1-83dd-7c42b51981ec",
      },
    ],
    medicamentosAplicados: [
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "irancho-mvp",
        idOriginalMedicamentoBlockchain: "40aa0e8c-5ceb-4b79-bd44-253139e57a21",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        dtMedicacao: "2021-06-07T12:00:40",
        unidadeMedida: "MILILITRO",
        quantidade: 2,
        idMedicamentoBlockchain: "8323131d-71b0-4562-ab7c-9cd1cf948102",
        medicamento: {
          idBlockchain: "40aa0e8c-5ceb-4b79-bd44-253139e57a21",
          nome: "Aborvac Brucelose",
          categoria: "FARMACIA",
          subCategoria: "VACINA",
          fabricante: "Zoetis",
          unidade: "MILILITRO",
          sexo: "FEMEA",
          idadeMaxima: 8,
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "irancho-mvp",
        idOriginalMedicamentoBlockchain: "3367926c-ceac-46be-8628-6774c88536a5",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        dtMedicacao: "2022-11-30T14:43:19",
        unidadeMedida: "MILILITRO",
        quantidade: 2,
        idMedicamentoBlockchain: "de3e3f97-67c2-4217-a61c-d2bc0251fd3a",
        medicamento: {
          idBlockchain: "3367926c-ceac-46be-8628-6774c88536a5",
          nome: "Aftosa ourovac 15 doses ",
          categoria: "FARMACIA",
          subCategoria: "VACINA",
          fabricante: "Ouro fino",
          unidade: "MILILITRO",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:30",
          dataUltimaAtualizacao: "2023-12-13T13:25:30",
        },
      },
    ],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:16:02",
      dtAlteracao: "2023-12-18T14:16:02",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      fazenda: {
        nome: "Fazenda União do Brasil ",
        dataEntrada: "2022-11-30T00:00:00",
        dataSaida: "2023-05-11T00:00:00",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
      },
      subdivisao: {
        nome: "Confinamento",
        dataEntrada: "2022-11-30T00:00:00",
        dataSaida: "2023-05-11T00:00:00",
      },
      idSistemaGestao: "619e1cbc-2bad-4330-aec8-7880432eea91",
      idLocalizacaoBlockchain: "faf1c63d-f84d-4edc-8e50-da8c6956d9b8",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Vale do Sol ",
          dataEntrada: "2021-10-26T00:00:00",
          dataSaida: "2022-11-30T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "transferencia de animais",
          dataEntrada: "2021-10-26T00:00:00",
          dataSaida: "2022-11-30T00:00:00",
        },
        idSistemaGestao: "619e1cbc-2bad-4330-aec8-7880432eea91",
        idLocalizacaoBlockchain: "6ab92bf1-5e8d-4263-8cf1-cf59e72c12e7",
      },
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Fazenda União do Brasil ",
          dataEntrada: "2022-11-30T00:00:00",
          dataSaida: "2023-05-11T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "Confinamento",
          dataEntrada: "2022-11-30T00:00:00",
          dataSaida: "2023-05-11T00:00:00",
        },
        idSistemaGestao: "619e1cbc-2bad-4330-aec8-7880432eea91",
        idLocalizacaoBlockchain: "faf1c63d-f84d-4edc-8e50-da8c6956d9b8",
      },
    ],
    loteHistorico: [],
    idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
    nomeResponsavel: "Fazenda União do Brasil",
    frigorifico: {
      nome: "Frigorífico Red Beef LTDA",
      maturidade: "2 dentes",
      acabamento: "Uniforme",
      pesoBanda1: "138.50 Kg",
      pesoBanda2: "138.50 Kg",
      obs1: "",
      obs2: "",
      dtMorte: "2023-05-11",
      porcentagemCarcaca: "50%",
      dtCriacao: "2023-12-18T18:58:10",
      dtAlteracao: "2023-12-18T18:58:10",
    },
  },
  "8f8d95fe-0800-4c5e-bf1d-c430d9952acb": {
    idLoteSync: "ec1f85a1-7f04-468d-ac28-2bfdf17d33b5",
    idBlockchain: "8f8d95fe-0800-4c5e-bf1d-c430d9952acb",
    docType: "ANIMAL",
    idSistemaGestao: "baa68b4c-9b88-493e-a717-f640926871cf",
    idSisbov: "",
    idICar: "982000444277810",
    nome: "",
    dataNascimento: "2022-06-01",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "982000444277810",
    idAnimalFazenda: "4277810",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Santa Gertrudis", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:15:38",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      idSistemaGestao: "cdce85d8-6a96-4d00-9fdf-01eb9497eeef",
      idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
      nomeResponsavel: "Fazenda União do Brasil",
      latitude: "",
      longitude: "",
      tipo: "Saída",
      peso: 439,
      dtPesagem: "2023-11-07",
      idPesagemBlockchain: "d1261717-b152-4527-af9b-be00c65b8156",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "e9930bea-f569-4194-abba-a1d247684541",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Entrada",
        peso: 266,
        dtPesagem: "2023-06-01",
        idPesagemBlockchain: "d0173bee-0a4d-408a-bedb-7bdd1efc0702",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "4fd97567-5b2e-4812-9688-0156d259e63b",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 265,
        dtPesagem: "2023-06-27",
        idPesagemBlockchain: "ba0436c6-17c5-49e5-b552-2f35f436046d",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "4527f660-efbc-4b62-a565-a579ceea1e87",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 360,
        dtPesagem: "2023-09-04",
        idPesagemBlockchain: "fdf95ea3-8458-429e-a691-1681d2ce0df1",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "cdce85d8-6a96-4d00-9fdf-01eb9497eeef",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Saída",
        peso: 439,
        dtPesagem: "2023-11-07",
        idPesagemBlockchain: "d1261717-b152-4527-af9b-be00c65b8156",
      },
    ],
    produtorAtual: {
      idBlockchain: "25bc0b49-7cf7-4519-b70c-3001a1e4bfed",
      nome: "Fazenda União do Brasil",
      documento: "08090246000103",
    },
    status: "ABATIDO",
    dataCriacao: "2023-12-18T13:22:59",
    dataUltimaAtualizacao: "2023-12-18T18:46:23",
    vendaAtual: {
      idBlockchain: "e0130d89-b68f-423b-ad3d-a429637fb5a3",
      documentoProprietarioDestino: "18293459000196",
      tipo: "INDUSTRIA",
      proprietarioOrigem: {
        nome: "Fazenda União do Brasil",
        documento: "08090246000103",
      },
      proprietarioDestino: {
        nome: "Guidara Indústria e Comércio de Alimentos Eireli",
        documento: "18293459000196",
      },
      dataCriacao: "2023-12-18T18:27:49",
      dataUltimaAtualizacao: "2023-12-18T18:28:02",
      status: "EFETIVADA",
      qtdAnimais: 25,
    },
    vendaHistorico: [
      {
        idBlockchain: "f10b303c-ab60-4d04-b8b0-0f217cda4abb",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:22:09",
        dataUltimaAtualizacao: "2023-12-18T18:24:42",
        status: "RECUSADA",
        qtdAnimais: 25,
      },
      {
        idBlockchain: "f1392d6d-fc67-43f4-918a-1da3da56a459",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:26:52",
        dataUltimaAtualizacao: "2023-12-18T18:27:14",
        status: "RECUSADA",
        qtdAnimais: 25,
      },
      {
        idBlockchain: "e0130d89-b68f-423b-ad3d-a429637fb5a3",
        documentoProprietarioDestino: "18293459000196",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Guidara Indústria e Comércio de Alimentos Eireli",
          documento: "18293459000196",
        },
        dataCriacao: "2023-12-18T18:27:49",
        dataUltimaAtualizacao: "2023-12-18T18:28:02",
        status: "EFETIVADA",
        qtdAnimais: 25,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [
      {
        dtCriacao: "2023-12-18T14:16:17",
        dtAlteracao: "2023-12-18T14:16:17",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "4aa02222-dcd2-4d49-9a1d-697afbadb6d9",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipoExame: "ULTRASSOM_CARCACA",
        dtExame: "2023-09-04",
        vrResultado: "",
        vrResultadoAol: "76.18",
        vrResultadoEgs: "5.18",
        vrResultadoEgg: "0.00",
        vrResultadoMar: "3.35",
        vrResultadoRatio: "0.54",
        qtDiasPrenhe: null,
        icApresentouCio: null,
        idExameBlockchain: "0d5ab897-9a08-4d23-851b-02fe21e92e92",
      },
    ],
    medicamentosAplicados: [
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "cbd915c1-f5e3-4800-a699-9a9d2979fe05",
        idOriginalMedicamentoBlockchain: "03a88948-7528-45c1-873a-b8d6012f9c15",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        dtMedicacao: "2023-06-27",
        unidadeMedida: "UNIDADE",
        quantidade: 2,
        idMedicamentoBlockchain: "f2bee8cb-3cd8-48ca-82f6-8eec98f09766",
        medicamento: {
          idBlockchain: "03a88948-7528-45c1-873a-b8d6012f9c15",
          nome: "Vacina Bovi-Shield",
          categoria: "FARMACIA",
          subCategoria: "VACINA",
          fabricante: "Zoetis",
          unidade: "UNIDADE",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "f2fc318b-ceee-403f-a6b5-86d569cf36f3",
        idOriginalMedicamentoBlockchain: "ba97487b-264d-4640-887a-1740557e1093",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        dtMedicacao: "2023-06-01",
        unidadeMedida: "MILILITRO",
        quantidade: 5,
        idMedicamentoBlockchain: "c4c3560b-21b1-407c-bbac-abb100436b8c",
        medicamento: {
          idBlockchain: "ba97487b-264d-4640-887a-1740557e1093",
          nome: "Iver-vet 3.5 injetável 1000 mL",
          categoria: "FARMACIA",
          subCategoria: "VERMIFUGO",
          fabricante: "Biovet",
          unidade: "LITRO",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
    ],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:16:02",
      dtAlteracao: "2023-12-18T14:16:02",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      fazenda: {
        nome: "Fazenda União do Brasil ",
        dataEntrada: "2023-06-27",
        dataSaida: "2023-11-07",
        latitude: "-23.68423843",
        longitude: "-48.59685500",
      },
      subdivisao: {
        nome: "Confinamento",
        dataEntrada: "2023-06-27",
        dataSaida: "2023-11-07",
      },
      idSistemaGestao: "5015344",
      idLocalizacaoBlockchain: "adf15ccc-c4fa-423d-848d-cd0c86327199",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Fazenda União do Brasil ",
          dataEntrada: "2023-06-27",
          dataSaida: "2023-11-07",
          latitude: "-23.68423843",
          longitude: "-48.59685500",
        },
        subdivisao: {
          nome: "Confinamento",
          dataEntrada: "2023-06-27",
          dataSaida: "2023-11-07",
        },
        idSistemaGestao: "5015344",
        idLocalizacaoBlockchain: "adf15ccc-c4fa-423d-848d-cd0c86327199",
      },
    ],
    loteHistorico: [],
    idResponsavel: "fdd249be-f2a0-402a-9213-c40dff9f7c9f",
    nomeResponsavel: "BARTIRA AGROPECUARIA S/A",
    frigorifico: {
      nome: "Guidara Industria",
      maturidade: "2 dentes",
      acabamento: "Uniforme",
      pesoBanda1: "116.25",
      pesoBanda2: "116.25",
      obs1: "",
      obs2: "",
      dtMorte: "2023-11-08T00:00:00",
      porcentagemCarcaca: "54.30",
      dtCriacao: "2023-12-18T18:46:23",
      dtAlteracao: "2023-12-18T18:46:23",
    },
  },
  "99055e3d-4d44-480a-991d-c66c67a47342": {
    idLoteSync: "ec1f85a1-7f04-468d-ac28-2bfdf17d33b5",
    idBlockchain: "99055e3d-4d44-480a-991d-c66c67a47342",
    docType: "ANIMAL",
    idSistemaGestao: "7ba9ff3b-0462-41b1-b15c-b3b9bbc1efaa",
    idSisbov: "",
    idICar: "982000444284101",
    nome: "",
    dataNascimento: "2022-04-02",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "982000444284101",
    idAnimalFazenda: "4284101",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Santa Gertrudis", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:15:38",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      idSistemaGestao: "7b74902e-04d1-4ffe-b41d-dd55d0f61b9d",
      idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
      nomeResponsavel: "Fazenda União do Brasil",
      latitude: "",
      longitude: "",
      tipo: "Saída",
      peso: 482,
      dtPesagem: "2023-11-07",
      idPesagemBlockchain: "a8da8857-8643-491c-b83c-4b7f19763e96",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "3f685333-9cab-46c0-84ad-3fee6501be21",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Entrada",
        peso: 326,
        dtPesagem: "2023-06-01",
        idPesagemBlockchain: "b5e9ae4a-437b-4723-bcb3-c5757a9a4d0e",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "9128dd53-376f-469f-bf96-df5e598572b9",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 286,
        dtPesagem: "2023-06-27",
        idPesagemBlockchain: "5af25fb8-caf8-4ed1-8c62-d08cc4045bb1",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "382cd95f-4bb3-4eee-a41f-ce90e866a591",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 399,
        dtPesagem: "2023-09-04",
        idPesagemBlockchain: "11efbc46-12b2-4d6b-92ff-64d23c6de3ea",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "7b74902e-04d1-4ffe-b41d-dd55d0f61b9d",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Saída",
        peso: 482,
        dtPesagem: "2023-11-07",
        idPesagemBlockchain: "a8da8857-8643-491c-b83c-4b7f19763e96",
      },
    ],
    produtorAtual: {
      idBlockchain: "25bc0b49-7cf7-4519-b70c-3001a1e4bfed",
      nome: "Fazenda União do Brasil",
      documento: "08090246000103",
    },
    status: "ABATIDO",
    dataCriacao: "2023-12-18T13:22:59",
    dataUltimaAtualizacao: "2023-12-18T18:49:26",
    vendaAtual: {
      idBlockchain: "e0130d89-b68f-423b-ad3d-a429637fb5a3",
      documentoProprietarioDestino: "18293459000196",
      tipo: "INDUSTRIA",
      proprietarioOrigem: {
        nome: "Fazenda União do Brasil",
        documento: "08090246000103",
      },
      proprietarioDestino: {
        nome: "Guidara Indústria e Comércio de Alimentos Eireli",
        documento: "18293459000196",
      },
      dataCriacao: "2023-12-18T18:27:49",
      dataUltimaAtualizacao: "2023-12-18T18:28:02",
      status: "EFETIVADA",
      qtdAnimais: 25,
    },
    vendaHistorico: [
      {
        idBlockchain: "f10b303c-ab60-4d04-b8b0-0f217cda4abb",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:22:09",
        dataUltimaAtualizacao: "2023-12-18T18:24:42",
        status: "RECUSADA",
        qtdAnimais: 25,
      },
      {
        idBlockchain: "f1392d6d-fc67-43f4-918a-1da3da56a459",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:26:52",
        dataUltimaAtualizacao: "2023-12-18T18:27:14",
        status: "RECUSADA",
        qtdAnimais: 25,
      },
      {
        idBlockchain: "e0130d89-b68f-423b-ad3d-a429637fb5a3",
        documentoProprietarioDestino: "18293459000196",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Guidara Indústria e Comércio de Alimentos Eireli",
          documento: "18293459000196",
        },
        dataCriacao: "2023-12-18T18:27:49",
        dataUltimaAtualizacao: "2023-12-18T18:28:02",
        status: "EFETIVADA",
        qtdAnimais: 25,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [
      {
        dtCriacao: "2023-12-18T14:16:17",
        dtAlteracao: "2023-12-18T14:16:17",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "a91a6080-a596-4006-a258-c1d130e3dae2",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipoExame: "ULTRASSOM_CARCACA",
        dtExame: "2023-09-04",
        vrResultado: "",
        vrResultadoAol: "92.50",
        vrResultadoEgs: "5.18",
        vrResultadoEgg: "0.00",
        vrResultadoMar: "3.42",
        vrResultadoRatio: "0.55",
        qtDiasPrenhe: null,
        icApresentouCio: null,
        idExameBlockchain: "f9ec64d2-4ab7-4a19-b75f-0e2466c824dc",
      },
    ],
    medicamentosAplicados: [
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "519cddf1-82a2-41fa-9dad-d6bf5073713f",
        idOriginalMedicamentoBlockchain: "ba97487b-264d-4640-887a-1740557e1093",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        dtMedicacao: "2023-06-01",
        unidadeMedida: "MILILITRO",
        quantidade: 5,
        idMedicamentoBlockchain: "482016ee-113c-480b-b503-52bea0af5615",
        medicamento: {
          idBlockchain: "ba97487b-264d-4640-887a-1740557e1093",
          nome: "Iver-vet 3.5 injetável 1000 mL",
          categoria: "FARMACIA",
          subCategoria: "VERMIFUGO",
          fabricante: "Biovet",
          unidade: "LITRO",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "71017a1d-883f-4bcb-9426-7430e0455569",
        idOriginalMedicamentoBlockchain: "03a88948-7528-45c1-873a-b8d6012f9c15",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        dtMedicacao: "2023-06-27",
        unidadeMedida: "UNIDADE",
        quantidade: 2,
        idMedicamentoBlockchain: "50ad4573-8be4-4741-ac2e-8b5688c0f40d",
        medicamento: {
          idBlockchain: "03a88948-7528-45c1-873a-b8d6012f9c15",
          nome: "Vacina Bovi-Shield",
          categoria: "FARMACIA",
          subCategoria: "VACINA",
          fabricante: "Zoetis",
          unidade: "UNIDADE",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
    ],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:16:02",
      dtAlteracao: "2023-12-18T14:16:02",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      fazenda: {
        nome: "Fazenda União do Brasil ",
        dataEntrada: "2023-06-27",
        dataSaida: "2023-11-07",
        latitude: "-23.68423843",
        longitude: "-48.59685500",
      },
      subdivisao: {
        nome: "Confinamento",
        dataEntrada: "2023-06-27",
        dataSaida: "2023-11-07",
      },
      idSistemaGestao: "5015415",
      idLocalizacaoBlockchain: "282e607f-7fb9-4fe2-8745-0b6b6f48e27c",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Fazenda União do Brasil ",
          dataEntrada: "2023-06-27",
          dataSaida: "2023-11-07",
          latitude: "-23.68423843",
          longitude: "-48.59685500",
        },
        subdivisao: {
          nome: "Confinamento",
          dataEntrada: "2023-06-27",
          dataSaida: "2023-11-07",
        },
        idSistemaGestao: "5015415",
        idLocalizacaoBlockchain: "282e607f-7fb9-4fe2-8745-0b6b6f48e27c",
      },
    ],
    loteHistorico: [],
    idResponsavel: "fdd249be-f2a0-402a-9213-c40dff9f7c9f",
    nomeResponsavel: "BARTIRA AGROPECUARIA S/A",
    frigorifico: {
      nome: "Guidara Industria",
      maturidade: "2 dentes",
      acabamento: "Uniforme",
      pesoBanda1: "135",
      pesoBanda2: "135",
      obs1: "",
      obs2: "",
      dtMorte: "2023-11-08T00:00:00",
      porcentagemCarcaca: "57.70",
      dtCriacao: "2023-12-18T18:49:26",
      dtAlteracao: "2023-12-18T18:49:26",
    },
  },
  "9c20ea90-7885-4212-ae66-4bea232042ef": {
    idLoteSync: "ec1f85a1-7f04-468d-ac28-2bfdf17d33b5",
    idBlockchain: "9c20ea90-7885-4212-ae66-4bea232042ef",
    docType: "ANIMAL",
    idSistemaGestao: "160d0458-87b4-41a3-9edc-d2967a2290aa",
    idSisbov: "",
    idICar: "982000444275812",
    nome: "",
    dataNascimento: "2022-06-01",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "982000444275812",
    idAnimalFazenda: "4275812",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Santa Gertrudis", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:15:38",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      idSistemaGestao: "47330024-79b2-48b1-96ed-88628f205d84",
      idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
      nomeResponsavel: "Fazenda União do Brasil",
      latitude: "",
      longitude: "",
      tipo: "Saída",
      peso: 462,
      dtPesagem: "2023-11-07",
      idPesagemBlockchain: "f3923e62-069f-40b4-ae2a-41d1038838da",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "b683c30d-2744-4ebc-a5ed-fe4669ab906a",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Entrada",
        peso: 286,
        dtPesagem: "2023-06-01",
        idPesagemBlockchain: "94136b20-bd72-433f-930e-3d6ca8ecb3e2",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "901b0b09-adf9-45f4-bb58-27bc3765fb79",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 276,
        dtPesagem: "2023-06-27",
        idPesagemBlockchain: "3e2c159e-d805-4944-a62b-23e21c629071",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "8e3325b1-1335-4e06-8826-a6f4fb99a339",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 379,
        dtPesagem: "2023-09-04",
        idPesagemBlockchain: "87e81233-6369-45a6-a284-48d809f41c99",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "47330024-79b2-48b1-96ed-88628f205d84",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Saída",
        peso: 462,
        dtPesagem: "2023-11-07",
        idPesagemBlockchain: "f3923e62-069f-40b4-ae2a-41d1038838da",
      },
    ],
    produtorAtual: {
      idBlockchain: "25bc0b49-7cf7-4519-b70c-3001a1e4bfed",
      nome: "Fazenda União do Brasil",
      documento: "08090246000103",
    },
    status: "ABATIDO",
    dataCriacao: "2023-12-18T13:22:59",
    dataUltimaAtualizacao: "2023-12-18T18:48:58",
    vendaAtual: {
      idBlockchain: "e0130d89-b68f-423b-ad3d-a429637fb5a3",
      documentoProprietarioDestino: "18293459000196",
      tipo: "INDUSTRIA",
      proprietarioOrigem: {
        nome: "Fazenda União do Brasil",
        documento: "08090246000103",
      },
      proprietarioDestino: {
        nome: "Guidara Indústria e Comércio de Alimentos Eireli",
        documento: "18293459000196",
      },
      dataCriacao: "2023-12-18T18:27:49",
      dataUltimaAtualizacao: "2023-12-18T18:28:02",
      status: "EFETIVADA",
      qtdAnimais: 25,
    },
    vendaHistorico: [
      {
        idBlockchain: "f10b303c-ab60-4d04-b8b0-0f217cda4abb",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:22:09",
        dataUltimaAtualizacao: "2023-12-18T18:24:42",
        status: "RECUSADA",
        qtdAnimais: 25,
      },
      {
        idBlockchain: "f1392d6d-fc67-43f4-918a-1da3da56a459",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:26:52",
        dataUltimaAtualizacao: "2023-12-18T18:27:14",
        status: "RECUSADA",
        qtdAnimais: 25,
      },
      {
        idBlockchain: "e0130d89-b68f-423b-ad3d-a429637fb5a3",
        documentoProprietarioDestino: "18293459000196",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Guidara Indústria e Comércio de Alimentos Eireli",
          documento: "18293459000196",
        },
        dataCriacao: "2023-12-18T18:27:49",
        dataUltimaAtualizacao: "2023-12-18T18:28:02",
        status: "EFETIVADA",
        qtdAnimais: 25,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [
      {
        dtCriacao: "2023-12-18T14:16:17",
        dtAlteracao: "2023-12-18T14:16:17",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "fc1458fb-7c2e-4711-9737-809486b578eb",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipoExame: "ULTRASSOM_CARCACA",
        dtExame: "2023-09-04",
        vrResultado: "",
        vrResultadoAol: "76.77",
        vrResultadoEgs: "5.18",
        vrResultadoEgg: "0.00",
        vrResultadoMar: "3.64",
        vrResultadoRatio: "0.50",
        qtDiasPrenhe: null,
        icApresentouCio: null,
        idExameBlockchain: "0cf6be2e-5c46-4319-a998-8104920296cd",
      },
    ],
    medicamentosAplicados: [
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "2f18c1db-6d0c-4411-bd05-ee5945215165",
        idOriginalMedicamentoBlockchain: "03a88948-7528-45c1-873a-b8d6012f9c15",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        dtMedicacao: "2023-06-27",
        unidadeMedida: "UNIDADE",
        quantidade: 2,
        idMedicamentoBlockchain: "c0f29a2c-40e7-427b-a7f9-488a0259e666",
        medicamento: {
          idBlockchain: "03a88948-7528-45c1-873a-b8d6012f9c15",
          nome: "Vacina Bovi-Shield",
          categoria: "FARMACIA",
          subCategoria: "VACINA",
          fabricante: "Zoetis",
          unidade: "UNIDADE",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "85201af9-0893-4be0-8ad2-efca8a648d29",
        idOriginalMedicamentoBlockchain: "ba97487b-264d-4640-887a-1740557e1093",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        dtMedicacao: "2023-06-01",
        unidadeMedida: "MILILITRO",
        quantidade: 5,
        idMedicamentoBlockchain: "b226edd4-8305-49a4-b5f4-90aba43dcd41",
        medicamento: {
          idBlockchain: "ba97487b-264d-4640-887a-1740557e1093",
          nome: "Iver-vet 3.5 injetável 1000 mL",
          categoria: "FARMACIA",
          subCategoria: "VERMIFUGO",
          fabricante: "Biovet",
          unidade: "LITRO",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
    ],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:16:02",
      dtAlteracao: "2023-12-18T14:16:02",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      fazenda: {
        nome: "Fazenda União do Brasil ",
        dataEntrada: "2023-06-27",
        dataSaida: "2023-11-07",
        latitude: "-23.68423843",
        longitude: "-48.59685500",
      },
      subdivisao: {
        nome: "Confinamento",
        dataEntrada: "2023-06-27",
        dataSaida: "2023-11-07",
      },
      idSistemaGestao: "5015337",
      idLocalizacaoBlockchain: "2a86c9a1-89a9-46dc-96a6-032300564333",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Fazenda União do Brasil ",
          dataEntrada: "2023-06-27",
          dataSaida: "2023-11-07",
          latitude: "-23.68423843",
          longitude: "-48.59685500",
        },
        subdivisao: {
          nome: "Confinamento",
          dataEntrada: "2023-06-27",
          dataSaida: "2023-11-07",
        },
        idSistemaGestao: "5015337",
        idLocalizacaoBlockchain: "2a86c9a1-89a9-46dc-96a6-032300564333",
      },
    ],
    loteHistorico: [],
    idResponsavel: "fdd249be-f2a0-402a-9213-c40dff9f7c9f",
    nomeResponsavel: "BARTIRA AGROPECUARIA S/A",
    frigorifico: {
      nome: "Guidara Industria",
      maturidade: "2 dentes",
      acabamento: "Uniforme",
      pesoBanda1: "130",
      pesoBanda2: "130",
      obs1: "",
      obs2: "",
      dtMorte: "2023-11-08T00:00:00",
      porcentagemCarcaca: "57.80",
      dtCriacao: "2023-12-18T18:48:58",
      dtAlteracao: "2023-12-18T18:48:58",
    },
  },
  "9d022bc9-297a-4e71-acce-80acf1b3afbc": {
    idLoteSync: "ec1f85a1-7f04-468d-ac28-2bfdf17d33b5",
    idBlockchain: "9d022bc9-297a-4e71-acce-80acf1b3afbc",
    docType: "ANIMAL",
    idSistemaGestao: "72cde3e5-abfb-4b32-b565-c2f6b3e71457",
    idSisbov: "",
    idICar: "982000444278358",
    nome: "",
    dataNascimento: "2022-04-02",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "982000444278358",
    idAnimalFazenda: "4278358",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Santa Gertrudis", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:15:38",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      idSistemaGestao: "6d5367d1-ed95-4970-936e-87d0a92362d2",
      idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
      nomeResponsavel: "Fazenda União do Brasil",
      latitude: "",
      longitude: "",
      tipo: "Saída",
      peso: 469,
      dtPesagem: "2023-11-07",
      idPesagemBlockchain: "bb56a6f9-0901-40a0-b3eb-0b2e23ed307f",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "4257f7ad-f639-43d8-a887-a83850b960b7",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Entrada",
        peso: 276,
        dtPesagem: "2023-06-01",
        idPesagemBlockchain: "88da9b0c-b97c-42f5-bf67-ad23dcf879fc",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "ea13ed92-74b5-4c97-a605-53c4227aa6ee",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 272,
        dtPesagem: "2023-06-27",
        idPesagemBlockchain: "b8efd1b0-9341-4c63-a333-2e676a915a16",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "9cc49eaa-f806-47f2-84d3-a401e0fcf1e7",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 375,
        dtPesagem: "2023-09-04",
        idPesagemBlockchain: "baddc77a-c7b0-44fe-9c18-69036b2da5eb",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "6d5367d1-ed95-4970-936e-87d0a92362d2",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Saída",
        peso: 469,
        dtPesagem: "2023-11-07",
        idPesagemBlockchain: "bb56a6f9-0901-40a0-b3eb-0b2e23ed307f",
      },
    ],
    produtorAtual: {
      idBlockchain: "25bc0b49-7cf7-4519-b70c-3001a1e4bfed",
      nome: "Fazenda União do Brasil",
      documento: "08090246000103",
    },
    status: "ABATIDO",
    dataCriacao: "2023-12-18T13:22:59",
    dataUltimaAtualizacao: "2023-12-18T18:48:25",
    vendaAtual: {
      idBlockchain: "e0130d89-b68f-423b-ad3d-a429637fb5a3",
      documentoProprietarioDestino: "18293459000196",
      tipo: "INDUSTRIA",
      proprietarioOrigem: {
        nome: "Fazenda União do Brasil",
        documento: "08090246000103",
      },
      proprietarioDestino: {
        nome: "Guidara Indústria e Comércio de Alimentos Eireli",
        documento: "18293459000196",
      },
      dataCriacao: "2023-12-18T18:27:49",
      dataUltimaAtualizacao: "2023-12-18T18:28:02",
      status: "EFETIVADA",
      qtdAnimais: 25,
    },
    vendaHistorico: [
      {
        idBlockchain: "f10b303c-ab60-4d04-b8b0-0f217cda4abb",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:22:09",
        dataUltimaAtualizacao: "2023-12-18T18:24:42",
        status: "RECUSADA",
        qtdAnimais: 25,
      },
      {
        idBlockchain: "f1392d6d-fc67-43f4-918a-1da3da56a459",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:26:52",
        dataUltimaAtualizacao: "2023-12-18T18:27:14",
        status: "RECUSADA",
        qtdAnimais: 25,
      },
      {
        idBlockchain: "e0130d89-b68f-423b-ad3d-a429637fb5a3",
        documentoProprietarioDestino: "18293459000196",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Guidara Indústria e Comércio de Alimentos Eireli",
          documento: "18293459000196",
        },
        dataCriacao: "2023-12-18T18:27:49",
        dataUltimaAtualizacao: "2023-12-18T18:28:02",
        status: "EFETIVADA",
        qtdAnimais: 25,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [
      {
        dtCriacao: "2023-12-18T14:16:17",
        dtAlteracao: "2023-12-18T14:16:17",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "2adb6200-e777-41de-870d-377c9c7441ae",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipoExame: "ULTRASSOM_CARCACA",
        dtExame: "2023-09-04",
        vrResultado: "",
        vrResultadoAol: "77.59",
        vrResultadoEgs: "5.18",
        vrResultadoEgg: "0.00",
        vrResultadoMar: "3.95",
        vrResultadoRatio: "0.52",
        qtDiasPrenhe: null,
        icApresentouCio: null,
        idExameBlockchain: "fca41cbc-3e96-4080-9313-49db406a68c2",
      },
    ],
    medicamentosAplicados: [
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "953ad6a2-51c9-4c72-869f-2d7ac5ba4338",
        idOriginalMedicamentoBlockchain: "ba97487b-264d-4640-887a-1740557e1093",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        dtMedicacao: "2023-06-01",
        unidadeMedida: "MILILITRO",
        quantidade: 5,
        idMedicamentoBlockchain: "84e7f3e3-9267-4b13-a582-90ff1e59fe93",
        medicamento: {
          idBlockchain: "ba97487b-264d-4640-887a-1740557e1093",
          nome: "Iver-vet 3.5 injetável 1000 mL",
          categoria: "FARMACIA",
          subCategoria: "VERMIFUGO",
          fabricante: "Biovet",
          unidade: "LITRO",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "e36d9c4c-32f4-436f-8e9f-6cdf32a81a02",
        idOriginalMedicamentoBlockchain: "03a88948-7528-45c1-873a-b8d6012f9c15",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        dtMedicacao: "2023-06-27",
        unidadeMedida: "UNIDADE",
        quantidade: 2,
        idMedicamentoBlockchain: "775afe0d-e706-4046-bd7b-033b28c3d083",
        medicamento: {
          idBlockchain: "03a88948-7528-45c1-873a-b8d6012f9c15",
          nome: "Vacina Bovi-Shield",
          categoria: "FARMACIA",
          subCategoria: "VACINA",
          fabricante: "Zoetis",
          unidade: "UNIDADE",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
    ],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:16:02",
      dtAlteracao: "2023-12-18T14:16:02",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      fazenda: {
        nome: "Fazenda União do Brasil ",
        dataEntrada: "2023-06-27",
        dataSaida: "2023-11-07",
        latitude: "-23.68423843",
        longitude: "-48.59685500",
      },
      subdivisao: {
        nome: "Confinamento",
        dataEntrada: "2023-06-27",
        dataSaida: "2023-11-07",
      },
      idSistemaGestao: "5015412",
      idLocalizacaoBlockchain: "43a4e046-2f89-4e55-954a-b675a998cf4d",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Fazenda União do Brasil ",
          dataEntrada: "2023-06-27",
          dataSaida: "2023-11-07",
          latitude: "-23.68423843",
          longitude: "-48.59685500",
        },
        subdivisao: {
          nome: "Confinamento",
          dataEntrada: "2023-06-27",
          dataSaida: "2023-11-07",
        },
        idSistemaGestao: "5015412",
        idLocalizacaoBlockchain: "43a4e046-2f89-4e55-954a-b675a998cf4d",
      },
    ],
    loteHistorico: [],
    idResponsavel: "fdd249be-f2a0-402a-9213-c40dff9f7c9f",
    nomeResponsavel: "BARTIRA AGROPECUARIA S/A",
    frigorifico: {
      nome: "Guidara Industria",
      maturidade: "2 dentes",
      acabamento: "Uniforme",
      pesoBanda1: "126",
      pesoBanda2: "126",
      obs1: "",
      obs2: "",
      dtMorte: "2023-11-08T00:00:00",
      porcentagemCarcaca: "55.30",
      dtCriacao: "2023-12-18T18:48:25",
      dtAlteracao: "2023-12-18T18:48:25",
    },
  },
  "9ff925dc-da00-490f-b5ed-2df97dbad5c0": {
    idLoteSync: "ec1f85a1-7f04-468d-ac28-2bfdf17d33b5",
    idBlockchain: "9ff925dc-da00-490f-b5ed-2df97dbad5c0",
    docType: "ANIMAL",
    idSistemaGestao: "a301d916-758c-430f-833f-54cdb9c60a89",
    idSisbov: "",
    idICar: "982000444279515",
    nome: "",
    dataNascimento: "2020-12-29",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "982000444279515",
    idAnimalFazenda: "3201419",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Santa Gertrudis", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:15:38",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      idSistemaGestao: "a301d916-758c-430f-833f-54cdb9c60a89",
      idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
      nomeResponsavel: "Fazenda União do Brasil",
      latitude: "-23.6825667",
      longitude: "-48.5986721",
      tipo: "Saída",
      peso: 630,
      dtPesagem: "2023-05-11T11:31:50",
      idPesagemBlockchain: "48b1f2b8-4f1e-471f-82c2-37989ae98467",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "a301d916-758c-430f-833f-54cdb9c60a89",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Nascimento",
        peso: 30,
        dtPesagem: "2020-12-29T03:00:00",
        idPesagemBlockchain: "e853c67f-8edc-4af2-b4ce-9b408dfa05d0",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "a301d916-758c-430f-833f-54cdb9c60a89",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Desmame",
        peso: 157,
        dtPesagem: "2021-09-28T18:32:46",
        idPesagemBlockchain: "65a14a58-0644-43fb-a2e0-cfb82f78f179",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "a301d916-758c-430f-833f-54cdb9c60a89",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 346,
        dtPesagem: "2022-10-04T07:40:54",
        idPesagemBlockchain: "f88ca5ae-4a14-4d0f-a506-684341cde2eb",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "a301d916-758c-430f-833f-54cdb9c60a89",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 510,
        dtPesagem: "2023-02-17T13:41:34",
        idPesagemBlockchain: "422e060b-1aad-4f5a-a918-80f71211fb46",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "a301d916-758c-430f-833f-54cdb9c60a89",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Saída",
        peso: 630,
        dtPesagem: "2023-05-11T11:31:50",
        idPesagemBlockchain: "48b1f2b8-4f1e-471f-82c2-37989ae98467",
      },
    ],
    produtorAtual: {
      idBlockchain: "25bc0b49-7cf7-4519-b70c-3001a1e4bfed",
      nome: "Fazenda União do Brasil",
      documento: "08090246000103",
    },
    status: "ABATIDO",
    dataCriacao: "2023-12-18T13:22:59",
    dataUltimaAtualizacao: "2023-12-18T18:57:03",
    vendaAtual: {
      idBlockchain: "0401071b-4fe8-4b0e-ba81-d94b5ab0b970",
      documentoProprietarioDestino: "30269671000113",
      tipo: "INDUSTRIA",
      proprietarioOrigem: {
        nome: "Fazenda União do Brasil",
        documento: "08090246000103",
      },
      proprietarioDestino: {
        nome: "Frigorífico Red Beef LTDA",
        documento: "30269671000113",
      },
      dataCriacao: "2023-12-18T18:33:01",
      dataUltimaAtualizacao: "2023-12-18T18:33:19",
      status: "EFETIVADA",
      qtdAnimais: 29,
    },
    vendaHistorico: [
      {
        idBlockchain: "eb66af4e-769b-4269-9378-dba27a1c0947",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:21:39",
        dataUltimaAtualizacao: "2023-12-18T18:24:08",
        status: "RECUSADA",
        qtdAnimais: 29,
      },
      {
        idBlockchain: "0401071b-4fe8-4b0e-ba81-d94b5ab0b970",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:33:01",
        dataUltimaAtualizacao: "2023-12-18T18:33:19",
        status: "EFETIVADA",
        qtdAnimais: 29,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [
      {
        dtCriacao: "2023-12-18T14:16:17",
        dtAlteracao: "2023-12-18T14:16:17",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "irancho-mvp",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipoExame: "ULTRASSOM_CARCACA",
        dtExame: "2023-05-11T11:31:50",
        vrResultado: "",
        vrResultadoAol: "81.34",
        vrResultadoEgs: "5.65",
        vrResultadoEgg: "0",
        vrResultadoMar: "4.19",
        vrResultadoRatio: "0",
        qtDiasPrenhe: null,
        icApresentouCio: null,
        idExameBlockchain: "6f85e251-d00c-41c3-8345-3af6d281fd69",
      },
    ],
    medicamentosAplicados: [
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "irancho-mvp",
        idOriginalMedicamentoBlockchain: "40aa0e8c-5ceb-4b79-bd44-253139e57a21",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        dtMedicacao: "2021-06-08T10:30:45",
        unidadeMedida: "MILILITRO",
        quantidade: 2,
        idMedicamentoBlockchain: "4abc9d57-9998-4abe-a266-6669e0564568",
        medicamento: {
          idBlockchain: "40aa0e8c-5ceb-4b79-bd44-253139e57a21",
          nome: "Aborvac Brucelose",
          categoria: "FARMACIA",
          subCategoria: "VACINA",
          fabricante: "Zoetis",
          unidade: "MILILITRO",
          sexo: "FEMEA",
          idadeMaxima: 8,
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
    ],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:16:02",
      dtAlteracao: "2023-12-18T14:16:02",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      fazenda: {
        nome: "Fazenda União do Brasil ",
        dataEntrada: "2022-10-04T00:00:00",
        dataSaida: "2023-05-11T00:00:00",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
      },
      subdivisao: {
        nome: "Confinamento",
        dataEntrada: "2022-10-04T00:00:00",
        dataSaida: "2023-05-11T00:00:00",
      },
      idSistemaGestao: "a301d916-758c-430f-833f-54cdb9c60a89",
      idLocalizacaoBlockchain: "65ce2a2e-12a8-4a3b-8047-09b8a799aeec",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Vale do Sol ",
          dataEntrada: "2021-09-28T00:00:00",
          dataSaida: "2022-10-04T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "transferencia de animais",
          dataEntrada: "2021-09-28T00:00:00",
          dataSaida: "2022-10-04T00:00:00",
        },
        idSistemaGestao: "a301d916-758c-430f-833f-54cdb9c60a89",
        idLocalizacaoBlockchain: "8687d1e2-7db0-45d7-a7c8-b7e8c6fee059",
      },
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Fazenda União do Brasil ",
          dataEntrada: "2022-10-04T00:00:00",
          dataSaida: "2023-05-11T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "Confinamento",
          dataEntrada: "2022-10-04T00:00:00",
          dataSaida: "2023-05-11T00:00:00",
        },
        idSistemaGestao: "a301d916-758c-430f-833f-54cdb9c60a89",
        idLocalizacaoBlockchain: "65ce2a2e-12a8-4a3b-8047-09b8a799aeec",
      },
    ],
    loteHistorico: [],
    idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
    nomeResponsavel: "Fazenda União do Brasil",
    frigorifico: {
      nome: "Frigorífico Red Beef LTDA",
      maturidade: "2 dentes",
      acabamento: "Uniforme",
      pesoBanda1: "165.50 Kg",
      pesoBanda2: "165.50 Kg",
      obs1: "",
      obs2: "",
      dtMorte: "2023-05-11",
      porcentagemCarcaca: "53%",
      dtCriacao: "2023-12-18T18:57:03",
      dtAlteracao: "2023-12-18T18:57:03",
    },
  },
  "a61322b8-fc33-4205-ac52-4e876a1e7ba0": {
    idLoteSync: "ec1f85a1-7f04-468d-ac28-2bfdf17d33b5",
    idBlockchain: "a61322b8-fc33-4205-ac52-4e876a1e7ba0",
    docType: "ANIMAL",
    idSistemaGestao: "d9e1d37b-8f9d-462d-a7f6-a416bf22c948",
    idSisbov: "",
    idICar: "982000444279636",
    nome: "",
    dataNascimento: "2022-04-02",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "982000444279636",
    idAnimalFazenda: "4279636",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Santa Gertrudis", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:15:38",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      idSistemaGestao: "26f6d2cc-7ad1-4684-9054-6e6307144b50",
      idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
      nomeResponsavel: "Fazenda União do Brasil",
      latitude: "",
      longitude: "",
      tipo: "Saída",
      peso: 435,
      dtPesagem: "2023-11-07",
      idPesagemBlockchain: "59992d6a-fc39-4026-b2ee-b8ccc096f7d9",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "14e53875-44a3-4e74-93e1-e41841a84772",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Entrada",
        peso: 250,
        dtPesagem: "2023-06-01",
        idPesagemBlockchain: "66874dea-b9ca-4bed-837e-f58bc7f6fe71",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "51dde51c-4b5d-41e6-b02b-f965459fd224",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 249,
        dtPesagem: "2023-06-27",
        idPesagemBlockchain: "20fa0b1b-e3a2-453a-80a2-d5759be2542e",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "54579a5d-ee50-4105-ba9e-d7f273337533",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 341,
        dtPesagem: "2023-09-04",
        idPesagemBlockchain: "4a5979cc-020f-4b35-ade0-7e1b1faa6428",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "26f6d2cc-7ad1-4684-9054-6e6307144b50",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Saída",
        peso: 435,
        dtPesagem: "2023-11-07",
        idPesagemBlockchain: "59992d6a-fc39-4026-b2ee-b8ccc096f7d9",
      },
    ],
    produtorAtual: {
      idBlockchain: "25bc0b49-7cf7-4519-b70c-3001a1e4bfed",
      nome: "Fazenda União do Brasil",
      documento: "08090246000103",
    },
    status: "ABATIDO",
    dataCriacao: "2023-12-18T13:22:59",
    dataUltimaAtualizacao: "2023-12-18T18:46:05",
    vendaAtual: {
      idBlockchain: "e0130d89-b68f-423b-ad3d-a429637fb5a3",
      documentoProprietarioDestino: "18293459000196",
      tipo: "INDUSTRIA",
      proprietarioOrigem: {
        nome: "Fazenda União do Brasil",
        documento: "08090246000103",
      },
      proprietarioDestino: {
        nome: "Guidara Indústria e Comércio de Alimentos Eireli",
        documento: "18293459000196",
      },
      dataCriacao: "2023-12-18T18:27:49",
      dataUltimaAtualizacao: "2023-12-18T18:28:02",
      status: "EFETIVADA",
      qtdAnimais: 25,
    },
    vendaHistorico: [
      {
        idBlockchain: "f10b303c-ab60-4d04-b8b0-0f217cda4abb",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:22:09",
        dataUltimaAtualizacao: "2023-12-18T18:24:42",
        status: "RECUSADA",
        qtdAnimais: 25,
      },
      {
        idBlockchain: "f1392d6d-fc67-43f4-918a-1da3da56a459",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:26:52",
        dataUltimaAtualizacao: "2023-12-18T18:27:14",
        status: "RECUSADA",
        qtdAnimais: 25,
      },
      {
        idBlockchain: "e0130d89-b68f-423b-ad3d-a429637fb5a3",
        documentoProprietarioDestino: "18293459000196",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Guidara Indústria e Comércio de Alimentos Eireli",
          documento: "18293459000196",
        },
        dataCriacao: "2023-12-18T18:27:49",
        dataUltimaAtualizacao: "2023-12-18T18:28:02",
        status: "EFETIVADA",
        qtdAnimais: 25,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [
      {
        dtCriacao: "2023-12-18T14:16:17",
        dtAlteracao: "2023-12-18T14:16:17",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "91ecaf44-491a-47f6-8e59-f0511045f732",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipoExame: "ULTRASSOM_CARCACA",
        dtExame: "2023-09-04",
        vrResultado: "",
        vrResultadoAol: "73.37",
        vrResultadoEgs: "5.65",
        vrResultadoEgg: "0.00",
        vrResultadoMar: "3.49",
        vrResultadoRatio: "0.50",
        qtDiasPrenhe: null,
        icApresentouCio: null,
        idExameBlockchain: "62902650-c270-4e8f-9564-983c0a3fb61d",
      },
    ],
    medicamentosAplicados: [
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "4c2f2304-8527-4bc2-9b07-8b3bd47a2cf6",
        idOriginalMedicamentoBlockchain: "ba97487b-264d-4640-887a-1740557e1093",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        dtMedicacao: "2023-06-01",
        unidadeMedida: "MILILITRO",
        quantidade: 5,
        idMedicamentoBlockchain: "5a7b6031-9d8e-4914-83c6-fd3230887186",
        medicamento: {
          idBlockchain: "ba97487b-264d-4640-887a-1740557e1093",
          nome: "Iver-vet 3.5 injetável 1000 mL",
          categoria: "FARMACIA",
          subCategoria: "VERMIFUGO",
          fabricante: "Biovet",
          unidade: "LITRO",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "a5b13606-13f6-4ab3-b44c-bf9b14919bb1",
        idOriginalMedicamentoBlockchain: "03a88948-7528-45c1-873a-b8d6012f9c15",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        dtMedicacao: "2023-06-27",
        unidadeMedida: "UNIDADE",
        quantidade: 2,
        idMedicamentoBlockchain: "81933bde-626c-436d-a1ff-21f85a3b43c9",
        medicamento: {
          idBlockchain: "03a88948-7528-45c1-873a-b8d6012f9c15",
          nome: "Vacina Bovi-Shield",
          categoria: "FARMACIA",
          subCategoria: "VACINA",
          fabricante: "Zoetis",
          unidade: "UNIDADE",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
    ],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:16:02",
      dtAlteracao: "2023-12-18T14:16:02",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      fazenda: {
        nome: "Fazenda União do Brasil ",
        dataEntrada: "2023-06-27",
        dataSaida: "2023-11-07",
        latitude: "-23.68423843",
        longitude: "-48.59685500",
      },
      subdivisao: {
        nome: "Confinamento",
        dataEntrada: "2023-06-27",
        dataSaida: "2023-11-07",
      },
      idSistemaGestao: "5015418",
      idLocalizacaoBlockchain: "5b1972d0-3ee8-44df-b07c-ca6bf92d94ca",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Fazenda União do Brasil ",
          dataEntrada: "2023-06-27",
          dataSaida: "2023-11-07",
          latitude: "-23.68423843",
          longitude: "-48.59685500",
        },
        subdivisao: {
          nome: "Confinamento",
          dataEntrada: "2023-06-27",
          dataSaida: "2023-11-07",
        },
        idSistemaGestao: "5015418",
        idLocalizacaoBlockchain: "5b1972d0-3ee8-44df-b07c-ca6bf92d94ca",
      },
    ],
    loteHistorico: [],
    idResponsavel: "fdd249be-f2a0-402a-9213-c40dff9f7c9f",
    nomeResponsavel: "BARTIRA AGROPECUARIA S/A",
    frigorifico: {
      nome: "Guidara Industria",
      maturidade: "2 dentes",
      acabamento: "Uniforme",
      pesoBanda1: "117.75",
      pesoBanda2: "117.75",
      obs1: "",
      obs2: "",
      dtMorte: "2023-11-08T00:00:00",
      porcentagemCarcaca: "55.80",
      dtCriacao: "2023-12-18T18:46:05",
      dtAlteracao: "2023-12-18T18:46:05",
    },
  },
  "b04e4aa8-1756-44f9-bf97-2b56ba693d71": {
    idLoteSync: "ec1f85a1-7f04-468d-ac28-2bfdf17d33b5",
    idBlockchain: "b04e4aa8-1756-44f9-bf97-2b56ba693d71",
    docType: "ANIMAL",
    idSistemaGestao: "98816b30-1ca6-4682-b338-4660190cb7ef",
    idSisbov: "",
    idICar: "982000444283547",
    nome: "",
    dataNascimento: "2022-04-02",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "982000444283547",
    idAnimalFazenda: "4283547",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Santa Gertrudis", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:15:38",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      idSistemaGestao: "994ea0f6-681f-42c9-ac35-8291cc194483",
      idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
      nomeResponsavel: "Fazenda União do Brasil",
      latitude: "",
      longitude: "",
      tipo: "Saída",
      peso: 414,
      dtPesagem: "2023-11-07",
      idPesagemBlockchain: "d81cf1b8-3976-4160-94eb-ad9bef45a7fb",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "e312567a-e5d3-4253-a057-9f4ec79db1fe",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Entrada",
        peso: 300,
        dtPesagem: "2023-06-01",
        idPesagemBlockchain: "e86b39fe-2cd0-4e23-a46d-24b84f1c8d57",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "fbcd7eb9-36ba-4020-80bb-af986256167e",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 277,
        dtPesagem: "2023-06-27",
        idPesagemBlockchain: "670e76c6-ee30-45c5-be0c-79e2235c5895",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "d1d357cf-357f-4cf4-bd34-9b6243f96e94",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 347,
        dtPesagem: "2023-09-04",
        idPesagemBlockchain: "cec4c0ad-0e64-4514-9225-fe7ebff7ebed",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "994ea0f6-681f-42c9-ac35-8291cc194483",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Saída",
        peso: 414,
        dtPesagem: "2023-11-07",
        idPesagemBlockchain: "d81cf1b8-3976-4160-94eb-ad9bef45a7fb",
      },
    ],
    produtorAtual: {
      idBlockchain: "25bc0b49-7cf7-4519-b70c-3001a1e4bfed",
      nome: "Fazenda União do Brasil",
      documento: "08090246000103",
    },
    status: "ABATIDO",
    dataCriacao: "2023-12-18T13:22:59",
    dataUltimaAtualizacao: "2023-12-18T18:46:16",
    vendaAtual: {
      idBlockchain: "e0130d89-b68f-423b-ad3d-a429637fb5a3",
      documentoProprietarioDestino: "18293459000196",
      tipo: "INDUSTRIA",
      proprietarioOrigem: {
        nome: "Fazenda União do Brasil",
        documento: "08090246000103",
      },
      proprietarioDestino: {
        nome: "Guidara Indústria e Comércio de Alimentos Eireli",
        documento: "18293459000196",
      },
      dataCriacao: "2023-12-18T18:27:49",
      dataUltimaAtualizacao: "2023-12-18T18:28:02",
      status: "EFETIVADA",
      qtdAnimais: 25,
    },
    vendaHistorico: [
      {
        idBlockchain: "f10b303c-ab60-4d04-b8b0-0f217cda4abb",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:22:09",
        dataUltimaAtualizacao: "2023-12-18T18:24:42",
        status: "RECUSADA",
        qtdAnimais: 25,
      },
      {
        idBlockchain: "f1392d6d-fc67-43f4-918a-1da3da56a459",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:26:52",
        dataUltimaAtualizacao: "2023-12-18T18:27:14",
        status: "RECUSADA",
        qtdAnimais: 25,
      },
      {
        idBlockchain: "e0130d89-b68f-423b-ad3d-a429637fb5a3",
        documentoProprietarioDestino: "18293459000196",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Guidara Indústria e Comércio de Alimentos Eireli",
          documento: "18293459000196",
        },
        dataCriacao: "2023-12-18T18:27:49",
        dataUltimaAtualizacao: "2023-12-18T18:28:02",
        status: "EFETIVADA",
        qtdAnimais: 25,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [
      {
        dtCriacao: "2023-12-18T14:16:17",
        dtAlteracao: "2023-12-18T14:16:17",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "d67c3280-4868-47ec-a9e7-454efeb40ddd",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipoExame: "ULTRASSOM_CARCACA",
        dtExame: "2023-09-04",
        vrResultado: "",
        vrResultadoAol: "77.95",
        vrResultadoEgs: "9.41",
        vrResultadoEgg: "0.00",
        vrResultadoMar: "3.91",
        vrResultadoRatio: "0.48",
        qtDiasPrenhe: null,
        icApresentouCio: null,
        idExameBlockchain: "79a3d93b-f52e-4fba-a902-8145855b440d",
      },
    ],
    medicamentosAplicados: [
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "6d11fa3b-ed2e-4db6-9371-85dcc93bb340",
        idOriginalMedicamentoBlockchain: "03a88948-7528-45c1-873a-b8d6012f9c15",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        dtMedicacao: "2023-06-27",
        unidadeMedida: "UNIDADE",
        quantidade: 2,
        idMedicamentoBlockchain: "92cac619-9af9-4ffe-beaf-d24f1fcdbc78",
        medicamento: {
          idBlockchain: "03a88948-7528-45c1-873a-b8d6012f9c15",
          nome: "Vacina Bovi-Shield",
          categoria: "FARMACIA",
          subCategoria: "VACINA",
          fabricante: "Zoetis",
          unidade: "UNIDADE",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "a267f390-ed08-453e-91e6-3b22ca18f042",
        idOriginalMedicamentoBlockchain: "ba97487b-264d-4640-887a-1740557e1093",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        dtMedicacao: "2023-06-01",
        unidadeMedida: "MILILITRO",
        quantidade: 5,
        idMedicamentoBlockchain: "00532ba8-19c7-47ad-8ad2-ee04b7365655",
        medicamento: {
          idBlockchain: "ba97487b-264d-4640-887a-1740557e1093",
          nome: "Iver-vet 3.5 injetável 1000 mL",
          categoria: "FARMACIA",
          subCategoria: "VERMIFUGO",
          fabricante: "Biovet",
          unidade: "LITRO",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
    ],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:16:02",
      dtAlteracao: "2023-12-18T14:16:02",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      fazenda: {
        nome: "Fazenda União do Brasil ",
        dataEntrada: "2023-06-27",
        dataSaida: "2023-11-07",
        latitude: "-23.68423843",
        longitude: "-48.59685500",
      },
      subdivisao: {
        nome: "Confinamento",
        dataEntrada: "2023-06-27",
        dataSaida: "2023-11-07",
      },
      idSistemaGestao: "5015328",
      idLocalizacaoBlockchain: "6af9ac3d-9a90-4de8-9c4a-28b041746a67",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Fazenda União do Brasil ",
          dataEntrada: "2023-06-27",
          dataSaida: "2023-11-07",
          latitude: "-23.68423843",
          longitude: "-48.59685500",
        },
        subdivisao: {
          nome: "Confinamento",
          dataEntrada: "2023-06-27",
          dataSaida: "2023-11-07",
        },
        idSistemaGestao: "5015328",
        idLocalizacaoBlockchain: "6af9ac3d-9a90-4de8-9c4a-28b041746a67",
      },
    ],
    loteHistorico: [],
    idResponsavel: "fdd249be-f2a0-402a-9213-c40dff9f7c9f",
    nomeResponsavel: "BARTIRA AGROPECUARIA S/A",
    frigorifico: {
      nome: "Guidara Industria",
      maturidade: "2 dentes",
      acabamento: "Uniforme",
      pesoBanda1: "117",
      pesoBanda2: "117",
      obs1: "",
      obs2: "",
      dtMorte: "2023-11-08T00:00:00",
      porcentagemCarcaca: "57.40",
      dtCriacao: "2023-12-18T18:46:16",
      dtAlteracao: "2023-12-18T18:46:16",
    },
  },
  "b0983777-3148-4a80-bb16-6ce6aedf3abe": {
    idLoteSync: "ec1f85a1-7f04-468d-ac28-2bfdf17d33b5",
    idBlockchain: "b0983777-3148-4a80-bb16-6ce6aedf3abe",
    docType: "ANIMAL",
    idSistemaGestao: "f4303c3d-a354-4895-8b86-fa058fd1032b",
    idSisbov: "",
    idICar: "982000444277166",
    nome: "",
    dataNascimento: "2022-04-02",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "982000444277166",
    idAnimalFazenda: "4277166",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Santa Gertrudis", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:15:38",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      idSistemaGestao: "355f59a0-a111-44d2-9057-75aa4111fb29",
      idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
      nomeResponsavel: "Fazenda União do Brasil",
      latitude: "",
      longitude: "",
      tipo: "Saída",
      peso: 450,
      dtPesagem: "2023-11-07",
      idPesagemBlockchain: "b3051717-442e-4344-8245-d5e132c7fa32",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "a435f3f7-cbe0-4dd8-bac4-9742cc395071",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Entrada",
        peso: 268,
        dtPesagem: "2023-06-01",
        idPesagemBlockchain: "65d92517-c8be-44be-b296-ce90e4d45243",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "1385f7ff-f1f0-48d4-8182-3b53d63e861e",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 268,
        dtPesagem: "2023-06-27",
        idPesagemBlockchain: "872d129a-dc98-4ace-bd12-2d516bb4f055",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "5b0e3427-e240-40c7-9994-b57afc7fbca3",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 367,
        dtPesagem: "2023-09-04",
        idPesagemBlockchain: "58abcc31-01be-434c-987a-6fd059c04828",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "355f59a0-a111-44d2-9057-75aa4111fb29",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Saída",
        peso: 450,
        dtPesagem: "2023-11-07",
        idPesagemBlockchain: "b3051717-442e-4344-8245-d5e132c7fa32",
      },
    ],
    produtorAtual: {
      idBlockchain: "25bc0b49-7cf7-4519-b70c-3001a1e4bfed",
      nome: "Fazenda União do Brasil",
      documento: "08090246000103",
    },
    status: "ABATIDO",
    dataCriacao: "2023-12-18T13:22:59",
    dataUltimaAtualizacao: "2023-12-18T18:48:29",
    vendaAtual: {
      idBlockchain: "e0130d89-b68f-423b-ad3d-a429637fb5a3",
      documentoProprietarioDestino: "18293459000196",
      tipo: "INDUSTRIA",
      proprietarioOrigem: {
        nome: "Fazenda União do Brasil",
        documento: "08090246000103",
      },
      proprietarioDestino: {
        nome: "Guidara Indústria e Comércio de Alimentos Eireli",
        documento: "18293459000196",
      },
      dataCriacao: "2023-12-18T18:27:49",
      dataUltimaAtualizacao: "2023-12-18T18:28:02",
      status: "EFETIVADA",
      qtdAnimais: 25,
    },
    vendaHistorico: [
      {
        idBlockchain: "f10b303c-ab60-4d04-b8b0-0f217cda4abb",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:22:09",
        dataUltimaAtualizacao: "2023-12-18T18:24:42",
        status: "RECUSADA",
        qtdAnimais: 25,
      },
      {
        idBlockchain: "f1392d6d-fc67-43f4-918a-1da3da56a459",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:26:52",
        dataUltimaAtualizacao: "2023-12-18T18:27:14",
        status: "RECUSADA",
        qtdAnimais: 25,
      },
      {
        idBlockchain: "e0130d89-b68f-423b-ad3d-a429637fb5a3",
        documentoProprietarioDestino: "18293459000196",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Guidara Indústria e Comércio de Alimentos Eireli",
          documento: "18293459000196",
        },
        dataCriacao: "2023-12-18T18:27:49",
        dataUltimaAtualizacao: "2023-12-18T18:28:02",
        status: "EFETIVADA",
        qtdAnimais: 25,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [
      {
        dtCriacao: "2023-12-18T14:16:17",
        dtAlteracao: "2023-12-18T14:16:17",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "5baf4ac5-a8bf-4ad6-9cd3-fab35829144c",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipoExame: "ULTRASSOM_CARCACA",
        dtExame: "2023-09-04",
        vrResultado: "",
        vrResultadoAol: "79.92",
        vrResultadoEgs: "7.06",
        vrResultadoEgg: "0.00",
        vrResultadoMar: "3.76",
        vrResultadoRatio: "0.48",
        qtDiasPrenhe: null,
        icApresentouCio: null,
        idExameBlockchain: "f81d3b64-12ac-45c7-8c72-7eda5629ae15",
      },
    ],
    medicamentosAplicados: [
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "af62311a-900b-40ea-95f7-c0317b55e009",
        idOriginalMedicamentoBlockchain: "03a88948-7528-45c1-873a-b8d6012f9c15",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        dtMedicacao: "2023-06-27",
        unidadeMedida: "UNIDADE",
        quantidade: 2,
        idMedicamentoBlockchain: "a6758074-6dfc-42b9-a7b8-4a269fbc6052",
        medicamento: {
          idBlockchain: "03a88948-7528-45c1-873a-b8d6012f9c15",
          nome: "Vacina Bovi-Shield",
          categoria: "FARMACIA",
          subCategoria: "VACINA",
          fabricante: "Zoetis",
          unidade: "UNIDADE",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "c3745b53-511c-440b-9cf8-1b5667ecbc3a",
        idOriginalMedicamentoBlockchain: "ba97487b-264d-4640-887a-1740557e1093",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        dtMedicacao: "2023-06-01",
        unidadeMedida: "MILILITRO",
        quantidade: 5,
        idMedicamentoBlockchain: "4b99752d-d751-43ff-86bb-b40d7d72842e",
        medicamento: {
          idBlockchain: "ba97487b-264d-4640-887a-1740557e1093",
          nome: "Iver-vet 3.5 injetável 1000 mL",
          categoria: "FARMACIA",
          subCategoria: "VERMIFUGO",
          fabricante: "Biovet",
          unidade: "LITRO",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
    ],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:16:02",
      dtAlteracao: "2023-12-18T14:16:02",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      fazenda: {
        nome: "Fazenda União do Brasil ",
        dataEntrada: "2023-06-27",
        dataSaida: "2023-11-07",
        latitude: "-23.68423843",
        longitude: "-48.59685500",
      },
      subdivisao: {
        nome: "Confinamento",
        dataEntrada: "2023-06-27",
        dataSaida: "2023-11-07",
      },
      idSistemaGestao: "5015421",
      idLocalizacaoBlockchain: "a746fd15-774c-43df-9307-9e2a219d5967",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Fazenda União do Brasil ",
          dataEntrada: "2023-06-27",
          dataSaida: "2023-11-07",
          latitude: "-23.68423843",
          longitude: "-48.59685500",
        },
        subdivisao: {
          nome: "Confinamento",
          dataEntrada: "2023-06-27",
          dataSaida: "2023-11-07",
        },
        idSistemaGestao: "5015421",
        idLocalizacaoBlockchain: "a746fd15-774c-43df-9307-9e2a219d5967",
      },
    ],
    loteHistorico: [],
    idResponsavel: "fdd249be-f2a0-402a-9213-c40dff9f7c9f",
    nomeResponsavel: "BARTIRA AGROPECUARIA S/A",
    frigorifico: {
      nome: "Guidara Industria",
      maturidade: "2 dentes",
      acabamento: "Uniforme",
      pesoBanda1: "124.5",
      pesoBanda2: "124.5",
      obs1: "",
      obs2: "",
      dtMorte: "2023-11-08T00:00:00",
      porcentagemCarcaca: "56.70",
      dtCriacao: "2023-12-18T18:48:29",
      dtAlteracao: "2023-12-18T18:48:29",
    },
  },
  "b605230f-2739-4cf3-a51d-3a380c67d8bf": {
    idLoteSync: "ec1f85a1-7f04-468d-ac28-2bfdf17d33b5",
    idBlockchain: "b605230f-2739-4cf3-a51d-3a380c67d8bf",
    docType: "ANIMAL",
    idSistemaGestao: "7c9f66b4-f9e6-4ed4-9fc3-acd6dbb31d59",
    idSisbov: "",
    idICar: "982000444282233",
    nome: "",
    dataNascimento: "2022-04-02",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "982000444282233",
    idAnimalFazenda: "4282233",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Santa Gertrudis", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:15:38",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      idSistemaGestao: "1fd1f18b-a025-48fe-baab-8ca8c35a2b11",
      idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
      nomeResponsavel: "Fazenda União do Brasil",
      latitude: "",
      longitude: "",
      tipo: "Saída",
      peso: 522,
      dtPesagem: "2023-11-07",
      idPesagemBlockchain: "57208db7-5609-4f44-8159-fb87010b58e1",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "c05f2bd8-181b-468f-811f-83eec560a64d",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Entrada",
        peso: 286,
        dtPesagem: "2023-06-01",
        idPesagemBlockchain: "1ad739ee-9c2e-4dca-8705-ecebe93fcb99",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "19dd0052-6ad9-4fc4-8a2e-05059d3abc1e",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 285,
        dtPesagem: "2023-06-27",
        idPesagemBlockchain: "556f703c-afe9-4f06-8827-428a9a20db31",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "a0025508-5e77-475f-88a1-6c56e835b188",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 408,
        dtPesagem: "2023-09-04",
        idPesagemBlockchain: "b10a0c15-dc87-43ed-9077-7fbca5acc395",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "1fd1f18b-a025-48fe-baab-8ca8c35a2b11",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Saída",
        peso: 522,
        dtPesagem: "2023-11-07",
        idPesagemBlockchain: "57208db7-5609-4f44-8159-fb87010b58e1",
      },
    ],
    produtorAtual: {
      idBlockchain: "25bc0b49-7cf7-4519-b70c-3001a1e4bfed",
      nome: "Fazenda União do Brasil",
      documento: "08090246000103",
    },
    status: "ABATIDO",
    dataCriacao: "2023-12-18T13:22:59",
    dataUltimaAtualizacao: "2023-12-18T18:48:20",
    vendaAtual: {
      idBlockchain: "e0130d89-b68f-423b-ad3d-a429637fb5a3",
      documentoProprietarioDestino: "18293459000196",
      tipo: "INDUSTRIA",
      proprietarioOrigem: {
        nome: "Fazenda União do Brasil",
        documento: "08090246000103",
      },
      proprietarioDestino: {
        nome: "Guidara Indústria e Comércio de Alimentos Eireli",
        documento: "18293459000196",
      },
      dataCriacao: "2023-12-18T18:27:49",
      dataUltimaAtualizacao: "2023-12-18T18:28:02",
      status: "EFETIVADA",
      qtdAnimais: 25,
    },
    vendaHistorico: [
      {
        idBlockchain: "f10b303c-ab60-4d04-b8b0-0f217cda4abb",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:22:09",
        dataUltimaAtualizacao: "2023-12-18T18:24:42",
        status: "RECUSADA",
        qtdAnimais: 25,
      },
      {
        idBlockchain: "f1392d6d-fc67-43f4-918a-1da3da56a459",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:26:52",
        dataUltimaAtualizacao: "2023-12-18T18:27:14",
        status: "RECUSADA",
        qtdAnimais: 25,
      },
      {
        idBlockchain: "e0130d89-b68f-423b-ad3d-a429637fb5a3",
        documentoProprietarioDestino: "18293459000196",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Guidara Indústria e Comércio de Alimentos Eireli",
          documento: "18293459000196",
        },
        dataCriacao: "2023-12-18T18:27:49",
        dataUltimaAtualizacao: "2023-12-18T18:28:02",
        status: "EFETIVADA",
        qtdAnimais: 25,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [
      {
        dtCriacao: "2023-12-18T14:16:17",
        dtAlteracao: "2023-12-18T14:16:17",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "c4ae60d7-c511-4b82-beb3-42a00d748252",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipoExame: "ULTRASSOM_CARCACA",
        dtExame: "2023-09-04",
        vrResultado: "",
        vrResultadoAol: "87.25",
        vrResultadoEgs: "6.59",
        vrResultadoEgg: "0.00",
        vrResultadoMar: "3.70",
        vrResultadoRatio: "0.50",
        qtDiasPrenhe: null,
        icApresentouCio: null,
        idExameBlockchain: "93638ba7-ffd0-4a23-824f-65d7a73fb757",
      },
    ],
    medicamentosAplicados: [
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "c5e7e9ec-ad56-4bd7-bb89-d80a80e941f7",
        idOriginalMedicamentoBlockchain: "03a88948-7528-45c1-873a-b8d6012f9c15",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        dtMedicacao: "2023-06-27",
        unidadeMedida: "UNIDADE",
        quantidade: 2,
        idMedicamentoBlockchain: "34d6da07-cb7f-4867-a20d-0438f9a0520a",
        medicamento: {
          idBlockchain: "03a88948-7528-45c1-873a-b8d6012f9c15",
          nome: "Vacina Bovi-Shield",
          categoria: "FARMACIA",
          subCategoria: "VACINA",
          fabricante: "Zoetis",
          unidade: "UNIDADE",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "cc31cd1d-b1bd-4439-9b3f-cfe5439bb726",
        idOriginalMedicamentoBlockchain: "ba97487b-264d-4640-887a-1740557e1093",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        dtMedicacao: "2023-06-01",
        unidadeMedida: "MILILITRO",
        quantidade: 5,
        idMedicamentoBlockchain: "fc0c65b6-be56-4498-b41b-72964e86de84",
        medicamento: {
          idBlockchain: "ba97487b-264d-4640-887a-1740557e1093",
          nome: "Iver-vet 3.5 injetável 1000 mL",
          categoria: "FARMACIA",
          subCategoria: "VERMIFUGO",
          fabricante: "Biovet",
          unidade: "LITRO",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
    ],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:16:02",
      dtAlteracao: "2023-12-18T14:16:02",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      fazenda: {
        nome: "Fazenda União do Brasil ",
        dataEntrada: "2023-06-27",
        dataSaida: "2023-11-07",
        latitude: "-23.68423843",
        longitude: "-48.59685500",
      },
      subdivisao: {
        nome: "Confinamento",
        dataEntrada: "2023-06-27",
        dataSaida: "2023-11-07",
      },
      idSistemaGestao: "5015364",
      idLocalizacaoBlockchain: "16990ec0-9407-4c66-a8a9-57c8894734d7",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Fazenda União do Brasil ",
          dataEntrada: "2023-06-27",
          dataSaida: "2023-11-07",
          latitude: "-23.68423843",
          longitude: "-48.59685500",
        },
        subdivisao: {
          nome: "Confinamento",
          dataEntrada: "2023-06-27",
          dataSaida: "2023-11-07",
        },
        idSistemaGestao: "5015364",
        idLocalizacaoBlockchain: "16990ec0-9407-4c66-a8a9-57c8894734d7",
      },
    ],
    loteHistorico: [],
    idResponsavel: "fdd249be-f2a0-402a-9213-c40dff9f7c9f",
    nomeResponsavel: "BARTIRA AGROPECUARIA S/A",
    frigorifico: {
      nome: "Guidara Industria",
      maturidade: "2 dentes",
      acabamento: "Uniforme",
      pesoBanda1: "140",
      pesoBanda2: "140",
      obs1: "",
      obs2: "",
      dtMorte: "2023-11-08T00:00:00",
      porcentagemCarcaca: "55.80",
      dtCriacao: "2023-12-18T18:48:20",
      dtAlteracao: "2023-12-18T18:48:20",
    },
  },
  "bac1692e-3d01-4e52-a24b-01e945219ac7": {
    idLoteSync: "ec1f85a1-7f04-468d-ac28-2bfdf17d33b5",
    idBlockchain: "bac1692e-3d01-4e52-a24b-01e945219ac7",
    docType: "ANIMAL",
    idSistemaGestao: "b77f8a68-6e4e-4432-b7b6-0f9ccd199abe",
    idSisbov: "",
    idICar: "982000444279539",
    nome: "",
    dataNascimento: "2021-11-10",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "982000444279539",
    idAnimalFazenda: "3210813",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Santa Gertrudis", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:15:38",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      idSistemaGestao: "b77f8a68-6e4e-4432-b7b6-0f9ccd199abe",
      idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
      nomeResponsavel: "Fazenda União do Brasil",
      latitude: "-23.6825667",
      longitude: "-48.5986721",
      tipo: "Saída",
      peso: 542,
      dtPesagem: "2023-05-11T11:31:50",
      idPesagemBlockchain: "6eacdfab-9b76-4444-99f3-dc4c031b56bd",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "b77f8a68-6e4e-4432-b7b6-0f9ccd199abe",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Nascimento",
        peso: 29,
        dtPesagem: "2021-11-10T03:00:00",
        idPesagemBlockchain: "b1e25bce-ad08-414c-939f-71fa3eee268f",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "b77f8a68-6e4e-4432-b7b6-0f9ccd199abe",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 246,
        dtPesagem: "2022-06-03T10:22:14",
        idPesagemBlockchain: "f4ae8d87-7741-4135-b71e-f47501e67ff1",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "b77f8a68-6e4e-4432-b7b6-0f9ccd199abe",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Desmame",
        peso: 246,
        dtPesagem: "2022-06-06T19:07:37",
        idPesagemBlockchain: "969f6b91-175e-46da-b684-8ee727a0a838",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "b77f8a68-6e4e-4432-b7b6-0f9ccd199abe",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 299,
        dtPesagem: "2022-10-04T07:40:54",
        idPesagemBlockchain: "5a916f3c-1113-4437-8a2b-2f50222f7fbf",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "b77f8a68-6e4e-4432-b7b6-0f9ccd199abe",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 445,
        dtPesagem: "2023-02-17T13:41:34",
        idPesagemBlockchain: "d6c143ca-a0b9-488b-b07c-cc3961d56739",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "b77f8a68-6e4e-4432-b7b6-0f9ccd199abe",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Saída",
        peso: 542,
        dtPesagem: "2023-05-11T11:31:50",
        idPesagemBlockchain: "6eacdfab-9b76-4444-99f3-dc4c031b56bd",
      },
    ],
    produtorAtual: {
      idBlockchain: "25bc0b49-7cf7-4519-b70c-3001a1e4bfed",
      nome: "Fazenda União do Brasil",
      documento: "08090246000103",
    },
    status: "ABATIDO",
    dataCriacao: "2023-12-18T13:22:59",
    dataUltimaAtualizacao: "2023-12-18T18:56:29",
    vendaAtual: {
      idBlockchain: "0401071b-4fe8-4b0e-ba81-d94b5ab0b970",
      documentoProprietarioDestino: "30269671000113",
      tipo: "INDUSTRIA",
      proprietarioOrigem: {
        nome: "Fazenda União do Brasil",
        documento: "08090246000103",
      },
      proprietarioDestino: {
        nome: "Frigorífico Red Beef LTDA",
        documento: "30269671000113",
      },
      dataCriacao: "2023-12-18T18:33:01",
      dataUltimaAtualizacao: "2023-12-18T18:33:19",
      status: "EFETIVADA",
      qtdAnimais: 29,
    },
    vendaHistorico: [
      {
        idBlockchain: "eb66af4e-769b-4269-9378-dba27a1c0947",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:21:39",
        dataUltimaAtualizacao: "2023-12-18T18:24:08",
        status: "RECUSADA",
        qtdAnimais: 29,
      },
      {
        idBlockchain: "0401071b-4fe8-4b0e-ba81-d94b5ab0b970",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:33:01",
        dataUltimaAtualizacao: "2023-12-18T18:33:19",
        status: "EFETIVADA",
        qtdAnimais: 29,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [
      {
        dtCriacao: "2023-12-18T14:16:17",
        dtAlteracao: "2023-12-18T14:16:17",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "irancho-mvp",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipoExame: "ULTRASSOM_CARCACA",
        dtExame: "2023-05-11T11:31:50",
        vrResultado: "",
        vrResultadoAol: "68.16",
        vrResultadoEgs: "8.47",
        vrResultadoEgg: "0",
        vrResultadoMar: "3.68",
        vrResultadoRatio: "0",
        qtDiasPrenhe: null,
        icApresentouCio: null,
        idExameBlockchain: "b0f445eb-f0ac-42f9-ba26-1e541aa07138",
      },
    ],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:16:02",
      dtAlteracao: "2023-12-18T14:16:02",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      fazenda: {
        nome: "Fazenda União do Brasil ",
        dataEntrada: "2022-10-04T00:00:00",
        dataSaida: "2023-05-11T00:00:00",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
      },
      subdivisao: {
        nome: "Confinamento",
        dataEntrada: "2022-10-04T00:00:00",
        dataSaida: "2023-05-11T00:00:00",
      },
      idSistemaGestao: "b77f8a68-6e4e-4432-b7b6-0f9ccd199abe",
      idLocalizacaoBlockchain: "74cead76-e25f-45e9-97c1-7d8f5a6bea01",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Vale do Sol ",
          dataEntrada: "2022-07-05T00:00:00",
          dataSaida: "2022-10-04T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "Desmama",
          dataEntrada: "2022-07-05T00:00:00",
          dataSaida: "2022-10-04T00:00:00",
        },
        idSistemaGestao: "b77f8a68-6e4e-4432-b7b6-0f9ccd199abe",
        idLocalizacaoBlockchain: "62bace32-120c-4445-b3da-80614f853fc4",
      },
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Fazenda União do Brasil ",
          dataEntrada: "2022-10-04T00:00:00",
          dataSaida: "2023-05-11T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "Confinamento",
          dataEntrada: "2022-10-04T00:00:00",
          dataSaida: "2023-05-11T00:00:00",
        },
        idSistemaGestao: "b77f8a68-6e4e-4432-b7b6-0f9ccd199abe",
        idLocalizacaoBlockchain: "74cead76-e25f-45e9-97c1-7d8f5a6bea01",
      },
    ],
    loteHistorico: [],
    idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
    nomeResponsavel: "Fazenda União do Brasil",
    frigorifico: {
      nome: "Frigorífico Red Beef LTDA",
      maturidade: "0 dentes",
      acabamento: "Uniforme",
      pesoBanda1: "146.25 Kg",
      pesoBanda2: "146.25 Kg",
      obs1: "",
      obs2: "",
      dtMorte: "2023-05-11",
      porcentagemCarcaca: "54%",
      dtCriacao: "2023-12-18T18:56:29",
      dtAlteracao: "2023-12-18T18:56:29",
    },
  },
  "bb0cdc34-0e7f-49d8-8e08-8dc6e6dc81ee": {
    idLoteSync: "ec1f85a1-7f04-468d-ac28-2bfdf17d33b5",
    idBlockchain: "bb0cdc34-0e7f-49d8-8e08-8dc6e6dc81ee",
    docType: "ANIMAL",
    idSistemaGestao: "f1b045dc-285e-4bc0-99bf-72a37fadf7f4",
    idSisbov: "",
    idICar: "982000444278466",
    nome: "",
    dataNascimento: "2021-12-02",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "982000444278466",
    idAnimalFazenda: "3210926",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Santa Gertrudis", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:15:38",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      idSistemaGestao: "f1b045dc-285e-4bc0-99bf-72a37fadf7f4",
      idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
      nomeResponsavel: "Fazenda União do Brasil",
      latitude: "-23.6825667",
      longitude: "-48.5986721",
      tipo: "Saída",
      peso: 528,
      dtPesagem: "2023-05-11T11:31:50",
      idPesagemBlockchain: "6c3e1297-246d-4eef-8275-eca23753b531",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "f1b045dc-285e-4bc0-99bf-72a37fadf7f4",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Nascimento",
        peso: 38,
        dtPesagem: "2021-12-02T03:00:00",
        idPesagemBlockchain: "27886331-f5be-490b-9934-d687915d8e7e",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "f1b045dc-285e-4bc0-99bf-72a37fadf7f4",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 211,
        dtPesagem: "2022-06-03T10:22:14",
        idPesagemBlockchain: "63e538ff-b68f-4a78-8cd6-cb95f5bf34cd",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "f1b045dc-285e-4bc0-99bf-72a37fadf7f4",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Desmame",
        peso: 211,
        dtPesagem: "2022-06-06T19:07:37",
        idPesagemBlockchain: "9e8fdfaf-2296-4744-826e-eae3eb2d4d6d",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "f1b045dc-285e-4bc0-99bf-72a37fadf7f4",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 273,
        dtPesagem: "2022-10-04T07:40:54",
        idPesagemBlockchain: "45e57cd3-dab3-4958-aab5-d92ff1869c45",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "f1b045dc-285e-4bc0-99bf-72a37fadf7f4",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 419,
        dtPesagem: "2023-02-17T13:41:34",
        idPesagemBlockchain: "de91d7f9-e067-448c-9551-4dce0174fc28",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "f1b045dc-285e-4bc0-99bf-72a37fadf7f4",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Saída",
        peso: 528,
        dtPesagem: "2023-05-11T11:31:50",
        idPesagemBlockchain: "6c3e1297-246d-4eef-8275-eca23753b531",
      },
    ],
    produtorAtual: {
      idBlockchain: "25bc0b49-7cf7-4519-b70c-3001a1e4bfed",
      nome: "Fazenda União do Brasil",
      documento: "08090246000103",
    },
    status: "ABATIDO",
    dataCriacao: "2023-12-18T13:22:59",
    dataUltimaAtualizacao: "2023-12-18T18:56:37",
    vendaAtual: {
      idBlockchain: "0401071b-4fe8-4b0e-ba81-d94b5ab0b970",
      documentoProprietarioDestino: "30269671000113",
      tipo: "INDUSTRIA",
      proprietarioOrigem: {
        nome: "Fazenda União do Brasil",
        documento: "08090246000103",
      },
      proprietarioDestino: {
        nome: "Frigorífico Red Beef LTDA",
        documento: "30269671000113",
      },
      dataCriacao: "2023-12-18T18:33:01",
      dataUltimaAtualizacao: "2023-12-18T18:33:19",
      status: "EFETIVADA",
      qtdAnimais: 29,
    },
    vendaHistorico: [
      {
        idBlockchain: "eb66af4e-769b-4269-9378-dba27a1c0947",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:21:39",
        dataUltimaAtualizacao: "2023-12-18T18:24:08",
        status: "RECUSADA",
        qtdAnimais: 29,
      },
      {
        idBlockchain: "0401071b-4fe8-4b0e-ba81-d94b5ab0b970",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:33:01",
        dataUltimaAtualizacao: "2023-12-18T18:33:19",
        status: "EFETIVADA",
        qtdAnimais: 29,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [
      {
        dtCriacao: "2023-12-18T14:16:17",
        dtAlteracao: "2023-12-18T14:16:17",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "irancho-mvp",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipoExame: "ULTRASSOM_CARCACA",
        dtExame: "2023-05-11T11:31:50",
        vrResultado: "",
        vrResultadoAol: "59.3",
        vrResultadoEgs: "9.41",
        vrResultadoEgg: "0",
        vrResultadoMar: "3.68",
        vrResultadoRatio: "0",
        qtDiasPrenhe: null,
        icApresentouCio: null,
        idExameBlockchain: "4ffb5946-67b2-47a7-89b8-8423a02e0c0a",
      },
    ],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:16:02",
      dtAlteracao: "2023-12-18T14:16:02",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      fazenda: {
        nome: "Fazenda União do Brasil ",
        dataEntrada: "2022-10-04T00:00:00",
        dataSaida: "2023-05-11T00:00:00",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
      },
      subdivisao: {
        nome: "Confinamento",
        dataEntrada: "2022-10-04T00:00:00",
        dataSaida: "2023-05-11T00:00:00",
      },
      idSistemaGestao: "f1b045dc-285e-4bc0-99bf-72a37fadf7f4",
      idLocalizacaoBlockchain: "efcddad2-f59d-4fcc-9e8f-a166cf476a2c",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Vale do Sol ",
          dataEntrada: "2022-07-05T00:00:00",
          dataSaida: "2022-10-04T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "Desmama",
          dataEntrada: "2022-07-05T00:00:00",
          dataSaida: "2022-10-04T00:00:00",
        },
        idSistemaGestao: "f1b045dc-285e-4bc0-99bf-72a37fadf7f4",
        idLocalizacaoBlockchain: "3090d6c6-4a13-42e4-a951-80a2d55a940c",
      },
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Fazenda União do Brasil ",
          dataEntrada: "2022-10-04T00:00:00",
          dataSaida: "2023-05-11T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "Confinamento",
          dataEntrada: "2022-10-04T00:00:00",
          dataSaida: "2023-05-11T00:00:00",
        },
        idSistemaGestao: "f1b045dc-285e-4bc0-99bf-72a37fadf7f4",
        idLocalizacaoBlockchain: "efcddad2-f59d-4fcc-9e8f-a166cf476a2c",
      },
    ],
    loteHistorico: [],
    idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
    nomeResponsavel: "Fazenda União do Brasil",
    frigorifico: {
      nome: "Frigorífico Red Beef LTDA",
      maturidade: "0 dentes",
      acabamento: "Uniforme",
      pesoBanda1: "135.75 Kg",
      pesoBanda2: "135.75 Kg",
      obs1: "",
      obs2: "",
      dtMorte: "2023-05-11",
      porcentagemCarcaca: "51%",
      dtCriacao: "2023-12-18T18:56:37",
      dtAlteracao: "2023-12-18T18:56:37",
    },
  },
  "bff83f0a-01f9-4bf0-9dc8-b73010c98b8b": {
    idLoteSync: "ec1f85a1-7f04-468d-ac28-2bfdf17d33b5",
    idBlockchain: "bff83f0a-01f9-4bf0-9dc8-b73010c98b8b",
    docType: "ANIMAL",
    idSistemaGestao: "e34bc9ec-372f-44bf-bab2-6a35f91d6a75",
    idSisbov: "",
    idICar: "982000444277455",
    nome: "",
    dataNascimento: "2021-08-25",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "982000444277455",
    idAnimalFazenda: "F 008044383",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Santa Gertrudis", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:15:38",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      idSistemaGestao: "e34bc9ec-372f-44bf-bab2-6a35f91d6a75",
      idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
      nomeResponsavel: "Fazenda União do Brasil",
      latitude: "-23.6825667",
      longitude: "-48.5986721",
      tipo: "Saída",
      peso: 554,
      dtPesagem: "2023-05-11T11:31:50",
      idPesagemBlockchain: "e27b5405-429b-4d79-8f9e-dca0ff7c042b",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "e34bc9ec-372f-44bf-bab2-6a35f91d6a75",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Entrada",
        peso: 172,
        dtPesagem: "2022-02-21T18:51:36",
        idPesagemBlockchain: "e4c2daca-2579-4152-aba1-21e2fa627707",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "e34bc9ec-372f-44bf-bab2-6a35f91d6a75",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 199,
        dtPesagem: "2022-04-25T19:18:47",
        idPesagemBlockchain: "9704880d-2bda-49f5-b896-2bb86f7ef7eb",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "e34bc9ec-372f-44bf-bab2-6a35f91d6a75",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 258,
        dtPesagem: "2022-10-04T07:40:54",
        idPesagemBlockchain: "10064bce-9114-4fc5-abd7-1faf3f24dc52",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "e34bc9ec-372f-44bf-bab2-6a35f91d6a75",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 442,
        dtPesagem: "2023-02-17T13:41:34",
        idPesagemBlockchain: "1aa6c760-7827-4232-a29a-d0d41a981777",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "e34bc9ec-372f-44bf-bab2-6a35f91d6a75",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Saída",
        peso: 554,
        dtPesagem: "2023-05-11T11:31:50",
        idPesagemBlockchain: "e27b5405-429b-4d79-8f9e-dca0ff7c042b",
      },
    ],
    produtorAtual: {
      idBlockchain: "25bc0b49-7cf7-4519-b70c-3001a1e4bfed",
      nome: "Fazenda União do Brasil",
      documento: "08090246000103",
    },
    status: "ABATIDO",
    dataCriacao: "2023-12-18T13:22:59",
    dataUltimaAtualizacao: "2023-12-18T18:55:39",
    vendaAtual: {
      idBlockchain: "0401071b-4fe8-4b0e-ba81-d94b5ab0b970",
      documentoProprietarioDestino: "30269671000113",
      tipo: "INDUSTRIA",
      proprietarioOrigem: {
        nome: "Fazenda União do Brasil",
        documento: "08090246000103",
      },
      proprietarioDestino: {
        nome: "Frigorífico Red Beef LTDA",
        documento: "30269671000113",
      },
      dataCriacao: "2023-12-18T18:33:01",
      dataUltimaAtualizacao: "2023-12-18T18:33:19",
      status: "EFETIVADA",
      qtdAnimais: 29,
    },
    vendaHistorico: [
      {
        idBlockchain: "eb66af4e-769b-4269-9378-dba27a1c0947",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:21:39",
        dataUltimaAtualizacao: "2023-12-18T18:24:08",
        status: "RECUSADA",
        qtdAnimais: 29,
      },
      {
        idBlockchain: "0401071b-4fe8-4b0e-ba81-d94b5ab0b970",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:33:01",
        dataUltimaAtualizacao: "2023-12-18T18:33:19",
        status: "EFETIVADA",
        qtdAnimais: 29,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [
      {
        dtCriacao: "2023-12-18T14:16:17",
        dtAlteracao: "2023-12-18T14:16:17",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "irancho-mvp",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipoExame: "ULTRASSOM_CARCACA",
        dtExame: "2023-05-11T11:31:50",
        vrResultado: "",
        vrResultadoAol: "74.49",
        vrResultadoEgs: "7.06",
        vrResultadoEgg: "0",
        vrResultadoMar: "3.91",
        vrResultadoRatio: "0",
        qtDiasPrenhe: null,
        icApresentouCio: null,
        idExameBlockchain: "f750c4ef-ac29-478f-acff-e16630e1dd47",
      },
    ],
    medicamentosAplicados: [
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "irancho-mvp",
        idOriginalMedicamentoBlockchain: "40aa0e8c-5ceb-4b79-bd44-253139e57a21",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        dtMedicacao: "2022-02-21T15:51:36",
        unidadeMedida: "MILILITRO",
        quantidade: 2,
        idMedicamentoBlockchain: "d4e54882-655b-4128-bf42-0ed8348b2a79",
        medicamento: {
          idBlockchain: "40aa0e8c-5ceb-4b79-bd44-253139e57a21",
          nome: "Aborvac Brucelose",
          categoria: "FARMACIA",
          subCategoria: "VACINA",
          fabricante: "Zoetis",
          unidade: "MILILITRO",
          sexo: "FEMEA",
          idadeMaxima: 8,
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "irancho-mvp",
        idOriginalMedicamentoBlockchain: "a98e882b-a392-4bfd-bb22-057e47b66cad",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        dtMedicacao: "2022-04-25T16:18:47",
        unidadeMedida: "MILILITRO",
        quantidade: 3.98,
        idMedicamentoBlockchain: "cbe9292c-3171-4fb2-bcf5-ddebc4b32a9c",
        medicamento: {
          idBlockchain: "a98e882b-a392-4bfd-bb22-057e47b66cad",
          nome: "Iver-vet ADE 1000 mL",
          categoria: "FARMACIA",
          subCategoria: "VERMIFUGO",
          fabricante: "Biovet",
          unidade: "MILILITRO",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "irancho-mvp",
        idOriginalMedicamentoBlockchain: "7838ec4f-23ec-49fc-87b1-3f6fdcc0ba70",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        dtMedicacao: "2022-04-25T16:18:47",
        unidadeMedida: "MILILITRO",
        quantidade: 2,
        idMedicamentoBlockchain: "4ca62cd1-d454-4258-8905-5838aa914680",
        medicamento: {
          idBlockchain: "7838ec4f-23ec-49fc-87b1-3f6fdcc0ba70",
          nome: "Aftosa ourovac 50 doses ",
          categoria: "FARMACIA",
          subCategoria: "VACINA",
          fabricante: "Ouro fino",
          unidade: "MILILITRO",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
    ],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:16:02",
      dtAlteracao: "2023-12-18T14:16:02",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      fazenda: {
        nome: "Fazenda União do Brasil ",
        dataEntrada: "2022-10-04T00:00:00",
        dataSaida: "2023-05-11T00:00:00",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
      },
      subdivisao: {
        nome: "Confinamento",
        dataEntrada: "2022-10-04T00:00:00",
        dataSaida: "2023-05-11T00:00:00",
      },
      idSistemaGestao: "e34bc9ec-372f-44bf-bab2-6a35f91d6a75",
      idLocalizacaoBlockchain: "d30ebc78-b3fa-4c5d-a6b1-eff3ae41d338",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Vale do Sol ",
          dataEntrada: "2022-05-02T00:00:00",
          dataSaida: "2022-10-04T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "Desmama",
          dataEntrada: "2022-05-02T00:00:00",
          dataSaida: "2022-10-04T00:00:00",
        },
        idSistemaGestao: "e34bc9ec-372f-44bf-bab2-6a35f91d6a75",
        idLocalizacaoBlockchain: "f5a707a9-6c46-4dc6-a9d4-fec01a961856",
      },
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Fazenda União do Brasil ",
          dataEntrada: "2022-10-04T00:00:00",
          dataSaida: "2023-05-11T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "Confinamento",
          dataEntrada: "2022-10-04T00:00:00",
          dataSaida: "2023-05-11T00:00:00",
        },
        idSistemaGestao: "e34bc9ec-372f-44bf-bab2-6a35f91d6a75",
        idLocalizacaoBlockchain: "d30ebc78-b3fa-4c5d-a6b1-eff3ae41d338",
      },
    ],
    loteHistorico: [],
    idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
    nomeResponsavel: "Fazenda União do Brasil",
    frigorifico: {
      nome: "Frigorífico Red Beef LTDA",
      maturidade: "0 dentes",
      acabamento: "Mediano",
      pesoBanda1: "140.25 Kg",
      pesoBanda2: "140.25 Kg",
      obs1: "",
      obs2: "",
      dtMorte: "2023-05-11",
      porcentagemCarcaca: "51%",
      dtCriacao: "2023-12-18T18:55:39",
      dtAlteracao: "2023-12-18T18:55:39",
    },
  },
  "c112fa4d-d338-43f6-9dff-8aa3eae878f7": {
    idLoteSync: "ec1f85a1-7f04-468d-ac28-2bfdf17d33b5",
    idBlockchain: "c112fa4d-d338-43f6-9dff-8aa3eae878f7",
    docType: "ANIMAL",
    idSistemaGestao: "d9fe4734-b9ac-422e-896d-077cee868624",
    idSisbov: "",
    idICar: "982000444282215",
    nome: "",
    dataNascimento: "2020-11-27",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "982000444282215",
    idAnimalFazenda: "1200317",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Santa Gertrudis", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:15:38",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      idSistemaGestao: "d9fe4734-b9ac-422e-896d-077cee868624",
      idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
      nomeResponsavel: "Fazenda União do Brasil",
      latitude: "-23.6825667",
      longitude: "-48.5986721",
      tipo: "Saída",
      peso: 536,
      dtPesagem: "2023-05-11T11:31:50",
      idPesagemBlockchain: "6941e295-b6d1-45fa-bd1b-a929da995ffc",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "d9fe4734-b9ac-422e-896d-077cee868624",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Nascimento",
        peso: 36,
        dtPesagem: "2020-11-27T03:00:00",
        idPesagemBlockchain: "0691d627-6473-45ed-9a17-593cf5a5b0a4",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "d9fe4734-b9ac-422e-896d-077cee868624",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Desmame",
        peso: 164.5,
        dtPesagem: "2021-08-17T19:38:45",
        idPesagemBlockchain: "deb9f918-74ec-4305-982f-921926e0a2a4",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "d9fe4734-b9ac-422e-896d-077cee868624",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 299,
        dtPesagem: "2022-10-04T07:40:54",
        idPesagemBlockchain: "d5191900-2d79-4254-bf92-9dbab81229c3",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "d9fe4734-b9ac-422e-896d-077cee868624",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 436,
        dtPesagem: "2023-02-17T13:41:34",
        idPesagemBlockchain: "777dc4c0-255d-4196-a5f4-c6f8dbc5d9e4",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "d9fe4734-b9ac-422e-896d-077cee868624",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Saída",
        peso: 536,
        dtPesagem: "2023-05-11T11:31:50",
        idPesagemBlockchain: "6941e295-b6d1-45fa-bd1b-a929da995ffc",
      },
    ],
    produtorAtual: {
      idBlockchain: "25bc0b49-7cf7-4519-b70c-3001a1e4bfed",
      nome: "Fazenda União do Brasil",
      documento: "08090246000103",
    },
    status: "ABATIDO",
    dataCriacao: "2023-12-18T13:22:59",
    dataUltimaAtualizacao: "2023-12-18T18:57:57",
    vendaAtual: {
      idBlockchain: "0401071b-4fe8-4b0e-ba81-d94b5ab0b970",
      documentoProprietarioDestino: "30269671000113",
      tipo: "INDUSTRIA",
      proprietarioOrigem: {
        nome: "Fazenda União do Brasil",
        documento: "08090246000103",
      },
      proprietarioDestino: {
        nome: "Frigorífico Red Beef LTDA",
        documento: "30269671000113",
      },
      dataCriacao: "2023-12-18T18:33:01",
      dataUltimaAtualizacao: "2023-12-18T18:33:19",
      status: "EFETIVADA",
      qtdAnimais: 29,
    },
    vendaHistorico: [
      {
        idBlockchain: "eb66af4e-769b-4269-9378-dba27a1c0947",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:21:39",
        dataUltimaAtualizacao: "2023-12-18T18:24:08",
        status: "RECUSADA",
        qtdAnimais: 29,
      },
      {
        idBlockchain: "0401071b-4fe8-4b0e-ba81-d94b5ab0b970",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:33:01",
        dataUltimaAtualizacao: "2023-12-18T18:33:19",
        status: "EFETIVADA",
        qtdAnimais: 29,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [
      {
        dtCriacao: "2023-12-18T14:16:17",
        dtAlteracao: "2023-12-18T14:16:17",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "irancho-mvp",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipoExame: "ULTRASSOM_CARCACA",
        dtExame: "2023-05-11T11:31:50",
        vrResultado: "",
        vrResultadoAol: "78.99",
        vrResultadoEgs: "6.59",
        vrResultadoEgg: "0",
        vrResultadoMar: "3.9",
        vrResultadoRatio: "0",
        qtDiasPrenhe: null,
        icApresentouCio: null,
        idExameBlockchain: "228bc319-97ec-4d90-b422-1258876bb9de",
      },
    ],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:16:02",
      dtAlteracao: "2023-12-18T14:16:02",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      fazenda: {
        nome: "Fazenda União do Brasil ",
        dataEntrada: "2022-10-04T00:00:00",
        dataSaida: "2023-05-11T00:00:00",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
      },
      subdivisao: {
        nome: "Confinamento",
        dataEntrada: "2022-10-04T00:00:00",
        dataSaida: "2023-05-11T00:00:00",
      },
      idSistemaGestao: "d9fe4734-b9ac-422e-896d-077cee868624",
      idLocalizacaoBlockchain: "101ae27c-608c-4679-97c9-a93830757f2d",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Vale do Sol ",
          dataEntrada: "2021-08-17T00:00:00",
          dataSaida: "2022-10-04T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "Desmama",
          dataEntrada: "2021-08-17T00:00:00",
          dataSaida: "2022-10-04T00:00:00",
        },
        idSistemaGestao: "d9fe4734-b9ac-422e-896d-077cee868624",
        idLocalizacaoBlockchain: "e7b15181-9322-4c40-958e-13a08f7012cb",
      },
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Fazenda União do Brasil ",
          dataEntrada: "2022-10-04T00:00:00",
          dataSaida: "2023-05-11T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "Confinamento",
          dataEntrada: "2022-10-04T00:00:00",
          dataSaida: "2023-05-11T00:00:00",
        },
        idSistemaGestao: "d9fe4734-b9ac-422e-896d-077cee868624",
        idLocalizacaoBlockchain: "101ae27c-608c-4679-97c9-a93830757f2d",
      },
    ],
    loteHistorico: [],
    idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
    nomeResponsavel: "Fazenda União do Brasil",
    frigorifico: {
      nome: "Frigorífico Red Beef LTDA",
      maturidade: "2 dentes",
      acabamento: "Uniforme",
      pesoBanda1: "142.00 Kg",
      pesoBanda2: "142.00 Kg",
      obs1: "",
      obs2: "",
      dtMorte: "2023-05-11",
      porcentagemCarcaca: "53%",
      dtCriacao: "2023-12-18T18:57:57",
      dtAlteracao: "2023-12-18T18:57:57",
    },
  },
  "c475da2b-e188-4974-83cc-397e2ea61dc2": {
    idLoteSync: "ec1f85a1-7f04-468d-ac28-2bfdf17d33b5",
    idBlockchain: "c475da2b-e188-4974-83cc-397e2ea61dc2",
    docType: "ANIMAL",
    idSistemaGestao: "492530b1-daec-4adb-bb25-fb0dcc9b6ac9",
    idSisbov: "",
    idICar: "982000444279952",
    nome: "",
    dataNascimento: "2020-12-12",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "982000444279952",
    idAnimalFazenda: "3200031",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Santa Gertrudis", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:15:38",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      idSistemaGestao: "492530b1-daec-4adb-bb25-fb0dcc9b6ac9",
      idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
      nomeResponsavel: "Fazenda União do Brasil",
      latitude: "-23.6825667",
      longitude: "-48.5986721",
      tipo: "Saída",
      peso: 532,
      dtPesagem: "2023-05-11T11:31:50",
      idPesagemBlockchain: "34b2f67c-d921-4e3d-9b41-84b633a2f7f8",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "492530b1-daec-4adb-bb25-fb0dcc9b6ac9",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 303,
        dtPesagem: "2022-10-04T07:40:54",
        idPesagemBlockchain: "d9944a3e-ab3a-47c0-98c2-2036f265e5cb",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "492530b1-daec-4adb-bb25-fb0dcc9b6ac9",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 420,
        dtPesagem: "2023-02-17T13:41:34",
        idPesagemBlockchain: "c28ee350-9dbe-4b8b-802b-1b7f4411a18e",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "492530b1-daec-4adb-bb25-fb0dcc9b6ac9",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Saída",
        peso: 532,
        dtPesagem: "2023-05-11T11:31:50",
        idPesagemBlockchain: "34b2f67c-d921-4e3d-9b41-84b633a2f7f8",
      },
    ],
    produtorAtual: {
      idBlockchain: "25bc0b49-7cf7-4519-b70c-3001a1e4bfed",
      nome: "Fazenda União do Brasil",
      documento: "08090246000103",
    },
    status: "ABATIDO",
    dataCriacao: "2023-12-18T13:22:59",
    dataUltimaAtualizacao: "2023-12-18T18:56:46",
    vendaAtual: {
      idBlockchain: "0401071b-4fe8-4b0e-ba81-d94b5ab0b970",
      documentoProprietarioDestino: "30269671000113",
      tipo: "INDUSTRIA",
      proprietarioOrigem: {
        nome: "Fazenda União do Brasil",
        documento: "08090246000103",
      },
      proprietarioDestino: {
        nome: "Frigorífico Red Beef LTDA",
        documento: "30269671000113",
      },
      dataCriacao: "2023-12-18T18:33:01",
      dataUltimaAtualizacao: "2023-12-18T18:33:19",
      status: "EFETIVADA",
      qtdAnimais: 29,
    },
    vendaHistorico: [
      {
        idBlockchain: "eb66af4e-769b-4269-9378-dba27a1c0947",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:21:39",
        dataUltimaAtualizacao: "2023-12-18T18:24:08",
        status: "RECUSADA",
        qtdAnimais: 29,
      },
      {
        idBlockchain: "0401071b-4fe8-4b0e-ba81-d94b5ab0b970",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:33:01",
        dataUltimaAtualizacao: "2023-12-18T18:33:19",
        status: "EFETIVADA",
        qtdAnimais: 29,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [
      {
        dtCriacao: "2023-12-18T14:16:17",
        dtAlteracao: "2023-12-18T14:16:17",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "irancho-mvp",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipoExame: "ULTRASSOM_CARCACA",
        dtExame: "2023-05-11T11:31:50",
        vrResultado: "",
        vrResultadoAol: "73.74",
        vrResultadoEgs: "6.59",
        vrResultadoEgg: "0",
        vrResultadoMar: "4.3",
        vrResultadoRatio: "0",
        qtDiasPrenhe: null,
        icApresentouCio: null,
        idExameBlockchain: "7494526f-8b80-47b5-bcd7-5b9aff1e8676",
      },
    ],
    medicamentosAplicados: [
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "irancho-mvp",
        idOriginalMedicamentoBlockchain: "40aa0e8c-5ceb-4b79-bd44-253139e57a21",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        dtMedicacao: "2021-06-08T10:30:45",
        unidadeMedida: "MILILITRO",
        quantidade: 2,
        idMedicamentoBlockchain: "c90f9dc4-87f0-4212-b236-ababad40628d",
        medicamento: {
          idBlockchain: "40aa0e8c-5ceb-4b79-bd44-253139e57a21",
          nome: "Aborvac Brucelose",
          categoria: "FARMACIA",
          subCategoria: "VACINA",
          fabricante: "Zoetis",
          unidade: "MILILITRO",
          sexo: "FEMEA",
          idadeMaxima: 8,
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
    ],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:16:02",
      dtAlteracao: "2023-12-18T14:16:02",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      fazenda: {
        nome: "Fazenda União do Brasil ",
        dataEntrada: "2022-10-04T00:00:00",
        dataSaida: "2023-05-11T00:00:00",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
      },
      subdivisao: {
        nome: "Confinamento",
        dataEntrada: "2022-10-04T00:00:00",
        dataSaida: "2023-05-11T00:00:00",
      },
      idSistemaGestao: "492530b1-daec-4adb-bb25-fb0dcc9b6ac9",
      idLocalizacaoBlockchain: "212eb7fc-1d51-4bd7-abb8-c61f6e6eeebb",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Vale do Sol ",
          dataEntrada: "2021-10-26T00:00:00",
          dataSaida: "2022-10-04T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "transferencia de animais",
          dataEntrada: "2021-10-26T00:00:00",
          dataSaida: "2022-10-04T00:00:00",
        },
        idSistemaGestao: "492530b1-daec-4adb-bb25-fb0dcc9b6ac9",
        idLocalizacaoBlockchain: "1afa82a0-01ac-402c-ac28-98a5691a2efc",
      },
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Fazenda União do Brasil ",
          dataEntrada: "2022-10-04T00:00:00",
          dataSaida: "2023-05-11T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "Confinamento",
          dataEntrada: "2022-10-04T00:00:00",
          dataSaida: "2023-05-11T00:00:00",
        },
        idSistemaGestao: "492530b1-daec-4adb-bb25-fb0dcc9b6ac9",
        idLocalizacaoBlockchain: "212eb7fc-1d51-4bd7-abb8-c61f6e6eeebb",
      },
    ],
    loteHistorico: [],
    idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
    nomeResponsavel: "Fazenda União do Brasil",
    frigorifico: {
      nome: "Frigorífico Red Beef LTDA",
      maturidade: "2 dentes",
      acabamento: "Mediano",
      pesoBanda1: "134.25 Kg",
      pesoBanda2: "134.25 Kg",
      obs1: "",
      obs2: "",
      dtMorte: "2023-05-11",
      porcentagemCarcaca: "50%",
      dtCriacao: "2023-12-18T18:56:46",
      dtAlteracao: "2023-12-18T18:56:46",
    },
  },
  "cf0c42e3-7c22-497d-82b9-ce27a3c01e41": {
    idLoteSync: "ec1f85a1-7f04-468d-ac28-2bfdf17d33b5",
    idBlockchain: "cf0c42e3-7c22-497d-82b9-ce27a3c01e41",
    docType: "ANIMAL",
    idSistemaGestao: "a737bf90-ff0b-4cb4-9301-9e21fa51daf8",
    idSisbov: "",
    idICar: "982000449378949",
    nome: "F 008041210107",
    dataNascimento: "2021-08-28",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "982000449378949",
    idAnimalFazenda: "F 008041210107",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Santa Gertrudis", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:15:38",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      idSistemaGestao: "a737bf90-ff0b-4cb4-9301-9e21fa51daf8",
      idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
      nomeResponsavel: "Fazenda União do Brasil",
      latitude: "-23.6825667",
      longitude: "-48.5986721",
      tipo: "Saída",
      peso: 530,
      dtPesagem: "2023-05-11T11:31:50",
      idPesagemBlockchain: "888200af-4ba2-47a4-8dee-18210d8ebc58",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "a737bf90-ff0b-4cb4-9301-9e21fa51daf8",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Nascimento",
        peso: 37,
        dtPesagem: "2021-08-28T03:00:00",
        idPesagemBlockchain: "b1a91f24-e80f-4cc5-a3f3-40adb9bd507c",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "a737bf90-ff0b-4cb4-9301-9e21fa51daf8",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Desmame",
        peso: 190,
        dtPesagem: "2022-04-19T22:49:44",
        idPesagemBlockchain: "5e427d0e-b4eb-44a6-aaff-702cf6ebe3e6",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "a737bf90-ff0b-4cb4-9301-9e21fa51daf8",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 293,
        dtPesagem: "2022-10-04T07:40:54",
        idPesagemBlockchain: "b82571af-e3e6-49c0-a6e5-b1d3bb0dd26b",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "a737bf90-ff0b-4cb4-9301-9e21fa51daf8",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 434,
        dtPesagem: "2023-02-17T13:41:34",
        idPesagemBlockchain: "bf68252b-3e27-42a8-a9c7-07c22d56a6ac",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "a737bf90-ff0b-4cb4-9301-9e21fa51daf8",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Saída",
        peso: 530,
        dtPesagem: "2023-05-11T11:31:50",
        idPesagemBlockchain: "888200af-4ba2-47a4-8dee-18210d8ebc58",
      },
    ],
    produtorAtual: {
      idBlockchain: "25bc0b49-7cf7-4519-b70c-3001a1e4bfed",
      nome: "Fazenda União do Brasil",
      documento: "08090246000103",
    },
    status: "ABATIDO",
    dataCriacao: "2023-12-18T13:22:59",
    dataUltimaAtualizacao: "2023-12-18T18:56:00",
    vendaAtual: {
      idBlockchain: "0401071b-4fe8-4b0e-ba81-d94b5ab0b970",
      documentoProprietarioDestino: "30269671000113",
      tipo: "INDUSTRIA",
      proprietarioOrigem: {
        nome: "Fazenda União do Brasil",
        documento: "08090246000103",
      },
      proprietarioDestino: {
        nome: "Frigorífico Red Beef LTDA",
        documento: "30269671000113",
      },
      dataCriacao: "2023-12-18T18:33:01",
      dataUltimaAtualizacao: "2023-12-18T18:33:19",
      status: "EFETIVADA",
      qtdAnimais: 29,
    },
    vendaHistorico: [
      {
        idBlockchain: "eb66af4e-769b-4269-9378-dba27a1c0947",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:21:39",
        dataUltimaAtualizacao: "2023-12-18T18:24:08",
        status: "RECUSADA",
        qtdAnimais: 29,
      },
      {
        idBlockchain: "0401071b-4fe8-4b0e-ba81-d94b5ab0b970",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:33:01",
        dataUltimaAtualizacao: "2023-12-18T18:33:19",
        status: "EFETIVADA",
        qtdAnimais: 29,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [
      {
        dtCriacao: "2023-12-18T14:16:17",
        dtAlteracao: "2023-12-18T14:16:17",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "irancho-mvp",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipoExame: "ULTRASSOM_CARCACA",
        dtExame: "2023-05-11T11:31:50",
        vrResultado: "",
        vrResultadoAol: "57.79",
        vrResultadoEgs: "5.65",
        vrResultadoEgg: "0",
        vrResultadoMar: "3.89",
        vrResultadoRatio: "0",
        qtDiasPrenhe: null,
        icApresentouCio: null,
        idExameBlockchain: "86408305-af13-4aa4-af81-76c01288b548",
      },
    ],
    medicamentosAplicados: [
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "irancho-mvp",
        idOriginalMedicamentoBlockchain: "40aa0e8c-5ceb-4b79-bd44-253139e57a21",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        dtMedicacao: "2022-04-19T15:09:38",
        unidadeMedida: "MILILITRO",
        quantidade: 2,
        idMedicamentoBlockchain: "b3ddc635-a576-4f32-a1c7-eb296c740030",
        medicamento: {
          idBlockchain: "40aa0e8c-5ceb-4b79-bd44-253139e57a21",
          nome: "Aborvac Brucelose",
          categoria: "FARMACIA",
          subCategoria: "VACINA",
          fabricante: "Zoetis",
          unidade: "MILILITRO",
          sexo: "FEMEA",
          idadeMaxima: 8,
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
    ],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:16:02",
      dtAlteracao: "2023-12-18T14:16:02",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      fazenda: {
        nome: "Fazenda União do Brasil ",
        dataEntrada: "2022-10-04T00:00:00",
        dataSaida: "2023-05-11T00:00:00",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
      },
      subdivisao: {
        nome: "Confinamento",
        dataEntrada: "2022-10-04T00:00:00",
        dataSaida: "2023-05-11T00:00:00",
      },
      idSistemaGestao: "a737bf90-ff0b-4cb4-9301-9e21fa51daf8",
      idLocalizacaoBlockchain: "b86fd5c9-afd8-43c4-bc23-5b2224039832",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Fazenda Santo Antonio ",
          dataEntrada: "2022-04-19T00:00:00",
          dataSaida: "2022-04-23T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "pasto da frente",
          dataEntrada: "2022-04-19T00:00:00",
          dataSaida: "2022-04-23T00:00:00",
        },
        idSistemaGestao: "a737bf90-ff0b-4cb4-9301-9e21fa51daf8",
        idLocalizacaoBlockchain: "8bd6480e-f705-433a-89e8-d4f3840dd2b6",
      },
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Vale do Sol ",
          dataEntrada: "2022-04-23T00:00:00",
          dataSaida: "2022-10-04T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "Desmama",
          dataEntrada: "2022-04-23T00:00:00",
          dataSaida: "2022-10-04T00:00:00",
        },
        idSistemaGestao: "a737bf90-ff0b-4cb4-9301-9e21fa51daf8",
        idLocalizacaoBlockchain: "e09e61e7-66ae-404f-8851-12a5a4a8c655",
      },
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Fazenda União do Brasil ",
          dataEntrada: "2022-10-04T00:00:00",
          dataSaida: "2023-05-11T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "Confinamento",
          dataEntrada: "2022-10-04T00:00:00",
          dataSaida: "2023-05-11T00:00:00",
        },
        idSistemaGestao: "a737bf90-ff0b-4cb4-9301-9e21fa51daf8",
        idLocalizacaoBlockchain: "b86fd5c9-afd8-43c4-bc23-5b2224039832",
      },
    ],
    loteHistorico: [],
    idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
    nomeResponsavel: "Fazenda União do Brasil",
    frigorifico: {
      nome: "Frigorífico Red Beef LTDA",
      maturidade: "0 dentes",
      acabamento: "Mediano",
      pesoBanda1: "137.25 Kg",
      pesoBanda2: "137.25 Kg",
      obs1: "",
      obs2: "",
      dtMorte: "2023-05-11",
      porcentagemCarcaca: "52%",
      dtCriacao: "2023-12-18T18:56:00",
      dtAlteracao: "2023-12-18T18:56:00",
    },
  },
  "d3721d2a-22b7-4f51-8b65-3bf703a4f290": {
    idLoteSync: "ec1f85a1-7f04-468d-ac28-2bfdf17d33b5",
    idBlockchain: "d3721d2a-22b7-4f51-8b65-3bf703a4f290",
    docType: "ANIMAL",
    idSistemaGestao: "2b7dc4f8-c4bb-4274-a405-363f23c81305",
    idSisbov: "",
    idICar: "982000444276475",
    nome: "",
    dataNascimento: "2022-04-02",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "982000444276475",
    idAnimalFazenda: "4276475",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Santa Gertrudis", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:15:38",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      idSistemaGestao: "487cb7e2-5881-4fb7-b17c-3fcb2400a444",
      idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
      nomeResponsavel: "Fazenda União do Brasil",
      latitude: "",
      longitude: "",
      tipo: "Saída",
      peso: 436,
      dtPesagem: "2023-11-07",
      idPesagemBlockchain: "91d84e72-8d20-434f-880b-5d3071da386f",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "ae65250a-7d79-4b1f-98fe-31b2775d4c3e",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Entrada",
        peso: 218,
        dtPesagem: "2023-06-01",
        idPesagemBlockchain: "0adc54e6-4fca-421d-ad0c-63c5c417e0b4",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "fb28d44c-5b5c-4f42-ae73-ff6298462710",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 262,
        dtPesagem: "2023-06-27",
        idPesagemBlockchain: "5aec6ac6-4c55-4153-ab8f-8df7caf79f4b",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "6404fdf0-aaee-44f3-87b2-a728c0e58e3a",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 335,
        dtPesagem: "2023-09-04",
        idPesagemBlockchain: "51f8da96-29f9-4fe4-a0b5-9e5d6670ac41",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "487cb7e2-5881-4fb7-b17c-3fcb2400a444",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Saída",
        peso: 436,
        dtPesagem: "2023-11-07",
        idPesagemBlockchain: "91d84e72-8d20-434f-880b-5d3071da386f",
      },
    ],
    produtorAtual: {
      idBlockchain: "25bc0b49-7cf7-4519-b70c-3001a1e4bfed",
      nome: "Fazenda União do Brasil",
      documento: "08090246000103",
    },
    status: "ABATIDO",
    dataCriacao: "2023-12-18T13:22:59",
    dataUltimaAtualizacao: "2023-12-18T18:49:13",
    vendaAtual: {
      idBlockchain: "e0130d89-b68f-423b-ad3d-a429637fb5a3",
      documentoProprietarioDestino: "18293459000196",
      tipo: "INDUSTRIA",
      proprietarioOrigem: {
        nome: "Fazenda União do Brasil",
        documento: "08090246000103",
      },
      proprietarioDestino: {
        nome: "Guidara Indústria e Comércio de Alimentos Eireli",
        documento: "18293459000196",
      },
      dataCriacao: "2023-12-18T18:27:49",
      dataUltimaAtualizacao: "2023-12-18T18:28:02",
      status: "EFETIVADA",
      qtdAnimais: 25,
    },
    vendaHistorico: [
      {
        idBlockchain: "f10b303c-ab60-4d04-b8b0-0f217cda4abb",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:22:09",
        dataUltimaAtualizacao: "2023-12-18T18:24:42",
        status: "RECUSADA",
        qtdAnimais: 25,
      },
      {
        idBlockchain: "f1392d6d-fc67-43f4-918a-1da3da56a459",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:26:52",
        dataUltimaAtualizacao: "2023-12-18T18:27:14",
        status: "RECUSADA",
        qtdAnimais: 25,
      },
      {
        idBlockchain: "e0130d89-b68f-423b-ad3d-a429637fb5a3",
        documentoProprietarioDestino: "18293459000196",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Guidara Indústria e Comércio de Alimentos Eireli",
          documento: "18293459000196",
        },
        dataCriacao: "2023-12-18T18:27:49",
        dataUltimaAtualizacao: "2023-12-18T18:28:02",
        status: "EFETIVADA",
        qtdAnimais: 25,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [
      {
        dtCriacao: "2023-12-18T14:16:17",
        dtAlteracao: "2023-12-18T14:16:17",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "480564a8-09ce-46b2-bfde-ca1b3ca6cfd2",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipoExame: "ULTRASSOM_CARCACA",
        dtExame: "2023-09-04",
        vrResultado: "",
        vrResultadoAol: "77.10",
        vrResultadoEgs: "4.22",
        vrResultadoEgg: "0.00",
        vrResultadoMar: "3.74",
        vrResultadoRatio: "0.46",
        qtDiasPrenhe: null,
        icApresentouCio: null,
        idExameBlockchain: "4b826106-d2ba-434e-b717-a730c121043e",
      },
    ],
    medicamentosAplicados: [
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "0e52942f-9fe0-409c-a4fc-a6abcddac6dc",
        idOriginalMedicamentoBlockchain: "ba97487b-264d-4640-887a-1740557e1093",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        dtMedicacao: "2023-06-01",
        unidadeMedida: "MILILITRO",
        quantidade: 5,
        idMedicamentoBlockchain: "d642ad94-e334-4b38-81da-996439f3609c",
        medicamento: {
          idBlockchain: "ba97487b-264d-4640-887a-1740557e1093",
          nome: "Iver-vet 3.5 injetável 1000 mL",
          categoria: "FARMACIA",
          subCategoria: "VERMIFUGO",
          fabricante: "Biovet",
          unidade: "LITRO",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "98f4564d-8467-4f68-a832-42d0c6536eed",
        idOriginalMedicamentoBlockchain: "03a88948-7528-45c1-873a-b8d6012f9c15",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        dtMedicacao: "2023-06-27",
        unidadeMedida: "UNIDADE",
        quantidade: 2,
        idMedicamentoBlockchain: "ceec3295-b9fe-4454-8500-06ea2baf9664",
        medicamento: {
          idBlockchain: "03a88948-7528-45c1-873a-b8d6012f9c15",
          nome: "Vacina Bovi-Shield",
          categoria: "FARMACIA",
          subCategoria: "VACINA",
          fabricante: "Zoetis",
          unidade: "UNIDADE",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
    ],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:16:02",
      dtAlteracao: "2023-12-18T14:16:02",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      fazenda: {
        nome: "Fazenda União do Brasil ",
        dataEntrada: "2023-06-27",
        dataSaida: "2023-11-07",
        latitude: "-23.68423843",
        longitude: "-48.59685500",
      },
      subdivisao: {
        nome: "Confinamento",
        dataEntrada: "2023-06-27",
        dataSaida: "2023-11-07",
      },
      idSistemaGestao: "5015427",
      idLocalizacaoBlockchain: "5d495b5e-2a09-4a7b-875e-a7de83636cc9",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Fazenda União do Brasil ",
          dataEntrada: "2023-06-27",
          dataSaida: "2023-11-07",
          latitude: "-23.68423843",
          longitude: "-48.59685500",
        },
        subdivisao: {
          nome: "Confinamento",
          dataEntrada: "2023-06-27",
          dataSaida: "2023-11-07",
        },
        idSistemaGestao: "5015427",
        idLocalizacaoBlockchain: "5d495b5e-2a09-4a7b-875e-a7de83636cc9",
      },
    ],
    loteHistorico: [],
    idResponsavel: "fdd249be-f2a0-402a-9213-c40dff9f7c9f",
    nomeResponsavel: "BARTIRA AGROPECUARIA S/A",
    frigorifico: {
      nome: "Guidara Industria",
      maturidade: "2 dentes",
      acabamento: "Uniforme",
      pesoBanda1: "122",
      pesoBanda2: "122",
      obs1: "",
      obs2: "",
      dtMorte: "2023-11-08T00:00:00",
      porcentagemCarcaca: "57.40",
      dtCriacao: "2023-12-18T18:49:13",
      dtAlteracao: "2023-12-18T18:49:13",
    },
  },
  "d4033b32-530e-434a-a7e0-6ff43c3aa680": {
    idLoteSync: "ec1f85a1-7f04-468d-ac28-2bfdf17d33b5",
    idBlockchain: "d4033b32-530e-434a-a7e0-6ff43c3aa680",
    docType: "ANIMAL",
    idSistemaGestao: "60df7897-0a53-44cb-9128-5c75df998044",
    idSisbov: "105350174102280",
    idICar: "982000444282069",
    nome: "",
    dataNascimento: "2020-09-27",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "982000444282069",
    idAnimalFazenda: "4282069",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Santa Gertrudis", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:15:38",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      idSistemaGestao: "60df7897-0a53-44cb-9128-5c75df998044",
      idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
      nomeResponsavel: "Fazenda União do Brasil",
      latitude: "-23.6825667",
      longitude: "-48.5986721",
      tipo: "Saída",
      peso: 554,
      dtPesagem: "2023-05-11T11:31:50",
      idPesagemBlockchain: "85333c17-0f73-4cee-9073-ea2531130636",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "60df7897-0a53-44cb-9128-5c75df998044",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Entrada",
        peso: 167,
        dtPesagem: "2021-07-27T19:39:16",
        idPesagemBlockchain: "3d8f2d88-719a-45d3-abf2-769e7aa1b647",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "60df7897-0a53-44cb-9128-5c75df998044",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 270,
        dtPesagem: "2022-04-14T16:14:02",
        idPesagemBlockchain: "3ba00738-40d9-463f-9991-af20063fb827",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "60df7897-0a53-44cb-9128-5c75df998044",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 283,
        dtPesagem: "2022-10-04T07:40:54",
        idPesagemBlockchain: "015aa8df-e248-4885-aa6d-53925d7c23c1",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "60df7897-0a53-44cb-9128-5c75df998044",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 463,
        dtPesagem: "2023-02-17T13:41:34",
        idPesagemBlockchain: "71645565-3d7a-49c5-9484-84460679355d",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "60df7897-0a53-44cb-9128-5c75df998044",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Saída",
        peso: 554,
        dtPesagem: "2023-05-11T11:31:50",
        idPesagemBlockchain: "85333c17-0f73-4cee-9073-ea2531130636",
      },
    ],
    produtorAtual: {
      idBlockchain: "25bc0b49-7cf7-4519-b70c-3001a1e4bfed",
      nome: "Fazenda União do Brasil",
      documento: "08090246000103",
    },
    status: "ABATIDO",
    dataCriacao: "2023-12-18T13:22:59",
    dataUltimaAtualizacao: "2023-12-18T18:56:55",
    vendaAtual: {
      idBlockchain: "0401071b-4fe8-4b0e-ba81-d94b5ab0b970",
      documentoProprietarioDestino: "30269671000113",
      tipo: "INDUSTRIA",
      proprietarioOrigem: {
        nome: "Fazenda União do Brasil",
        documento: "08090246000103",
      },
      proprietarioDestino: {
        nome: "Frigorífico Red Beef LTDA",
        documento: "30269671000113",
      },
      dataCriacao: "2023-12-18T18:33:01",
      dataUltimaAtualizacao: "2023-12-18T18:33:19",
      status: "EFETIVADA",
      qtdAnimais: 29,
    },
    vendaHistorico: [
      {
        idBlockchain: "eb66af4e-769b-4269-9378-dba27a1c0947",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:21:39",
        dataUltimaAtualizacao: "2023-12-18T18:24:08",
        status: "RECUSADA",
        qtdAnimais: 29,
      },
      {
        idBlockchain: "0401071b-4fe8-4b0e-ba81-d94b5ab0b970",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:33:01",
        dataUltimaAtualizacao: "2023-12-18T18:33:19",
        status: "EFETIVADA",
        qtdAnimais: 29,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [
      {
        dtCriacao: "2023-12-18T14:16:17",
        dtAlteracao: "2023-12-18T14:16:17",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "irancho-mvp",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipoExame: "ULTRASSOM_CARCACA",
        dtExame: "2023-05-11T11:31:50",
        vrResultado: "",
        vrResultadoAol: "79.03",
        vrResultadoEgs: "6.59",
        vrResultadoEgg: "0",
        vrResultadoMar: "3.55",
        vrResultadoRatio: "0",
        qtDiasPrenhe: null,
        icApresentouCio: null,
        idExameBlockchain: "a9891ddd-322e-4dbe-8941-0d7601d75498",
      },
    ],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:16:02",
      dtAlteracao: "2023-12-18T14:16:02",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      fazenda: {
        nome: "Fazenda União do Brasil ",
        dataEntrada: "2022-10-04T00:00:00",
        dataSaida: "2023-05-11T00:00:00",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
      },
      subdivisao: {
        nome: "Confinamento",
        dataEntrada: "2022-10-04T00:00:00",
        dataSaida: "2023-05-11T00:00:00",
      },
      idSistemaGestao: "60df7897-0a53-44cb-9128-5c75df998044",
      idLocalizacaoBlockchain: "e14da0a6-5518-49d3-bf2f-3bc1795fe61b",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Vale do Sol ",
          dataEntrada: "2021-07-27T00:00:00",
          dataSaida: "2021-10-29T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "Compra desmama",
          dataEntrada: "2021-07-27T00:00:00",
          dataSaida: "2021-10-29T00:00:00",
        },
        idSistemaGestao: "60df7897-0a53-44cb-9128-5c75df998044",
        idLocalizacaoBlockchain: "9f39826c-1767-46fd-a6b2-de7a4abdba8f",
      },
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Fazenda União do Brasil ",
          dataEntrada: "2022-10-04T00:00:00",
          dataSaida: "2023-05-11T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "Confinamento",
          dataEntrada: "2022-10-04T00:00:00",
          dataSaida: "2023-05-11T00:00:00",
        },
        idSistemaGestao: "60df7897-0a53-44cb-9128-5c75df998044",
        idLocalizacaoBlockchain: "e14da0a6-5518-49d3-bf2f-3bc1795fe61b",
      },
    ],
    loteHistorico: [],
    idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
    nomeResponsavel: "Fazenda União do Brasil",
    frigorifico: {
      nome: "Frigorífico Red Beef LTDA",
      maturidade: "2 dentes",
      acabamento: "Uniforme",
      pesoBanda1: "142.75 Kg",
      pesoBanda2: "142.75 Kg",
      obs1: "",
      obs2: "",
      dtMorte: "2023-05-11",
      porcentagemCarcaca: "52%",
      dtCriacao: "2023-12-18T18:56:55",
      dtAlteracao: "2023-12-18T18:56:55",
    },
  },
  "f8101936-8266-420c-8c67-b3e2a419c5ac": {
    idLoteSync: "ec1f85a1-7f04-468d-ac28-2bfdf17d33b5",
    idBlockchain: "f8101936-8266-420c-8c67-b3e2a419c5ac",
    docType: "ANIMAL",
    idSistemaGestao: "446077bf-4894-43ee-9468-0150a6ce0f3c",
    idSisbov: "",
    idICar: "982000444276097",
    nome: "",
    dataNascimento: "2022-06-01",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "982000444276097",
    idAnimalFazenda: "4276097",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Santa Gertrudis", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:15:38",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      idSistemaGestao: "89ecbbf4-7311-436e-8977-cf30dbc4ff55",
      idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
      nomeResponsavel: "Fazenda União do Brasil",
      latitude: "",
      longitude: "",
      tipo: "Saída",
      peso: 463,
      dtPesagem: "2023-11-07",
      idPesagemBlockchain: "a79205bd-5205-4e32-b717-afd7c8505d3f",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "4995fee0-f5a6-4f76-ac4a-877b8ffce57c",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Entrada",
        peso: 294,
        dtPesagem: "2023-06-01",
        idPesagemBlockchain: "5109499f-7175-4eb1-acb8-c9dc04b3daac",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "2cfae43c-3834-4052-b07c-14fccc259766",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 269,
        dtPesagem: "2023-06-27",
        idPesagemBlockchain: "4d0d72ca-ba09-4ce1-99f3-25e5a500d051",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "b1b19af0-22d5-4dac-b1b4-3b26bea8b167",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 398,
        dtPesagem: "2023-09-04",
        idPesagemBlockchain: "7d54f9b7-e8d2-4a11-9f8b-c0542fead1c1",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "89ecbbf4-7311-436e-8977-cf30dbc4ff55",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Saída",
        peso: 463,
        dtPesagem: "2023-11-07",
        idPesagemBlockchain: "a79205bd-5205-4e32-b717-afd7c8505d3f",
      },
    ],
    produtorAtual: {
      idBlockchain: "25bc0b49-7cf7-4519-b70c-3001a1e4bfed",
      nome: "Fazenda União do Brasil",
      documento: "08090246000103",
    },
    status: "ABATIDO",
    dataCriacao: "2023-12-18T13:22:59",
    dataUltimaAtualizacao: "2023-12-18T18:48:53",
    vendaAtual: {
      idBlockchain: "e0130d89-b68f-423b-ad3d-a429637fb5a3",
      documentoProprietarioDestino: "18293459000196",
      tipo: "INDUSTRIA",
      proprietarioOrigem: {
        nome: "Fazenda União do Brasil",
        documento: "08090246000103",
      },
      proprietarioDestino: {
        nome: "Guidara Indústria e Comércio de Alimentos Eireli",
        documento: "18293459000196",
      },
      dataCriacao: "2023-12-18T18:27:49",
      dataUltimaAtualizacao: "2023-12-18T18:28:02",
      status: "EFETIVADA",
      qtdAnimais: 25,
    },
    vendaHistorico: [
      {
        idBlockchain: "f10b303c-ab60-4d04-b8b0-0f217cda4abb",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:22:09",
        dataUltimaAtualizacao: "2023-12-18T18:24:42",
        status: "RECUSADA",
        qtdAnimais: 25,
      },
      {
        idBlockchain: "f1392d6d-fc67-43f4-918a-1da3da56a459",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:26:52",
        dataUltimaAtualizacao: "2023-12-18T18:27:14",
        status: "RECUSADA",
        qtdAnimais: 25,
      },
      {
        idBlockchain: "e0130d89-b68f-423b-ad3d-a429637fb5a3",
        documentoProprietarioDestino: "18293459000196",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Guidara Indústria e Comércio de Alimentos Eireli",
          documento: "18293459000196",
        },
        dataCriacao: "2023-12-18T18:27:49",
        dataUltimaAtualizacao: "2023-12-18T18:28:02",
        status: "EFETIVADA",
        qtdAnimais: 25,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [
      {
        dtCriacao: "2023-12-18T14:16:17",
        dtAlteracao: "2023-12-18T14:16:17",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "cb2a19fe-32c0-487e-a283-9a42d0d94290",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipoExame: "ULTRASSOM_CARCACA",
        dtExame: "2023-09-04",
        vrResultado: "",
        vrResultadoAol: "77.39",
        vrResultadoEgs: "8.94",
        vrResultadoEgg: "0.00",
        vrResultadoMar: "3.63",
        vrResultadoRatio: "0.51",
        qtDiasPrenhe: null,
        icApresentouCio: null,
        idExameBlockchain: "6cb6332a-facc-4258-a205-ecce32c950b5",
      },
    ],
    medicamentosAplicados: [
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "6356948d-e376-4b76-b70f-50795a0f0f57",
        idOriginalMedicamentoBlockchain: "03a88948-7528-45c1-873a-b8d6012f9c15",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        dtMedicacao: "2023-06-27",
        unidadeMedida: "UNIDADE",
        quantidade: 2,
        idMedicamentoBlockchain: "5a0d3e5a-631a-444e-bea5-2c7becf85882",
        medicamento: {
          idBlockchain: "03a88948-7528-45c1-873a-b8d6012f9c15",
          nome: "Vacina Bovi-Shield",
          categoria: "FARMACIA",
          subCategoria: "VACINA",
          fabricante: "Zoetis",
          unidade: "UNIDADE",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "e4790ab7-1e5b-44d9-8746-bc2a1d651d40",
        idOriginalMedicamentoBlockchain: "ba97487b-264d-4640-887a-1740557e1093",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        dtMedicacao: "2023-06-01",
        unidadeMedida: "MILILITRO",
        quantidade: 5,
        idMedicamentoBlockchain: "7848d03f-cf6c-4d4c-9969-1ef0f7883f57",
        medicamento: {
          idBlockchain: "ba97487b-264d-4640-887a-1740557e1093",
          nome: "Iver-vet 3.5 injetável 1000 mL",
          categoria: "FARMACIA",
          subCategoria: "VERMIFUGO",
          fabricante: "Biovet",
          unidade: "LITRO",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
    ],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:16:02",
      dtAlteracao: "2023-12-18T14:16:02",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      fazenda: {
        nome: "Fazenda União do Brasil ",
        dataEntrada: "2023-06-27",
        dataSaida: "2023-11-07",
        latitude: "-23.68423843",
        longitude: "-48.59685500",
      },
      subdivisao: {
        nome: "Confinamento",
        dataEntrada: "2023-06-27",
        dataSaida: "2023-11-07",
      },
      idSistemaGestao: "5015324",
      idLocalizacaoBlockchain: "f7deaae5-24f0-4f1c-a4bd-bd23a00fc4a5",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Fazenda União do Brasil ",
          dataEntrada: "2023-06-27",
          dataSaida: "2023-11-07",
          latitude: "-23.68423843",
          longitude: "-48.59685500",
        },
        subdivisao: {
          nome: "Confinamento",
          dataEntrada: "2023-06-27",
          dataSaida: "2023-11-07",
        },
        idSistemaGestao: "5015324",
        idLocalizacaoBlockchain: "f7deaae5-24f0-4f1c-a4bd-bd23a00fc4a5",
      },
    ],
    loteHistorico: [],
    idResponsavel: "fdd249be-f2a0-402a-9213-c40dff9f7c9f",
    nomeResponsavel: "BARTIRA AGROPECUARIA S/A",
    frigorifico: {
      nome: "Guidara Industria",
      maturidade: "2 dentes",
      acabamento: "Uniforme",
      pesoBanda1: "125.5",
      pesoBanda2: "125.5",
      obs1: "",
      obs2: "",
      dtMorte: "2023-11-08T00:00:00",
      porcentagemCarcaca: "55.70",
      dtCriacao: "2023-12-18T18:48:53",
      dtAlteracao: "2023-12-18T18:48:53",
    },
  },
  "f87186aa-5eaf-4b62-88c9-da4b9ff2b35a": {
    idLoteSync: "ec1f85a1-7f04-468d-ac28-2bfdf17d33b5",
    idBlockchain: "f87186aa-5eaf-4b62-88c9-da4b9ff2b35a",
    docType: "ANIMAL",
    idSistemaGestao: "1bb3d937-815a-4907-b523-e12e1e238e63",
    idSisbov: "",
    idICar: "982000444276650",
    nome: "",
    dataNascimento: "2022-04-02",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "982000444276650",
    idAnimalFazenda: "4276650",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Santa Gertrudis", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:15:38",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      idSistemaGestao: "4a44ab88-6ae8-4ab6-9290-3092a2bd6c6e",
      idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
      nomeResponsavel: "Fazenda União do Brasil",
      latitude: "",
      longitude: "",
      tipo: "Saída",
      peso: 464,
      dtPesagem: "2023-11-07",
      idPesagemBlockchain: "bbb3ae28-ef61-44d3-8efd-a98b6b32f64b",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "15b94365-2028-4fcf-af32-d12dec198e0a",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Entrada",
        peso: 258,
        dtPesagem: "2023-06-01",
        idPesagemBlockchain: "479aaae3-efe9-464b-aeb3-c0a9cdfb8401",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "7b76f7a4-7753-457b-98b3-040e40901a09",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 265,
        dtPesagem: "2023-06-27",
        idPesagemBlockchain: "5af64b22-d732-42ee-a9fd-e292e8f26fe4",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "ff2a30af-c8ee-4578-83b1-d87d2c186e9d",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 380,
        dtPesagem: "2023-09-04",
        idPesagemBlockchain: "887575a1-4b18-4dae-9499-d00f21ccf66a",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "4a44ab88-6ae8-4ab6-9290-3092a2bd6c6e",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Saída",
        peso: 464,
        dtPesagem: "2023-11-07",
        idPesagemBlockchain: "bbb3ae28-ef61-44d3-8efd-a98b6b32f64b",
      },
    ],
    produtorAtual: {
      idBlockchain: "25bc0b49-7cf7-4519-b70c-3001a1e4bfed",
      nome: "Fazenda União do Brasil",
      documento: "08090246000103",
    },
    status: "ABATIDO",
    dataCriacao: "2023-12-18T13:22:59",
    dataUltimaAtualizacao: "2023-12-18T18:49:04",
    vendaAtual: {
      idBlockchain: "e0130d89-b68f-423b-ad3d-a429637fb5a3",
      documentoProprietarioDestino: "18293459000196",
      tipo: "INDUSTRIA",
      proprietarioOrigem: {
        nome: "Fazenda União do Brasil",
        documento: "08090246000103",
      },
      proprietarioDestino: {
        nome: "Guidara Indústria e Comércio de Alimentos Eireli",
        documento: "18293459000196",
      },
      dataCriacao: "2023-12-18T18:27:49",
      dataUltimaAtualizacao: "2023-12-18T18:28:02",
      status: "EFETIVADA",
      qtdAnimais: 25,
    },
    vendaHistorico: [
      {
        idBlockchain: "f10b303c-ab60-4d04-b8b0-0f217cda4abb",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:22:09",
        dataUltimaAtualizacao: "2023-12-18T18:24:42",
        status: "RECUSADA",
        qtdAnimais: 25,
      },
      {
        idBlockchain: "f1392d6d-fc67-43f4-918a-1da3da56a459",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:26:52",
        dataUltimaAtualizacao: "2023-12-18T18:27:14",
        status: "RECUSADA",
        qtdAnimais: 25,
      },
      {
        idBlockchain: "e0130d89-b68f-423b-ad3d-a429637fb5a3",
        documentoProprietarioDestino: "18293459000196",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Guidara Indústria e Comércio de Alimentos Eireli",
          documento: "18293459000196",
        },
        dataCriacao: "2023-12-18T18:27:49",
        dataUltimaAtualizacao: "2023-12-18T18:28:02",
        status: "EFETIVADA",
        qtdAnimais: 25,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [
      {
        dtCriacao: "2023-12-18T14:16:17",
        dtAlteracao: "2023-12-18T14:16:17",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "1dbcce45-8c0e-4507-af73-36ace477a656",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipoExame: "ULTRASSOM_CARCACA",
        dtExame: "2023-09-04",
        vrResultado: "",
        vrResultadoAol: "76.60",
        vrResultadoEgs: "4.24",
        vrResultadoEgg: "0.00",
        vrResultadoMar: "3.49",
        vrResultadoRatio: "0.53",
        qtDiasPrenhe: null,
        icApresentouCio: null,
        idExameBlockchain: "ea033233-80b9-4c3d-a98c-9fd73086e79b",
      },
    ],
    medicamentosAplicados: [
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "81106418-f520-42de-ad8f-f420ae928253",
        idOriginalMedicamentoBlockchain: "03a88948-7528-45c1-873a-b8d6012f9c15",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        dtMedicacao: "2023-06-27",
        unidadeMedida: "UNIDADE",
        quantidade: 2,
        idMedicamentoBlockchain: "75d3fcf1-5ccc-4ac9-bd22-9578a5736de0",
        medicamento: {
          idBlockchain: "03a88948-7528-45c1-873a-b8d6012f9c15",
          nome: "Vacina Bovi-Shield",
          categoria: "FARMACIA",
          subCategoria: "VACINA",
          fabricante: "Zoetis",
          unidade: "UNIDADE",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "d1c4e86e-7187-46df-aa40-cc8c74aa74d5",
        idOriginalMedicamentoBlockchain: "ba97487b-264d-4640-887a-1740557e1093",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        dtMedicacao: "2023-06-01",
        unidadeMedida: "MILILITRO",
        quantidade: 5,
        idMedicamentoBlockchain: "e9ba0fe7-1b5b-4e84-af4e-fad97079073d",
        medicamento: {
          idBlockchain: "ba97487b-264d-4640-887a-1740557e1093",
          nome: "Iver-vet 3.5 injetável 1000 mL",
          categoria: "FARMACIA",
          subCategoria: "VERMIFUGO",
          fabricante: "Biovet",
          unidade: "LITRO",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
    ],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:16:02",
      dtAlteracao: "2023-12-18T14:16:02",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      fazenda: {
        nome: "Fazenda União do Brasil ",
        dataEntrada: "2023-06-27",
        dataSaida: "2023-11-07",
        latitude: "-23.68423843",
        longitude: "-48.59685500",
      },
      subdivisao: {
        nome: "Confinamento",
        dataEntrada: "2023-06-27",
        dataSaida: "2023-11-07",
      },
      idSistemaGestao: "5015391",
      idLocalizacaoBlockchain: "8b751711-d4ea-4124-bc8b-bcbc281104fb",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Fazenda União do Brasil ",
          dataEntrada: "2023-06-27",
          dataSaida: "2023-11-07",
          latitude: "-23.68423843",
          longitude: "-48.59685500",
        },
        subdivisao: {
          nome: "Confinamento",
          dataEntrada: "2023-06-27",
          dataSaida: "2023-11-07",
        },
        idSistemaGestao: "5015391",
        idLocalizacaoBlockchain: "8b751711-d4ea-4124-bc8b-bcbc281104fb",
      },
    ],
    loteHistorico: [],
    idResponsavel: "fdd249be-f2a0-402a-9213-c40dff9f7c9f",
    nomeResponsavel: "BARTIRA AGROPECUARIA S/A",
    frigorifico: {
      nome: "Guidara Industria",
      maturidade: "2 dentes",
      acabamento: "Uniforme",
      pesoBanda1: "131.5",
      pesoBanda2: "131.5",
      obs1: "",
      obs2: "",
      dtMorte: "2023-11-08T00:00:00",
      porcentagemCarcaca: "58.30",
      dtCriacao: "2023-12-18T18:49:04",
      dtAlteracao: "2023-12-18T18:49:04",
    },
  },
  "fb4ed6ab-38c0-414f-9dca-3e8df6236617": {
    idLoteSync: "ec1f85a1-7f04-468d-ac28-2bfdf17d33b5",
    idBlockchain: "fb4ed6ab-38c0-414f-9dca-3e8df6236617",
    docType: "ANIMAL",
    idSistemaGestao: "9721c4fe-dd74-4c9d-9d01-3956ada1ecc4",
    idSisbov: "",
    idICar: "982000444275437",
    nome: "",
    dataNascimento: "2022-06-01",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "982000444275437",
    idAnimalFazenda: "4275437",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Santa Gertrudis", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:15:38",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      idSistemaGestao: "357ca2ac-0eee-4d2f-8400-2119c44c5949",
      idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
      nomeResponsavel: "Fazenda União do Brasil",
      latitude: "",
      longitude: "",
      tipo: "Saída",
      peso: 405,
      dtPesagem: "2023-11-07",
      idPesagemBlockchain: "00e3cccd-aee6-41a5-8564-1404d7a43b9d",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "5a7214f1-cf78-4174-aa30-51b4c9d76248",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Entrada",
        peso: 248,
        dtPesagem: "2023-06-01",
        idPesagemBlockchain: "b867572d-d984-4894-8f51-763272c6711c",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "6bc14e1f-5957-46aa-b6b2-8111a9669c5d",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 233,
        dtPesagem: "2023-06-27",
        idPesagemBlockchain: "5978fc4f-bddf-45da-8a59-1bbb168f88d2",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "abab4fba-aa24-4f7e-86b6-87cb2302bd47",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 342,
        dtPesagem: "2023-09-04",
        idPesagemBlockchain: "09819aba-0dcd-4c96-a886-b7854ee4b7f9",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "357ca2ac-0eee-4d2f-8400-2119c44c5949",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipo: "Saída",
        peso: 405,
        dtPesagem: "2023-11-07",
        idPesagemBlockchain: "00e3cccd-aee6-41a5-8564-1404d7a43b9d",
      },
    ],
    produtorAtual: {
      idBlockchain: "25bc0b49-7cf7-4519-b70c-3001a1e4bfed",
      nome: "Fazenda União do Brasil",
      documento: "08090246000103",
    },
    status: "ABATIDO",
    dataCriacao: "2023-12-18T13:22:59",
    dataUltimaAtualizacao: "2023-12-18T18:48:48",
    vendaAtual: {
      idBlockchain: "e0130d89-b68f-423b-ad3d-a429637fb5a3",
      documentoProprietarioDestino: "18293459000196",
      tipo: "INDUSTRIA",
      proprietarioOrigem: {
        nome: "Fazenda União do Brasil",
        documento: "08090246000103",
      },
      proprietarioDestino: {
        nome: "Guidara Indústria e Comércio de Alimentos Eireli",
        documento: "18293459000196",
      },
      dataCriacao: "2023-12-18T18:27:49",
      dataUltimaAtualizacao: "2023-12-18T18:28:02",
      status: "EFETIVADA",
      qtdAnimais: 25,
    },
    vendaHistorico: [
      {
        idBlockchain: "f10b303c-ab60-4d04-b8b0-0f217cda4abb",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:22:09",
        dataUltimaAtualizacao: "2023-12-18T18:24:42",
        status: "RECUSADA",
        qtdAnimais: 25,
      },
      {
        idBlockchain: "f1392d6d-fc67-43f4-918a-1da3da56a459",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:26:52",
        dataUltimaAtualizacao: "2023-12-18T18:27:14",
        status: "RECUSADA",
        qtdAnimais: 25,
      },
      {
        idBlockchain: "e0130d89-b68f-423b-ad3d-a429637fb5a3",
        documentoProprietarioDestino: "18293459000196",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Guidara Indústria e Comércio de Alimentos Eireli",
          documento: "18293459000196",
        },
        dataCriacao: "2023-12-18T18:27:49",
        dataUltimaAtualizacao: "2023-12-18T18:28:02",
        status: "EFETIVADA",
        qtdAnimais: 25,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [
      {
        dtCriacao: "2023-12-18T14:16:17",
        dtAlteracao: "2023-12-18T14:16:17",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "36f2d97d-b986-4f3c-af86-242de713aa37",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        tipoExame: "ULTRASSOM_CARCACA",
        dtExame: "2023-09-04",
        vrResultado: "",
        vrResultadoAol: "82.82",
        vrResultadoEgs: "7.52",
        vrResultadoEgg: "0.00",
        vrResultadoMar: "3.86",
        vrResultadoRatio: "0.55",
        qtDiasPrenhe: null,
        icApresentouCio: null,
        idExameBlockchain: "61ac4d62-4b8f-47ea-9428-cc3463993fdf",
      },
    ],
    medicamentosAplicados: [
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "829af6f8-62ca-4798-92b1-342eca6b9a4f",
        idOriginalMedicamentoBlockchain: "ba97487b-264d-4640-887a-1740557e1093",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        dtMedicacao: "2023-06-01",
        unidadeMedida: "MILILITRO",
        quantidade: 5,
        idMedicamentoBlockchain: "f07e2009-b265-4731-a36a-45cebc4c743e",
        medicamento: {
          idBlockchain: "ba97487b-264d-4640-887a-1740557e1093",
          nome: "Iver-vet 3.5 injetável 1000 mL",
          categoria: "FARMACIA",
          subCategoria: "VERMIFUGO",
          fabricante: "Biovet",
          unidade: "LITRO",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "c6a525b9-053f-4977-a69c-e4cc3f416f87",
        idOriginalMedicamentoBlockchain: "03a88948-7528-45c1-873a-b8d6012f9c15",
        idResponsavel: "9680754c-7c6d-488d-af09-203b407bec83",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "",
        longitude: "",
        dtMedicacao: "2023-06-27",
        unidadeMedida: "UNIDADE",
        quantidade: 2,
        idMedicamentoBlockchain: "2bacc99b-1f45-41ae-92b0-a2688c35d79a",
        medicamento: {
          idBlockchain: "03a88948-7528-45c1-873a-b8d6012f9c15",
          nome: "Vacina Bovi-Shield",
          categoria: "FARMACIA",
          subCategoria: "VACINA",
          fabricante: "Zoetis",
          unidade: "UNIDADE",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
    ],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:16:02",
      dtAlteracao: "2023-12-18T14:16:02",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      fazenda: {
        nome: "Fazenda União do Brasil ",
        dataEntrada: "2023-06-27",
        dataSaida: "2023-11-07",
        latitude: "-23.68423843",
        longitude: "-48.59685500",
      },
      subdivisao: {
        nome: "Confinamento",
        dataEntrada: "2023-06-27",
        dataSaida: "2023-11-07",
      },
      idSistemaGestao: "5015359",
      idLocalizacaoBlockchain: "8032b3e5-edd7-4ad5-8fe6-6c34621e0672",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Fazenda União do Brasil ",
          dataEntrada: "2023-06-27",
          dataSaida: "2023-11-07",
          latitude: "-23.68423843",
          longitude: "-48.59685500",
        },
        subdivisao: {
          nome: "Confinamento",
          dataEntrada: "2023-06-27",
          dataSaida: "2023-11-07",
        },
        idSistemaGestao: "5015359",
        idLocalizacaoBlockchain: "8032b3e5-edd7-4ad5-8fe6-6c34621e0672",
      },
    ],
    loteHistorico: [],
    idResponsavel: "fdd249be-f2a0-402a-9213-c40dff9f7c9f",
    nomeResponsavel: "BARTIRA AGROPECUARIA S/A",
    frigorifico: {
      nome: "Guidara Industria",
      maturidade: "2 dentes",
      acabamento: "Uniforme",
      pesoBanda1: "109.5",
      pesoBanda2: "109.5",
      obs1: "",
      obs2: "",
      dtMorte: "2023-11-08T00:00:00",
      porcentagemCarcaca: "60.20",
      dtCriacao: "2023-12-18T18:48:48",
      dtAlteracao: "2023-12-18T18:48:48",
    },
  },
  "fdecbb52-dc7c-4943-8b17-5f2b35289345": {
    idLoteSync: "ec1f85a1-7f04-468d-ac28-2bfdf17d33b5",
    idBlockchain: "fdecbb52-dc7c-4943-8b17-5f2b35289345",
    docType: "ANIMAL",
    idSistemaGestao: "be69065a-7755-4aec-a392-145357462b34",
    idSisbov: "",
    idICar: "982000444280148",
    nome: "",
    dataNascimento: "2021-01-11",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "982000444280148",
    idAnimalFazenda: "3210079",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Santa Gertrudis", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:15:38",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      idSistemaGestao: "be69065a-7755-4aec-a392-145357462b34",
      idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
      nomeResponsavel: "Fazenda União do Brasil",
      latitude: "-23.6825667",
      longitude: "-48.5986721",
      tipo: "Saída",
      peso: 526,
      dtPesagem: "2023-05-11T11:31:50",
      idPesagemBlockchain: "09550d4d-4653-4967-8efd-1680b2d93cc5",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "be69065a-7755-4aec-a392-145357462b34",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Nascimento",
        peso: 22,
        dtPesagem: "2021-01-11T03:00:00",
        idPesagemBlockchain: "049e04ed-fd73-424d-bed6-5010dc09a5f8",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "be69065a-7755-4aec-a392-145357462b34",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Desmame",
        peso: 137,
        dtPesagem: "2021-09-23T13:06:24",
        idPesagemBlockchain: "51da2b91-e71c-49fd-b583-6d7d35f399e0",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "be69065a-7755-4aec-a392-145357462b34",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 312,
        dtPesagem: "2022-10-04T07:40:54",
        idPesagemBlockchain: "2db7f758-de58-420c-9288-f73bd64c24a0",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "be69065a-7755-4aec-a392-145357462b34",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Pesagem Simples",
        peso: 436,
        dtPesagem: "2023-02-17T13:41:34",
        idPesagemBlockchain: "2f38f0a6-6808-4b63-9549-cd426a43aa7e",
      },
      {
        dtCriacao: "2023-12-18T14:15:38",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "be69065a-7755-4aec-a392-145357462b34",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipo: "Saída",
        peso: 526,
        dtPesagem: "2023-05-11T11:31:50",
        idPesagemBlockchain: "09550d4d-4653-4967-8efd-1680b2d93cc5",
      },
    ],
    produtorAtual: {
      idBlockchain: "25bc0b49-7cf7-4519-b70c-3001a1e4bfed",
      nome: "Fazenda União do Brasil",
      documento: "08090246000103",
    },
    status: "ABATIDO",
    dataCriacao: "2023-12-18T13:22:59",
    dataUltimaAtualizacao: "2023-12-18T18:55:05",
    vendaAtual: {
      idBlockchain: "0401071b-4fe8-4b0e-ba81-d94b5ab0b970",
      documentoProprietarioDestino: "30269671000113",
      tipo: "INDUSTRIA",
      proprietarioOrigem: {
        nome: "Fazenda União do Brasil",
        documento: "08090246000103",
      },
      proprietarioDestino: {
        nome: "Frigorífico Red Beef LTDA",
        documento: "30269671000113",
      },
      dataCriacao: "2023-12-18T18:33:01",
      dataUltimaAtualizacao: "2023-12-18T18:33:19",
      status: "EFETIVADA",
      qtdAnimais: 29,
    },
    vendaHistorico: [
      {
        idBlockchain: "eb66af4e-769b-4269-9378-dba27a1c0947",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:21:39",
        dataUltimaAtualizacao: "2023-12-18T18:24:08",
        status: "RECUSADA",
        qtdAnimais: 29,
      },
      {
        idBlockchain: "0401071b-4fe8-4b0e-ba81-d94b5ab0b970",
        documentoProprietarioDestino: "30269671000113",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Fazenda União do Brasil",
          documento: "08090246000103",
        },
        proprietarioDestino: {
          nome: "Frigorífico Red Beef LTDA",
          documento: "30269671000113",
        },
        dataCriacao: "2023-12-18T18:33:01",
        dataUltimaAtualizacao: "2023-12-18T18:33:19",
        status: "EFETIVADA",
        qtdAnimais: 29,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [
      {
        dtCriacao: "2023-12-18T14:16:17",
        dtAlteracao: "2023-12-18T14:16:17",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        idSistemaGestao: "irancho-mvp",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        tipoExame: "ULTRASSOM_CARCACA",
        dtExame: "2023-05-11T11:31:50",
        vrResultado: "",
        vrResultadoAol: "75.49",
        vrResultadoEgs: "10.35",
        vrResultadoEgg: "0",
        vrResultadoMar: "5.5",
        vrResultadoRatio: "0",
        qtDiasPrenhe: null,
        icApresentouCio: null,
        idExameBlockchain: "3898f6d2-185c-4b5f-8f20-18b2e159cf46",
      },
    ],
    medicamentosAplicados: [
      {
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        dtCriacao: "2023-12-18T14:19:47",
        dtAlteracao: "2023-12-18T14:19:47",
        idSistemaGestao: "irancho-mvp",
        idOriginalMedicamentoBlockchain: "40aa0e8c-5ceb-4b79-bd44-253139e57a21",
        idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
        nomeResponsavel: "Fazenda União do Brasil",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
        dtMedicacao: "2021-06-07T12:00:40",
        unidadeMedida: "MILILITRO",
        quantidade: 2,
        idMedicamentoBlockchain: "ef445805-127c-48cb-9e7c-a441652ff420",
        medicamento: {
          idBlockchain: "40aa0e8c-5ceb-4b79-bd44-253139e57a21",
          nome: "Aborvac Brucelose",
          categoria: "FARMACIA",
          subCategoria: "VACINA",
          fabricante: "Zoetis",
          unidade: "MILILITRO",
          sexo: "FEMEA",
          idadeMaxima: 8,
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
    ],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:16:02",
      dtAlteracao: "2023-12-18T14:16:02",
      idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
      fazenda: {
        nome: "Fazenda União do Brasil ",
        dataEntrada: "2022-10-04T00:00:00",
        dataSaida: "2023-05-11T00:00:00",
        latitude: "-23.6825667",
        longitude: "-48.5986721",
      },
      subdivisao: {
        nome: "Confinamento",
        dataEntrada: "2022-10-04T00:00:00",
        dataSaida: "2023-05-11T00:00:00",
      },
      idSistemaGestao: "be69065a-7755-4aec-a392-145357462b34",
      idLocalizacaoBlockchain: "a489be28-1a7c-4b6e-87a1-f9e2d73ccfe8",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Vale do Sol ",
          dataEntrada: "2021-10-26T00:00:00",
          dataSaida: "2022-10-04T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "transferencia de animais",
          dataEntrada: "2021-10-26T00:00:00",
          dataSaida: "2022-10-04T00:00:00",
        },
        idSistemaGestao: "be69065a-7755-4aec-a392-145357462b34",
        idLocalizacaoBlockchain: "44364847-c0dd-480b-ae6b-58bf6fed6f5d",
      },
      {
        dtCriacao: "2023-12-18T14:16:02",
        dtAlteracao: "2023-12-18T14:16:02",
        idLoteSync: "e3849e46-e7c3-4841-949d-cb099db961ad",
        fazenda: {
          nome: "Fazenda União do Brasil ",
          dataEntrada: "2022-10-04T00:00:00",
          dataSaida: "2023-05-11T00:00:00",
          latitude: "-23.6825667",
          longitude: "-48.5986721",
        },
        subdivisao: {
          nome: "Confinamento",
          dataEntrada: "2022-10-04T00:00:00",
          dataSaida: "2023-05-11T00:00:00",
        },
        idSistemaGestao: "be69065a-7755-4aec-a392-145357462b34",
        idLocalizacaoBlockchain: "a489be28-1a7c-4b6e-87a1-f9e2d73ccfe8",
      },
    ],
    loteHistorico: [],
    idResponsavel: "426d8fa9-d174-4775-b43f-9f068aab7907",
    nomeResponsavel: "Fazenda União do Brasil",
    frigorifico: {
      nome: "Frigorífico Red Beef LTDA",
      maturidade: "2 dentes",
      acabamento: "Uniforme",
      pesoBanda1: "144.50 Kg",
      pesoBanda2: "144.50 Kg",
      obs1: "",
      obs2: "",
      dtMorte: "2023-05-11",
      porcentagemCarcaca: "55%",
      dtCriacao: "2023-12-18T18:55:05",
      dtAlteracao: "2023-12-18T18:55:05",
    },
  },
  "25ea840b-3b52-412e-9e0d-38da332285f7": {
    idLoteSync: "8b507fea-7eb4-48f9-bf56-81c9b8654e8b",
    idBlockchain: "25ea840b-3b52-412e-9e0d-38da332285f7",
    docType: "ANIMAL",
    idSistemaGestao: "928c71f2-b619-457b-ab85-6f715dc33867",
    idSisbov: "105520357182364",
    idICar: "",
    nome: "",
    dataNascimento: "2018-10-28",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "7182364",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:21:37",
      idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
      idSistemaGestao: "3316b1c1-56f7-4ee8-b328-a51923831760",
      idResponsavel: "e1227ca6-211b-4b43-b310-7288f3a945da",
      nomeResponsavel: "Wanderson Souza Nascimento",
      latitude: "",
      longitude: "",
      tipo: "Pesagem Simples",
      peso: 336,
      dtPesagem: "2022-05-13",
      idPesagemBlockchain: "f2228421-a60b-4abe-98e3-fdcb0447fd9c",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:21:37",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        idSistemaGestao: "3316b1c1-56f7-4ee8-b328-a51923831760",
        idResponsavel: "e1227ca6-211b-4b43-b310-7288f3a945da",
        nomeResponsavel: "Wanderson Souza Nascimento",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 336,
        dtPesagem: "2022-05-13",
        idPesagemBlockchain: "f2228421-a60b-4abe-98e3-fdcb0447fd9c",
      },
    ],
    produtorAtual: {
      idBlockchain: "46bd5b75-1cba-4da3-96ac-ad89f874846e",
      nome: "Wanderson",
      documento: "92374000125",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:25:22",
    dataUltimaAtualizacao: "2023-12-18T18:37:53",
    vendaAtual: {
      idBlockchain: "fd157fd6-8411-4f9a-aeda-aa0ff3777b01",
      documentoProprietarioDestino: "18293459000196",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "Wanderson", documento: "92374000125" },
      proprietarioDestino: {
        nome: "Guidara Indústria e Comércio de Alimentos Eireli",
        documento: "18293459000196",
      },
      dataCriacao: "2023-12-18T18:37:07",
      dataUltimaAtualizacao: "2023-12-18T18:37:53",
      status: "EFETIVADA",
      qtdAnimais: 15,
    },
    vendaHistorico: [
      {
        idBlockchain: "fd157fd6-8411-4f9a-aeda-aa0ff3777b01",
        documentoProprietarioDestino: "18293459000196",
        tipo: "INDUSTRIA",
        proprietarioOrigem: { nome: "Wanderson", documento: "92374000125" },
        proprietarioDestino: {
          nome: "Guidara Indústria e Comércio de Alimentos Eireli",
          documento: "18293459000196",
        },
        dataCriacao: "2023-12-18T18:37:07",
        dataUltimaAtualizacao: "2023-12-18T18:37:53",
        status: "EFETIVADA",
        qtdAnimais: 15,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:22:04",
      dtAlteracao: "2023-12-18T14:22:04",
      idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
      fazenda: {
        nome: "FAZENDA VERA CRUZ",
        dataEntrada: "2022-05-13",
        dataSaida: "",
        latitude: "",
        longitude: "",
      },
      subdivisao: { nome: "INV1", dataEntrada: "2022-05-13", dataSaida: "" },
      idSistemaGestao: "2434023",
      idLocalizacaoBlockchain: "7087aa7d-bb7d-4790-b38c-4b4ebcb24484",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:22:04",
        dtAlteracao: "2023-12-18T14:22:04",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        fazenda: {
          nome: "FAZENDA VERA CRUZ",
          dataEntrada: "2022-02-09",
          dataSaida: "2022-05-13",
          latitude: "",
          longitude: "",
        },
        subdivisao: {
          nome: "PASTO4",
          dataEntrada: "2022-02-09",
          dataSaida: "2022-05-13",
        },
        idSistemaGestao: "2207713",
        idLocalizacaoBlockchain: "86fc23db-a66e-4d53-974b-ff75b001ff7f",
      },
      {
        dtCriacao: "2023-12-18T14:22:04",
        dtAlteracao: "2023-12-18T14:22:04",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        fazenda: {
          nome: "FAZENDA VERA CRUZ",
          dataEntrada: "2022-05-13",
          dataSaida: "2022-05-13",
          latitude: "",
          longitude: "",
        },
        subdivisao: {
          nome: "BAIA06",
          dataEntrada: "2022-05-13",
          dataSaida: "2022-05-13",
        },
        idSistemaGestao: "2434013",
        idLocalizacaoBlockchain: "e417832b-303f-4800-9777-bf16c49d82f1",
      },
      {
        dtCriacao: "2023-12-18T14:22:04",
        dtAlteracao: "2023-12-18T14:22:04",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        fazenda: {
          nome: "FAZENDA VERA CRUZ",
          dataEntrada: "2022-05-13",
          dataSaida: "",
          latitude: "",
          longitude: "",
        },
        subdivisao: { nome: "INV1", dataEntrada: "2022-05-13", dataSaida: "" },
        idSistemaGestao: "2434023",
        idLocalizacaoBlockchain: "7087aa7d-bb7d-4790-b38c-4b4ebcb24484",
      },
    ],
    loteAtual: {
      dtCriacao: "2023-12-18T14:21:50",
      dtAlteracao: "2023-12-18T14:21:50",
      idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
      nome: "VC2",
      dataEntrada: "2022-01-01",
      dataSaida: null,
      idSistemaGestao: "2302352",
      idLoteBlockchain: "2ce68086-3c8d-4c65-be64-c899003a33fd",
    },
    loteHistorico: [
      {
        dtCriacao: "2023-12-18T14:21:50",
        dtAlteracao: "2023-12-18T14:21:50",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        nome: "VC2",
        dataEntrada: "2022-01-01",
        dataSaida: null,
        idSistemaGestao: "2302352",
        idLoteBlockchain: "2ce68086-3c8d-4c65-be64-c899003a33fd",
      },
    ],
    idResponsavel: "e1227ca6-211b-4b43-b310-7288f3a945da",
    nomeResponsavel: "Wanderson Souza Nascimento",
    frigorifico: null,
  },
  "26130bd5-206e-44c0-a794-07d22bdd95ba": {
    idLoteSync: "8b507fea-7eb4-48f9-bf56-81c9b8654e8b",
    idBlockchain: "26130bd5-206e-44c0-a794-07d22bdd95ba",
    docType: "ANIMAL",
    idSistemaGestao: "4fd6d916-a8a0-42f1-a595-587599eac33d",
    idSisbov: "105520357181996",
    idICar: "",
    nome: "",
    dataNascimento: "2018-10-28",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "7181996",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:21:37",
      idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
      idSistemaGestao: "abffd06f-46aa-40f0-ab76-536ba071388c",
      idResponsavel: "e1227ca6-211b-4b43-b310-7288f3a945da",
      nomeResponsavel: "Wanderson Souza Nascimento",
      latitude: "",
      longitude: "",
      tipo: "Pesagem Simples",
      peso: 329,
      dtPesagem: "2022-05-13",
      idPesagemBlockchain: "2aa931fc-38a3-4101-8288-318cc9290861",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:21:37",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        idSistemaGestao: "abffd06f-46aa-40f0-ab76-536ba071388c",
        idResponsavel: "e1227ca6-211b-4b43-b310-7288f3a945da",
        nomeResponsavel: "Wanderson Souza Nascimento",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 329,
        dtPesagem: "2022-05-13",
        idPesagemBlockchain: "2aa931fc-38a3-4101-8288-318cc9290861",
      },
    ],
    produtorAtual: {
      idBlockchain: "46bd5b75-1cba-4da3-96ac-ad89f874846e",
      nome: "Wanderson",
      documento: "92374000125",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:25:22",
    dataUltimaAtualizacao: "2023-12-18T18:37:53",
    vendaAtual: {
      idBlockchain: "fd157fd6-8411-4f9a-aeda-aa0ff3777b01",
      documentoProprietarioDestino: "18293459000196",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "Wanderson", documento: "92374000125" },
      proprietarioDestino: {
        nome: "Guidara Indústria e Comércio de Alimentos Eireli",
        documento: "18293459000196",
      },
      dataCriacao: "2023-12-18T18:37:07",
      dataUltimaAtualizacao: "2023-12-18T18:37:53",
      status: "EFETIVADA",
      qtdAnimais: 15,
    },
    vendaHistorico: [
      {
        idBlockchain: "fd157fd6-8411-4f9a-aeda-aa0ff3777b01",
        documentoProprietarioDestino: "18293459000196",
        tipo: "INDUSTRIA",
        proprietarioOrigem: { nome: "Wanderson", documento: "92374000125" },
        proprietarioDestino: {
          nome: "Guidara Indústria e Comércio de Alimentos Eireli",
          documento: "18293459000196",
        },
        dataCriacao: "2023-12-18T18:37:07",
        dataUltimaAtualizacao: "2023-12-18T18:37:53",
        status: "EFETIVADA",
        qtdAnimais: 15,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:22:04",
      dtAlteracao: "2023-12-18T14:22:04",
      idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
      fazenda: {
        nome: "FAZENDA VERA CRUZ",
        dataEntrada: "2022-05-13",
        dataSaida: "",
        latitude: "",
        longitude: "",
      },
      subdivisao: { nome: "INV1", dataEntrada: "2022-05-13", dataSaida: "" },
      idSistemaGestao: "2434022",
      idLocalizacaoBlockchain: "3621040d-d522-47ed-8aba-a96bd1b04d1c",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:22:04",
        dtAlteracao: "2023-12-18T14:22:04",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        fazenda: {
          nome: "FAZENDA VERA CRUZ",
          dataEntrada: "2022-02-09",
          dataSaida: "2022-05-13",
          latitude: "",
          longitude: "",
        },
        subdivisao: {
          nome: "PASTO4",
          dataEntrada: "2022-02-09",
          dataSaida: "2022-05-13",
        },
        idSistemaGestao: "2207512",
        idLocalizacaoBlockchain: "051c74ec-adc9-4cf9-bd3e-1f1965f79402",
      },
      {
        dtCriacao: "2023-12-18T14:22:04",
        dtAlteracao: "2023-12-18T14:22:04",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        fazenda: {
          nome: "FAZENDA VERA CRUZ",
          dataEntrada: "2022-05-13",
          dataSaida: "2022-05-13",
          latitude: "",
          longitude: "",
        },
        subdivisao: {
          nome: "BAIA06",
          dataEntrada: "2022-05-13",
          dataSaida: "2022-05-13",
        },
        idSistemaGestao: "2434014",
        idLocalizacaoBlockchain: "8f2e96a3-f288-4749-86bc-534f072551c0",
      },
      {
        dtCriacao: "2023-12-18T14:22:04",
        dtAlteracao: "2023-12-18T14:22:04",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        fazenda: {
          nome: "FAZENDA VERA CRUZ",
          dataEntrada: "2022-05-13",
          dataSaida: "",
          latitude: "",
          longitude: "",
        },
        subdivisao: { nome: "INV1", dataEntrada: "2022-05-13", dataSaida: "" },
        idSistemaGestao: "2434022",
        idLocalizacaoBlockchain: "3621040d-d522-47ed-8aba-a96bd1b04d1c",
      },
    ],
    loteAtual: {
      dtCriacao: "2023-12-18T14:21:50",
      dtAlteracao: "2023-12-18T14:21:50",
      idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
      nome: "VERA CRUZ",
      dataEntrada: "2022-01-01",
      dataSaida: null,
      idSistemaGestao: "2299322",
      idLoteBlockchain: "c19696a8-9c70-40bf-a254-7d57ad69bee7",
    },
    loteHistorico: [
      {
        dtCriacao: "2023-12-18T14:21:50",
        dtAlteracao: "2023-12-18T14:21:50",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        nome: "VERA CRUZ",
        dataEntrada: "2022-01-01",
        dataSaida: null,
        idSistemaGestao: "2299322",
        idLoteBlockchain: "c19696a8-9c70-40bf-a254-7d57ad69bee7",
      },
    ],
    idResponsavel: "e1227ca6-211b-4b43-b310-7288f3a945da",
    nomeResponsavel: "Wanderson Souza Nascimento",
    frigorifico: null,
  },
  "2884c2ec-58cd-4222-a028-656c668076c6": {
    idLoteSync: "8b507fea-7eb4-48f9-bf56-81c9b8654e8b",
    idBlockchain: "2884c2ec-58cd-4222-a028-656c668076c6",
    docType: "ANIMAL",
    idSistemaGestao: "c2f39b3d-df2b-4080-9c87-84d73b4478a9",
    idSisbov: "105520357183476",
    idICar: "",
    nome: "",
    dataNascimento: "2018-09-28",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "7183476",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:21:37",
      idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
      idSistemaGestao: "34ad01f6-e16f-41a7-82fc-dd6d96c90f4e",
      idResponsavel: "e1227ca6-211b-4b43-b310-7288f3a945da",
      nomeResponsavel: "Wanderson Souza Nascimento",
      latitude: "",
      longitude: "",
      tipo: "Pesagem Simples",
      peso: 340,
      dtPesagem: "2022-05-13",
      idPesagemBlockchain: "93932d12-7f7d-430f-885a-773fc460afca",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:21:37",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        idSistemaGestao: "34ad01f6-e16f-41a7-82fc-dd6d96c90f4e",
        idResponsavel: "e1227ca6-211b-4b43-b310-7288f3a945da",
        nomeResponsavel: "Wanderson Souza Nascimento",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 340,
        dtPesagem: "2022-05-13",
        idPesagemBlockchain: "93932d12-7f7d-430f-885a-773fc460afca",
      },
    ],
    produtorAtual: {
      idBlockchain: "46bd5b75-1cba-4da3-96ac-ad89f874846e",
      nome: "Wanderson",
      documento: "92374000125",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:25:22",
    dataUltimaAtualizacao: "2023-12-18T18:37:53",
    vendaAtual: {
      idBlockchain: "fd157fd6-8411-4f9a-aeda-aa0ff3777b01",
      documentoProprietarioDestino: "18293459000196",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "Wanderson", documento: "92374000125" },
      proprietarioDestino: {
        nome: "Guidara Indústria e Comércio de Alimentos Eireli",
        documento: "18293459000196",
      },
      dataCriacao: "2023-12-18T18:37:07",
      dataUltimaAtualizacao: "2023-12-18T18:37:53",
      status: "EFETIVADA",
      qtdAnimais: 15,
    },
    vendaHistorico: [
      {
        idBlockchain: "fd157fd6-8411-4f9a-aeda-aa0ff3777b01",
        documentoProprietarioDestino: "18293459000196",
        tipo: "INDUSTRIA",
        proprietarioOrigem: { nome: "Wanderson", documento: "92374000125" },
        proprietarioDestino: {
          nome: "Guidara Indústria e Comércio de Alimentos Eireli",
          documento: "18293459000196",
        },
        dataCriacao: "2023-12-18T18:37:07",
        dataUltimaAtualizacao: "2023-12-18T18:37:53",
        status: "EFETIVADA",
        qtdAnimais: 15,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:22:04",
      dtAlteracao: "2023-12-18T14:22:04",
      idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
      fazenda: {
        nome: "FAZENDA VERA CRUZ",
        dataEntrada: "2022-05-13",
        dataSaida: "",
        latitude: "",
        longitude: "",
      },
      subdivisao: { nome: "INV1", dataEntrada: "2022-05-13", dataSaida: "" },
      idSistemaGestao: "2434024",
      idLocalizacaoBlockchain: "86224ef5-74f0-4ace-a88d-1b453b042f96",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:22:04",
        dtAlteracao: "2023-12-18T14:22:04",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        fazenda: {
          nome: "FAZENDA VERA CRUZ",
          dataEntrada: "2022-02-09",
          dataSaida: "2022-05-13",
          latitude: "",
          longitude: "",
        },
        subdivisao: {
          nome: "PASTO4",
          dataEntrada: "2022-02-09",
          dataSaida: "2022-05-13",
        },
        idSistemaGestao: "2207883",
        idLocalizacaoBlockchain: "df8e43d5-8a90-4a1f-a340-b710e2a62fa5",
      },
      {
        dtCriacao: "2023-12-18T14:22:04",
        dtAlteracao: "2023-12-18T14:22:04",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        fazenda: {
          nome: "FAZENDA VERA CRUZ",
          dataEntrada: "2022-05-13",
          dataSaida: "2022-05-13",
          latitude: "",
          longitude: "",
        },
        subdivisao: {
          nome: "BAIA06",
          dataEntrada: "2022-05-13",
          dataSaida: "2022-05-13",
        },
        idSistemaGestao: "2434010",
        idLocalizacaoBlockchain: "86afb44c-a064-4042-a7a4-c834d5f763fa",
      },
      {
        dtCriacao: "2023-12-18T14:22:04",
        dtAlteracao: "2023-12-18T14:22:04",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        fazenda: {
          nome: "FAZENDA VERA CRUZ",
          dataEntrada: "2022-05-13",
          dataSaida: "",
          latitude: "",
          longitude: "",
        },
        subdivisao: { nome: "INV1", dataEntrada: "2022-05-13", dataSaida: "" },
        idSistemaGestao: "2434024",
        idLocalizacaoBlockchain: "86224ef5-74f0-4ace-a88d-1b453b042f96",
      },
    ],
    loteAtual: {
      dtCriacao: "2023-12-18T14:21:50",
      dtAlteracao: "2023-12-18T14:21:50",
      idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
      nome: "VC2",
      dataEntrada: "2022-01-01",
      dataSaida: null,
      idSistemaGestao: "2304091",
      idLoteBlockchain: "56949089-1fe2-4108-9875-46dd7f3fc2b4",
    },
    loteHistorico: [
      {
        dtCriacao: "2023-12-18T14:21:50",
        dtAlteracao: "2023-12-18T14:21:50",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        nome: "VC2",
        dataEntrada: "2022-01-01",
        dataSaida: null,
        idSistemaGestao: "2304091",
        idLoteBlockchain: "56949089-1fe2-4108-9875-46dd7f3fc2b4",
      },
    ],
    idResponsavel: "e1227ca6-211b-4b43-b310-7288f3a945da",
    nomeResponsavel: "Wanderson Souza Nascimento",
    frigorifico: null,
  },
  "2aafa97a-1bf9-42e9-b45f-0c281fa0eaf0": {
    idLoteSync: "8b507fea-7eb4-48f9-bf56-81c9b8654e8b",
    idBlockchain: "2aafa97a-1bf9-42e9-b45f-0c281fa0eaf0",
    docType: "ANIMAL",
    idSistemaGestao: "17a304ec-d2dc-448e-9b8c-0da12bcd54e3",
    idSisbov: "105520357181163",
    idICar: "",
    nome: "",
    dataNascimento: "2018-09-27",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "7181163",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:21:37",
      idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
      idSistemaGestao: "dcaca266-46a5-47b1-acc1-754382348ba5",
      idResponsavel: "e1227ca6-211b-4b43-b310-7288f3a945da",
      nomeResponsavel: "Wanderson Souza Nascimento",
      latitude: "",
      longitude: "",
      tipo: "Pesagem Simples",
      peso: 357,
      dtPesagem: "2022-05-13",
      idPesagemBlockchain: "834b5c2e-e7da-423e-b866-3f82567414c0",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:21:37",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        idSistemaGestao: "dcaca266-46a5-47b1-acc1-754382348ba5",
        idResponsavel: "e1227ca6-211b-4b43-b310-7288f3a945da",
        nomeResponsavel: "Wanderson Souza Nascimento",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 357,
        dtPesagem: "2022-05-13",
        idPesagemBlockchain: "834b5c2e-e7da-423e-b866-3f82567414c0",
      },
    ],
    produtorAtual: {
      idBlockchain: "46bd5b75-1cba-4da3-96ac-ad89f874846e",
      nome: "Wanderson",
      documento: "92374000125",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:25:22",
    dataUltimaAtualizacao: "2023-12-18T18:37:53",
    vendaAtual: {
      idBlockchain: "fd157fd6-8411-4f9a-aeda-aa0ff3777b01",
      documentoProprietarioDestino: "18293459000196",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "Wanderson", documento: "92374000125" },
      proprietarioDestino: {
        nome: "Guidara Indústria e Comércio de Alimentos Eireli",
        documento: "18293459000196",
      },
      dataCriacao: "2023-12-18T18:37:07",
      dataUltimaAtualizacao: "2023-12-18T18:37:53",
      status: "EFETIVADA",
      qtdAnimais: 15,
    },
    vendaHistorico: [
      {
        idBlockchain: "fd157fd6-8411-4f9a-aeda-aa0ff3777b01",
        documentoProprietarioDestino: "18293459000196",
        tipo: "INDUSTRIA",
        proprietarioOrigem: { nome: "Wanderson", documento: "92374000125" },
        proprietarioDestino: {
          nome: "Guidara Indústria e Comércio de Alimentos Eireli",
          documento: "18293459000196",
        },
        dataCriacao: "2023-12-18T18:37:07",
        dataUltimaAtualizacao: "2023-12-18T18:37:53",
        status: "EFETIVADA",
        qtdAnimais: 15,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:22:04",
      dtAlteracao: "2023-12-18T14:22:04",
      idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
      fazenda: {
        nome: "FAZENDA VERA CRUZ",
        dataEntrada: "2022-05-13",
        dataSaida: "",
        latitude: "",
        longitude: "",
      },
      subdivisao: { nome: "INV1", dataEntrada: "2022-05-13", dataSaida: "" },
      idSistemaGestao: "2434016",
      idLocalizacaoBlockchain: "1956d6d9-1809-4caa-a911-bd9bb64b511b",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:22:04",
        dtAlteracao: "2023-12-18T14:22:04",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        fazenda: {
          nome: "FAZENDA VERA CRUZ",
          dataEntrada: "2022-02-09",
          dataSaida: "2022-05-13",
          latitude: "",
          longitude: "",
        },
        subdivisao: {
          nome: "PASTO4",
          dataEntrada: "2022-02-09",
          dataSaida: "2022-05-13",
        },
        idSistemaGestao: "2207348",
        idLocalizacaoBlockchain: "ab5c6a95-4092-4258-96cc-d6dd499c4153",
      },
      {
        dtCriacao: "2023-12-18T14:22:04",
        dtAlteracao: "2023-12-18T14:22:04",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        fazenda: {
          nome: "FAZENDA VERA CRUZ",
          dataEntrada: "2022-05-13",
          dataSaida: "2022-05-13",
          latitude: "",
          longitude: "",
        },
        subdivisao: {
          nome: "BAIA06",
          dataEntrada: "2022-05-13",
          dataSaida: "2022-05-13",
        },
        idSistemaGestao: "2434009",
        idLocalizacaoBlockchain: "6e033765-bf30-468e-a852-3296b754a807",
      },
      {
        dtCriacao: "2023-12-18T14:22:04",
        dtAlteracao: "2023-12-18T14:22:04",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        fazenda: {
          nome: "FAZENDA VERA CRUZ",
          dataEntrada: "2022-05-13",
          dataSaida: "",
          latitude: "",
          longitude: "",
        },
        subdivisao: { nome: "INV1", dataEntrada: "2022-05-13", dataSaida: "" },
        idSistemaGestao: "2434016",
        idLocalizacaoBlockchain: "1956d6d9-1809-4caa-a911-bd9bb64b511b",
      },
    ],
    loteAtual: {
      dtCriacao: "2023-12-18T14:21:50",
      dtAlteracao: "2023-12-18T14:21:50",
      idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
      nome: "VERA CRUZ",
      dataEntrada: "2022-01-01",
      dataSaida: null,
      idSistemaGestao: "2297313",
      idLoteBlockchain: "8a01c6e2-4229-460e-9823-ef85b996be29",
    },
    loteHistorico: [
      {
        dtCriacao: "2023-12-18T14:21:50",
        dtAlteracao: "2023-12-18T14:21:50",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        nome: "VERA CRUZ",
        dataEntrada: "2022-01-01",
        dataSaida: null,
        idSistemaGestao: "2297313",
        idLoteBlockchain: "8a01c6e2-4229-460e-9823-ef85b996be29",
      },
    ],
    idResponsavel: "e1227ca6-211b-4b43-b310-7288f3a945da",
    nomeResponsavel: "Wanderson Souza Nascimento",
    frigorifico: null,
  },
  "3420e57e-2dc0-4723-a969-973385084d63": {
    idLoteSync: "8b507fea-7eb4-48f9-bf56-81c9b8654e8b",
    idBlockchain: "3420e57e-2dc0-4723-a969-973385084d63",
    docType: "ANIMAL",
    idSistemaGestao: "001839d0-5ba2-4015-a1d8-dbc72d61cd21",
    idSisbov: "105520374386525",
    idICar: "",
    nome: "",
    dataNascimento: "2019-10-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "4386525",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Composto", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "46bd5b75-1cba-4da3-96ac-ad89f874846e",
      nome: "Wanderson",
      documento: "92374000125",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:25:22",
    dataUltimaAtualizacao: "2023-12-18T18:37:53",
    vendaAtual: {
      idBlockchain: "fd157fd6-8411-4f9a-aeda-aa0ff3777b01",
      documentoProprietarioDestino: "18293459000196",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "Wanderson", documento: "92374000125" },
      proprietarioDestino: {
        nome: "Guidara Indústria e Comércio de Alimentos Eireli",
        documento: "18293459000196",
      },
      dataCriacao: "2023-12-18T18:37:07",
      dataUltimaAtualizacao: "2023-12-18T18:37:53",
      status: "EFETIVADA",
      qtdAnimais: 15,
    },
    vendaHistorico: [
      {
        idBlockchain: "fd157fd6-8411-4f9a-aeda-aa0ff3777b01",
        documentoProprietarioDestino: "18293459000196",
        tipo: "INDUSTRIA",
        proprietarioOrigem: { nome: "Wanderson", documento: "92374000125" },
        proprietarioDestino: {
          nome: "Guidara Indústria e Comércio de Alimentos Eireli",
          documento: "18293459000196",
        },
        dataCriacao: "2023-12-18T18:37:07",
        dataUltimaAtualizacao: "2023-12-18T18:37:53",
        status: "EFETIVADA",
        qtdAnimais: 15,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:22:04",
      dtAlteracao: "2023-12-18T14:22:04",
      idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
      fazenda: {
        nome: "FAZENDA VERA CRUZ",
        dataEntrada: "2022-06-27",
        dataSaida: "",
        latitude: "",
        longitude: "",
      },
      subdivisao: { nome: "INV1", dataEntrada: "2022-06-27", dataSaida: "" },
      idSistemaGestao: "2654078",
      idLocalizacaoBlockchain: "82cc04b6-bff7-4b9d-a059-3f78fdcc4617",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:22:04",
        dtAlteracao: "2023-12-18T14:22:04",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        fazenda: {
          nome: "FAZENDA VERA CRUZ",
          dataEntrada: "2022-01-01",
          dataSaida: "2022-06-27",
          latitude: "",
          longitude: "",
        },
        subdivisao: {
          nome: "A1",
          dataEntrada: "2022-01-01",
          dataSaida: "2022-06-27",
        },
        idSistemaGestao: "1978156",
        idLocalizacaoBlockchain: "94f954cf-1b2d-4d5e-8b64-321471cfb804",
      },
      {
        dtCriacao: "2023-12-18T14:22:04",
        dtAlteracao: "2023-12-18T14:22:04",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        fazenda: {
          nome: "FAZENDA VERA CRUZ",
          dataEntrada: "2022-06-27",
          dataSaida: "",
          latitude: "",
          longitude: "",
        },
        subdivisao: { nome: "INV1", dataEntrada: "2022-06-27", dataSaida: "" },
        idSistemaGestao: "2654078",
        idLocalizacaoBlockchain: "82cc04b6-bff7-4b9d-a059-3f78fdcc4617",
      },
    ],
    loteAtual: {
      dtCriacao: "2023-12-18T14:21:50",
      dtAlteracao: "2023-12-18T14:21:50",
      idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
      nome: "VERA CRUZ",
      dataEntrada: "2022-01-01",
      dataSaida: null,
      idSistemaGestao: "2296465",
      idLoteBlockchain: "0f05f6d1-f049-4f1b-b9ef-1142a4a83bc7",
    },
    loteHistorico: [
      {
        dtCriacao: "2023-12-18T14:21:50",
        dtAlteracao: "2023-12-18T14:21:50",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        nome: "VERA CRUZ",
        dataEntrada: "2022-01-01",
        dataSaida: null,
        idSistemaGestao: "2296465",
        idLoteBlockchain: "0f05f6d1-f049-4f1b-b9ef-1142a4a83bc7",
      },
    ],
    idResponsavel: "e1227ca6-211b-4b43-b310-7288f3a945da",
    nomeResponsavel: "Wanderson Souza Nascimento",
    frigorifico: null,
  },
  "38fc5b5a-4795-46dc-bb7d-85277444fa72": {
    idLoteSync: "8b507fea-7eb4-48f9-bf56-81c9b8654e8b",
    idBlockchain: "38fc5b5a-4795-46dc-bb7d-85277444fa72",
    docType: "ANIMAL",
    idSistemaGestao: "4a95ceee-82f0-4257-9855-b878df4e196e",
    idSisbov: "105520357182380",
    idICar: "",
    nome: "",
    dataNascimento: "2018-09-28",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "7182380",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:21:37",
      idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
      idSistemaGestao: "d0365001-a253-48fe-80be-68430c1b7d4a",
      idResponsavel: "e1227ca6-211b-4b43-b310-7288f3a945da",
      nomeResponsavel: "Wanderson Souza Nascimento",
      latitude: "",
      longitude: "",
      tipo: "Pesagem Simples",
      peso: 354,
      dtPesagem: "2022-05-13",
      idPesagemBlockchain: "7632aca6-eb1b-4eab-aac5-5c6226034063",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:21:37",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        idSistemaGestao: "d0365001-a253-48fe-80be-68430c1b7d4a",
        idResponsavel: "e1227ca6-211b-4b43-b310-7288f3a945da",
        nomeResponsavel: "Wanderson Souza Nascimento",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 354,
        dtPesagem: "2022-05-13",
        idPesagemBlockchain: "7632aca6-eb1b-4eab-aac5-5c6226034063",
      },
    ],
    produtorAtual: {
      idBlockchain: "46bd5b75-1cba-4da3-96ac-ad89f874846e",
      nome: "Wanderson",
      documento: "92374000125",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:25:22",
    dataUltimaAtualizacao: "2023-12-18T18:37:53",
    vendaAtual: {
      idBlockchain: "fd157fd6-8411-4f9a-aeda-aa0ff3777b01",
      documentoProprietarioDestino: "18293459000196",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "Wanderson", documento: "92374000125" },
      proprietarioDestino: {
        nome: "Guidara Indústria e Comércio de Alimentos Eireli",
        documento: "18293459000196",
      },
      dataCriacao: "2023-12-18T18:37:07",
      dataUltimaAtualizacao: "2023-12-18T18:37:53",
      status: "EFETIVADA",
      qtdAnimais: 15,
    },
    vendaHistorico: [
      {
        idBlockchain: "fd157fd6-8411-4f9a-aeda-aa0ff3777b01",
        documentoProprietarioDestino: "18293459000196",
        tipo: "INDUSTRIA",
        proprietarioOrigem: { nome: "Wanderson", documento: "92374000125" },
        proprietarioDestino: {
          nome: "Guidara Indústria e Comércio de Alimentos Eireli",
          documento: "18293459000196",
        },
        dataCriacao: "2023-12-18T18:37:07",
        dataUltimaAtualizacao: "2023-12-18T18:37:53",
        status: "EFETIVADA",
        qtdAnimais: 15,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:22:04",
      dtAlteracao: "2023-12-18T14:22:04",
      idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
      fazenda: {
        nome: "FAZENDA VERA CRUZ",
        dataEntrada: "2022-05-13",
        dataSaida: "",
        latitude: "",
        longitude: "",
      },
      subdivisao: { nome: "INV1", dataEntrada: "2022-05-13", dataSaida: "" },
      idSistemaGestao: "2434021",
      idLocalizacaoBlockchain: "4797e9cb-6a3d-4aae-99ad-2d50b4b83b0d",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:22:04",
        dtAlteracao: "2023-12-18T14:22:04",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        fazenda: {
          nome: "FAZENDA VERA CRUZ",
          dataEntrada: "2022-02-09",
          dataSaida: "2022-05-13",
          latitude: "",
          longitude: "",
        },
        subdivisao: {
          nome: "PASTO4",
          dataEntrada: "2022-02-09",
          dataSaida: "2022-05-13",
        },
        idSistemaGestao: "2207491",
        idLocalizacaoBlockchain: "8ecb4e23-be86-4a92-825c-85b75ae05e1c",
      },
      {
        dtCriacao: "2023-12-18T14:22:04",
        dtAlteracao: "2023-12-18T14:22:04",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        fazenda: {
          nome: "FAZENDA VERA CRUZ",
          dataEntrada: "2022-05-13",
          dataSaida: "2022-05-13",
          latitude: "",
          longitude: "",
        },
        subdivisao: {
          nome: "BAIA06",
          dataEntrada: "2022-05-13",
          dataSaida: "2022-05-13",
        },
        idSistemaGestao: "2434011",
        idLocalizacaoBlockchain: "d279526d-fa81-4a29-a6e9-89fbae2bc735",
      },
      {
        dtCriacao: "2023-12-18T14:22:04",
        dtAlteracao: "2023-12-18T14:22:04",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        fazenda: {
          nome: "FAZENDA VERA CRUZ",
          dataEntrada: "2022-05-13",
          dataSaida: "",
          latitude: "",
          longitude: "",
        },
        subdivisao: { nome: "INV1", dataEntrada: "2022-05-13", dataSaida: "" },
        idSistemaGestao: "2434021",
        idLocalizacaoBlockchain: "4797e9cb-6a3d-4aae-99ad-2d50b4b83b0d",
      },
    ],
    loteAtual: {
      dtCriacao: "2023-12-18T14:21:50",
      dtAlteracao: "2023-12-18T14:21:50",
      idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
      nome: "VERA CRUZ",
      dataEntrada: "2022-01-01",
      dataSaida: null,
      idSistemaGestao: "2299131",
      idLoteBlockchain: "b98e3c6a-1bf9-476c-9302-908df0a764a9",
    },
    loteHistorico: [
      {
        dtCriacao: "2023-12-18T14:21:50",
        dtAlteracao: "2023-12-18T14:21:50",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        nome: "VERA CRUZ",
        dataEntrada: "2022-01-01",
        dataSaida: null,
        idSistemaGestao: "2299131",
        idLoteBlockchain: "b98e3c6a-1bf9-476c-9302-908df0a764a9",
      },
    ],
    idResponsavel: "e1227ca6-211b-4b43-b310-7288f3a945da",
    nomeResponsavel: "Wanderson Souza Nascimento",
    frigorifico: null,
  },
  "451e1ae3-1e1a-4949-a5a4-71ccaec3c49f": {
    idLoteSync: "8b507fea-7eb4-48f9-bf56-81c9b8654e8b",
    idBlockchain: "451e1ae3-1e1a-4949-a5a4-71ccaec3c49f",
    docType: "ANIMAL",
    idSistemaGestao: "00f9d5a3-26a8-418f-9656-83fb6728bcf6",
    idSisbov: "105520374370190",
    idICar: "",
    nome: "",
    dataNascimento: "2019-09-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "4370190",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Composto", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "46bd5b75-1cba-4da3-96ac-ad89f874846e",
      nome: "Wanderson",
      documento: "92374000125",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:25:22",
    dataUltimaAtualizacao: "2023-12-18T18:37:53",
    vendaAtual: {
      idBlockchain: "fd157fd6-8411-4f9a-aeda-aa0ff3777b01",
      documentoProprietarioDestino: "18293459000196",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "Wanderson", documento: "92374000125" },
      proprietarioDestino: {
        nome: "Guidara Indústria e Comércio de Alimentos Eireli",
        documento: "18293459000196",
      },
      dataCriacao: "2023-12-18T18:37:07",
      dataUltimaAtualizacao: "2023-12-18T18:37:53",
      status: "EFETIVADA",
      qtdAnimais: 15,
    },
    vendaHistorico: [
      {
        idBlockchain: "fd157fd6-8411-4f9a-aeda-aa0ff3777b01",
        documentoProprietarioDestino: "18293459000196",
        tipo: "INDUSTRIA",
        proprietarioOrigem: { nome: "Wanderson", documento: "92374000125" },
        proprietarioDestino: {
          nome: "Guidara Indústria e Comércio de Alimentos Eireli",
          documento: "18293459000196",
        },
        dataCriacao: "2023-12-18T18:37:07",
        dataUltimaAtualizacao: "2023-12-18T18:37:53",
        status: "EFETIVADA",
        qtdAnimais: 15,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:22:04",
      dtAlteracao: "2023-12-18T14:22:04",
      idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
      fazenda: {
        nome: "FAZENDA VERA CRUZ",
        dataEntrada: "2022-06-27",
        dataSaida: "",
        latitude: "",
        longitude: "",
      },
      subdivisao: { nome: "INV1", dataEntrada: "2022-06-27", dataSaida: "" },
      idSistemaGestao: "2654082",
      idLocalizacaoBlockchain: "97eeb6c5-3140-46b1-b3ab-515f90eb82d2",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:22:04",
        dtAlteracao: "2023-12-18T14:22:04",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        fazenda: {
          nome: "FAZENDA VERA CRUZ",
          dataEntrada: "2022-01-01",
          dataSaida: "2022-06-27",
          latitude: "",
          longitude: "",
        },
        subdivisao: {
          nome: "A1",
          dataEntrada: "2022-01-01",
          dataSaida: "2022-06-27",
        },
        idSistemaGestao: "1978160",
        idLocalizacaoBlockchain: "60a40e42-c46a-46bc-bfa6-2ce167ededb6",
      },
      {
        dtCriacao: "2023-12-18T14:22:04",
        dtAlteracao: "2023-12-18T14:22:04",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        fazenda: {
          nome: "FAZENDA VERA CRUZ",
          dataEntrada: "2022-06-27",
          dataSaida: "",
          latitude: "",
          longitude: "",
        },
        subdivisao: { nome: "INV1", dataEntrada: "2022-06-27", dataSaida: "" },
        idSistemaGestao: "2654082",
        idLocalizacaoBlockchain: "97eeb6c5-3140-46b1-b3ab-515f90eb82d2",
      },
    ],
    loteAtual: {
      dtCriacao: "2023-12-18T14:21:50",
      dtAlteracao: "2023-12-18T14:21:50",
      idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
      nome: "VERA CRUZ",
      dataEntrada: "2022-01-01",
      dataSaida: null,
      idSistemaGestao: "2296487",
      idLoteBlockchain: "45a2f65e-2c7c-496c-80ba-214972c71682",
    },
    loteHistorico: [
      {
        dtCriacao: "2023-12-18T14:21:50",
        dtAlteracao: "2023-12-18T14:21:50",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        nome: "VERA CRUZ",
        dataEntrada: "2022-01-01",
        dataSaida: null,
        idSistemaGestao: "2296487",
        idLoteBlockchain: "45a2f65e-2c7c-496c-80ba-214972c71682",
      },
    ],
    idResponsavel: "e1227ca6-211b-4b43-b310-7288f3a945da",
    nomeResponsavel: "Wanderson Souza Nascimento",
    frigorifico: null,
  },
  "5c9df962-05f7-401a-b582-ae227504a2d8": {
    idLoteSync: "8b507fea-7eb4-48f9-bf56-81c9b8654e8b",
    idBlockchain: "5c9df962-05f7-401a-b582-ae227504a2d8",
    docType: "ANIMAL",
    idSistemaGestao: "3bae2fd5-edff-47b1-b86b-fd49608e5024",
    idSisbov: "105520357182097",
    idICar: "",
    nome: "",
    dataNascimento: "2018-08-28",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "7182097",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:21:37",
      idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
      idSistemaGestao: "14f0b162-6929-4d8d-87c1-9c7ea38a6170",
      idResponsavel: "e1227ca6-211b-4b43-b310-7288f3a945da",
      nomeResponsavel: "Wanderson Souza Nascimento",
      latitude: "",
      longitude: "",
      tipo: "Pesagem Simples",
      peso: 360,
      dtPesagem: "2022-05-13",
      idPesagemBlockchain: "d022f026-c599-495f-b1f1-a2c6122fa595",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:21:37",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        idSistemaGestao: "14f0b162-6929-4d8d-87c1-9c7ea38a6170",
        idResponsavel: "e1227ca6-211b-4b43-b310-7288f3a945da",
        nomeResponsavel: "Wanderson Souza Nascimento",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 360,
        dtPesagem: "2022-05-13",
        idPesagemBlockchain: "d022f026-c599-495f-b1f1-a2c6122fa595",
      },
    ],
    produtorAtual: {
      idBlockchain: "46bd5b75-1cba-4da3-96ac-ad89f874846e",
      nome: "Wanderson",
      documento: "92374000125",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:25:22",
    dataUltimaAtualizacao: "2023-12-18T18:37:53",
    vendaAtual: {
      idBlockchain: "fd157fd6-8411-4f9a-aeda-aa0ff3777b01",
      documentoProprietarioDestino: "18293459000196",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "Wanderson", documento: "92374000125" },
      proprietarioDestino: {
        nome: "Guidara Indústria e Comércio de Alimentos Eireli",
        documento: "18293459000196",
      },
      dataCriacao: "2023-12-18T18:37:07",
      dataUltimaAtualizacao: "2023-12-18T18:37:53",
      status: "EFETIVADA",
      qtdAnimais: 15,
    },
    vendaHistorico: [
      {
        idBlockchain: "fd157fd6-8411-4f9a-aeda-aa0ff3777b01",
        documentoProprietarioDestino: "18293459000196",
        tipo: "INDUSTRIA",
        proprietarioOrigem: { nome: "Wanderson", documento: "92374000125" },
        proprietarioDestino: {
          nome: "Guidara Indústria e Comércio de Alimentos Eireli",
          documento: "18293459000196",
        },
        dataCriacao: "2023-12-18T18:37:07",
        dataUltimaAtualizacao: "2023-12-18T18:37:53",
        status: "EFETIVADA",
        qtdAnimais: 15,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:22:04",
      dtAlteracao: "2023-12-18T14:22:04",
      idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
      fazenda: {
        nome: "FAZENDA VERA CRUZ",
        dataEntrada: "2022-05-13",
        dataSaida: "",
        latitude: "",
        longitude: "",
      },
      subdivisao: { nome: "INV1", dataEntrada: "2022-05-13", dataSaida: "" },
      idSistemaGestao: "2434019",
      idLocalizacaoBlockchain: "a9947386-bd22-42cb-b440-e1b8f0c5138e",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:22:04",
        dtAlteracao: "2023-12-18T14:22:04",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        fazenda: {
          nome: "FAZENDA VERA CRUZ",
          dataEntrada: "2022-02-09",
          dataSaida: "2022-05-13",
          latitude: "",
          longitude: "",
        },
        subdivisao: {
          nome: "PASTO4",
          dataEntrada: "2022-02-09",
          dataSaida: "2022-05-13",
        },
        idSistemaGestao: "2207446",
        idLocalizacaoBlockchain: "e8c8e683-05f5-421a-ac10-8513e49d142d",
      },
      {
        dtCriacao: "2023-12-18T14:22:04",
        dtAlteracao: "2023-12-18T14:22:04",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        fazenda: {
          nome: "FAZENDA VERA CRUZ",
          dataEntrada: "2022-05-13",
          dataSaida: "2022-05-13",
          latitude: "",
          longitude: "",
        },
        subdivisao: {
          nome: "BAIA06",
          dataEntrada: "2022-05-13",
          dataSaida: "2022-05-13",
        },
        idSistemaGestao: "2434006",
        idLocalizacaoBlockchain: "b8f26e21-1c30-4151-90d6-e65f6311c93b",
      },
      {
        dtCriacao: "2023-12-18T14:22:04",
        dtAlteracao: "2023-12-18T14:22:04",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        fazenda: {
          nome: "FAZENDA VERA CRUZ",
          dataEntrada: "2022-05-13",
          dataSaida: "",
          latitude: "",
          longitude: "",
        },
        subdivisao: { nome: "INV1", dataEntrada: "2022-05-13", dataSaida: "" },
        idSistemaGestao: "2434019",
        idLocalizacaoBlockchain: "a9947386-bd22-42cb-b440-e1b8f0c5138e",
      },
    ],
    loteAtual: {
      dtCriacao: "2023-12-18T14:21:50",
      dtAlteracao: "2023-12-18T14:21:50",
      idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
      nome: "VERA CRUZ",
      dataEntrada: "2022-01-01",
      dataSaida: null,
      idSistemaGestao: "2298594",
      idLoteBlockchain: "3e18ff0c-d49d-4ac9-8d7b-380d4de9b579",
    },
    loteHistorico: [
      {
        dtCriacao: "2023-12-18T14:21:50",
        dtAlteracao: "2023-12-18T14:21:50",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        nome: "VERA CRUZ",
        dataEntrada: "2022-01-01",
        dataSaida: null,
        idSistemaGestao: "2298594",
        idLoteBlockchain: "3e18ff0c-d49d-4ac9-8d7b-380d4de9b579",
      },
    ],
    idResponsavel: "e1227ca6-211b-4b43-b310-7288f3a945da",
    nomeResponsavel: "Wanderson Souza Nascimento",
    frigorifico: null,
  },
  "625c3c30-be10-4586-9cb9-a4d1e83d0324": {
    idLoteSync: "8b507fea-7eb4-48f9-bf56-81c9b8654e8b",
    idBlockchain: "625c3c30-be10-4586-9cb9-a4d1e83d0324",
    docType: "ANIMAL",
    idSistemaGestao: "2a08268d-0bc0-4b50-81c7-37ddd7bee958",
    idSisbov: "105520357182585",
    idICar: "",
    nome: "",
    dataNascimento: "2018-09-28",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "7182585",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:21:37",
      idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
      idSistemaGestao: "61b15f9c-836a-4f64-af05-38713d8cb9ff",
      idResponsavel: "e1227ca6-211b-4b43-b310-7288f3a945da",
      nomeResponsavel: "Wanderson Souza Nascimento",
      latitude: "",
      longitude: "",
      tipo: "Pesagem Simples",
      peso: 360,
      dtPesagem: "2022-05-13",
      idPesagemBlockchain: "5617d9ab-970f-43b2-bd93-3ca0353b9765",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:21:37",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        idSistemaGestao: "61b15f9c-836a-4f64-af05-38713d8cb9ff",
        idResponsavel: "e1227ca6-211b-4b43-b310-7288f3a945da",
        nomeResponsavel: "Wanderson Souza Nascimento",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 360,
        dtPesagem: "2022-05-13",
        idPesagemBlockchain: "5617d9ab-970f-43b2-bd93-3ca0353b9765",
      },
    ],
    produtorAtual: {
      idBlockchain: "46bd5b75-1cba-4da3-96ac-ad89f874846e",
      nome: "Wanderson",
      documento: "92374000125",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:25:22",
    dataUltimaAtualizacao: "2023-12-18T18:37:53",
    vendaAtual: {
      idBlockchain: "fd157fd6-8411-4f9a-aeda-aa0ff3777b01",
      documentoProprietarioDestino: "18293459000196",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "Wanderson", documento: "92374000125" },
      proprietarioDestino: {
        nome: "Guidara Indústria e Comércio de Alimentos Eireli",
        documento: "18293459000196",
      },
      dataCriacao: "2023-12-18T18:37:07",
      dataUltimaAtualizacao: "2023-12-18T18:37:53",
      status: "EFETIVADA",
      qtdAnimais: 15,
    },
    vendaHistorico: [
      {
        idBlockchain: "fd157fd6-8411-4f9a-aeda-aa0ff3777b01",
        documentoProprietarioDestino: "18293459000196",
        tipo: "INDUSTRIA",
        proprietarioOrigem: { nome: "Wanderson", documento: "92374000125" },
        proprietarioDestino: {
          nome: "Guidara Indústria e Comércio de Alimentos Eireli",
          documento: "18293459000196",
        },
        dataCriacao: "2023-12-18T18:37:07",
        dataUltimaAtualizacao: "2023-12-18T18:37:53",
        status: "EFETIVADA",
        qtdAnimais: 15,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:22:04",
      dtAlteracao: "2023-12-18T14:22:04",
      idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
      fazenda: {
        nome: "FAZENDA VERA CRUZ",
        dataEntrada: "2022-05-13",
        dataSaida: "",
        latitude: "",
        longitude: "",
      },
      subdivisao: { nome: "INV1", dataEntrada: "2022-05-13", dataSaida: "" },
      idSistemaGestao: "2434017",
      idLocalizacaoBlockchain: "338fe63b-ba22-4f66-a0d2-cb88703bdc5a",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:22:04",
        dtAlteracao: "2023-12-18T14:22:04",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        fazenda: {
          nome: "FAZENDA VERA CRUZ",
          dataEntrada: "2022-02-09",
          dataSaida: "2022-05-13",
          latitude: "",
          longitude: "",
        },
        subdivisao: {
          nome: "PASTO4",
          dataEntrada: "2022-02-09",
          dataSaida: "2022-05-13",
        },
        idSistemaGestao: "2207402",
        idLocalizacaoBlockchain: "f51c6d56-b34b-401c-bdd9-25abf0e2d2fa",
      },
      {
        dtCriacao: "2023-12-18T14:22:04",
        dtAlteracao: "2023-12-18T14:22:04",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        fazenda: {
          nome: "FAZENDA VERA CRUZ",
          dataEntrada: "2022-05-13",
          dataSaida: "2022-05-13",
          latitude: "",
          longitude: "",
        },
        subdivisao: {
          nome: "BAIA06",
          dataEntrada: "2022-05-13",
          dataSaida: "2022-05-13",
        },
        idSistemaGestao: "2434012",
        idLocalizacaoBlockchain: "5148f709-a836-4592-aae3-415b882fabb5",
      },
      {
        dtCriacao: "2023-12-18T14:22:04",
        dtAlteracao: "2023-12-18T14:22:04",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        fazenda: {
          nome: "FAZENDA VERA CRUZ",
          dataEntrada: "2022-05-13",
          dataSaida: "",
          latitude: "",
          longitude: "",
        },
        subdivisao: { nome: "INV1", dataEntrada: "2022-05-13", dataSaida: "" },
        idSistemaGestao: "2434017",
        idLocalizacaoBlockchain: "338fe63b-ba22-4f66-a0d2-cb88703bdc5a",
      },
    ],
    loteAtual: {
      dtCriacao: "2023-12-18T14:21:50",
      dtAlteracao: "2023-12-18T14:21:50",
      idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
      nome: "VERA CRUZ",
      dataEntrada: "2022-01-01",
      dataSaida: null,
      idSistemaGestao: "2297981",
      idLoteBlockchain: "6a5bfee8-7dae-48da-a4ba-88a05c44c627",
    },
    loteHistorico: [
      {
        dtCriacao: "2023-12-18T14:21:50",
        dtAlteracao: "2023-12-18T14:21:50",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        nome: "VERA CRUZ",
        dataEntrada: "2022-01-01",
        dataSaida: null,
        idSistemaGestao: "2297981",
        idLoteBlockchain: "6a5bfee8-7dae-48da-a4ba-88a05c44c627",
      },
    ],
    idResponsavel: "e1227ca6-211b-4b43-b310-7288f3a945da",
    nomeResponsavel: "Wanderson Souza Nascimento",
    frigorifico: null,
  },
  "7025a628-7085-4ff0-990e-265b8a3bc8bb": {
    idLoteSync: "8b507fea-7eb4-48f9-bf56-81c9b8654e8b",
    idBlockchain: "7025a628-7085-4ff0-990e-265b8a3bc8bb",
    docType: "ANIMAL",
    idSistemaGestao: "0020898c-992a-4206-b61b-bbf8737555ac",
    idSisbov: "105520374381361",
    idICar: "",
    nome: "",
    dataNascimento: "2019-11-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "4381361",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Composto", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "46bd5b75-1cba-4da3-96ac-ad89f874846e",
      nome: "Wanderson",
      documento: "92374000125",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:25:22",
    dataUltimaAtualizacao: "2023-12-18T18:37:53",
    vendaAtual: {
      idBlockchain: "fd157fd6-8411-4f9a-aeda-aa0ff3777b01",
      documentoProprietarioDestino: "18293459000196",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "Wanderson", documento: "92374000125" },
      proprietarioDestino: {
        nome: "Guidara Indústria e Comércio de Alimentos Eireli",
        documento: "18293459000196",
      },
      dataCriacao: "2023-12-18T18:37:07",
      dataUltimaAtualizacao: "2023-12-18T18:37:53",
      status: "EFETIVADA",
      qtdAnimais: 15,
    },
    vendaHistorico: [
      {
        idBlockchain: "fd157fd6-8411-4f9a-aeda-aa0ff3777b01",
        documentoProprietarioDestino: "18293459000196",
        tipo: "INDUSTRIA",
        proprietarioOrigem: { nome: "Wanderson", documento: "92374000125" },
        proprietarioDestino: {
          nome: "Guidara Indústria e Comércio de Alimentos Eireli",
          documento: "18293459000196",
        },
        dataCriacao: "2023-12-18T18:37:07",
        dataUltimaAtualizacao: "2023-12-18T18:37:53",
        status: "EFETIVADA",
        qtdAnimais: 15,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:22:04",
      dtAlteracao: "2023-12-18T14:22:04",
      idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
      fazenda: {
        nome: "FAZENDA VERA CRUZ",
        dataEntrada: "2022-06-27",
        dataSaida: "",
        latitude: "",
        longitude: "",
      },
      subdivisao: { nome: "INV1", dataEntrada: "2022-06-27", dataSaida: "" },
      idSistemaGestao: "2654079",
      idLocalizacaoBlockchain: "f9be4358-58b7-4ecc-981d-44f348be3d6f",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:22:04",
        dtAlteracao: "2023-12-18T14:22:04",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        fazenda: {
          nome: "FAZENDA VERA CRUZ",
          dataEntrada: "2022-01-01",
          dataSaida: "2022-06-27",
          latitude: "",
          longitude: "",
        },
        subdivisao: {
          nome: "A1",
          dataEntrada: "2022-01-01",
          dataSaida: "2022-06-27",
        },
        idSistemaGestao: "1978157",
        idLocalizacaoBlockchain: "5dc89aa4-f958-417f-a6e3-33a2a141432e",
      },
      {
        dtCriacao: "2023-12-18T14:22:04",
        dtAlteracao: "2023-12-18T14:22:04",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        fazenda: {
          nome: "FAZENDA VERA CRUZ",
          dataEntrada: "2022-06-27",
          dataSaida: "",
          latitude: "",
          longitude: "",
        },
        subdivisao: { nome: "INV1", dataEntrada: "2022-06-27", dataSaida: "" },
        idSistemaGestao: "2654079",
        idLocalizacaoBlockchain: "f9be4358-58b7-4ecc-981d-44f348be3d6f",
      },
    ],
    loteAtual: {
      dtCriacao: "2023-12-18T14:21:50",
      dtAlteracao: "2023-12-18T14:21:50",
      idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
      nome: "VERA CRUZ",
      dataEntrada: "2022-01-01",
      dataSaida: null,
      idSistemaGestao: "2296466",
      idLoteBlockchain: "daa0b789-e270-4ce5-bfb5-22effc53a391",
    },
    loteHistorico: [
      {
        dtCriacao: "2023-12-18T14:21:50",
        dtAlteracao: "2023-12-18T14:21:50",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        nome: "VERA CRUZ",
        dataEntrada: "2022-01-01",
        dataSaida: null,
        idSistemaGestao: "2296466",
        idLoteBlockchain: "daa0b789-e270-4ce5-bfb5-22effc53a391",
      },
    ],
    idResponsavel: "e1227ca6-211b-4b43-b310-7288f3a945da",
    nomeResponsavel: "Wanderson Souza Nascimento",
    frigorifico: null,
  },
  "737f0d97-089f-4f37-be4b-449a8c2c0aa7": {
    idLoteSync: "8b507fea-7eb4-48f9-bf56-81c9b8654e8b",
    idBlockchain: "737f0d97-089f-4f37-be4b-449a8c2c0aa7",
    docType: "ANIMAL",
    idSistemaGestao: "00f467b1-bac7-4c8a-a5cc-e3adfd558fe3",
    idSisbov: "105520374376171",
    idICar: "",
    nome: "",
    dataNascimento: "2019-09-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "4376171",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Composto", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "46bd5b75-1cba-4da3-96ac-ad89f874846e",
      nome: "Wanderson",
      documento: "92374000125",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:25:22",
    dataUltimaAtualizacao: "2023-12-18T18:37:53",
    vendaAtual: {
      idBlockchain: "fd157fd6-8411-4f9a-aeda-aa0ff3777b01",
      documentoProprietarioDestino: "18293459000196",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "Wanderson", documento: "92374000125" },
      proprietarioDestino: {
        nome: "Guidara Indústria e Comércio de Alimentos Eireli",
        documento: "18293459000196",
      },
      dataCriacao: "2023-12-18T18:37:07",
      dataUltimaAtualizacao: "2023-12-18T18:37:53",
      status: "EFETIVADA",
      qtdAnimais: 15,
    },
    vendaHistorico: [
      {
        idBlockchain: "fd157fd6-8411-4f9a-aeda-aa0ff3777b01",
        documentoProprietarioDestino: "18293459000196",
        tipo: "INDUSTRIA",
        proprietarioOrigem: { nome: "Wanderson", documento: "92374000125" },
        proprietarioDestino: {
          nome: "Guidara Indústria e Comércio de Alimentos Eireli",
          documento: "18293459000196",
        },
        dataCriacao: "2023-12-18T18:37:07",
        dataUltimaAtualizacao: "2023-12-18T18:37:53",
        status: "EFETIVADA",
        qtdAnimais: 15,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:22:04",
      dtAlteracao: "2023-12-18T14:22:04",
      idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
      fazenda: {
        nome: "FAZENDA VERA CRUZ",
        dataEntrada: "2022-06-27",
        dataSaida: "",
        latitude: "",
        longitude: "",
      },
      subdivisao: { nome: "INV1", dataEntrada: "2022-06-27", dataSaida: "" },
      idSistemaGestao: "2654081",
      idLocalizacaoBlockchain: "a50d36ef-6ee0-4b60-8579-be1edb9b85fe",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:22:04",
        dtAlteracao: "2023-12-18T14:22:04",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        fazenda: {
          nome: "FAZENDA VERA CRUZ",
          dataEntrada: "2022-01-01",
          dataSaida: "2022-06-27",
          latitude: "",
          longitude: "",
        },
        subdivisao: {
          nome: "A1",
          dataEntrada: "2022-01-01",
          dataSaida: "2022-06-27",
        },
        idSistemaGestao: "1978159",
        idLocalizacaoBlockchain: "faaa5b19-fc59-45ba-ad00-316bd46af3c8",
      },
      {
        dtCriacao: "2023-12-18T14:22:04",
        dtAlteracao: "2023-12-18T14:22:04",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        fazenda: {
          nome: "FAZENDA VERA CRUZ",
          dataEntrada: "2022-06-27",
          dataSaida: "",
          latitude: "",
          longitude: "",
        },
        subdivisao: { nome: "INV1", dataEntrada: "2022-06-27", dataSaida: "" },
        idSistemaGestao: "2654081",
        idLocalizacaoBlockchain: "a50d36ef-6ee0-4b60-8579-be1edb9b85fe",
      },
    ],
    loteAtual: {
      dtCriacao: "2023-12-18T14:21:50",
      dtAlteracao: "2023-12-18T14:21:50",
      idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
      nome: "VERA CRUZ",
      dataEntrada: "2022-01-01",
      dataSaida: null,
      idSistemaGestao: "2296485",
      idLoteBlockchain: "de26aede-0dbd-4a66-82d5-ed652a06474f",
    },
    loteHistorico: [
      {
        dtCriacao: "2023-12-18T14:21:50",
        dtAlteracao: "2023-12-18T14:21:50",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        nome: "VERA CRUZ",
        dataEntrada: "2022-01-01",
        dataSaida: null,
        idSistemaGestao: "2296485",
        idLoteBlockchain: "de26aede-0dbd-4a66-82d5-ed652a06474f",
      },
    ],
    idResponsavel: "e1227ca6-211b-4b43-b310-7288f3a945da",
    nomeResponsavel: "Wanderson Souza Nascimento",
    frigorifico: null,
  },
  "7dfd0fec-fb2e-417f-b525-d634ad7681d4": {
    idLoteSync: "8b507fea-7eb4-48f9-bf56-81c9b8654e8b",
    idBlockchain: "7dfd0fec-fb2e-417f-b525-d634ad7681d4",
    docType: "ANIMAL",
    idSistemaGestao: "002eed72-835c-4567-89b6-314a84235878",
    idSisbov: "105520361766464",
    idICar: "",
    nome: "",
    dataNascimento: "2019-10-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "1766464",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Composto", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "46bd5b75-1cba-4da3-96ac-ad89f874846e",
      nome: "Wanderson",
      documento: "92374000125",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:25:22",
    dataUltimaAtualizacao: "2023-12-18T18:37:53",
    vendaAtual: {
      idBlockchain: "fd157fd6-8411-4f9a-aeda-aa0ff3777b01",
      documentoProprietarioDestino: "18293459000196",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "Wanderson", documento: "92374000125" },
      proprietarioDestino: {
        nome: "Guidara Indústria e Comércio de Alimentos Eireli",
        documento: "18293459000196",
      },
      dataCriacao: "2023-12-18T18:37:07",
      dataUltimaAtualizacao: "2023-12-18T18:37:53",
      status: "EFETIVADA",
      qtdAnimais: 15,
    },
    vendaHistorico: [
      {
        idBlockchain: "fd157fd6-8411-4f9a-aeda-aa0ff3777b01",
        documentoProprietarioDestino: "18293459000196",
        tipo: "INDUSTRIA",
        proprietarioOrigem: { nome: "Wanderson", documento: "92374000125" },
        proprietarioDestino: {
          nome: "Guidara Indústria e Comércio de Alimentos Eireli",
          documento: "18293459000196",
        },
        dataCriacao: "2023-12-18T18:37:07",
        dataUltimaAtualizacao: "2023-12-18T18:37:53",
        status: "EFETIVADA",
        qtdAnimais: 15,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:22:04",
      dtAlteracao: "2023-12-18T14:22:04",
      idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
      fazenda: {
        nome: "FAZENDA VERA CRUZ",
        dataEntrada: "2022-06-27",
        dataSaida: "",
        latitude: "",
        longitude: "",
      },
      subdivisao: { nome: "INV1", dataEntrada: "2022-06-27", dataSaida: "" },
      idSistemaGestao: "2654080",
      idLocalizacaoBlockchain: "3f0a1549-2864-4930-a341-4d2999c81378",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:22:04",
        dtAlteracao: "2023-12-18T14:22:04",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        fazenda: {
          nome: "FAZENDA VERA CRUZ",
          dataEntrada: "2022-01-01",
          dataSaida: "2022-06-27",
          latitude: "",
          longitude: "",
        },
        subdivisao: {
          nome: "A1",
          dataEntrada: "2022-01-01",
          dataSaida: "2022-06-27",
        },
        idSistemaGestao: "1978158",
        idLocalizacaoBlockchain: "2d2076b1-bd40-495e-8d40-70ca11516baf",
      },
      {
        dtCriacao: "2023-12-18T14:22:04",
        dtAlteracao: "2023-12-18T14:22:04",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        fazenda: {
          nome: "FAZENDA VERA CRUZ",
          dataEntrada: "2022-06-27",
          dataSaida: "",
          latitude: "",
          longitude: "",
        },
        subdivisao: { nome: "INV1", dataEntrada: "2022-06-27", dataSaida: "" },
        idSistemaGestao: "2654080",
        idLocalizacaoBlockchain: "3f0a1549-2864-4930-a341-4d2999c81378",
      },
    ],
    loteAtual: {
      dtCriacao: "2023-12-18T14:21:50",
      dtAlteracao: "2023-12-18T14:21:50",
      idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
      nome: "VERA CRUZ",
      dataEntrada: "2022-01-01",
      dataSaida: null,
      idSistemaGestao: "2296467",
      idLoteBlockchain: "d5bc24b9-3f60-4d42-a983-092450ab4792",
    },
    loteHistorico: [
      {
        dtCriacao: "2023-12-18T14:21:50",
        dtAlteracao: "2023-12-18T14:21:50",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        nome: "VERA CRUZ",
        dataEntrada: "2022-01-01",
        dataSaida: null,
        idSistemaGestao: "2296467",
        idLoteBlockchain: "d5bc24b9-3f60-4d42-a983-092450ab4792",
      },
    ],
    idResponsavel: "e1227ca6-211b-4b43-b310-7288f3a945da",
    nomeResponsavel: "Wanderson Souza Nascimento",
    frigorifico: null,
  },
  "8931afa6-dbcc-472e-a922-ed0851b68b47": {
    idLoteSync: "8b507fea-7eb4-48f9-bf56-81c9b8654e8b",
    idBlockchain: "8931afa6-dbcc-472e-a922-ed0851b68b47",
    docType: "ANIMAL",
    idSistemaGestao: "38614b35-648f-4436-9f62-3d40b8106852",
    idSisbov: "105520357183905",
    idICar: "",
    nome: "",
    dataNascimento: "2018-08-29",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "7183905",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:21:37",
      idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
      idSistemaGestao: "7711287f-fe81-481f-a1bb-70d97ed9c8a5",
      idResponsavel: "e1227ca6-211b-4b43-b310-7288f3a945da",
      nomeResponsavel: "Wanderson Souza Nascimento",
      latitude: "",
      longitude: "",
      tipo: "Pesagem Simples",
      peso: 351,
      dtPesagem: "2022-05-13",
      idPesagemBlockchain: "8f2bd664-dbe1-4175-a92f-cba365dc58f9",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:21:37",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        idSistemaGestao: "7711287f-fe81-481f-a1bb-70d97ed9c8a5",
        idResponsavel: "e1227ca6-211b-4b43-b310-7288f3a945da",
        nomeResponsavel: "Wanderson Souza Nascimento",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 351,
        dtPesagem: "2022-05-13",
        idPesagemBlockchain: "8f2bd664-dbe1-4175-a92f-cba365dc58f9",
      },
    ],
    produtorAtual: {
      idBlockchain: "46bd5b75-1cba-4da3-96ac-ad89f874846e",
      nome: "Wanderson",
      documento: "92374000125",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:25:22",
    dataUltimaAtualizacao: "2023-12-18T18:37:53",
    vendaAtual: {
      idBlockchain: "fd157fd6-8411-4f9a-aeda-aa0ff3777b01",
      documentoProprietarioDestino: "18293459000196",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "Wanderson", documento: "92374000125" },
      proprietarioDestino: {
        nome: "Guidara Indústria e Comércio de Alimentos Eireli",
        documento: "18293459000196",
      },
      dataCriacao: "2023-12-18T18:37:07",
      dataUltimaAtualizacao: "2023-12-18T18:37:53",
      status: "EFETIVADA",
      qtdAnimais: 15,
    },
    vendaHistorico: [
      {
        idBlockchain: "fd157fd6-8411-4f9a-aeda-aa0ff3777b01",
        documentoProprietarioDestino: "18293459000196",
        tipo: "INDUSTRIA",
        proprietarioOrigem: { nome: "Wanderson", documento: "92374000125" },
        proprietarioDestino: {
          nome: "Guidara Indústria e Comércio de Alimentos Eireli",
          documento: "18293459000196",
        },
        dataCriacao: "2023-12-18T18:37:07",
        dataUltimaAtualizacao: "2023-12-18T18:37:53",
        status: "EFETIVADA",
        qtdAnimais: 15,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:22:04",
      dtAlteracao: "2023-12-18T14:22:04",
      idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
      fazenda: {
        nome: "FAZENDA VERA CRUZ",
        dataEntrada: "2022-05-13",
        dataSaida: "",
        latitude: "",
        longitude: "",
      },
      subdivisao: { nome: "INV1", dataEntrada: "2022-05-13", dataSaida: "" },
      idSistemaGestao: "2434018",
      idLocalizacaoBlockchain: "9ad09019-ed96-435a-8e0d-e865ba3ee16c",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:22:04",
        dtAlteracao: "2023-12-18T14:22:04",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        fazenda: {
          nome: "FAZENDA VERA CRUZ",
          dataEntrada: "2022-02-09",
          dataSaida: "2022-05-13",
          latitude: "",
          longitude: "",
        },
        subdivisao: {
          nome: "PASTO4",
          dataEntrada: "2022-02-09",
          dataSaida: "2022-05-13",
        },
        idSistemaGestao: "2207438",
        idLocalizacaoBlockchain: "03ac3143-36aa-4411-a429-50f4911fe536",
      },
      {
        dtCriacao: "2023-12-18T14:22:04",
        dtAlteracao: "2023-12-18T14:22:04",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        fazenda: {
          nome: "FAZENDA VERA CRUZ",
          dataEntrada: "2022-05-13",
          dataSaida: "2022-05-13",
          latitude: "",
          longitude: "",
        },
        subdivisao: {
          nome: "BAIA06",
          dataEntrada: "2022-05-13",
          dataSaida: "2022-05-13",
        },
        idSistemaGestao: "2434008",
        idLocalizacaoBlockchain: "102886d1-c7d8-4f0c-98e0-3b8f9e9d83d5",
      },
      {
        dtCriacao: "2023-12-18T14:22:04",
        dtAlteracao: "2023-12-18T14:22:04",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        fazenda: {
          nome: "FAZENDA VERA CRUZ",
          dataEntrada: "2022-05-13",
          dataSaida: "",
          latitude: "",
          longitude: "",
        },
        subdivisao: { nome: "INV1", dataEntrada: "2022-05-13", dataSaida: "" },
        idSistemaGestao: "2434018",
        idLocalizacaoBlockchain: "9ad09019-ed96-435a-8e0d-e865ba3ee16c",
      },
    ],
    loteAtual: {
      dtCriacao: "2023-12-18T14:21:50",
      dtAlteracao: "2023-12-18T14:21:50",
      idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
      nome: "VERA CRUZ",
      dataEntrada: "2022-01-01",
      dataSaida: null,
      idSistemaGestao: "2298483",
      idLoteBlockchain: "20baac64-e2ff-4ac3-b251-240bc0480845",
    },
    loteHistorico: [
      {
        dtCriacao: "2023-12-18T14:21:50",
        dtAlteracao: "2023-12-18T14:21:50",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        nome: "VERA CRUZ",
        dataEntrada: "2022-01-01",
        dataSaida: null,
        idSistemaGestao: "2298483",
        idLoteBlockchain: "20baac64-e2ff-4ac3-b251-240bc0480845",
      },
    ],
    idResponsavel: "e1227ca6-211b-4b43-b310-7288f3a945da",
    nomeResponsavel: "Wanderson Souza Nascimento",
    frigorifico: null,
  },
  "b4dcfa52-192b-4d8c-bbea-d04695f5aec6": {
    idLoteSync: "8b507fea-7eb4-48f9-bf56-81c9b8654e8b",
    idBlockchain: "b4dcfa52-192b-4d8c-bbea-d04695f5aec6",
    docType: "ANIMAL",
    idSistemaGestao: "f8bf1c87-2c04-4a19-837c-1accf25889e0",
    idSisbov: "105520357183042",
    idICar: "",
    nome: "",
    dataNascimento: "2018-08-29",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "7183042",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:21:37",
      idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
      idSistemaGestao: "80b7efa5-579e-4dc3-a98b-1f828393f7e8",
      idResponsavel: "e1227ca6-211b-4b43-b310-7288f3a945da",
      nomeResponsavel: "Wanderson Souza Nascimento",
      latitude: "",
      longitude: "",
      tipo: "Pesagem Simples",
      peso: 365,
      dtPesagem: "2022-05-13",
      idPesagemBlockchain: "0e4a58ce-567d-457e-918e-4cab5f7bc5c3",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:21:37",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        idSistemaGestao: "80b7efa5-579e-4dc3-a98b-1f828393f7e8",
        idResponsavel: "e1227ca6-211b-4b43-b310-7288f3a945da",
        nomeResponsavel: "Wanderson Souza Nascimento",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 365,
        dtPesagem: "2022-05-13",
        idPesagemBlockchain: "0e4a58ce-567d-457e-918e-4cab5f7bc5c3",
      },
    ],
    produtorAtual: {
      idBlockchain: "46bd5b75-1cba-4da3-96ac-ad89f874846e",
      nome: "Wanderson",
      documento: "92374000125",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:25:22",
    dataUltimaAtualizacao: "2023-12-18T18:37:53",
    vendaAtual: {
      idBlockchain: "fd157fd6-8411-4f9a-aeda-aa0ff3777b01",
      documentoProprietarioDestino: "18293459000196",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "Wanderson", documento: "92374000125" },
      proprietarioDestino: {
        nome: "Guidara Indústria e Comércio de Alimentos Eireli",
        documento: "18293459000196",
      },
      dataCriacao: "2023-12-18T18:37:07",
      dataUltimaAtualizacao: "2023-12-18T18:37:53",
      status: "EFETIVADA",
      qtdAnimais: 15,
    },
    vendaHistorico: [
      {
        idBlockchain: "fd157fd6-8411-4f9a-aeda-aa0ff3777b01",
        documentoProprietarioDestino: "18293459000196",
        tipo: "INDUSTRIA",
        proprietarioOrigem: { nome: "Wanderson", documento: "92374000125" },
        proprietarioDestino: {
          nome: "Guidara Indústria e Comércio de Alimentos Eireli",
          documento: "18293459000196",
        },
        dataCriacao: "2023-12-18T18:37:07",
        dataUltimaAtualizacao: "2023-12-18T18:37:53",
        status: "EFETIVADA",
        qtdAnimais: 15,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:22:04",
      dtAlteracao: "2023-12-18T14:22:04",
      idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
      fazenda: {
        nome: "FAZENDA VERA CRUZ",
        dataEntrada: "2022-05-13",
        dataSaida: "",
        latitude: "",
        longitude: "",
      },
      subdivisao: { nome: "INV1", dataEntrada: "2022-05-13", dataSaida: "" },
      idSistemaGestao: "2434025",
      idLocalizacaoBlockchain: "73234528-cd36-4383-b08b-5f3c5d1f2ca9",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:22:04",
        dtAlteracao: "2023-12-18T14:22:04",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        fazenda: {
          nome: "FAZENDA VERA CRUZ",
          dataEntrada: "2022-02-09",
          dataSaida: "2022-05-13",
          latitude: "",
          longitude: "",
        },
        subdivisao: {
          nome: "PASTO4",
          dataEntrada: "2022-02-09",
          dataSaida: "2022-05-13",
        },
        idSistemaGestao: "2208037",
        idLocalizacaoBlockchain: "61778cbb-9a9c-435c-bac3-470d243f2153",
      },
      {
        dtCriacao: "2023-12-18T14:22:04",
        dtAlteracao: "2023-12-18T14:22:04",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        fazenda: {
          nome: "FAZENDA VERA CRUZ",
          dataEntrada: "2022-05-13",
          dataSaida: "2022-05-13",
          latitude: "",
          longitude: "",
        },
        subdivisao: {
          nome: "BAIA06",
          dataEntrada: "2022-05-13",
          dataSaida: "2022-05-13",
        },
        idSistemaGestao: "2434007",
        idLocalizacaoBlockchain: "9cfb980f-f091-4398-be29-4ac818515372",
      },
      {
        dtCriacao: "2023-12-18T14:22:04",
        dtAlteracao: "2023-12-18T14:22:04",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        fazenda: {
          nome: "FAZENDA VERA CRUZ",
          dataEntrada: "2022-05-13",
          dataSaida: "",
          latitude: "",
          longitude: "",
        },
        subdivisao: { nome: "INV1", dataEntrada: "2022-05-13", dataSaida: "" },
        idSistemaGestao: "2434025",
        idLocalizacaoBlockchain: "73234528-cd36-4383-b08b-5f3c5d1f2ca9",
      },
    ],
    loteAtual: {
      dtCriacao: "2023-12-18T14:21:50",
      dtAlteracao: "2023-12-18T14:21:50",
      idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
      nome: "VC2",
      dataEntrada: "2022-01-01",
      dataSaida: null,
      idSistemaGestao: "2306016",
      idLoteBlockchain: "0ed21feb-8de2-4007-8548-78efb792a884",
    },
    loteHistorico: [
      {
        dtCriacao: "2023-12-18T14:21:50",
        dtAlteracao: "2023-12-18T14:21:50",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        nome: "VC2",
        dataEntrada: "2022-01-01",
        dataSaida: null,
        idSistemaGestao: "2306016",
        idLoteBlockchain: "0ed21feb-8de2-4007-8548-78efb792a884",
      },
    ],
    idResponsavel: "e1227ca6-211b-4b43-b310-7288f3a945da",
    nomeResponsavel: "Wanderson Souza Nascimento",
    frigorifico: null,
  },
  "fa13b794-578f-404b-9aa4-402b2c894c67": {
    idLoteSync: "8b507fea-7eb4-48f9-bf56-81c9b8654e8b",
    idBlockchain: "fa13b794-578f-404b-9aa4-402b2c894c67",
    docType: "ANIMAL",
    idSistemaGestao: "3d9dce9d-9aa2-461e-8b91-c92078414a3b",
    idSisbov: "105520357180752",
    idICar: "",
    nome: "",
    dataNascimento: "2018-11-27",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "7180752",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:21:37",
      idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
      idSistemaGestao: "fc20c118-f8c3-4c3b-b489-f9b72aedd442",
      idResponsavel: "e1227ca6-211b-4b43-b310-7288f3a945da",
      nomeResponsavel: "Wanderson Souza Nascimento",
      latitude: "",
      longitude: "",
      tipo: "Pesagem Simples",
      peso: 322,
      dtPesagem: "2022-05-13",
      idPesagemBlockchain: "cb4f6ebe-0d50-4fbf-bfeb-ec24af7f1fc7",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:21:37",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        idSistemaGestao: "fc20c118-f8c3-4c3b-b489-f9b72aedd442",
        idResponsavel: "e1227ca6-211b-4b43-b310-7288f3a945da",
        nomeResponsavel: "Wanderson Souza Nascimento",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 322,
        dtPesagem: "2022-05-13",
        idPesagemBlockchain: "cb4f6ebe-0d50-4fbf-bfeb-ec24af7f1fc7",
      },
    ],
    produtorAtual: {
      idBlockchain: "46bd5b75-1cba-4da3-96ac-ad89f874846e",
      nome: "Wanderson",
      documento: "92374000125",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:25:22",
    dataUltimaAtualizacao: "2023-12-18T18:37:53",
    vendaAtual: {
      idBlockchain: "fd157fd6-8411-4f9a-aeda-aa0ff3777b01",
      documentoProprietarioDestino: "18293459000196",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "Wanderson", documento: "92374000125" },
      proprietarioDestino: {
        nome: "Guidara Indústria e Comércio de Alimentos Eireli",
        documento: "18293459000196",
      },
      dataCriacao: "2023-12-18T18:37:07",
      dataUltimaAtualizacao: "2023-12-18T18:37:53",
      status: "EFETIVADA",
      qtdAnimais: 15,
    },
    vendaHistorico: [
      {
        idBlockchain: "fd157fd6-8411-4f9a-aeda-aa0ff3777b01",
        documentoProprietarioDestino: "18293459000196",
        tipo: "INDUSTRIA",
        proprietarioOrigem: { nome: "Wanderson", documento: "92374000125" },
        proprietarioDestino: {
          nome: "Guidara Indústria e Comércio de Alimentos Eireli",
          documento: "18293459000196",
        },
        dataCriacao: "2023-12-18T18:37:07",
        dataUltimaAtualizacao: "2023-12-18T18:37:53",
        status: "EFETIVADA",
        qtdAnimais: 15,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:22:04",
      dtAlteracao: "2023-12-18T14:22:04",
      idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
      fazenda: {
        nome: "FAZENDA VERA CRUZ",
        dataEntrada: "2022-05-13",
        dataSaida: "",
        latitude: "",
        longitude: "",
      },
      subdivisao: { nome: "INV1", dataEntrada: "2022-05-13", dataSaida: "" },
      idSistemaGestao: "2434020",
      idLocalizacaoBlockchain: "e06dafbf-d42f-4fb4-aaf8-31c7f34e4371",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:22:04",
        dtAlteracao: "2023-12-18T14:22:04",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        fazenda: {
          nome: "FAZENDA VERA CRUZ",
          dataEntrada: "2022-02-09",
          dataSaida: "2022-05-13",
          latitude: "",
          longitude: "",
        },
        subdivisao: {
          nome: "PASTO4",
          dataEntrada: "2022-02-09",
          dataSaida: "2022-05-13",
        },
        idSistemaGestao: "2207452",
        idLocalizacaoBlockchain: "2e89dd23-62c8-4f33-b67b-09a1259c8a8a",
      },
      {
        dtCriacao: "2023-12-18T14:22:04",
        dtAlteracao: "2023-12-18T14:22:04",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        fazenda: {
          nome: "FAZENDA VERA CRUZ",
          dataEntrada: "2022-05-13",
          dataSaida: "2022-05-13",
          latitude: "",
          longitude: "",
        },
        subdivisao: {
          nome: "BAIA06",
          dataEntrada: "2022-05-13",
          dataSaida: "2022-05-13",
        },
        idSistemaGestao: "2434015",
        idLocalizacaoBlockchain: "23c51e19-f2e4-469a-8840-46f2f6afeef4",
      },
      {
        dtCriacao: "2023-12-18T14:22:04",
        dtAlteracao: "2023-12-18T14:22:04",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        fazenda: {
          nome: "FAZENDA VERA CRUZ",
          dataEntrada: "2022-05-13",
          dataSaida: "",
          latitude: "",
          longitude: "",
        },
        subdivisao: { nome: "INV1", dataEntrada: "2022-05-13", dataSaida: "" },
        idSistemaGestao: "2434020",
        idLocalizacaoBlockchain: "e06dafbf-d42f-4fb4-aaf8-31c7f34e4371",
      },
    ],
    loteAtual: {
      dtCriacao: "2023-12-18T14:21:50",
      dtAlteracao: "2023-12-18T14:21:50",
      idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
      nome: "VERA CRUZ",
      dataEntrada: "2022-01-01",
      dataSaida: null,
      idSistemaGestao: "2298666",
      idLoteBlockchain: "5b0330c2-fc39-4dd1-9ed4-2f70aafaad11",
    },
    loteHistorico: [
      {
        dtCriacao: "2023-12-18T14:21:50",
        dtAlteracao: "2023-12-18T14:21:50",
        idLoteSync: "e5db047b-cf45-46c1-9257-093e3d8d0176",
        nome: "VERA CRUZ",
        dataEntrada: "2022-01-01",
        dataSaida: null,
        idSistemaGestao: "2298666",
        idLoteBlockchain: "5b0330c2-fc39-4dd1-9ed4-2f70aafaad11",
      },
    ],
    idResponsavel: "e1227ca6-211b-4b43-b310-7288f3a945da",
    nomeResponsavel: "Wanderson Souza Nascimento",
    frigorifico: null,
  },
  "006fe6e1-2ec0-45e3-8d0f-af42c24f957a": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "006fe6e1-2ec0-45e3-8d0f-af42c24f957a",
    docType: "ANIMAL",
    idSistemaGestao: "9cb05a7c-2135-474b-8581-46e3b51c1105",
    idSisbov: "df0fbbb1-d673-43f6-aa04-e706cb46d91b",
    idICar: "39a2ab40-7d22-4ba0-a142-769e019b5346",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "a0ba58f1-3a95-4322-b73e-1ecb7e617a51",
    idAnimalFazenda: "57906baa-0dd6-4cc0-bebb-944e86dcdcef",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:30",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "0108d8f5-535e-4d27-9c93-83f7dda6f693": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "0108d8f5-535e-4d27-9c93-83f7dda6f693",
    docType: "ANIMAL",
    idSistemaGestao: "d9ef13c5-2f7f-4936-a616-19dd4899f180",
    idSisbov: "8fb76a85-e1bc-4ce3-86f3-b17f26864eaa",
    idICar: "bf5c12a7-d169-4f4a-af78-22a5b789c907",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "ebd146bf-e42c-465b-909b-4664750b6fbe",
    idAnimalFazenda: "e5837b10-71dd-452c-930c-23263e3740f1",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:30",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "0494990c-6494-40b5-8f4b-c50e3d8746c7": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "0494990c-6494-40b5-8f4b-c50e3d8746c7",
    docType: "ANIMAL",
    idSistemaGestao: "9d7896db-1785-4cba-a744-e79b47ba92e7",
    idSisbov: "d06cb06d-1bbd-4c02-a7c3-833cbe8c75ff",
    idICar: "c41d0df6-73d3-43c6-ad75-3dfc2afbfdd6",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "d6dfd2f3-ae12-4cef-b49a-45de2309af29",
    idAnimalFazenda: "7c86b90a-432a-4fa0-a193-3683ff5ee151",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:31",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "0629888c-110e-442f-87a6-edec4f2a0bf6": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "0629888c-110e-442f-87a6-edec4f2a0bf6",
    docType: "ANIMAL",
    idSistemaGestao: "4c81f16b-3144-4ebd-9e4d-806f92c6b2ae",
    idSisbov: "b66fe401-6d48-4d3e-92b5-da84e05475b8",
    idICar: "a4018229-275d-489c-8273-f8532112078e",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "42dd9209-e226-4044-b90c-bf0f39bd4d70",
    idAnimalFazenda: "4152299c-1dd3-4881-a077-e50e77909c14",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:30",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "06f7cf38-67ef-4c70-86f6-35257f6de0b0": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "06f7cf38-67ef-4c70-86f6-35257f6de0b0",
    docType: "ANIMAL",
    idSistemaGestao: "f7cf988f-58b0-49b4-a914-f80c7a242f9a",
    idSisbov: "b0225542-322e-4870-a971-3f41b696eaac",
    idICar: "2e76b5eb-8c38-49b4-be75-9de3f8f9ae68",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "0414a768-4230-494d-9998-9e0d5ec3f761",
    idAnimalFazenda: "1ee5ffa1-ab71-40c3-8c88-c21de3a423f6",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:31",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "07d80bda-becd-4ad0-8d92-e42eaec8c99d": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "07d80bda-becd-4ad0-8d92-e42eaec8c99d",
    docType: "ANIMAL",
    idSistemaGestao: "1f8e11ef-2823-49ff-b6f1-5a72a765f706",
    idSisbov: "ea05740e-bda7-44fc-932d-77d98db7bda3",
    idICar: "31caf56a-bf77-4ad5-aa54-4b4a2ad1de2d",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "3414daeb-220c-4603-b728-084a8bac4908",
    idAnimalFazenda: "f3ad48a7-64c1-452a-a82a-adc00e3e97fc",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:30",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "0f288664-23cb-4f42-a7a8-32795dc26e15": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "0f288664-23cb-4f42-a7a8-32795dc26e15",
    docType: "ANIMAL",
    idSistemaGestao: "ad68bc25-6bc8-47eb-8126-be7ea0e5e812",
    idSisbov: "4441b913-8657-491d-893e-e8c01678eefa",
    idICar: "d4635186-7bea-4083-b39a-b1d6d49b9258",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "cce96f73-a28f-4acb-babb-4905930a4acb",
    idAnimalFazenda: "96ac860b-3798-4067-b773-28677811a424",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:31",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "12eaabf3-f2e8-4925-adc5-4ba48f79502a": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "12eaabf3-f2e8-4925-adc5-4ba48f79502a",
    docType: "ANIMAL",
    idSistemaGestao: "34e2ba32-bc3c-48e1-ac58-2cff4cea2f8a",
    idSisbov: "e7e10920-cf85-4a9f-855f-f0360b28ed0e",
    idICar: "def56680-c590-4a50-ab4c-d03df395074d",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "7da0b88e-cb93-42e2-8e99-5fb6e74c820e",
    idAnimalFazenda: "e2514608-dc1b-4cc4-98cb-e402ffef2fc5",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:31",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "139dab39-415a-4ee6-80f2-ac9fc2e5de99": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "139dab39-415a-4ee6-80f2-ac9fc2e5de99",
    docType: "ANIMAL",
    idSistemaGestao: "7c1f2ab8-7f40-4e2b-87a1-2cb15f2ad9c5",
    idSisbov: "da84af1e-146b-4e21-8be9-a1798c1fe75d",
    idICar: "880a74c3-269f-4507-bde9-8ca94762407a",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "e9153fc1-9b8b-4b3f-a497-22dd566abbca",
    idAnimalFazenda: "bfc8d297-b263-4347-be63-491427baaaf1",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:30",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "14880e28-d7e4-4c6b-a9b1-50627d3cdba2": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "14880e28-d7e4-4c6b-a9b1-50627d3cdba2",
    docType: "ANIMAL",
    idSistemaGestao: "68e49b4f-de1f-4b28-a388-75e1696df7f1",
    idSisbov: "19fe9d3a-a002-4dea-b7d8-077bd49fac13",
    idICar: "8d610cbf-d04a-4d27-92a3-4d6d3f8128a0",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "100b0a04-d413-4d14-9716-9248fa11bf2f",
    idAnimalFazenda: "2cd0e47e-f7ae-4bf0-8169-7a8ae542e331",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:31",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "17bed6dd-2bf5-432e-a4da-145a2587e6ff": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "17bed6dd-2bf5-432e-a4da-145a2587e6ff",
    docType: "ANIMAL",
    idSistemaGestao: "da86d8b8-cced-4c28-b33b-fd5dd963ec8f",
    idSisbov: "b15abfd0-3385-434e-ac7f-6d4daebfa10d",
    idICar: "abe10f05-1287-4415-8f4d-06fde59e6a2e",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "85792457-cdcb-41db-96cd-efccfde8654e",
    idAnimalFazenda: "4f1bf97a-4cd8-4620-b90c-478723522fc6",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:31",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "1d014728-23a5-4cf0-a6ea-bffc8adef53c": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "1d014728-23a5-4cf0-a6ea-bffc8adef53c",
    docType: "ANIMAL",
    idSistemaGestao: "698a96f3-8d80-472e-9611-2b6cfd4f7176",
    idSisbov: "00ca6176-c9ef-46ae-9fe8-c4e83e28bf4b",
    idICar: "dc2bca0e-a9de-41d6-b16c-a280b6f0f7e4",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "d3b895c5-9abc-4335-ac03-60d1a1b74aa8",
    idAnimalFazenda: "c3061adc-8403-4aab-b40b-e12b462ba5ed",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:30",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "1e2da235-fcb7-4ff0-8699-f0a50968d5fd": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "1e2da235-fcb7-4ff0-8699-f0a50968d5fd",
    docType: "ANIMAL",
    idSistemaGestao: "bc7e16ea-5799-4a98-91f7-3fe56c4e1b5a",
    idSisbov: "5c764abc-3d1f-4c1b-bf54-b1c8b8a84aaf",
    idICar: "dc1de256-6e43-4f70-b40e-52a62e783909",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "75105012-3046-4f23-bd89-c3729fc9107f",
    idAnimalFazenda: "c0469543-4994-4e91-9c2d-66ff8ec553bd",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:30",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "1f62f39a-92a3-421b-bf51-1ac67b9ddde6": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "1f62f39a-92a3-421b-bf51-1ac67b9ddde6",
    docType: "ANIMAL",
    idSistemaGestao: "8bb3d80c-bcff-4d1f-9179-630c8d257cd8",
    idSisbov: "91d374cb-b621-42b6-b24b-edbfdccdca30",
    idICar: "ae42f817-9c44-46d2-ad2c-fe9298c73963",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "702262e0-778c-4de0-a818-b258fec11d2c",
    idAnimalFazenda: "3b33f8dc-60f9-4095-b3a2-168846027e8f",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:31",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "1f689436-c662-44e6-a390-e6ad3e5685bc": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "1f689436-c662-44e6-a390-e6ad3e5685bc",
    docType: "ANIMAL",
    idSistemaGestao: "bc4b5794-8a73-4f6d-bb75-18e6b87524c4",
    idSisbov: "84d5c736-8097-4e7a-9696-5f5704ef4f71",
    idICar: "a940935f-31a9-45ee-bb25-873cdf846b86",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "78862917-cc17-45cd-b456-69a40981b2af",
    idAnimalFazenda: "0305a1a5-43bd-4ed2-81a4-5309bd2000bc",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:31",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "225b0fde-2046-41b2-be0a-97308c31cd1e": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "225b0fde-2046-41b2-be0a-97308c31cd1e",
    docType: "ANIMAL",
    idSistemaGestao: "a1b46547-7ed4-49ad-bf77-9addb6da28a1",
    idSisbov: "0533483e-4dd0-4654-a28a-b8cc5c7a2699",
    idICar: "2f37b965-8735-4c93-99ea-8022a9118945",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "ebd4ee33-0964-49b1-9f95-7a5dacffde08",
    idAnimalFazenda: "d6647fc4-131f-42c1-9bfe-64fb3d259266",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:30",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "25a99a98-a01b-4c44-ba51-03cbece3b70d": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "25a99a98-a01b-4c44-ba51-03cbece3b70d",
    docType: "ANIMAL",
    idSistemaGestao: "9b9ae093-21b7-41d3-8909-e3b34f2d71a6",
    idSisbov: "f0fe56fd-46da-412e-ab13-f70fa27df17e",
    idICar: "45dc0753-d002-424b-b908-0026032e8421",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "8ad1a6e9-3345-4d4c-8d01-f47950301b33",
    idAnimalFazenda: "9db66e1d-bc15-43bc-a906-ccdbf2f2650b",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:31",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "2995cae3-c345-498b-9149-cbc6d734c8dc": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "2995cae3-c345-498b-9149-cbc6d734c8dc",
    docType: "ANIMAL",
    idSistemaGestao: "5970d923-a655-4a5e-aa44-42c47e9274e9",
    idSisbov: "2122977a-2c27-45bd-99f5-33826f702603",
    idICar: "aee28f6f-de66-434d-b4b7-ea35e20fec18",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "e70709c4-5319-4f1e-9b05-76275f983692",
    idAnimalFazenda: "c0ee5e99-8de8-4f1c-8170-2a65a5c755d2",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:31",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "2b049547-131e-4655-a3c3-10ce7357f266": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "2b049547-131e-4655-a3c3-10ce7357f266",
    docType: "ANIMAL",
    idSistemaGestao: "c22b2c9f-76bb-4462-9103-4f37680cef41",
    idSisbov: "0b765cb8-5618-48f4-9d1d-01a769fc799b",
    idICar: "6b7d6538-69cc-45fd-a7eb-8990710a9ede",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "28db8f9d-a30b-4ef9-9d84-778f235a1bb2",
    idAnimalFazenda: "926f71c5-c14b-4781-bc1b-f9caeb6b6645",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:31",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "300d8f34-9324-4f86-a865-95bae0dbec6a": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "300d8f34-9324-4f86-a865-95bae0dbec6a",
    docType: "ANIMAL",
    idSistemaGestao: "7a067b40-22c5-4e25-bd10-3f78b30abbbc",
    idSisbov: "c9c62b21-04bf-4eaa-8963-5298e1ff6ded",
    idICar: "f84ff7b4-2cbe-4956-b13d-842ed32ff07d",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "83426d95-a4eb-41f3-8f66-1926be0815a8",
    idAnimalFazenda: "d5a653d9-2887-49b0-bed0-7f441cef7472",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:30",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "33491b06-11d0-4c22-b055-237b53f06854": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "33491b06-11d0-4c22-b055-237b53f06854",
    docType: "ANIMAL",
    idSistemaGestao: "5c9b158f-3955-4a06-844f-b994a283e0b3",
    idSisbov: "247704c3-b77f-4284-a0d0-d78db6712dce",
    idICar: "2a668aea-aa2e-4df3-aed1-9546e820e2c4",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "92957adf-1eff-4346-b7ea-ac8544170f64",
    idAnimalFazenda: "a8bbc0bd-76bb-4c99-b8d2-30b50ff7dabf",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:31",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "38a96b61-90d3-425d-960e-81818e6c1923": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "38a96b61-90d3-425d-960e-81818e6c1923",
    docType: "ANIMAL",
    idSistemaGestao: "093ae0b5-0533-49ba-84d1-6eb966816139",
    idSisbov: "43d42099-c1fe-4501-aff3-1aebe1b76a97",
    idICar: "149821e7-aa1d-4aa5-9fd9-2342f5d0ef6e",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "e479e3ac-e99a-4e30-a6d8-8f149759ce2e",
    idAnimalFazenda: "dc4621bc-4743-4bd5-bf66-49d516830e21",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:31",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "42419a4c-b720-4458-b560-cb6fae0795d1": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "42419a4c-b720-4458-b560-cb6fae0795d1",
    docType: "ANIMAL",
    idSistemaGestao: "079fa418-d210-4183-ad3e-f197825f761f",
    idSisbov: "c8a9220e-c355-43c1-a6b6-37722397721f",
    idICar: "fd5f8b93-369e-4243-98b9-83ef540d1cac",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "20c0549d-d570-4ab0-848d-7aacee9b03f1",
    idAnimalFazenda: "eff2a155-d672-4c85-882b-2d09f9358496",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:32",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "42f6a110-f88f-48e9-99f0-7b9d57ad4509": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "42f6a110-f88f-48e9-99f0-7b9d57ad4509",
    docType: "ANIMAL",
    idSistemaGestao: "373fa676-2fc2-4eee-a6ca-c8a131f2d4be",
    idSisbov: "730c8a42-20d1-4864-86bf-b5713c45ba9f",
    idICar: "9c9363a0-ea53-4f5e-ac96-7713b159e1e0",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "41ab04b6-83a6-4848-9ba9-766b396b2cb7",
    idAnimalFazenda: "ce86c3fd-de78-4b05-afb9-01884be64366",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:30",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "453d9e39-da45-469c-838f-18d314723e82": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "453d9e39-da45-469c-838f-18d314723e82",
    docType: "ANIMAL",
    idSistemaGestao: "e824d9c7-2401-4af4-839e-510495872e33",
    idSisbov: "4f05e18c-7756-43ea-8562-6ae7cf00e29d",
    idICar: "95cb37f2-4618-41d8-b18d-ca715b7d9500",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "af1a98ae-86fc-459c-a8a2-59de080e597a",
    idAnimalFazenda: "76aa8d4a-e8b3-46ef-a0b8-e8735e905594",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:31",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "49b4d27b-8cf8-4d35-a41f-b004d590fdfb": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "49b4d27b-8cf8-4d35-a41f-b004d590fdfb",
    docType: "ANIMAL",
    idSistemaGestao: "9fe2b0fa-b3cf-4f26-ad28-4d965892af06",
    idSisbov: "23071271-3806-45b6-9dde-1c8e23e9be39",
    idICar: "a2a16afb-605d-4142-9e24-ccebb0f39121",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "bd9c493a-5b33-45af-84f0-a79ca21b34a5",
    idAnimalFazenda: "9f1ae29c-0d85-4c78-be1a-58dca41f391b",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:31",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "4ad303f7-1739-47fd-8aa4-9ee504789bc1": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "4ad303f7-1739-47fd-8aa4-9ee504789bc1",
    docType: "ANIMAL",
    idSistemaGestao: "0f051ded-0809-4e19-a492-d52311ae3b0b",
    idSisbov: "3925a762-22f0-4ebb-aa2d-30f6e9ec1c30",
    idICar: "d3b663af-d570-43cc-a131-68ead1ece296",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "d8f9c43d-2b71-4874-a8bb-3608a1f2727d",
    idAnimalFazenda: "0d8fd072-a50a-48dc-94f2-1ef13a59ad5d",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:31",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "4b98e891-6b1e-42f3-8c44-bb2dd574b7ba": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "4b98e891-6b1e-42f3-8c44-bb2dd574b7ba",
    docType: "ANIMAL",
    idSistemaGestao: "1abb914f-7ec5-4d0d-997c-c20cb700b4b0",
    idSisbov: "6979fbdd-e846-4fc4-9685-85607624f66c",
    idICar: "e470f9cd-3154-439d-8450-7b82def8fcc9",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "da170db9-07ca-426e-a6ed-22087bf12a17",
    idAnimalFazenda: "b5e8ce16-e68f-4846-bf77-53033df29784",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:30",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "4f087a37-7341-4e94-8735-6004515e9307": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "4f087a37-7341-4e94-8735-6004515e9307",
    docType: "ANIMAL",
    idSistemaGestao: "004df916-9ef4-4856-b1be-b16516c98b60",
    idSisbov: "867b61fe-cf2e-4ff9-8970-054fda59f543",
    idICar: "80b60b72-06ec-4ce5-8d9d-1fd0b9249c08",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "079c4f9b-1573-4976-8150-553c3643f3f5",
    idAnimalFazenda: "6a30aabe-b598-4acc-b6c3-b1580895870f",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:31",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "4f2f2e7d-db55-41c4-a14f-436c38da6311": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "4f2f2e7d-db55-41c4-a14f-436c38da6311",
    docType: "ANIMAL",
    idSistemaGestao: "17420a85-54c7-4fc9-a8e7-bd28e12ee575",
    idSisbov: "8e13d6b8-bb5f-4c46-8c2c-ff47065e42d6",
    idICar: "478b2723-da42-4d79-a5e9-12a7cf28df65",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "bbbf7c52-7c0e-4e49-b3a1-dc0f55937c31",
    idAnimalFazenda: "b433a972-2c65-433e-80ac-ab3e11bd75e3",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:31",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "52334151-ea73-4fd8-ad69-c885a6165e91": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "52334151-ea73-4fd8-ad69-c885a6165e91",
    docType: "ANIMAL",
    idSistemaGestao: "63139084-4d5d-4d34-a7c3-7ade0a81f0db",
    idSisbov: "2e049269-568c-4686-9485-d649e203db2e",
    idICar: "f8bc97a0-0cc7-4851-a40e-9575d59df98f",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "29d1aab6-7891-4cc4-8a14-52dd243c5bc5",
    idAnimalFazenda: "279149c6-e769-4dbf-9cb5-de7ec5b2d0ef",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:31",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "5278b335-8b81-4121-9198-fa3b52369736": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "5278b335-8b81-4121-9198-fa3b52369736",
    docType: "ANIMAL",
    idSistemaGestao: "96e78d84-4e38-4b0e-b9d2-f0a461f20731",
    idSisbov: "63e52d31-1551-4e00-8f73-7f5e2974ab12",
    idICar: "96122c70-fb52-4746-8fe3-b01f21303e8d",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "045f74ac-ca4d-4f3d-9e20-c348bf523ffa",
    idAnimalFazenda: "83124783-74c5-4eda-a9c4-0e452f1b8f85",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:31",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "58810a77-b408-4eb8-ab02-652542d74e6c": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "58810a77-b408-4eb8-ab02-652542d74e6c",
    docType: "ANIMAL",
    idSistemaGestao: "4cdbdefa-76e4-41d4-b349-4b28de1f3838",
    idSisbov: "f89435ed-26bb-49d9-a526-e7bd53c29a65",
    idICar: "8ad4b199-c384-485d-9db1-8737bd3c9a0a",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "a07af108-38f5-4f41-84dd-0fb316c30c57",
    idAnimalFazenda: "5a43302b-5177-4d0e-8796-700e84782c31",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:31",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "58879caa-43dc-456d-a189-4aa7b5b024cd": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "58879caa-43dc-456d-a189-4aa7b5b024cd",
    docType: "ANIMAL",
    idSistemaGestao: "b9c941c7-00c2-44ff-b7e6-b9f9a253aafb",
    idSisbov: "3050717e-bf24-4cc7-9032-7cb2419e29c9",
    idICar: "9cae4025-8877-4965-a60b-100eb29bd14b",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "5bfe2132-9354-4882-98d8-4e05eba63ef3",
    idAnimalFazenda: "5c88c9ef-fc35-40d2-9789-4b20a0f235b1",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:30",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "5a9ef1fc-dd2d-4a72-8229-f688a50a3711": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "5a9ef1fc-dd2d-4a72-8229-f688a50a3711",
    docType: "ANIMAL",
    idSistemaGestao: "e44f189f-d0e9-4126-a3a6-00dad1362db5",
    idSisbov: "60d0d103-6ae8-4f32-b378-c1eadf87116c",
    idICar: "f849e821-5312-41d8-879f-34fca270efe4",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "ae33187f-0242-4e60-89b8-d5034491a0c3",
    idAnimalFazenda: "a25a3b5d-ceea-47e7-a49e-405aa564e87f",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:30",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "5bf0118d-35c0-4d0a-9d01-cf76a34206a6": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "5bf0118d-35c0-4d0a-9d01-cf76a34206a6",
    docType: "ANIMAL",
    idSistemaGestao: "13837131-38f3-4060-a47c-f46c10db8ad8",
    idSisbov: "ca139885-63e0-4bb4-ae45-f39e9ce2577b",
    idICar: "f0dba5e9-e31d-4037-bc84-aa44591bf93e",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "736b1005-e633-48d3-abc9-03c7d894e837",
    idAnimalFazenda: "c698db42-9587-4f68-aadc-c22b0eeb7991",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:31",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "5c5ca4a7-f471-46ca-832b-298f151ee7cc": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "5c5ca4a7-f471-46ca-832b-298f151ee7cc",
    docType: "ANIMAL",
    idSistemaGestao: "c7073e90-6ffd-4b5d-9630-c2b5bcdd8c10",
    idSisbov: "7a86e625-fe8f-4d23-a68a-200b827fea9c",
    idICar: "f361780e-3dbc-4400-b2f3-9c97f470707a",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "42e55e20-0ca2-433f-9004-1a3ab47581a7",
    idAnimalFazenda: "2f2cf649-cdb3-4c36-89b7-b0df2240f0e9",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:30",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "603105f3-b564-457f-94b0-5d62fb2b37ab": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "603105f3-b564-457f-94b0-5d62fb2b37ab",
    docType: "ANIMAL",
    idSistemaGestao: "48078fef-3e43-4359-8dae-a6d9662f7b22",
    idSisbov: "167b2caf-0b3b-41e8-a0b4-adca384af5fa",
    idICar: "5857f0f2-1a9e-44ee-b629-1e5821dff8c6",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "cb3af948-0aaa-4b24-82ec-73f1494ffbad",
    idAnimalFazenda: "b6442d51-8156-4311-bb5b-589ff334ebfe",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:30",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "6054597c-968c-4e7a-a261-21d9cdc9c2d8": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "6054597c-968c-4e7a-a261-21d9cdc9c2d8",
    docType: "ANIMAL",
    idSistemaGestao: "b92ddfa7-bebb-4142-926d-af3bbe9caa48",
    idSisbov: "34719062-9f47-4a05-8e49-09c83ab607fb",
    idICar: "af395ce5-9e7f-4528-b81d-27d594ac91c7",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "b736e0f9-97ec-43d7-9f71-f5e4e5e018b6",
    idAnimalFazenda: "70ce25b5-334e-45ed-b597-b37506e10950",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:30",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "60f448bc-9aa7-46d6-8052-f59c14decc12": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "60f448bc-9aa7-46d6-8052-f59c14decc12",
    docType: "ANIMAL",
    idSistemaGestao: "103de1c3-77d2-482d-9144-138c2f40477b",
    idSisbov: "af07dadd-75ca-4bd5-8ad3-d6f10c6bf3f7",
    idICar: "1c427666-1880-4243-b6ad-f3ea0c50c895",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "9eddff33-7740-4f95-9843-1aa5e92cd0ed",
    idAnimalFazenda: "9ef78326-8cb6-4d97-ac47-466b821a119d",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:30",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "651e23fe-1e79-416d-b0e4-02e243702ab0": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "651e23fe-1e79-416d-b0e4-02e243702ab0",
    docType: "ANIMAL",
    idSistemaGestao: "320fe2f2-dc35-400f-be53-1f2066aacefd",
    idSisbov: "3e5fa146-15d3-42aa-9d3d-0e4b974b9b0e",
    idICar: "ecd6ec5a-6749-46bc-addd-be243f0dc2ac",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "19c55eec-26c1-4f17-8659-86bcb368aa4c",
    idAnimalFazenda: "c9d57177-ce9a-4f9a-a870-98cffff319b7",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:32",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "658d4e52-9ffe-4c7c-8085-ec1fabaa0eee": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "658d4e52-9ffe-4c7c-8085-ec1fabaa0eee",
    docType: "ANIMAL",
    idSistemaGestao: "525f4248-3e64-46e3-9c69-953c203cb38f",
    idSisbov: "1898af66-8d89-4fe0-bdab-343ab2288474",
    idICar: "065c7374-772f-471f-91cd-ece4424166b4",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "35e2068e-7cf8-4af9-b1cb-f0bb4e93d70c",
    idAnimalFazenda: "bb5e7a24-b74a-443e-9c2b-9b000be63f35",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:30",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "65b895c9-a84c-470e-af26-60f305de7105": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "65b895c9-a84c-470e-af26-60f305de7105",
    docType: "ANIMAL",
    idSistemaGestao: "31dc9621-eac3-4f5a-bdb1-a4b04000441a",
    idSisbov: "267d452a-e51c-4ca0-b60f-d028c0c08a84",
    idICar: "84e9d373-094a-46a9-a453-3110212f3dd6",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "a48e1056-5ec7-40c1-b34d-d860993d270b",
    idAnimalFazenda: "4d95fe39-9fc7-4baa-a1d3-13b9b5dce530",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:31",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "6bcd448f-1b44-4c38-b630-682d4bac75f4": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "6bcd448f-1b44-4c38-b630-682d4bac75f4",
    docType: "ANIMAL",
    idSistemaGestao: "9c3900ee-8409-4d0a-b10f-e205b8535cc5",
    idSisbov: "b86d2f0b-61ca-46cc-ab9d-35c0d7d0a16c",
    idICar: "29cf9efe-a0a1-4770-b5dd-0ac6beb3a103",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "57d12449-eb18-467c-9449-082d9511d714",
    idAnimalFazenda: "118e8760-bb76-469e-848c-62592e0f33ca",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:32",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "76d7db56-e90a-4575-a33e-bf7822bd79ea": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "76d7db56-e90a-4575-a33e-bf7822bd79ea",
    docType: "ANIMAL",
    idSistemaGestao: "89b46b93-b133-45cc-be76-11500a098f3f",
    idSisbov: "419c8e48-9d77-4002-9ef3-8968da2756bd",
    idICar: "fc02ac94-672a-4853-8953-59c89c3a1c8c",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "bd29376b-5e7b-4652-8231-a36ba4dad394",
    idAnimalFazenda: "cfa7d901-ca97-40c8-9f60-04b04be59560",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:32",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "786cf0d7-d25a-4342-8caf-757d7536e887": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "786cf0d7-d25a-4342-8caf-757d7536e887",
    docType: "ANIMAL",
    idSistemaGestao: "2de03cc8-0c6f-4b1f-b6df-53af921881f3",
    idSisbov: "12c5009d-f09a-49c9-9fae-39f5afcfef68",
    idICar: "22c9ea30-ef23-48d3-a370-654a28050d91",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "3d639507-0476-44f3-bc69-77812202ef3e",
    idAnimalFazenda: "6ef7faad-4c8e-4d18-b005-6051ae2a1605",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:30",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "7de7b940-5fae-4ee7-b11c-00c9564262ec": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "7de7b940-5fae-4ee7-b11c-00c9564262ec",
    docType: "ANIMAL",
    idSistemaGestao: "7a8a7f6d-60ce-480b-89ac-989276601ea9",
    idSisbov: "859a1891-1bb3-477b-bf99-8444d30b03e2",
    idICar: "d24e24ca-fe90-4479-b144-0f3d32321934",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "fe579623-cc15-443b-ab8f-d798e4ded909",
    idAnimalFazenda: "8e0ffa2d-5ce2-4b54-88ec-e3feb25f9788",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:30",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "7fd7405e-0aa0-4fdb-8e83-23f1e1af72d4": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "7fd7405e-0aa0-4fdb-8e83-23f1e1af72d4",
    docType: "ANIMAL",
    idSistemaGestao: "66f5e752-0cdd-46f5-bc14-c9a75c09ce4b",
    idSisbov: "e0332087-cde9-4840-9cd2-cac0e2bf257b",
    idICar: "5fc294c6-b0b5-4c8c-87d5-8a0f70c83738",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "b5002a89-238d-476f-b521-a58797573844",
    idAnimalFazenda: "3945a4f8-562f-4b8a-9bf4-f22f6f8757a2",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:31",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "81bfc6ad-59a5-4a51-8986-28a41c383aaf": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "81bfc6ad-59a5-4a51-8986-28a41c383aaf",
    docType: "ANIMAL",
    idSistemaGestao: "78d5686c-4246-42e5-a3d5-9b1317bd4480",
    idSisbov: "cfb4d382-b1c7-46c1-ad78-dc59ea25ab12",
    idICar: "eac56bf0-35aa-459f-b443-bb44ec643fd8",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "0524b927-9f31-4ea2-8c01-7acbc04946a9",
    idAnimalFazenda: "3f772dcd-6f2a-4e5e-a5a8-13afea362dd0",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:30",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "87eaad58-b694-420f-8dd4-b4b0b6c7a36e": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "87eaad58-b694-420f-8dd4-b4b0b6c7a36e",
    docType: "ANIMAL",
    idSistemaGestao: "08d4201d-04bf-4f59-89f0-c8f0a0a46778",
    idSisbov: "ab3b6bba-8f0b-45c6-8370-8994310fc2e7",
    idICar: "1fbd7dd1-b60f-4691-831b-ebecfb940603",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "0fa9f5c6-ebf7-41fa-b2ab-924bc6911314",
    idAnimalFazenda: "0f82faad-9573-4bb4-8be6-9ab70f1cade5",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:30",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "8e517297-4a68-4837-9846-f323716099ca": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "8e517297-4a68-4837-9846-f323716099ca",
    docType: "ANIMAL",
    idSistemaGestao: "809230f7-9656-4c9c-a426-9830044e948f",
    idSisbov: "469bb24c-b8ba-4d52-b1a5-cd44d5255f5a",
    idICar: "26a22151-1e90-48f9-8546-43279621c5d8",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "44c52e92-d432-4d0b-9b11-721d0a110bd9",
    idAnimalFazenda: "73b0cabd-6421-431e-907b-de4bc47ede9f",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:31",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "8ff0fd34-94a6-4665-92a0-0802649ad346": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "8ff0fd34-94a6-4665-92a0-0802649ad346",
    docType: "ANIMAL",
    idSistemaGestao: "845e9240-ea77-473f-ba54-81a63f41631c",
    idSisbov: "7d7328ae-7447-4324-9ebf-bb2a8d36fede",
    idICar: "45677275-a1cc-42fe-944d-a814004dad7e",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "e71437e8-8349-4c2e-a57a-af940cf01616",
    idAnimalFazenda: "77a8d274-74b6-42d4-a49f-b283e27a48f1",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:31",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "907102a9-dd7d-4a56-86e1-1c0a4b427d80": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "907102a9-dd7d-4a56-86e1-1c0a4b427d80",
    docType: "ANIMAL",
    idSistemaGestao: "a18561fa-d1b2-47a2-bc9e-c6f3357a1864",
    idSisbov: "80ef7ccc-67b9-4555-9e3b-36cfffa1149d",
    idICar: "2dacb1e3-2bcc-405b-9a93-810fbc9d2adc",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "7bb07758-e474-4687-b682-4dda7f2fdec3",
    idAnimalFazenda: "32b1a813-34bf-4a93-a6d9-6104a8c84ca9",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:31",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "93192039-974f-42d5-a6d6-d028c50e2b2a": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "93192039-974f-42d5-a6d6-d028c50e2b2a",
    docType: "ANIMAL",
    idSistemaGestao: "551aa599-f1b5-44a8-ba07-b4f577415aab",
    idSisbov: "0dbf0b12-e8c6-48ae-8017-20be187fe3f0",
    idICar: "2504fda1-704c-4821-939e-79770c7ea91f",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "2412d790-701e-449e-a0ec-6f5c55d2b32f",
    idAnimalFazenda: "dd78781a-936e-4382-af7b-ba6b2eef5873",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:32",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "9500012f-60a5-4712-a5e5-13e7903575f0": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "9500012f-60a5-4712-a5e5-13e7903575f0",
    docType: "ANIMAL",
    idSistemaGestao: "c4b4ffe1-9f93-44d4-ba40-30af638117ae",
    idSisbov: "f7b9e2df-13cb-4dc3-9b0c-1c677083951c",
    idICar: "a8f78593-304b-483e-82c4-83e63080ce18",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "5b9b5bde-2a6e-4315-9be8-9d264d828af2",
    idAnimalFazenda: "4e9eddd1-3a54-48f1-97a7-7914ac287ceb",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:30",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "950c96bf-4b94-4580-bf98-47fa6e8d55ae": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "950c96bf-4b94-4580-bf98-47fa6e8d55ae",
    docType: "ANIMAL",
    idSistemaGestao: "2950e000-0bb1-4cbc-b08e-358881139ad0",
    idSisbov: "1763893e-2f3e-4745-af0b-eee7477d3dd9",
    idICar: "03132ca9-20ea-48cf-a7c6-8396bdf0af99",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "07a621f5-be90-4f16-b25a-3e44f4ba675e",
    idAnimalFazenda: "42e32d5b-b044-4095-bce6-636e2569c7fc",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:31",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "96900e6e-1945-48a3-a170-7def7137da07": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "96900e6e-1945-48a3-a170-7def7137da07",
    docType: "ANIMAL",
    idSistemaGestao: "54369751-b9b6-4e9a-a05f-790b61972c3b",
    idSisbov: "875271a1-d45d-4818-998f-4681bfc50622",
    idICar: "5b02ef4e-194b-43bd-88a7-10a83f8cd7d4",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "2a68f6a5-0a29-4c9c-b5e4-9e210e1ff992",
    idAnimalFazenda: "2c0133e4-d2a2-49fb-8411-16636adb028c",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:30",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "9911f22e-414a-4d9a-8775-3e5c09f8ab78": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "9911f22e-414a-4d9a-8775-3e5c09f8ab78",
    docType: "ANIMAL",
    idSistemaGestao: "142863a1-39c9-4cf2-9bd6-7179a60d3e8c",
    idSisbov: "96dddb9c-9fb1-41ff-b75c-ccd2d0518a98",
    idICar: "b2ac64d1-6e06-4fdb-b48e-8326f8b0debd",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "1690945b-ad2e-4bc7-866c-72c2fe9fa8cc",
    idAnimalFazenda: "6fd2bfa2-54ac-46d9-a7c6-4f41430d638d",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:30",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "9b67eacb-b323-4af3-aa63-7e293f309ab9": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "9b67eacb-b323-4af3-aa63-7e293f309ab9",
    docType: "ANIMAL",
    idSistemaGestao: "df060436-508e-428a-aa86-13c1c372f58a",
    idSisbov: "5887e081-e8e2-4f36-83dd-1b640b0982d7",
    idICar: "e268c71d-6970-4a59-b0d9-19f262b5c58d",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "f855a1af-b348-44b4-92f3-4c391de8d4d3",
    idAnimalFazenda: "99f324be-09cb-44be-8092-40a7d16bf031",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:31",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "9c5d84d6-90fe-4cab-8263-47e24b84bed4": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "9c5d84d6-90fe-4cab-8263-47e24b84bed4",
    docType: "ANIMAL",
    idSistemaGestao: "246159af-eb82-4d72-9ff9-db9e40aece04",
    idSisbov: "612fb7e0-b965-4a2d-b5dc-28e2477c0768",
    idICar: "c4dcebdc-95ff-4720-a254-919295998ba6",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "8be390f9-918a-4ea7-af86-0a8c5ea6fa51",
    idAnimalFazenda: "53404174-5d8c-4c02-8a61-b8d3ac3f9fe6",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:31",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "9d3ae34d-5bc8-468f-8a48-9edb3845367c": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "9d3ae34d-5bc8-468f-8a48-9edb3845367c",
    docType: "ANIMAL",
    idSistemaGestao: "02ae8d86-b4eb-41ab-b77e-080a84376ee8",
    idSisbov: "fc1ab10a-13d2-4fbe-bffb-1d842a1841a5",
    idICar: "ba2b9d35-f64d-4b68-bc89-4d062b881d98",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "8d527577-c24b-4bc1-afcc-e6ea480f371b",
    idAnimalFazenda: "8e108f8c-2a55-45a9-8617-af0cd54f2014",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:30",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "9f5f5b5c-3976-41da-874d-920ce3a303e1": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "9f5f5b5c-3976-41da-874d-920ce3a303e1",
    docType: "ANIMAL",
    idSistemaGestao: "bda16567-a01b-4a86-aec4-641157106ce8",
    idSisbov: "5898903b-277a-4917-8daf-c05d80e9ce7b",
    idICar: "7837e8ce-a807-470e-af60-dce5c098c5d7",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "56a38674-7020-425c-91a0-d534a15fc3e0",
    idAnimalFazenda: "1e622d9a-cc85-4209-a348-d52057816c13",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:30",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "a66b4f44-67dd-4a1a-bfa5-dd9a91d3bf36": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "a66b4f44-67dd-4a1a-bfa5-dd9a91d3bf36",
    docType: "ANIMAL",
    idSistemaGestao: "4626d00a-6024-4fbb-9cfa-e3ec581a6a3c",
    idSisbov: "2566c2db-f6b3-4d45-911f-9b2954f2df1d",
    idICar: "c13483f6-f494-46a7-b3fb-2c1c249b2f3e",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "aa19f149-c8e4-4c54-890e-a4c34a474335",
    idAnimalFazenda: "1fa18762-a415-4dce-9dee-2a67f1e68599",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:30",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "a9d5c7a9-10bc-4714-a6a9-c6692c5d8782": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "a9d5c7a9-10bc-4714-a6a9-c6692c5d8782",
    docType: "ANIMAL",
    idSistemaGestao: "06b879db-7cbd-4f96-845b-84465c2cd432",
    idSisbov: "62ea7464-b0d7-4831-8bb2-ca056571eb28",
    idICar: "ce39a1cd-31cd-45f8-a914-681ed70093f5",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "cb80626a-6656-405d-a063-e877fa022046",
    idAnimalFazenda: "1658720d-02de-4492-bf07-f22a6c1509e7",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:31",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "aaa92670-146f-41f8-8dd4-60dd6a6fbcb0": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "aaa92670-146f-41f8-8dd4-60dd6a6fbcb0",
    docType: "ANIMAL",
    idSistemaGestao: "41b4452e-0964-4ad6-a2d2-66cedd31e13a",
    idSisbov: "7b496c49-5ddf-4933-8686-40cb559dcabe",
    idICar: "deea4c56-a867-4ae4-a121-abe821e5bf7b",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "fa97e1dc-d8a6-4a73-b578-e6213f193840",
    idAnimalFazenda: "8da06bbb-67d9-41e8-b39b-d3258fb3f2fc",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:30",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "aac8f2ff-2c84-4417-9713-9e9d39ee76e5": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "aac8f2ff-2c84-4417-9713-9e9d39ee76e5",
    docType: "ANIMAL",
    idSistemaGestao: "2731a901-a308-448a-88fc-e196a55b1b78",
    idSisbov: "edb25244-4a56-4084-8daa-0294f9e61ca5",
    idICar: "b40392d0-d4e8-4d19-8ba6-c65784795aca",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "6acd2f8f-b635-47c8-9dbf-b3e5b8aa2b2d",
    idAnimalFazenda: "736483c5-b1db-4e80-a77d-9420361e1e1c",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:30",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "b1f7bd7b-ebc9-4640-8683-3d5ac85ab867": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "b1f7bd7b-ebc9-4640-8683-3d5ac85ab867",
    docType: "ANIMAL",
    idSistemaGestao: "fcdea7fc-9782-4aa2-af84-60b595aa2ea7",
    idSisbov: "bd1f7895-4cb2-436e-93ed-1d7899bea852",
    idICar: "9093ec8a-efbd-4a4f-8066-0d98a4f783d4",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "7bb27e54-f5dd-4279-9218-e09e2bedb6d7",
    idAnimalFazenda: "18215d57-39b7-4464-a92f-b496ed4ffc2a",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:30",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "b203b583-ed36-42fc-a153-c244c430bd41": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "b203b583-ed36-42fc-a153-c244c430bd41",
    docType: "ANIMAL",
    idSistemaGestao: "701a34dd-c2f0-40f7-b417-9bb663b7ab88",
    idSisbov: "1c3fa24a-0e17-4723-9590-55230313df48",
    idICar: "ba742a63-22c0-47b6-a00d-1949c1e54930",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "998b7219-54af-4824-b327-e4faaa7a2282",
    idAnimalFazenda: "1db7cfb9-e9c1-481d-ba56-84fe1bd315d1",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:30",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "b26b520d-8f15-46d1-a357-a5e01d97da60": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "b26b520d-8f15-46d1-a357-a5e01d97da60",
    docType: "ANIMAL",
    idSistemaGestao: "619b0fe0-f0e5-45b7-bd7b-c3f75e42d9a1",
    idSisbov: "1aec7fd9-dcd8-41f0-b005-e75103e519d2",
    idICar: "8de3dd38-5e7e-4e03-8640-23cbe9f12be7",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "cebf0c7f-77e1-4d30-a98a-0f12881ee0db",
    idAnimalFazenda: "661ebd31-92ec-4eaf-a61c-f82e41f2b745",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:30",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "b490ff17-ee84-4ac0-ae52-bcb01689ddab": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "b490ff17-ee84-4ac0-ae52-bcb01689ddab",
    docType: "ANIMAL",
    idSistemaGestao: "ddbbf4f1-4527-416e-9104-33afc7938b15",
    idSisbov: "a2f1186c-185b-4cf8-9cb0-56c4e97986c1",
    idICar: "e39dce10-ad76-45a1-97bc-55fd2c49b08c",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "47839827-4e79-4ed2-b368-2ef5cc889bd5",
    idAnimalFazenda: "ba567526-33c0-43be-8fd7-e7b9fba7352d",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:30",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "b5eccfb1-c3d8-4d62-9dd9-79a63a84f85c": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "b5eccfb1-c3d8-4d62-9dd9-79a63a84f85c",
    docType: "ANIMAL",
    idSistemaGestao: "54b2757d-75fc-43ea-9329-f48a3e6e39f6",
    idSisbov: "26a7d796-90f6-464d-8c64-efd9c2590895",
    idICar: "5bf9f216-6a0f-4e23-be0c-cdaabb327365",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "e3929565-eb08-4fee-85a2-623eb8111895",
    idAnimalFazenda: "fceb911d-9bc8-41ef-9955-dd7f2a52bc23",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:31",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "b69d5406-ade6-4bae-873f-3a020f6c51da": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "b69d5406-ade6-4bae-873f-3a020f6c51da",
    docType: "ANIMAL",
    idSistemaGestao: "6a12b34e-ef2e-45ee-83e8-efb09fe4c9f3",
    idSisbov: "eba5f71d-01d6-4bc4-9d8f-802c511c8cbb",
    idICar: "624d6954-ad91-42bc-9944-d98cb12f529b",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "fa768aa5-6e63-40ee-833c-4f9961cb4511",
    idAnimalFazenda: "8054ca5e-6306-42a1-8675-f9e6af58846b",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:31",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "b70889c7-7e1e-4180-8d49-366a854dc4a3": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "b70889c7-7e1e-4180-8d49-366a854dc4a3",
    docType: "ANIMAL",
    idSistemaGestao: "50a55f50-fd6c-4eee-9203-c48bc6f2b152",
    idSisbov: "86e2b254-2fca-4bf5-a643-879255bbfc11",
    idICar: "f401e93f-b4de-4395-ab37-38a7c772d8e3",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "a421b91b-ac7f-4a5f-ac79-769b4c9bbb38",
    idAnimalFazenda: "66cac844-4230-4936-958a-22414b12a667",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:30",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "b7edb8a3-57f1-43cf-8b90-a035b96ba0f9": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "b7edb8a3-57f1-43cf-8b90-a035b96ba0f9",
    docType: "ANIMAL",
    idSistemaGestao: "d7646763-30c5-4b49-a1d9-616fc90e3147",
    idSisbov: "4ea1b1d3-b17a-484a-885f-48976a8c88a0",
    idICar: "e412f51c-3f95-4c69-ad0d-d0cb604098f6",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "8b397e74-c3c3-433d-b4c5-fb51608c0882",
    idAnimalFazenda: "b93f4690-d9b7-4811-bac9-6df078725427",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:30",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "b8a12dbf-05c2-4623-b74b-86fbac57fc73": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "b8a12dbf-05c2-4623-b74b-86fbac57fc73",
    docType: "ANIMAL",
    idSistemaGestao: "62e11cbd-d63f-437c-84d0-2eaf7ceca59d",
    idSisbov: "c19bab22-97c5-466b-b0cf-08557f270cdb",
    idICar: "3f8044ff-7b78-406e-9a05-7f3acf5117da",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "c6066d99-6843-468a-8cd3-b67dd3cf9273",
    idAnimalFazenda: "fd07e646-e4e0-4b69-8692-21344f62744f",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:32",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "bbf13927-e928-402a-af1b-765a87fc7258": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "bbf13927-e928-402a-af1b-765a87fc7258",
    docType: "ANIMAL",
    idSistemaGestao: "13dbfd3e-325f-45fe-a680-de2a6b7dcd60",
    idSisbov: "13dbbe8f-7629-4b64-a579-3a0879afe0e1",
    idICar: "a1903bed-5c81-4166-aa32-936d02887d3b",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "23d89e2c-f5be-4aa7-a1ee-72aba7fb8f4d",
    idAnimalFazenda: "c5260a5e-b9cb-4396-9ac2-1de95f75a8aa",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:31",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "bd5820eb-51dd-43dc-a43f-0bcde9c69546": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "bd5820eb-51dd-43dc-a43f-0bcde9c69546",
    docType: "ANIMAL",
    idSistemaGestao: "149b0270-0f21-4374-98ca-0fc577f13694",
    idSisbov: "6b70236a-2df9-45ab-9799-d7dfa76570d4",
    idICar: "b7cbf5c2-ea4e-4752-b3d8-fadda86fd77a",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "96e6bc53-6ce6-4f79-b48c-3ec786896dc6",
    idAnimalFazenda: "bda5d091-d014-4142-8e6e-72d9a8f2df6c",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:31",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "bee430df-029f-465f-b06d-1586c5ad4c22": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "bee430df-029f-465f-b06d-1586c5ad4c22",
    docType: "ANIMAL",
    idSistemaGestao: "bfd38949-6108-4095-8f31-3cc4e9f8eeaa",
    idSisbov: "6aa586ed-3393-4b47-912f-a02fe9eda231",
    idICar: "d9fbaa99-a905-400a-af7b-682163fda11e",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "fb96d1fc-c7a5-432f-83f9-5ba31816fd82",
    idAnimalFazenda: "38803608-48e3-437a-b847-9d33dab1325b",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:30",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "befb95f3-ed54-4b6f-b3ab-f8d43755ed41": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "befb95f3-ed54-4b6f-b3ab-f8d43755ed41",
    docType: "ANIMAL",
    idSistemaGestao: "36d9b192-61ac-4e0f-b086-ac7fb3e7c39f",
    idSisbov: "10bb8881-52f4-4ff3-899c-14fb2c644715",
    idICar: "9c7ede9f-35b4-4466-89b2-6a466f453190",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "81eb742d-d584-4588-ba21-c0784fb1b796",
    idAnimalFazenda: "48ceed3a-edd7-4957-89bd-7bdf75f839d9",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:09:22",
      idLoteSync: "241eb0b4-cfc1-4e27-812b-833339dd429f",
      idSistemaGestao: "12321",
      idResponsavel: "1111111",
      nomeResponsavel: "Mauricio Augusta",
      latitude: "",
      longitude: "",
      tipo: "PESO 0",
      peso: 150,
      dtPesagem: "2023-03-26T01:01:01",
      idPesagemBlockchain: "b95d5b40-6764-4856-a398-03e927963a28",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:09:22",
        idLoteSync: "241eb0b4-cfc1-4e27-812b-833339dd429f",
        idSistemaGestao: "12321",
        idResponsavel: "1111111",
        nomeResponsavel: "Mauricio Augusta",
        latitude: "",
        longitude: "",
        tipo: "PESO 0",
        peso: 100,
        dtPesagem: "2023-01-26T01:01:01",
        idPesagemBlockchain: "cd5b57c5-bd87-43f0-b112-ab3cd3d4e80f",
      },
      {
        dtCriacao: "2023-12-18T14:09:22",
        idLoteSync: "241eb0b4-cfc1-4e27-812b-833339dd429f",
        idSistemaGestao: "12321",
        idResponsavel: "1111111",
        nomeResponsavel: "Mauricio Augusta",
        latitude: "",
        longitude: "",
        tipo: "PESO 0",
        peso: 150,
        dtPesagem: "2023-03-26T01:01:01",
        idPesagemBlockchain: "b95d5b40-6764-4856-a398-03e927963a28",
      },
    ],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T18:31:42",
    vendaAtual: {
      idBlockchain: "b417987c-e9f4-4cde-be7b-af6ce0859ba7",
      documentoProprietarioDestino: "222",
      tipo: "INDUSTRIA",
      proprietarioOrigem: {
        nome: "João da Silva",
        documento: "40740176000103",
      },
      proprietarioDestino: { nome: "Frigorífico", documento: "222" },
      dataCriacao: "2023-12-18T18:31:42",
      dataUltimaAtualizacao: "2023-12-18T18:31:42",
      status: "NAO_CONFIRMADA",
      qtdAnimais: 1,
    },
    vendaHistorico: [
      {
        idBlockchain: "1803037a-75ad-4421-b681-41faee7d449d",
        documentoProprietarioDestino: "18293459000196",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "João da Silva",
          documento: "40740176000103",
        },
        proprietarioDestino: {
          nome: "Guidara Indústria e Comércio de Alimentos Eireli",
          documento: "18293459000196",
        },
        dataCriacao: "2023-12-18T18:29:06",
        dataUltimaAtualizacao: "2023-12-18T18:31:20",
        status: "RECUSADA",
        qtdAnimais: 1,
      },
    ],
    reproducoes: [],
    acoes: [
      {
        dtCriacao: "2023-12-18T14:10:19",
        dtAlteracao: "2023-12-18T14:10:19",
        idLoteSync: "241eb0b4-cfc1-4e27-812b-833339dd429f",
        idSistemaGestao: "1321",
        idResponsavel: "11111111",
        nomeResponsavel: "Carlão",
        dtAcao: "2023-03-24",
        nome: "Prisao do boi 3 - atualizada",
        latitude: "31.213131",
        longitude: "31.213131",
        idAcaoBlockchain: "f56453e3-6f82-4bc8-85b1-3572bec423a7",
      },
    ],
    exames: [
      {
        dtCriacao: "2023-12-18T14:10:00",
        dtAlteracao: "2023-12-18T14:10:00",
        idLoteSync: "241eb0b4-cfc1-4e27-812b-833339dd429f",
        idSistemaGestao: "23131",
        idResponsavel: "111111",
        nomeResponsavel: "Mauricio",
        latitude: "",
        longitude: "",
        tipoExame: "ANDROLOGICO",
        dtExame: "2022-11-01",
        vrResultado: "APTO",
        vrResultadoAol: "",
        vrResultadoEgs: "",
        vrResultadoEgg: "",
        vrResultadoMar: "",
        vrResultadoRatio: "",
        qtDiasPrenhe: null,
        icApresentouCio: null,
        idExameBlockchain: "c7b259cb-601e-4d4a-a626-dedd575617cc",
      },
    ],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:09:41",
      dtAlteracao: "2023-12-18T14:09:41",
      idLoteSync: "241eb0b4-cfc1-4e27-812b-833339dd429f",
      fazenda: {
        nome: "Fazenda 1",
        dataEntrada: "2021-09-09",
        latitude: "-21.3213132",
        longitude: "11.2321312",
      },
      subdivisao: { nome: "Subdivisão 1", dataEntrada: "2022-12-09" },
      idSistemaGestao: "124312",
      idLocalizacaoBlockchain: "09b68ff9-463a-4fa4-b888-de1dee70b7c9",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:09:41",
        dtAlteracao: "2023-12-18T14:09:41",
        idLoteSync: "241eb0b4-cfc1-4e27-812b-833339dd429f",
        fazenda: {
          nome: "Fazenda 1",
          dataEntrada: "2021-09-09",
          latitude: "-21.3213132",
          longitude: "11.2321312",
        },
        subdivisao: { nome: "Subdivisão 1", dataEntrada: "2022-12-09" },
        idSistemaGestao: "124312",
        idLocalizacaoBlockchain: "09b68ff9-463a-4fa4-b888-de1dee70b7c9",
      },
    ],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "c23fb3b5-a605-4353-a0b5-425c630812b8": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "c23fb3b5-a605-4353-a0b5-425c630812b8",
    docType: "ANIMAL",
    idSistemaGestao: "a811b877-28dd-469a-aa60-b79b05416b18",
    idSisbov: "c580da21-d965-4cd6-ac1b-0e9a48d0d106",
    idICar: "c1a88931-b73c-45c6-a355-e4455778792b",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "9448ed95-0dab-41da-8ae1-70a312468a49",
    idAnimalFazenda: "fee7a0c7-84b8-4a8c-9bdc-02c7bc640e98",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:31",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "c2edb64a-cdd6-4c2e-a4cc-dd9445c5914b": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "c2edb64a-cdd6-4c2e-a4cc-dd9445c5914b",
    docType: "ANIMAL",
    idSistemaGestao: "9d9a355e-d6e1-4902-a6e6-1dd59b9d7576",
    idSisbov: "75d35706-1748-4e6f-8eda-32cec6771afe",
    idICar: "e86754aa-cad8-4a2d-8cb5-74741c8f0395",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "fbd42c39-eeb7-45da-a839-d1621414108d",
    idAnimalFazenda: "f6dedc29-bf47-4d10-a025-5e485351f015",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:32",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "c91c58c2-9bb5-45f6-beeb-e5d622d65c32": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "c91c58c2-9bb5-45f6-beeb-e5d622d65c32",
    docType: "ANIMAL",
    idSistemaGestao: "a3f932b3-3a42-4353-b51e-465d2aba48aa",
    idSisbov: "712c4fbe-59fc-491a-b740-88fbbe47ac98",
    idICar: "86918d97-74bc-4664-ae96-03ff876c47ba",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "b00b9bf0-f9ae-4468-8322-6102edb065a1",
    idAnimalFazenda: "617b49bd-1773-42e7-90b1-e2652f98910a",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:32",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "d003c4b7-45e7-49f7-93ec-471b689ffd13": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "d003c4b7-45e7-49f7-93ec-471b689ffd13",
    docType: "ANIMAL",
    idSistemaGestao: "32edb46d-0d20-4b5c-b0ee-44f918cb6e1f",
    idSisbov: "8cb94d70-6935-4fa9-9526-d19ab30bb631",
    idICar: "49fb0bdc-eeb6-48d1-8c34-844b85ed210b",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "ef08980f-b5ec-495b-b3bf-9c7bb7c1556b",
    idAnimalFazenda: "fafaafeb-d1a8-48c4-88f4-a66b1765e649",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:31",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "d1d7fe11-ad09-4b3d-8cdd-f57cdca07138": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "d1d7fe11-ad09-4b3d-8cdd-f57cdca07138",
    docType: "ANIMAL",
    idSistemaGestao: "a4a4d172-bf8e-452d-879b-87ca73485ace",
    idSisbov: "83d94d65-2b50-4a90-b024-43597886393a",
    idICar: "993cb77f-aaf0-4d13-8059-e6611c6006d1",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "192eea54-bc9b-4a37-8b90-429e32caa84a",
    idAnimalFazenda: "19247b7f-e1db-44f2-88eb-202fa8bbb510",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:32",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "d3cb95a3-14b3-4e3c-85cd-9b863a98b2d3": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "d3cb95a3-14b3-4e3c-85cd-9b863a98b2d3",
    docType: "ANIMAL",
    idSistemaGestao: "0e955ba0-0f7b-4ddf-9f00-389684dfd2ba",
    idSisbov: "f4d272b7-7a1d-4a09-9a38-97c8563e5314",
    idICar: "88b77d40-af32-48d3-a2f5-8c6b252d3c3c",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "4c686b21-c295-421a-af0f-465016c913cc",
    idAnimalFazenda: "f2e8d588-9d90-4be5-a641-cff277fbe0ce",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:31",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "db035166-3e36-42b7-bb17-7c45f7650f76": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "db035166-3e36-42b7-bb17-7c45f7650f76",
    docType: "ANIMAL",
    idSistemaGestao: "7de65719-714d-46fe-a0b0-65448c1d299f",
    idSisbov: "df797b75-d814-4f58-a5d5-a978e7dabb52",
    idICar: "ed597b62-9eec-41a1-8ce5-a62bbea2e7e6",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "1cd7b734-6fbf-4356-80f9-f20b7aa62acb",
    idAnimalFazenda: "62e71ed4-e6b3-4040-9d7c-16e4ea37551f",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:31",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "e374a359-f51a-49d2-a013-4d4f5d871c43": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "e374a359-f51a-49d2-a013-4d4f5d871c43",
    docType: "ANIMAL",
    idSistemaGestao: "b8a48445-188e-4efe-98bc-ce555c5c479e",
    idSisbov: "4af5e992-506d-436f-82d9-abfd56117bc2",
    idICar: "52ae5076-878b-4987-ab2e-87f394b38330",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "84c6ff93-7ac0-42a5-8763-8dc47e8e76fd",
    idAnimalFazenda: "185702fc-cf21-4a14-9115-ef56614ef1f8",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:30",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "e38ec99a-e3d9-4531-84e2-efd8947ed637": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "e38ec99a-e3d9-4531-84e2-efd8947ed637",
    docType: "ANIMAL",
    idSistemaGestao: "4d722b09-c250-48aa-96ea-c1d5f015633f",
    idSisbov: "7bdab9a7-380f-42ee-b5ae-37dc577bc037",
    idICar: "d0117dc7-a816-4359-9291-b913316981c7",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "22a117fc-e553-4e3a-a463-1386f6a915db",
    idAnimalFazenda: "c1ffb780-95f1-4c50-bc9b-4747cc51ffa4",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:30",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "e5a6f6c6-93a9-4cf1-9a81-0de0b32592d7": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "e5a6f6c6-93a9-4cf1-9a81-0de0b32592d7",
    docType: "ANIMAL",
    idSistemaGestao: "d11fd297-4504-40b9-9897-a5d20c8aee07",
    idSisbov: "9b542ac3-8fbb-4a12-88fd-bc534ee3d936",
    idICar: "1b47f8a3-0b66-47f0-98ad-f70a68254862",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "7d00203c-bc29-43ab-ae9f-6bd6c2678f84",
    idAnimalFazenda: "1b7b73d8-f90b-4db7-a752-6051c777d790",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:30",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "e7030679-9f32-4af0-a0b8-a6fa4135e9d3": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "e7030679-9f32-4af0-a0b8-a6fa4135e9d3",
    docType: "ANIMAL",
    idSistemaGestao: "ca2a738e-1903-4f9e-8f34-898b3ac99222",
    idSisbov: "feac64b0-4c3b-43dd-bf90-974cbd319279",
    idICar: "6196bc5f-7f8c-4446-b3a3-a814e41159eb",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "5ce33fcb-6922-4b13-83f8-a9d29b8f7da8",
    idAnimalFazenda: "66755de6-b82d-4ace-8e7a-275c6dcb1fe4",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:31",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "eba07f5b-340d-4b2f-892a-6e713924dea0": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "eba07f5b-340d-4b2f-892a-6e713924dea0",
    docType: "ANIMAL",
    idSistemaGestao: "b794ae19-d8e2-4299-8495-b5ae3eb22348",
    idSisbov: "eedb8903-dd66-415a-bc5d-493b507d6d3c",
    idICar: "5d8df72b-18be-4bb3-9db2-26c692d0ba38",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "bb113140-a0d1-4741-96ad-1e9dbe4ccad1",
    idAnimalFazenda: "2a3ea0cb-b4d4-4e89-92e5-73fde0b8b287",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T14:09:22",
      idLoteSync: "241eb0b4-cfc1-4e27-812b-833339dd429f",
      idSistemaGestao: "12321",
      idResponsavel: "1111111",
      nomeResponsavel: "Mauricio Augusta",
      latitude: "",
      longitude: "",
      tipo: "PESO 0",
      peso: 150,
      dtPesagem: "2023-03-26T01:01:01",
      idPesagemBlockchain: "4d8e6656-0a8c-408f-990d-cfa1a6e72a7e",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T14:09:22",
        idLoteSync: "241eb0b4-cfc1-4e27-812b-833339dd429f",
        idSistemaGestao: "12321",
        idResponsavel: "1111111",
        nomeResponsavel: "Mauricio Augusta",
        latitude: "",
        longitude: "",
        tipo: "PESO 0",
        peso: 100,
        dtPesagem: "2023-01-26T01:01:01",
        idPesagemBlockchain: "3acd67b5-cf64-412a-b104-c5d3a8485697",
      },
      {
        dtCriacao: "2023-12-18T14:09:22",
        idLoteSync: "241eb0b4-cfc1-4e27-812b-833339dd429f",
        idSistemaGestao: "12321",
        idResponsavel: "1111111",
        nomeResponsavel: "Mauricio Augusta",
        latitude: "",
        longitude: "",
        tipo: "PESO 0",
        peso: 150,
        dtPesagem: "2023-03-26T01:01:01",
        idPesagemBlockchain: "4d8e6656-0a8c-408f-990d-cfa1a6e72a7e",
      },
    ],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "ABATIDO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T18:45:30",
    vendaAtual: {
      idBlockchain: "072f90e7-27c9-47bd-ab4c-a4170bb6839b",
      documentoProprietarioDestino: "07162028000417",
      tipo: "INDUSTRIA",
      proprietarioOrigem: {
        nome: "João da Silva",
        documento: "40740176000103",
      },
      proprietarioDestino: {
        nome: "VPJ Alimentos",
        documento: "07162028000417",
      },
      dataCriacao: "2023-12-18T18:19:19",
      dataUltimaAtualizacao: "2023-12-18T18:41:14",
      status: "EFETIVADA",
      qtdAnimais: 1,
    },
    vendaHistorico: [
      {
        idBlockchain: "072f90e7-27c9-47bd-ab4c-a4170bb6839b",
        documentoProprietarioDestino: "07162028000417",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "João da Silva",
          documento: "40740176000103",
        },
        proprietarioDestino: {
          nome: "VPJ Alimentos",
          documento: "07162028000417",
        },
        dataCriacao: "2023-12-18T18:19:19",
        dataUltimaAtualizacao: "2023-12-18T18:41:14",
        status: "EFETIVADA",
        qtdAnimais: 1,
      },
    ],
    reproducoes: [],
    acoes: [
      {
        dtCriacao: "2023-12-18T14:10:19",
        dtAlteracao: "2023-12-18T14:10:19",
        idLoteSync: "241eb0b4-cfc1-4e27-812b-833339dd429f",
        idSistemaGestao: "123",
        idResponsavel: "11111111",
        nomeResponsavel: "Augusto",
        dtAcao: "2021-11-01",
        nome: "Castração 4",
        latitude: "",
        longitude: "",
        idAcaoBlockchain: "38f4f142-0d7d-4f0c-ab52-b6d76c2675fe",
      },
    ],
    exames: [
      {
        dtCriacao: "2023-12-18T14:10:00",
        dtAlteracao: "2023-12-18T14:10:00",
        idLoteSync: "241eb0b4-cfc1-4e27-812b-833339dd429f",
        idSistemaGestao: "23131",
        idResponsavel: "111111",
        nomeResponsavel: "Mauricio",
        latitude: "",
        longitude: "",
        tipoExame: "ANDROLOGICO",
        dtExame: "2022-11-01",
        vrResultado: "APTO",
        vrResultadoAol: "",
        vrResultadoEgs: "",
        vrResultadoEgg: "",
        vrResultadoMar: "",
        vrResultadoRatio: "",
        qtDiasPrenhe: null,
        icApresentouCio: null,
        idExameBlockchain: "da6da5e6-5544-4883-92b6-6daf8b8b935e",
      },
    ],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:09:41",
      dtAlteracao: "2023-12-18T14:09:41",
      idLoteSync: "241eb0b4-cfc1-4e27-812b-833339dd429f",
      fazenda: {
        nome: "Fazenda 1",
        dataEntrada: "2021-09-09",
        latitude: "-21.3213132",
        longitude: "11.2321312",
      },
      subdivisao: { nome: "Subdivisão 1", dataEntrada: "2022-12-09" },
      idSistemaGestao: "124312",
      idLocalizacaoBlockchain: "471cc5bd-4c68-4d09-9716-7637a88d4029",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:09:41",
        dtAlteracao: "2023-12-18T14:09:41",
        idLoteSync: "241eb0b4-cfc1-4e27-812b-833339dd429f",
        fazenda: {
          nome: "Fazenda 1",
          dataEntrada: "2021-09-09",
          latitude: "-21.3213132",
          longitude: "11.2321312",
        },
        subdivisao: { nome: "Subdivisão 1", dataEntrada: "2022-12-09" },
        idSistemaGestao: "124312",
        idLocalizacaoBlockchain: "471cc5bd-4c68-4d09-9716-7637a88d4029",
      },
    ],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: {
      nome: "VPJ",
      maturidade: "50%",
      acabamento: "",
      pesoBanda1: "",
      pesoBanda2: "",
      obs1: "",
      obs2: "",
      dtMorte: "2023-03-26",
      porcentagemCarcaca: "",
      dtCriacao: "2023-12-18T18:45:30",
      dtAlteracao: "2023-12-18T18:45:30",
    },
  },
  "ef819536-a882-422e-a3a7-e64ac72ceffb": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "ef819536-a882-422e-a3a7-e64ac72ceffb",
    docType: "ANIMAL",
    idSistemaGestao: "75a4e87f-0d57-4885-9fab-41a8ba8f2690",
    idSisbov: "577bf571-00f3-4c60-98c0-67ced0ddeb5e",
    idICar: "b8e1ed5e-37cc-49d9-b08d-71eadfc4d0c9",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "57f8f4aa-e5b0-4589-9c9c-7208be6fc9ad",
    idAnimalFazenda: "a40a5c9c-d2e6-4c2a-99c0-0318164109bb",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:32",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "efc616cd-4eff-47c1-a9f8-138e77dd8bb2": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "efc616cd-4eff-47c1-a9f8-138e77dd8bb2",
    docType: "ANIMAL",
    idSistemaGestao: "671c5da1-f324-4e16-bc3d-c5c27f13e76e",
    idSisbov: "4044c80e-ac22-4665-91b2-2f848035ae85",
    idICar: "a956aaae-b10b-4116-a541-b9ea948c8f3c",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "bf694538-9138-4757-8a91-7deb83958248",
    idAnimalFazenda: "5ef16494-0e03-4af7-a319-e31486c552bc",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:30",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "f0d53d67-01d3-4a6f-81c9-39d454068e1c": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "f0d53d67-01d3-4a6f-81c9-39d454068e1c",
    docType: "ANIMAL",
    idSistemaGestao: "b30cdb18-2066-4ab5-86da-6fb2cb3e66d3",
    idSisbov: "332b257c-9cdf-41fb-b3dd-8a8856cd9acd",
    idICar: "9b4e4fed-ce60-4124-84d7-d8f41ecf010b",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "d36728ce-69fb-4ea3-88b0-2d2e4123a402",
    idAnimalFazenda: "d9cbeff5-9348-45f1-aa1b-28fbc280691a",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:31",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "f25c3347-44ef-49f7-9a2a-b48f55558837": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "f25c3347-44ef-49f7-9a2a-b48f55558837",
    docType: "ANIMAL",
    idSistemaGestao: "c3849125-79c4-4ec4-8cb6-c33d4539fa6e",
    idSisbov: "e248a633-6052-436c-aa33-ef699cc4faaf",
    idICar: "10b50203-3518-4dea-bd92-d078579793e6",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "3701bd1a-98f0-44a1-bd2c-0ced3b9328d8",
    idAnimalFazenda: "3f2873b5-482e-411d-a74d-bd00069ff9ba",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:31",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "f25fa045-a476-43ab-a8f6-5cf0c5257f03": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "f25fa045-a476-43ab-a8f6-5cf0c5257f03",
    docType: "ANIMAL",
    idSistemaGestao: "b667c3f2-6209-471e-8279-55ca4f3a5a14",
    idSisbov: "440359a3-5bf2-45e9-8fae-5c3afe749221",
    idICar: "3c1bda4c-b42a-4653-b423-ae648b298055",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "0c70367c-cf69-4b58-a3dc-3d652e9ba514",
    idAnimalFazenda: "f6d32f02-c5f5-484f-a47c-c57d3d4bc89d",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:31",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "f496f3f6-8fab-4a6b-bda9-f9fbc3921644": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "f496f3f6-8fab-4a6b-bda9-f9fbc3921644",
    docType: "ANIMAL",
    idSistemaGestao: "620e4620-f32c-4c2d-a997-10d9ecc1260f",
    idSisbov: "9fe15f69-6599-4659-8523-b87467bb9622",
    idICar: "4f20320c-f55e-4ae9-bc41-a28872f224df",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "2c19e987-97de-4c21-8916-6483b8767abe",
    idAnimalFazenda: "bd404a6d-9d54-4c33-9990-a06859ae98d0",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:31",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "f7fb5be2-e59d-44b8-bfc2-864182252cf2": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "f7fb5be2-e59d-44b8-bfc2-864182252cf2",
    docType: "ANIMAL",
    idSistemaGestao: "b6455449-47ad-46db-83d5-14c8e2b25f12",
    idSisbov: "851e75b8-f9b5-4dd1-9384-afb344735f63",
    idICar: "820007f6-1d3d-4d14-9ef5-29700b1928da",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "af3d0f83-0f8c-4ad5-b359-61bcc3916ae5",
    idAnimalFazenda: "f524e8ca-5ca6-42ea-a44d-ab9d92d37062",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:31",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "fbf32aee-76ae-49a4-8876-f0d87ecce210": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "fbf32aee-76ae-49a4-8876-f0d87ecce210",
    docType: "ANIMAL",
    idSistemaGestao: "7e9ab93e-f77a-4612-bfd9-cfde0dd7c6e3",
    idSisbov: "389ab511-154b-4362-929b-5a6162c4ec21",
    idICar: "d375b595-a66b-4e6b-b4ad-3d5057bd7bf5",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "28e090b2-646d-4926-a312-2001a9b63853",
    idAnimalFazenda: "b72f3194-21aa-4431-9cbb-5b3515c85e3d",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:31",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "fe157c64-393d-46eb-b100-7cf604eb00c0": {
    idLoteSync: "0f7c7c27-1f74-44d1-b214-375240b4301c",
    idBlockchain: "fe157c64-393d-46eb-b100-7cf604eb00c0",
    docType: "ANIMAL",
    idSistemaGestao: "9fada6d4-1527-49b5-9352-d8400d437f61",
    idSisbov: "3efee03d-3e6f-4bcb-bd84-893de8b87c3a",
    idICar: "4d5acc56-363f-4f2a-96fd-12509f2e6d20",
    nome: "Boi Barão",
    dataNascimento: "2020-01-01",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "0d879a1b-d6f1-43b5-a32c-a785f9fd618e",
    idAnimalFazenda: "76137b30-898f-4d5f-b473-d3544e4383fb",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "4fd136f7-0b1a-4297-9e67-ea0a0e43c335",
      nome: "João da Silva",
      documento: "40740176000103",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:17:30",
    dataUltimaAtualizacao: "2023-12-18T13:17:30",
    vendaAtual: null,
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "1231231312",
    nomeResponsavel: "Mauricião",
    frigorifico: null,
  },
  "079213ff-8b2b-4206-8595-71c73d65aa9a": {
    idLoteSync: "7adfa622-1d0c-41a1-accf-1a4d29fa2e22",
    idBlockchain: "079213ff-8b2b-4206-8595-71c73d65aa9a",
    docType: "ANIMAL",
    idSistemaGestao: "92790bf6-7be4-409a-8cb1-b580eeb986c0",
    idSisbov: "105320181010059",
    idICar: "",
    nome: "",
    dataNascimento: "2020-01-10",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "101005",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Aberdeen Angus", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "5ba141f9-74f9-4630-be2f-ceebde517b39",
      nome: "teste-safe",
      documento: "123456789123456",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:21:49",
    dataUltimaAtualizacao: "2023-12-18T18:35:36",
    vendaAtual: {
      idBlockchain: "fcaeffe6-1066-4af0-af3f-bc2f5c3a90a3",
      documentoProprietarioDestino: "222",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "teste-safe", documento: "123456789123456" },
      proprietarioDestino: { nome: "Frigorífico", documento: "222" },
      dataCriacao: "2023-12-18T18:35:36",
      dataUltimaAtualizacao: "2023-12-18T18:35:36",
      status: "NAO_CONFIRMADA",
      qtdAnimais: 50,
    },
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:12:16",
      dtAlteracao: "2023-12-18T14:12:16",
      idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
      fazenda: {
        nome: "FAZENDA TRES BARRAS",
        dataEntrada: "2023-03-28",
        dataSaida: "",
        latitude: "",
        longitude: "",
      },
      subdivisao: { nome: "A2", dataEntrada: "2023-03-28", dataSaida: "" },
      idSistemaGestao: "64751",
      idLocalizacaoBlockchain: "0807dc5f-b070-4551-a769-5c0c2aee71be",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:12:16",
        dtAlteracao: "2023-12-18T14:12:16",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        fazenda: {
          nome: "Fazenda Rancho",
          dataEntrada: "2023-01-04",
          dataSaida: "2023-03-28",
          latitude: "",
          longitude: "",
        },
        subdivisao: {
          nome: "sub 01",
          dataEntrada: "2023-01-04",
          dataSaida: "2023-03-28",
        },
        idSistemaGestao: "54881",
        idLocalizacaoBlockchain: "b8b6ee84-30ec-4fc5-a1e9-c9ce63965d21",
      },
      {
        dtCriacao: "2023-12-18T14:12:16",
        dtAlteracao: "2023-12-18T14:12:16",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        fazenda: {
          nome: "FAZENDA TRES BARRAS",
          dataEntrada: "2023-03-28",
          dataSaida: "",
          latitude: "",
          longitude: "",
        },
        subdivisao: { nome: "A2", dataEntrada: "2023-03-28", dataSaida: "" },
        idSistemaGestao: "64751",
        idLocalizacaoBlockchain: "0807dc5f-b070-4551-a769-5c0c2aee71be",
      },
    ],
    loteAtual: {
      dtCriacao: "2023-12-18T14:11:53",
      dtAlteracao: "2023-12-18T14:11:53",
      idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
      nome: "Garrote",
      dataEntrada: "2023-03-28",
      dataSaida: null,
      idSistemaGestao: "84440",
      idLoteBlockchain: "a9c98e7e-85fd-4a8b-804e-45a936bedd64",
    },
    loteHistorico: [
      {
        dtCriacao: "2023-12-18T14:11:53",
        dtAlteracao: "2023-12-18T14:11:53",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        nome: "geral",
        dataEntrada: "2023-02-13",
        dataSaida: "2023-03-28",
        idSistemaGestao: "69980",
        idLoteBlockchain: "959b6238-06a7-479d-ba06-1e21a8148345",
      },
      {
        dtCriacao: "2023-12-18T14:11:53",
        dtAlteracao: "2023-12-18T14:11:53",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        nome: "Garrote",
        dataEntrada: "2023-03-28",
        dataSaida: null,
        idSistemaGestao: "84440",
        idLoteBlockchain: "a9c98e7e-85fd-4a8b-804e-45a936bedd64",
      },
    ],
    idResponsavel: "2d4c3e5c-db8d-4b4e-9212-b3cc8779559c",
    nomeResponsavel: "teste-safe",
    frigorifico: null,
  },
  "1f012514-1f25-49a8-9dc1-f179e28435f9": {
    idLoteSync: "7adfa622-1d0c-41a1-accf-1a4d29fa2e22",
    idBlockchain: "1f012514-1f25-49a8-9dc1-f179e28435f9",
    docType: "ANIMAL",
    idSistemaGestao: "c792fac7-02f1-417f-b416-1b269a231d85",
    idSisbov: "105120141021120",
    idICar: "",
    nome: "",
    dataNascimento: "2019-12-12",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "102112",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Aberdeen Angus", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "5ba141f9-74f9-4630-be2f-ceebde517b39",
      nome: "teste-safe",
      documento: "123456789123456",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:21:49",
    dataUltimaAtualizacao: "2023-12-18T18:35:36",
    vendaAtual: {
      idBlockchain: "fcaeffe6-1066-4af0-af3f-bc2f5c3a90a3",
      documentoProprietarioDestino: "222",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "teste-safe", documento: "123456789123456" },
      proprietarioDestino: { nome: "Frigorífico", documento: "222" },
      dataCriacao: "2023-12-18T18:35:36",
      dataUltimaAtualizacao: "2023-12-18T18:35:36",
      status: "NAO_CONFIRMADA",
      qtdAnimais: 50,
    },
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:12:16",
      dtAlteracao: "2023-12-18T14:12:16",
      idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
      fazenda: {
        nome: "FAZENDA TRES BARRAS",
        dataEntrada: "2023-03-28",
        dataSaida: "",
        latitude: "",
        longitude: "",
      },
      subdivisao: { nome: "A2", dataEntrada: "2023-03-28", dataSaida: "" },
      idSistemaGestao: "66392",
      idLocalizacaoBlockchain: "3a7d0a46-5fd4-43be-8c4e-091e41b3b7db",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:12:16",
        dtAlteracao: "2023-12-18T14:12:16",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        fazenda: {
          nome: "Fazenda Rancho",
          dataEntrada: "2023-01-04",
          dataSaida: "2023-03-28",
          latitude: "",
          longitude: "",
        },
        subdivisao: {
          nome: "sub 01",
          dataEntrada: "2023-01-04",
          dataSaida: "2023-03-28",
        },
        idSistemaGestao: "55110",
        idLocalizacaoBlockchain: "f443226e-24b5-4df0-ad20-cc2d6a71fe3d",
      },
      {
        dtCriacao: "2023-12-18T14:12:16",
        dtAlteracao: "2023-12-18T14:12:16",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        fazenda: {
          nome: "FAZENDA TRES BARRAS",
          dataEntrada: "2023-03-28",
          dataSaida: "",
          latitude: "",
          longitude: "",
        },
        subdivisao: { nome: "A2", dataEntrada: "2023-03-28", dataSaida: "" },
        idSistemaGestao: "66392",
        idLocalizacaoBlockchain: "3a7d0a46-5fd4-43be-8c4e-091e41b3b7db",
      },
    ],
    loteAtual: {
      dtCriacao: "2023-12-18T14:11:53",
      dtAlteracao: "2023-12-18T14:11:53",
      idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
      nome: "Garrote",
      dataEntrada: "2023-03-28",
      dataSaida: null,
      idSistemaGestao: "86084",
      idLoteBlockchain: "e09e9864-640d-481d-9a2f-394935d60b72",
    },
    loteHistorico: [
      {
        dtCriacao: "2023-12-18T14:11:53",
        dtAlteracao: "2023-12-18T14:11:53",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        nome: "geral",
        dataEntrada: "2023-02-13",
        dataSaida: "2023-03-28",
        idSistemaGestao: "70204",
        idLoteBlockchain: "1c7e9c2c-7ae9-4213-bf49-874e5c07288c",
      },
      {
        dtCriacao: "2023-12-18T14:11:53",
        dtAlteracao: "2023-12-18T14:11:53",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        nome: "Garrote",
        dataEntrada: "2023-03-28",
        dataSaida: null,
        idSistemaGestao: "86084",
        idLoteBlockchain: "e09e9864-640d-481d-9a2f-394935d60b72",
      },
    ],
    idResponsavel: "2d4c3e5c-db8d-4b4e-9212-b3cc8779559c",
    nomeResponsavel: "teste-safe",
    frigorifico: null,
  },
  "27783989-932e-411c-aec7-c2feab608206": {
    idLoteSync: "7adfa622-1d0c-41a1-accf-1a4d29fa2e22",
    idBlockchain: "27783989-932e-411c-aec7-c2feab608206",
    docType: "ANIMAL",
    idSistemaGestao: "593c7a0b-5c14-4ce0-a1e9-cf3b4d0f5449",
    idSisbov: "105930404455298",
    idICar: "",
    nome: "",
    dataNascimento: "2022-02-01",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "445529",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "5ba141f9-74f9-4630-be2f-ceebde517b39",
      nome: "teste-safe",
      documento: "123456789123456",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:21:49",
    dataUltimaAtualizacao: "2023-12-18T18:35:36",
    vendaAtual: {
      idBlockchain: "fcaeffe6-1066-4af0-af3f-bc2f5c3a90a3",
      documentoProprietarioDestino: "222",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "teste-safe", documento: "123456789123456" },
      proprietarioDestino: { nome: "Frigorífico", documento: "222" },
      dataCriacao: "2023-12-18T18:35:36",
      dataUltimaAtualizacao: "2023-12-18T18:35:36",
      status: "NAO_CONFIRMADA",
      qtdAnimais: 50,
    },
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "2d4c3e5c-db8d-4b4e-9212-b3cc8779559c",
    nomeResponsavel: "teste-safe",
    frigorifico: null,
  },
  "300459f5-2d67-4780-b622-ba608c794b41": {
    idLoteSync: "7adfa622-1d0c-41a1-accf-1a4d29fa2e22",
    idBlockchain: "300459f5-2d67-4780-b622-ba608c794b41",
    docType: "ANIMAL",
    idSistemaGestao: "70db3338-6c13-4598-aebe-6c3e4fed39bd",
    idSisbov: "105320181010113",
    idICar: "",
    nome: "",
    dataNascimento: "2020-05-10",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "101011",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Bonsmara", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "5ba141f9-74f9-4630-be2f-ceebde517b39",
      nome: "teste-safe",
      documento: "123456789123456",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:21:49",
    dataUltimaAtualizacao: "2023-12-18T18:35:36",
    vendaAtual: {
      idBlockchain: "fcaeffe6-1066-4af0-af3f-bc2f5c3a90a3",
      documentoProprietarioDestino: "222",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "teste-safe", documento: "123456789123456" },
      proprietarioDestino: { nome: "Frigorífico", documento: "222" },
      dataCriacao: "2023-12-18T18:35:36",
      dataUltimaAtualizacao: "2023-12-18T18:35:36",
      status: "NAO_CONFIRMADA",
      qtdAnimais: 50,
    },
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:12:16",
      dtAlteracao: "2023-12-18T14:12:16",
      idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
      fazenda: {
        nome: "FAZENDA TRES BARRAS",
        dataEntrada: "2023-03-28",
        dataSaida: "",
        latitude: "",
        longitude: "",
      },
      subdivisao: { nome: "A2", dataEntrada: "2023-03-28", dataSaida: "" },
      idSistemaGestao: "63681",
      idLocalizacaoBlockchain: "18f46c30-2453-41f6-81bb-ef5d85fa4f57",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:12:16",
        dtAlteracao: "2023-12-18T14:12:16",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        fazenda: {
          nome: "Fazenda Rancho",
          dataEntrada: "2023-01-04",
          dataSaida: "2023-03-28",
          latitude: "",
          longitude: "",
        },
        subdivisao: {
          nome: "sub 01",
          dataEntrada: "2023-01-04",
          dataSaida: "2023-03-28",
        },
        idSistemaGestao: "54740",
        idLocalizacaoBlockchain: "84ac8f7c-1f1f-4cc2-b461-63264691be33",
      },
      {
        dtCriacao: "2023-12-18T14:12:16",
        dtAlteracao: "2023-12-18T14:12:16",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        fazenda: {
          nome: "FAZENDA TRES BARRAS",
          dataEntrada: "2023-03-28",
          dataSaida: "",
          latitude: "",
          longitude: "",
        },
        subdivisao: { nome: "A2", dataEntrada: "2023-03-28", dataSaida: "" },
        idSistemaGestao: "63681",
        idLocalizacaoBlockchain: "18f46c30-2453-41f6-81bb-ef5d85fa4f57",
      },
    ],
    loteAtual: {
      dtCriacao: "2023-12-18T14:11:53",
      dtAlteracao: "2023-12-18T14:11:53",
      idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
      nome: "Garrote",
      dataEntrada: "2023-03-28",
      dataSaida: null,
      idSistemaGestao: "83368",
      idLoteBlockchain: "71493d79-56ba-4000-9064-fcb3fcae9981",
    },
    loteHistorico: [
      {
        dtCriacao: "2023-12-18T14:11:53",
        dtAlteracao: "2023-12-18T14:11:53",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        nome: "geral",
        dataEntrada: "2023-02-13",
        dataSaida: "2023-03-28",
        idSistemaGestao: "69843",
        idLoteBlockchain: "6c330f94-b04d-4196-932d-650e7a101c34",
      },
      {
        dtCriacao: "2023-12-18T14:11:53",
        dtAlteracao: "2023-12-18T14:11:53",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        nome: "Garrote",
        dataEntrada: "2023-03-28",
        dataSaida: null,
        idSistemaGestao: "83368",
        idLoteBlockchain: "71493d79-56ba-4000-9064-fcb3fcae9981",
      },
    ],
    idResponsavel: "2d4c3e5c-db8d-4b4e-9212-b3cc8779559c",
    nomeResponsavel: "teste-safe",
    frigorifico: null,
  },
  "453dbd0c-0115-4adb-81b1-c1fef7183935": {
    idLoteSync: "7adfa622-1d0c-41a1-accf-1a4d29fa2e22",
    idBlockchain: "453dbd0c-0115-4adb-81b1-c1fef7183935",
    docType: "ANIMAL",
    idSistemaGestao: "56168457-c0ab-4d58-8190-6d99f743bae5",
    idSisbov: "105930404455085",
    idICar: "",
    nome: "",
    dataNascimento: "2022-02-01",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "445508",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "5ba141f9-74f9-4630-be2f-ceebde517b39",
      nome: "teste-safe",
      documento: "123456789123456",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:21:49",
    dataUltimaAtualizacao: "2023-12-18T18:35:36",
    vendaAtual: {
      idBlockchain: "fcaeffe6-1066-4af0-af3f-bc2f5c3a90a3",
      documentoProprietarioDestino: "222",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "teste-safe", documento: "123456789123456" },
      proprietarioDestino: { nome: "Frigorífico", documento: "222" },
      dataCriacao: "2023-12-18T18:35:36",
      dataUltimaAtualizacao: "2023-12-18T18:35:36",
      status: "NAO_CONFIRMADA",
      qtdAnimais: 50,
    },
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "2d4c3e5c-db8d-4b4e-9212-b3cc8779559c",
    nomeResponsavel: "teste-safe",
    frigorifico: null,
  },
  "4872969b-676d-4b10-9066-e92b3df4b0f6": {
    idLoteSync: "7adfa622-1d0c-41a1-accf-1a4d29fa2e22",
    idBlockchain: "4872969b-676d-4b10-9066-e92b3df4b0f6",
    docType: "ANIMAL",
    idSistemaGestao: "0152be37-1bb5-4113-b704-c4f946901b7c",
    idSisbov: "105930404455280",
    idICar: "",
    nome: "",
    dataNascimento: "2022-02-01",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "445528",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "5ba141f9-74f9-4630-be2f-ceebde517b39",
      nome: "teste-safe",
      documento: "123456789123456",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:21:49",
    dataUltimaAtualizacao: "2023-12-18T18:35:36",
    vendaAtual: {
      idBlockchain: "fcaeffe6-1066-4af0-af3f-bc2f5c3a90a3",
      documentoProprietarioDestino: "222",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "teste-safe", documento: "123456789123456" },
      proprietarioDestino: { nome: "Frigorífico", documento: "222" },
      dataCriacao: "2023-12-18T18:35:36",
      dataUltimaAtualizacao: "2023-12-18T18:35:36",
      status: "NAO_CONFIRMADA",
      qtdAnimais: 50,
    },
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "2d4c3e5c-db8d-4b4e-9212-b3cc8779559c",
    nomeResponsavel: "teste-safe",
    frigorifico: null,
  },
  "586467e4-35a1-4dd5-9f10-12d5ef8ecd7a": {
    idLoteSync: "7adfa622-1d0c-41a1-accf-1a4d29fa2e22",
    idBlockchain: "586467e4-35a1-4dd5-9f10-12d5ef8ecd7a",
    docType: "ANIMAL",
    idSistemaGestao: "d9ceb132-2251-4397-8f93-bf7bb4639d6d",
    idSisbov: "105930404455107",
    idICar: "",
    nome: "",
    dataNascimento: "2022-02-01",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "445510",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "5ba141f9-74f9-4630-be2f-ceebde517b39",
      nome: "teste-safe",
      documento: "123456789123456",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:21:49",
    dataUltimaAtualizacao: "2023-12-18T18:35:36",
    vendaAtual: {
      idBlockchain: "fcaeffe6-1066-4af0-af3f-bc2f5c3a90a3",
      documentoProprietarioDestino: "222",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "teste-safe", documento: "123456789123456" },
      proprietarioDestino: { nome: "Frigorífico", documento: "222" },
      dataCriacao: "2023-12-18T18:35:36",
      dataUltimaAtualizacao: "2023-12-18T18:35:36",
      status: "NAO_CONFIRMADA",
      qtdAnimais: 50,
    },
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "2d4c3e5c-db8d-4b4e-9212-b3cc8779559c",
    nomeResponsavel: "teste-safe",
    frigorifico: null,
  },
  "5cca22f2-97e1-45c5-9bb0-a5b6f5df2070": {
    idLoteSync: "7adfa622-1d0c-41a1-accf-1a4d29fa2e22",
    idBlockchain: "5cca22f2-97e1-45c5-9bb0-a5b6f5df2070",
    docType: "ANIMAL",
    idSistemaGestao: "83b306f5-3e73-4cfd-9956-4396b5980974",
    idSisbov: "105320181010040",
    idICar: "",
    nome: "",
    dataNascimento: "2020-01-10",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "101004",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Aberdeen Angus", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "5ba141f9-74f9-4630-be2f-ceebde517b39",
      nome: "teste-safe",
      documento: "123456789123456",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:21:49",
    dataUltimaAtualizacao: "2023-12-18T18:35:36",
    vendaAtual: {
      idBlockchain: "fcaeffe6-1066-4af0-af3f-bc2f5c3a90a3",
      documentoProprietarioDestino: "222",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "teste-safe", documento: "123456789123456" },
      proprietarioDestino: { nome: "Frigorífico", documento: "222" },
      dataCriacao: "2023-12-18T18:35:36",
      dataUltimaAtualizacao: "2023-12-18T18:35:36",
      status: "NAO_CONFIRMADA",
      qtdAnimais: 50,
    },
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:12:16",
      dtAlteracao: "2023-12-18T14:12:16",
      idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
      fazenda: {
        nome: "FAZENDA TRES BARRAS",
        dataEntrada: "2023-03-28",
        dataSaida: "",
        latitude: "",
        longitude: "",
      },
      subdivisao: { nome: "A2", dataEntrada: "2023-03-28", dataSaida: "" },
      idSistemaGestao: "64309",
      idLocalizacaoBlockchain: "3bf138fd-f003-4f6f-9e24-705a5459f49b",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:12:16",
        dtAlteracao: "2023-12-18T14:12:16",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        fazenda: {
          nome: "Fazenda Rancho",
          dataEntrada: "2023-01-04",
          dataSaida: "2023-03-28",
          latitude: "",
          longitude: "",
        },
        subdivisao: {
          nome: "sub 01",
          dataEntrada: "2023-01-04",
          dataSaida: "2023-03-28",
        },
        idSistemaGestao: "54821",
        idLocalizacaoBlockchain: "3f1b03cd-c9f6-4c3d-9245-88d6980ee1ed",
      },
      {
        dtCriacao: "2023-12-18T14:12:16",
        dtAlteracao: "2023-12-18T14:12:16",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        fazenda: {
          nome: "FAZENDA TRES BARRAS",
          dataEntrada: "2023-03-28",
          dataSaida: "",
          latitude: "",
          longitude: "",
        },
        subdivisao: { nome: "A2", dataEntrada: "2023-03-28", dataSaida: "" },
        idSistemaGestao: "64309",
        idLocalizacaoBlockchain: "3bf138fd-f003-4f6f-9e24-705a5459f49b",
      },
    ],
    loteAtual: {
      dtCriacao: "2023-12-18T14:11:53",
      dtAlteracao: "2023-12-18T14:11:53",
      idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
      nome: "Garrote",
      dataEntrada: "2023-03-28",
      dataSaida: null,
      idSistemaGestao: "83996",
      idLoteBlockchain: "97e2078a-9bbc-4fb7-ae25-4266ad18f8d4",
    },
    loteHistorico: [
      {
        dtCriacao: "2023-12-18T14:11:53",
        dtAlteracao: "2023-12-18T14:11:53",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        nome: "geral",
        dataEntrada: "2023-02-13",
        dataSaida: "2023-03-28",
        idSistemaGestao: "69922",
        idLoteBlockchain: "3d6c759a-ad51-4f73-a261-693ff5c14b7d",
      },
      {
        dtCriacao: "2023-12-18T14:11:53",
        dtAlteracao: "2023-12-18T14:11:53",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        nome: "Garrote",
        dataEntrada: "2023-03-28",
        dataSaida: null,
        idSistemaGestao: "83996",
        idLoteBlockchain: "97e2078a-9bbc-4fb7-ae25-4266ad18f8d4",
      },
    ],
    idResponsavel: "2d4c3e5c-db8d-4b4e-9212-b3cc8779559c",
    nomeResponsavel: "teste-safe",
    frigorifico: null,
  },
  "6286fbac-9273-4a74-b40a-82274dc0e869": {
    idLoteSync: "7adfa622-1d0c-41a1-accf-1a4d29fa2e22",
    idBlockchain: "6286fbac-9273-4a74-b40a-82274dc0e869",
    docType: "ANIMAL",
    idSistemaGestao: "5341a4b7-93e8-4bad-ba38-e5b34901700b",
    idSisbov: "105930404455310",
    idICar: "",
    nome: "",
    dataNascimento: "2022-02-01",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "445531",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "5ba141f9-74f9-4630-be2f-ceebde517b39",
      nome: "teste-safe",
      documento: "123456789123456",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:21:49",
    dataUltimaAtualizacao: "2023-12-18T18:35:36",
    vendaAtual: {
      idBlockchain: "fcaeffe6-1066-4af0-af3f-bc2f5c3a90a3",
      documentoProprietarioDestino: "222",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "teste-safe", documento: "123456789123456" },
      proprietarioDestino: { nome: "Frigorífico", documento: "222" },
      dataCriacao: "2023-12-18T18:35:36",
      dataUltimaAtualizacao: "2023-12-18T18:35:36",
      status: "NAO_CONFIRMADA",
      qtdAnimais: 50,
    },
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "2d4c3e5c-db8d-4b4e-9212-b3cc8779559c",
    nomeResponsavel: "teste-safe",
    frigorifico: null,
  },
  "6c4ac029-17f2-4e06-b0f9-c3e55c7ceff1": {
    idLoteSync: "7adfa622-1d0c-41a1-accf-1a4d29fa2e22",
    idBlockchain: "6c4ac029-17f2-4e06-b0f9-c3e55c7ceff1",
    docType: "ANIMAL",
    idSistemaGestao: "3c4e0bfb-295d-4e2e-b998-9fb8583c53f3",
    idSisbov: "105930404455263",
    idICar: "",
    nome: "",
    dataNascimento: "2022-02-01",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "445526",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "5ba141f9-74f9-4630-be2f-ceebde517b39",
      nome: "teste-safe",
      documento: "123456789123456",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:21:49",
    dataUltimaAtualizacao: "2023-12-18T18:35:36",
    vendaAtual: {
      idBlockchain: "fcaeffe6-1066-4af0-af3f-bc2f5c3a90a3",
      documentoProprietarioDestino: "222",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "teste-safe", documento: "123456789123456" },
      proprietarioDestino: { nome: "Frigorífico", documento: "222" },
      dataCriacao: "2023-12-18T18:35:36",
      dataUltimaAtualizacao: "2023-12-18T18:35:36",
      status: "NAO_CONFIRMADA",
      qtdAnimais: 50,
    },
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "2d4c3e5c-db8d-4b4e-9212-b3cc8779559c",
    nomeResponsavel: "teste-safe",
    frigorifico: null,
  },
  "6c556ea8-2672-41f9-8e81-ce90077d7418": {
    idLoteSync: "7adfa622-1d0c-41a1-accf-1a4d29fa2e22",
    idBlockchain: "6c556ea8-2672-41f9-8e81-ce90077d7418",
    docType: "ANIMAL",
    idSistemaGestao: "5f1a108d-df2d-4862-beb5-5e4be5563d27",
    idSisbov: "105930404455182",
    idICar: "",
    nome: "",
    dataNascimento: "2022-02-01",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "445518",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "5ba141f9-74f9-4630-be2f-ceebde517b39",
      nome: "teste-safe",
      documento: "123456789123456",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:21:49",
    dataUltimaAtualizacao: "2023-12-18T18:35:36",
    vendaAtual: {
      idBlockchain: "fcaeffe6-1066-4af0-af3f-bc2f5c3a90a3",
      documentoProprietarioDestino: "222",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "teste-safe", documento: "123456789123456" },
      proprietarioDestino: { nome: "Frigorífico", documento: "222" },
      dataCriacao: "2023-12-18T18:35:36",
      dataUltimaAtualizacao: "2023-12-18T18:35:36",
      status: "NAO_CONFIRMADA",
      qtdAnimais: 50,
    },
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "2d4c3e5c-db8d-4b4e-9212-b3cc8779559c",
    nomeResponsavel: "teste-safe",
    frigorifico: null,
  },
  "707f4882-94f4-4c94-bf50-03ff3e1fa323": {
    idLoteSync: "7adfa622-1d0c-41a1-accf-1a4d29fa2e22",
    idBlockchain: "707f4882-94f4-4c94-bf50-03ff3e1fa323",
    docType: "ANIMAL",
    idSistemaGestao: "03794ce9-5b02-492e-a61a-4ef6bf47bafd",
    idSisbov: "105930404455077",
    idICar: "",
    nome: "",
    dataNascimento: "2022-02-01",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "445507",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "5ba141f9-74f9-4630-be2f-ceebde517b39",
      nome: "teste-safe",
      documento: "123456789123456",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:21:49",
    dataUltimaAtualizacao: "2023-12-18T18:35:36",
    vendaAtual: {
      idBlockchain: "fcaeffe6-1066-4af0-af3f-bc2f5c3a90a3",
      documentoProprietarioDestino: "222",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "teste-safe", documento: "123456789123456" },
      proprietarioDestino: { nome: "Frigorífico", documento: "222" },
      dataCriacao: "2023-12-18T18:35:36",
      dataUltimaAtualizacao: "2023-12-18T18:35:36",
      status: "NAO_CONFIRMADA",
      qtdAnimais: 50,
    },
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "2d4c3e5c-db8d-4b4e-9212-b3cc8779559c",
    nomeResponsavel: "teste-safe",
    frigorifico: null,
  },
  "799914fe-dc53-42e1-b2e5-5f7afa942933": {
    idLoteSync: "7adfa622-1d0c-41a1-accf-1a4d29fa2e22",
    idBlockchain: "799914fe-dc53-42e1-b2e5-5f7afa942933",
    docType: "ANIMAL",
    idSistemaGestao: "7a93e45d-b73a-46f6-8e1e-515a3dc46a34",
    idSisbov: "105930404455069",
    idICar: "",
    nome: "",
    dataNascimento: "2022-02-01",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "445506",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "5ba141f9-74f9-4630-be2f-ceebde517b39",
      nome: "teste-safe",
      documento: "123456789123456",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:21:49",
    dataUltimaAtualizacao: "2023-12-18T18:35:36",
    vendaAtual: {
      idBlockchain: "fcaeffe6-1066-4af0-af3f-bc2f5c3a90a3",
      documentoProprietarioDestino: "222",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "teste-safe", documento: "123456789123456" },
      proprietarioDestino: { nome: "Frigorífico", documento: "222" },
      dataCriacao: "2023-12-18T18:35:36",
      dataUltimaAtualizacao: "2023-12-18T18:35:36",
      status: "NAO_CONFIRMADA",
      qtdAnimais: 50,
    },
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "2d4c3e5c-db8d-4b4e-9212-b3cc8779559c",
    nomeResponsavel: "teste-safe",
    frigorifico: null,
  },
  "7b12d756-de24-498a-b049-4373aa81b3bd": {
    idLoteSync: "7adfa622-1d0c-41a1-accf-1a4d29fa2e22",
    idBlockchain: "7b12d756-de24-498a-b049-4373aa81b3bd",
    docType: "ANIMAL",
    idSistemaGestao: "dfa1d8cd-4728-4d9d-895c-5e345059553a",
    idSisbov: "105320181010032",
    idICar: "",
    nome: "",
    dataNascimento: "2020-01-10",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "101003",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Aberdeen Angus", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "5ba141f9-74f9-4630-be2f-ceebde517b39",
      nome: "teste-safe",
      documento: "123456789123456",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:21:49",
    dataUltimaAtualizacao: "2023-12-18T18:35:36",
    vendaAtual: {
      idBlockchain: "fcaeffe6-1066-4af0-af3f-bc2f5c3a90a3",
      documentoProprietarioDestino: "222",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "teste-safe", documento: "123456789123456" },
      proprietarioDestino: { nome: "Frigorífico", documento: "222" },
      dataCriacao: "2023-12-18T18:35:36",
      dataUltimaAtualizacao: "2023-12-18T18:35:36",
      status: "NAO_CONFIRMADA",
      qtdAnimais: 50,
    },
    vendaHistorico: [],
    reproducoes: [],
    acoes: [
      {
        dtCriacao: "2023-12-18T14:13:07",
        dtAlteracao: "2023-12-18T14:13:07",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        idSistemaGestao: "86271ad1-15d3-4a26-9ca0-e5fab5196148",
        idResponsavel: "2439a13a-1b44-4d37-b2fa-50cd244308bb",
        nomeResponsavel: "Aline Neves Queiroz",
        dtAcao: "2021-08-20",
        nome: "retirada de sisbov",
        latitude: "",
        longitude: "",
        idAcaoBlockchain: "4f59762e-5b38-4bab-96e2-b6d023cc2546",
      },
    ],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:12:16",
      dtAlteracao: "2023-12-18T14:12:16",
      idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
      fazenda: {
        nome: "FAZENDA TRES BARRAS",
        dataEntrada: "2023-03-28",
        dataSaida: "",
        latitude: "",
        longitude: "",
      },
      subdivisao: { nome: "A2", dataEntrada: "2023-03-28", dataSaida: "" },
      idSistemaGestao: "67130",
      idLocalizacaoBlockchain: "5b4016a8-e5c7-4d88-8eea-199c5371315c",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:12:16",
        dtAlteracao: "2023-12-18T14:12:16",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        fazenda: {
          nome: "Fazenda Rancho",
          dataEntrada: "2023-01-04",
          dataSaida: "2023-03-28",
          latitude: "",
          longitude: "",
        },
        subdivisao: {
          nome: "sub 01",
          dataEntrada: "2023-01-04",
          dataSaida: "2023-03-28",
        },
        idSistemaGestao: "55212",
        idLocalizacaoBlockchain: "8c067983-acc6-4b2d-b6f4-0e2fbc75ac92",
      },
      {
        dtCriacao: "2023-12-18T14:12:16",
        dtAlteracao: "2023-12-18T14:12:16",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        fazenda: {
          nome: "FAZENDA TRES BARRAS",
          dataEntrada: "2023-03-28",
          dataSaida: "",
          latitude: "",
          longitude: "",
        },
        subdivisao: { nome: "A2", dataEntrada: "2023-03-28", dataSaida: "" },
        idSistemaGestao: "67130",
        idLocalizacaoBlockchain: "5b4016a8-e5c7-4d88-8eea-199c5371315c",
      },
    ],
    loteAtual: {
      dtCriacao: "2023-12-18T14:11:53",
      dtAlteracao: "2023-12-18T14:11:53",
      idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
      nome: "Garrote",
      dataEntrada: "2023-03-28",
      dataSaida: null,
      idSistemaGestao: "86824",
      idLoteBlockchain: "ab80e5b5-cd79-4b99-8320-282120d81627",
    },
    loteHistorico: [
      {
        dtCriacao: "2023-12-18T14:11:53",
        dtAlteracao: "2023-12-18T14:11:53",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        nome: "geral",
        dataEntrada: "2023-02-13",
        dataSaida: "2023-03-28",
        idSistemaGestao: "70306",
        idLoteBlockchain: "441e63bd-34d5-4807-88c3-1ec0751ffebc",
      },
      {
        dtCriacao: "2023-12-18T14:11:53",
        dtAlteracao: "2023-12-18T14:11:53",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        nome: "Garrote",
        dataEntrada: "2023-03-28",
        dataSaida: null,
        idSistemaGestao: "86824",
        idLoteBlockchain: "ab80e5b5-cd79-4b99-8320-282120d81627",
      },
    ],
    idResponsavel: "2d4c3e5c-db8d-4b4e-9212-b3cc8779559c",
    nomeResponsavel: "teste-safe",
    frigorifico: null,
  },
  "7e2658d6-20d7-45e3-bbab-0bcdf93fd29b": {
    idLoteSync: "7adfa622-1d0c-41a1-accf-1a4d29fa2e22",
    idBlockchain: "7e2658d6-20d7-45e3-bbab-0bcdf93fd29b",
    docType: "ANIMAL",
    idSistemaGestao: "c75ee00e-4bb4-4a8f-ac03-c8a9c50ead8f",
    idSisbov: "105120141021111",
    idICar: "",
    nome: "",
    dataNascimento: "2019-12-12",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "102111",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Aberdeen Angus", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "5ba141f9-74f9-4630-be2f-ceebde517b39",
      nome: "teste-safe",
      documento: "123456789123456",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:21:49",
    dataUltimaAtualizacao: "2023-12-18T18:35:36",
    vendaAtual: {
      idBlockchain: "fcaeffe6-1066-4af0-af3f-bc2f5c3a90a3",
      documentoProprietarioDestino: "222",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "teste-safe", documento: "123456789123456" },
      proprietarioDestino: { nome: "Frigorífico", documento: "222" },
      dataCriacao: "2023-12-18T18:35:36",
      dataUltimaAtualizacao: "2023-12-18T18:35:36",
      status: "NAO_CONFIRMADA",
      qtdAnimais: 50,
    },
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:12:16",
      dtAlteracao: "2023-12-18T14:12:16",
      idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
      fazenda: {
        nome: "FAZENDA TRES BARRAS",
        dataEntrada: "2023-03-28",
        dataSaida: "",
        latitude: "",
        longitude: "",
      },
      subdivisao: { nome: "A2", dataEntrada: "2023-03-28", dataSaida: "" },
      idSistemaGestao: "66387",
      idLocalizacaoBlockchain: "98b3acb8-7ffe-412e-8db5-ecb724474b02",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:12:16",
        dtAlteracao: "2023-12-18T14:12:16",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        fazenda: {
          nome: "Fazenda Rancho",
          dataEntrada: "2023-01-04",
          dataSaida: "2023-03-28",
          latitude: "",
          longitude: "",
        },
        subdivisao: {
          nome: "sub 01",
          dataEntrada: "2023-01-04",
          dataSaida: "2023-03-28",
        },
        idSistemaGestao: "55109",
        idLocalizacaoBlockchain: "6dea2720-030e-4533-8796-95b79450bd17",
      },
      {
        dtCriacao: "2023-12-18T14:12:16",
        dtAlteracao: "2023-12-18T14:12:16",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        fazenda: {
          nome: "FAZENDA TRES BARRAS",
          dataEntrada: "2023-03-28",
          dataSaida: "",
          latitude: "",
          longitude: "",
        },
        subdivisao: { nome: "A2", dataEntrada: "2023-03-28", dataSaida: "" },
        idSistemaGestao: "66387",
        idLocalizacaoBlockchain: "98b3acb8-7ffe-412e-8db5-ecb724474b02",
      },
    ],
    loteAtual: {
      dtCriacao: "2023-12-18T14:11:53",
      dtAlteracao: "2023-12-18T14:11:53",
      idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
      nome: "Garrote",
      dataEntrada: "2023-03-28",
      dataSaida: null,
      idSistemaGestao: "86079",
      idLoteBlockchain: "54c5f840-fcf9-4e04-95c5-3775dad6e0fb",
    },
    loteHistorico: [
      {
        dtCriacao: "2023-12-18T14:11:53",
        dtAlteracao: "2023-12-18T14:11:53",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        nome: "geral",
        dataEntrada: "2023-02-13",
        dataSaida: "2023-03-28",
        idSistemaGestao: "70203",
        idLoteBlockchain: "3f67817c-d822-4514-938b-f62c49fa636c",
      },
      {
        dtCriacao: "2023-12-18T14:11:53",
        dtAlteracao: "2023-12-18T14:11:53",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        nome: "Garrote",
        dataEntrada: "2023-03-28",
        dataSaida: null,
        idSistemaGestao: "86079",
        idLoteBlockchain: "54c5f840-fcf9-4e04-95c5-3775dad6e0fb",
      },
    ],
    idResponsavel: "2d4c3e5c-db8d-4b4e-9212-b3cc8779559c",
    nomeResponsavel: "teste-safe",
    frigorifico: null,
  },
  "8094d77e-cbf5-4a3a-97d8-2696de36c321": {
    idLoteSync: "7adfa622-1d0c-41a1-accf-1a4d29fa2e22",
    idBlockchain: "8094d77e-cbf5-4a3a-97d8-2696de36c321",
    docType: "ANIMAL",
    idSistemaGestao: "27aa8d04-18c9-4c71-a004-84d52553ec8f",
    idSisbov: "105930404455271",
    idICar: "",
    nome: "",
    dataNascimento: "2022-02-01",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "445527",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "5ba141f9-74f9-4630-be2f-ceebde517b39",
      nome: "teste-safe",
      documento: "123456789123456",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:21:49",
    dataUltimaAtualizacao: "2023-12-18T18:35:36",
    vendaAtual: {
      idBlockchain: "fcaeffe6-1066-4af0-af3f-bc2f5c3a90a3",
      documentoProprietarioDestino: "222",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "teste-safe", documento: "123456789123456" },
      proprietarioDestino: { nome: "Frigorífico", documento: "222" },
      dataCriacao: "2023-12-18T18:35:36",
      dataUltimaAtualizacao: "2023-12-18T18:35:36",
      status: "NAO_CONFIRMADA",
      qtdAnimais: 50,
    },
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "2d4c3e5c-db8d-4b4e-9212-b3cc8779559c",
    nomeResponsavel: "teste-safe",
    frigorifico: null,
  },
  "869e1924-2e4a-4a92-8442-81963eb3a832": {
    idLoteSync: "7adfa622-1d0c-41a1-accf-1a4d29fa2e22",
    idBlockchain: "869e1924-2e4a-4a92-8442-81963eb3a832",
    docType: "ANIMAL",
    idSistemaGestao: "a0689900-e4bb-464e-a0e4-6cef0f2533c6",
    idSisbov: "105930404455166",
    idICar: "",
    nome: "",
    dataNascimento: "2022-02-01",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "445516",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "5ba141f9-74f9-4630-be2f-ceebde517b39",
      nome: "teste-safe",
      documento: "123456789123456",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:21:49",
    dataUltimaAtualizacao: "2023-12-18T18:35:36",
    vendaAtual: {
      idBlockchain: "fcaeffe6-1066-4af0-af3f-bc2f5c3a90a3",
      documentoProprietarioDestino: "222",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "teste-safe", documento: "123456789123456" },
      proprietarioDestino: { nome: "Frigorífico", documento: "222" },
      dataCriacao: "2023-12-18T18:35:36",
      dataUltimaAtualizacao: "2023-12-18T18:35:36",
      status: "NAO_CONFIRMADA",
      qtdAnimais: 50,
    },
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "2d4c3e5c-db8d-4b4e-9212-b3cc8779559c",
    nomeResponsavel: "teste-safe",
    frigorifico: null,
  },
  "8b30ed3f-ca21-492a-b6e5-ad3620d9ef33": {
    idLoteSync: "7adfa622-1d0c-41a1-accf-1a4d29fa2e22",
    idBlockchain: "8b30ed3f-ca21-492a-b6e5-ad3620d9ef33",
    docType: "ANIMAL",
    idSistemaGestao: "4d8e6f63-ce4d-400d-a8eb-53ff1622f75d",
    idSisbov: "105320181010156",
    idICar: "",
    nome: "",
    dataNascimento: "2020-05-10",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "101015",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Bonsmara", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "5ba141f9-74f9-4630-be2f-ceebde517b39",
      nome: "teste-safe",
      documento: "123456789123456",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:21:49",
    dataUltimaAtualizacao: "2023-12-18T18:35:36",
    vendaAtual: {
      idBlockchain: "fcaeffe6-1066-4af0-af3f-bc2f5c3a90a3",
      documentoProprietarioDestino: "222",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "teste-safe", documento: "123456789123456" },
      proprietarioDestino: { nome: "Frigorífico", documento: "222" },
      dataCriacao: "2023-12-18T18:35:36",
      dataUltimaAtualizacao: "2023-12-18T18:35:36",
      status: "NAO_CONFIRMADA",
      qtdAnimais: 50,
    },
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:12:16",
      dtAlteracao: "2023-12-18T14:12:16",
      idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
      fazenda: {
        nome: "FAZENDA TRES BARRAS",
        dataEntrada: "2023-03-28",
        dataSaida: "",
        latitude: "",
        longitude: "",
      },
      subdivisao: { nome: "A2", dataEntrada: "2023-03-28", dataSaida: "" },
      idSistemaGestao: "62531",
      idLocalizacaoBlockchain: "c04b43c8-92ab-4bae-b90d-d021dec90004",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:12:16",
        dtAlteracao: "2023-12-18T14:12:16",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        fazenda: {
          nome: "Fazenda Rancho",
          dataEntrada: "2023-01-04",
          dataSaida: "2023-03-28",
          latitude: "",
          longitude: "",
        },
        subdivisao: {
          nome: "sub 01",
          dataEntrada: "2023-01-04",
          dataSaida: "2023-03-28",
        },
        idSistemaGestao: "54601",
        idLocalizacaoBlockchain: "203bcd22-1ca6-4821-b119-4985314f0e38",
      },
      {
        dtCriacao: "2023-12-18T14:12:16",
        dtAlteracao: "2023-12-18T14:12:16",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        fazenda: {
          nome: "FAZENDA TRES BARRAS",
          dataEntrada: "2023-03-28",
          dataSaida: "",
          latitude: "",
          longitude: "",
        },
        subdivisao: { nome: "A2", dataEntrada: "2023-03-28", dataSaida: "" },
        idSistemaGestao: "62531",
        idLocalizacaoBlockchain: "c04b43c8-92ab-4bae-b90d-d021dec90004",
      },
    ],
    loteAtual: {
      dtCriacao: "2023-12-18T14:11:53",
      dtAlteracao: "2023-12-18T14:11:53",
      idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
      nome: "Garrote",
      dataEntrada: "2023-03-28",
      dataSaida: null,
      idSistemaGestao: "82216",
      idLoteBlockchain: "2a16790b-06c4-4583-bc39-9bf17779013a",
    },
    loteHistorico: [
      {
        dtCriacao: "2023-12-18T14:11:53",
        dtAlteracao: "2023-12-18T14:11:53",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        nome: "geral",
        dataEntrada: "2023-02-13",
        dataSaida: "2023-03-28",
        idSistemaGestao: "69706",
        idLoteBlockchain: "2bb997f6-0252-44b5-ad08-ddf820a28c5a",
      },
      {
        dtCriacao: "2023-12-18T14:11:53",
        dtAlteracao: "2023-12-18T14:11:53",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        nome: "Garrote",
        dataEntrada: "2023-03-28",
        dataSaida: null,
        idSistemaGestao: "82216",
        idLoteBlockchain: "2a16790b-06c4-4583-bc39-9bf17779013a",
      },
    ],
    idResponsavel: "2d4c3e5c-db8d-4b4e-9212-b3cc8779559c",
    nomeResponsavel: "teste-safe",
    frigorifico: null,
  },
  "944a27ec-d41d-4ee0-9561-e5e1291c6e33": {
    idLoteSync: "7adfa622-1d0c-41a1-accf-1a4d29fa2e22",
    idBlockchain: "944a27ec-d41d-4ee0-9561-e5e1291c6e33",
    docType: "ANIMAL",
    idSistemaGestao: "844bdf4a-aa91-42d7-bfde-4d30ecac79eb",
    idSisbov: "105120141021103",
    idICar: "",
    nome: "",
    dataNascimento: "2019-12-12",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "102110",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Senepol", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "5ba141f9-74f9-4630-be2f-ceebde517b39",
      nome: "teste-safe",
      documento: "123456789123456",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:21:49",
    dataUltimaAtualizacao: "2023-12-18T18:35:36",
    vendaAtual: {
      idBlockchain: "fcaeffe6-1066-4af0-af3f-bc2f5c3a90a3",
      documentoProprietarioDestino: "222",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "teste-safe", documento: "123456789123456" },
      proprietarioDestino: { nome: "Frigorífico", documento: "222" },
      dataCriacao: "2023-12-18T18:35:36",
      dataUltimaAtualizacao: "2023-12-18T18:35:36",
      status: "NAO_CONFIRMADA",
      qtdAnimais: 50,
    },
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:12:16",
      dtAlteracao: "2023-12-18T14:12:16",
      idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
      fazenda: {
        nome: "FAZENDA TRES BARRAS",
        dataEntrada: "2023-03-28",
        dataSaida: "",
        latitude: "",
        longitude: "",
      },
      subdivisao: { nome: "A2", dataEntrada: "2023-03-28", dataSaida: "" },
      idSistemaGestao: "64327",
      idLocalizacaoBlockchain: "0334304e-7c62-42b1-8fea-d0344a470083",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:12:16",
        dtAlteracao: "2023-12-18T14:12:16",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        fazenda: {
          nome: "Fazenda Rancho",
          dataEntrada: "2023-01-04",
          dataSaida: "2023-03-28",
          latitude: "",
          longitude: "",
        },
        subdivisao: {
          nome: "sub 01",
          dataEntrada: "2023-01-04",
          dataSaida: "2023-03-28",
        },
        idSistemaGestao: "54825",
        idLocalizacaoBlockchain: "2908ee6e-a9c8-4804-ba25-d91c44954a77",
      },
      {
        dtCriacao: "2023-12-18T14:12:16",
        dtAlteracao: "2023-12-18T14:12:16",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        fazenda: {
          nome: "FAZENDA TRES BARRAS",
          dataEntrada: "2023-03-28",
          dataSaida: "",
          latitude: "",
          longitude: "",
        },
        subdivisao: { nome: "A2", dataEntrada: "2023-03-28", dataSaida: "" },
        idSistemaGestao: "64327",
        idLocalizacaoBlockchain: "0334304e-7c62-42b1-8fea-d0344a470083",
      },
    ],
    loteAtual: {
      dtCriacao: "2023-12-18T14:11:53",
      dtAlteracao: "2023-12-18T14:11:53",
      idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
      nome: "Garrote",
      dataEntrada: "2023-03-28",
      dataSaida: null,
      idSistemaGestao: "84014",
      idLoteBlockchain: "34211521-527f-4c34-8e4e-35edaf58b69c",
    },
    loteHistorico: [
      {
        dtCriacao: "2023-12-18T14:11:53",
        dtAlteracao: "2023-12-18T14:11:53",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        nome: "geral",
        dataEntrada: "2023-02-13",
        dataSaida: "2023-03-28",
        idSistemaGestao: "69926",
        idLoteBlockchain: "651cddd4-0504-4948-a32f-cbf1d6f6d243",
      },
      {
        dtCriacao: "2023-12-18T14:11:53",
        dtAlteracao: "2023-12-18T14:11:53",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        nome: "Garrote",
        dataEntrada: "2023-03-28",
        dataSaida: null,
        idSistemaGestao: "84014",
        idLoteBlockchain: "34211521-527f-4c34-8e4e-35edaf58b69c",
      },
    ],
    idResponsavel: "2d4c3e5c-db8d-4b4e-9212-b3cc8779559c",
    nomeResponsavel: "teste-safe",
    frigorifico: null,
  },
  "95807f04-fa82-4b42-b002-8848c0f2d13b": {
    idLoteSync: "7adfa622-1d0c-41a1-accf-1a4d29fa2e22",
    idBlockchain: "95807f04-fa82-4b42-b002-8848c0f2d13b",
    docType: "ANIMAL",
    idSistemaGestao: "37256803-a9bc-4459-bb8d-18440dc37844",
    idSisbov: "105930404455174",
    idICar: "",
    nome: "",
    dataNascimento: "2022-02-01",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "445517",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "5ba141f9-74f9-4630-be2f-ceebde517b39",
      nome: "teste-safe",
      documento: "123456789123456",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:21:49",
    dataUltimaAtualizacao: "2023-12-18T18:35:36",
    vendaAtual: {
      idBlockchain: "fcaeffe6-1066-4af0-af3f-bc2f5c3a90a3",
      documentoProprietarioDestino: "222",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "teste-safe", documento: "123456789123456" },
      proprietarioDestino: { nome: "Frigorífico", documento: "222" },
      dataCriacao: "2023-12-18T18:35:36",
      dataUltimaAtualizacao: "2023-12-18T18:35:36",
      status: "NAO_CONFIRMADA",
      qtdAnimais: 50,
    },
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "2d4c3e5c-db8d-4b4e-9212-b3cc8779559c",
    nomeResponsavel: "teste-safe",
    frigorifico: null,
  },
  "95eb1c77-9c6b-48ab-94e1-4c0a035b2124": {
    idLoteSync: "7adfa622-1d0c-41a1-accf-1a4d29fa2e22",
    idBlockchain: "95eb1c77-9c6b-48ab-94e1-4c0a035b2124",
    docType: "ANIMAL",
    idSistemaGestao: "92851137-498b-4833-852d-26967292b81a",
    idSisbov: "105930404455123",
    idICar: "",
    nome: "",
    dataNascimento: "2022-02-01",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "445512",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "5ba141f9-74f9-4630-be2f-ceebde517b39",
      nome: "teste-safe",
      documento: "123456789123456",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:21:49",
    dataUltimaAtualizacao: "2023-12-18T18:35:36",
    vendaAtual: {
      idBlockchain: "fcaeffe6-1066-4af0-af3f-bc2f5c3a90a3",
      documentoProprietarioDestino: "222",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "teste-safe", documento: "123456789123456" },
      proprietarioDestino: { nome: "Frigorífico", documento: "222" },
      dataCriacao: "2023-12-18T18:35:36",
      dataUltimaAtualizacao: "2023-12-18T18:35:36",
      status: "NAO_CONFIRMADA",
      qtdAnimais: 50,
    },
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "2d4c3e5c-db8d-4b4e-9212-b3cc8779559c",
    nomeResponsavel: "teste-safe",
    frigorifico: null,
  },
  "983861ea-66cf-4d51-bd14-e4ca9498b13b": {
    idLoteSync: "7adfa622-1d0c-41a1-accf-1a4d29fa2e22",
    idBlockchain: "983861ea-66cf-4d51-bd14-e4ca9498b13b",
    docType: "ANIMAL",
    idSistemaGestao: "8a46cefb-73bd-419a-9d47-1060b2eda892",
    idSisbov: "105930404455204",
    idICar: "",
    nome: "",
    dataNascimento: "2022-02-01",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "445520",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "5ba141f9-74f9-4630-be2f-ceebde517b39",
      nome: "teste-safe",
      documento: "123456789123456",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:21:49",
    dataUltimaAtualizacao: "2023-12-18T18:35:36",
    vendaAtual: {
      idBlockchain: "fcaeffe6-1066-4af0-af3f-bc2f5c3a90a3",
      documentoProprietarioDestino: "222",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "teste-safe", documento: "123456789123456" },
      proprietarioDestino: { nome: "Frigorífico", documento: "222" },
      dataCriacao: "2023-12-18T18:35:36",
      dataUltimaAtualizacao: "2023-12-18T18:35:36",
      status: "NAO_CONFIRMADA",
      qtdAnimais: 50,
    },
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "2d4c3e5c-db8d-4b4e-9212-b3cc8779559c",
    nomeResponsavel: "teste-safe",
    frigorifico: null,
  },
  "99e6a251-f0c7-45ad-a460-0abfa5fc66b0": {
    idLoteSync: "7adfa622-1d0c-41a1-accf-1a4d29fa2e22",
    idBlockchain: "99e6a251-f0c7-45ad-a460-0abfa5fc66b0",
    docType: "ANIMAL",
    idSistemaGestao: "26369a11-40b0-4c92-9a0d-9f872a8da391",
    idSisbov: "105930404455034",
    idICar: "",
    nome: "",
    dataNascimento: "2022-02-01",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "445503",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "5ba141f9-74f9-4630-be2f-ceebde517b39",
      nome: "teste-safe",
      documento: "123456789123456",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:21:49",
    dataUltimaAtualizacao: "2023-12-18T18:35:36",
    vendaAtual: {
      idBlockchain: "fcaeffe6-1066-4af0-af3f-bc2f5c3a90a3",
      documentoProprietarioDestino: "222",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "teste-safe", documento: "123456789123456" },
      proprietarioDestino: { nome: "Frigorífico", documento: "222" },
      dataCriacao: "2023-12-18T18:35:36",
      dataUltimaAtualizacao: "2023-12-18T18:35:36",
      status: "NAO_CONFIRMADA",
      qtdAnimais: 50,
    },
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "2d4c3e5c-db8d-4b4e-9212-b3cc8779559c",
    nomeResponsavel: "teste-safe",
    frigorifico: null,
  },
  "9fd1f247-4102-46b7-81f8-421fee5e93c7": {
    idLoteSync: "7adfa622-1d0c-41a1-accf-1a4d29fa2e22",
    idBlockchain: "9fd1f247-4102-46b7-81f8-421fee5e93c7",
    docType: "ANIMAL",
    idSistemaGestao: "ca3f416f-42be-42ac-b959-d80ce6a8e698",
    idSisbov: "105930404455093",
    idICar: "",
    nome: "",
    dataNascimento: "2022-02-01",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "445509",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "5ba141f9-74f9-4630-be2f-ceebde517b39",
      nome: "teste-safe",
      documento: "123456789123456",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:21:49",
    dataUltimaAtualizacao: "2023-12-18T18:35:36",
    vendaAtual: {
      idBlockchain: "fcaeffe6-1066-4af0-af3f-bc2f5c3a90a3",
      documentoProprietarioDestino: "222",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "teste-safe", documento: "123456789123456" },
      proprietarioDestino: { nome: "Frigorífico", documento: "222" },
      dataCriacao: "2023-12-18T18:35:36",
      dataUltimaAtualizacao: "2023-12-18T18:35:36",
      status: "NAO_CONFIRMADA",
      qtdAnimais: 50,
    },
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "2d4c3e5c-db8d-4b4e-9212-b3cc8779559c",
    nomeResponsavel: "teste-safe",
    frigorifico: null,
  },
  "a0472079-8cde-4845-b583-3b3a99227927": {
    idLoteSync: "7adfa622-1d0c-41a1-accf-1a4d29fa2e22",
    idBlockchain: "a0472079-8cde-4845-b583-3b3a99227927",
    docType: "ANIMAL",
    idSistemaGestao: "fc83ba97-4504-4a73-925e-27d046a2a70f",
    idSisbov: "105320181010091",
    idICar: "",
    nome: "",
    dataNascimento: "2020-01-10",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "101009",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Aberdeen Angus", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "5ba141f9-74f9-4630-be2f-ceebde517b39",
      nome: "teste-safe",
      documento: "123456789123456",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:21:49",
    dataUltimaAtualizacao: "2023-12-18T18:35:36",
    vendaAtual: {
      idBlockchain: "fcaeffe6-1066-4af0-af3f-bc2f5c3a90a3",
      documentoProprietarioDestino: "222",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "teste-safe", documento: "123456789123456" },
      proprietarioDestino: { nome: "Frigorífico", documento: "222" },
      dataCriacao: "2023-12-18T18:35:36",
      dataUltimaAtualizacao: "2023-12-18T18:35:36",
      status: "NAO_CONFIRMADA",
      qtdAnimais: 50,
    },
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:12:16",
      dtAlteracao: "2023-12-18T14:12:16",
      idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
      fazenda: {
        nome: "FAZENDA TRES BARRAS",
        dataEntrada: "2023-03-28",
        dataSaida: "",
        latitude: "",
        longitude: "",
      },
      subdivisao: { nome: "A2", dataEntrada: "2023-03-28", dataSaida: "" },
      idSistemaGestao: "68014",
      idLocalizacaoBlockchain: "2a21fab7-7d47-47f8-ba1b-1415733f9dbb",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:12:16",
        dtAlteracao: "2023-12-18T14:12:16",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        fazenda: {
          nome: "Fazenda Rancho",
          dataEntrada: "2023-01-04",
          dataSaida: "2023-03-28",
          latitude: "",
          longitude: "",
        },
        subdivisao: {
          nome: "sub 01",
          dataEntrada: "2023-01-04",
          dataSaida: "2023-03-28",
        },
        idSistemaGestao: "55322",
        idLocalizacaoBlockchain: "b45ef25d-b41f-422f-ad13-411c74562abc",
      },
      {
        dtCriacao: "2023-12-18T14:12:16",
        dtAlteracao: "2023-12-18T14:12:16",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        fazenda: {
          nome: "FAZENDA TRES BARRAS",
          dataEntrada: "2023-03-28",
          dataSaida: "",
          latitude: "",
          longitude: "",
        },
        subdivisao: { nome: "A2", dataEntrada: "2023-03-28", dataSaida: "" },
        idSistemaGestao: "68014",
        idLocalizacaoBlockchain: "2a21fab7-7d47-47f8-ba1b-1415733f9dbb",
      },
    ],
    loteAtual: {
      dtCriacao: "2023-12-18T14:11:53",
      dtAlteracao: "2023-12-18T14:11:53",
      idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
      nome: "Garrote",
      dataEntrada: "2023-03-28",
      dataSaida: null,
      idSistemaGestao: "87708",
      idLoteBlockchain: "128829ad-76dc-4e2a-970f-3f99118e27a8",
    },
    loteHistorico: [
      {
        dtCriacao: "2023-12-18T14:11:53",
        dtAlteracao: "2023-12-18T14:11:53",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        nome: "geral",
        dataEntrada: "2023-02-13",
        dataSaida: "2023-03-28",
        idSistemaGestao: "70414",
        idLoteBlockchain: "dbb7ace9-c299-46e9-b909-02afed58105d",
      },
      {
        dtCriacao: "2023-12-18T14:11:53",
        dtAlteracao: "2023-12-18T14:11:53",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        nome: "Garrote",
        dataEntrada: "2023-03-28",
        dataSaida: null,
        idSistemaGestao: "87708",
        idLoteBlockchain: "128829ad-76dc-4e2a-970f-3f99118e27a8",
      },
    ],
    idResponsavel: "2d4c3e5c-db8d-4b4e-9212-b3cc8779559c",
    nomeResponsavel: "teste-safe",
    frigorifico: null,
  },
  "a1080bef-774e-46a9-8f69-74551cf502a3": {
    idLoteSync: "7adfa622-1d0c-41a1-accf-1a4d29fa2e22",
    idBlockchain: "a1080bef-774e-46a9-8f69-74551cf502a3",
    docType: "ANIMAL",
    idSistemaGestao: "d26e41bb-7dd9-4794-bc0e-95a3621a2425",
    idSisbov: "105320181010024",
    idICar: "",
    nome: "",
    dataNascimento: "2020-01-10",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "101002",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Aberdeen Angus", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "5ba141f9-74f9-4630-be2f-ceebde517b39",
      nome: "teste-safe",
      documento: "123456789123456",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:21:49",
    dataUltimaAtualizacao: "2023-12-18T18:35:36",
    vendaAtual: {
      idBlockchain: "fcaeffe6-1066-4af0-af3f-bc2f5c3a90a3",
      documentoProprietarioDestino: "222",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "teste-safe", documento: "123456789123456" },
      proprietarioDestino: { nome: "Frigorífico", documento: "222" },
      dataCriacao: "2023-12-18T18:35:36",
      dataUltimaAtualizacao: "2023-12-18T18:35:36",
      status: "NAO_CONFIRMADA",
      qtdAnimais: 50,
    },
    vendaHistorico: [],
    reproducoes: [],
    acoes: [
      {
        dtCriacao: "2023-12-18T14:13:07",
        dtAlteracao: "2023-12-18T14:13:07",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        idSistemaGestao: "de21b002-eed1-46b1-a2df-990cbdc321ec",
        idResponsavel: "2439a13a-1b44-4d37-b2fa-50cd244308bb",
        nomeResponsavel: "Aline Neves Queiroz",
        dtAcao: "2021-08-20",
        nome: "retirada de sisbov",
        latitude: "",
        longitude: "",
        idAcaoBlockchain: "791ecda1-2491-464b-887d-ed952b19034a",
      },
    ],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:12:16",
      dtAlteracao: "2023-12-18T14:12:16",
      idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
      fazenda: {
        nome: "FAZENDA TRES BARRAS",
        dataEntrada: "2023-03-28",
        dataSaida: "",
        latitude: "",
        longitude: "",
      },
      subdivisao: { nome: "A2", dataEntrada: "2023-03-28", dataSaida: "" },
      idSistemaGestao: "66741",
      idLocalizacaoBlockchain: "f0183161-f1cb-4676-baea-0c103cf088a9",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:12:16",
        dtAlteracao: "2023-12-18T14:12:16",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        fazenda: {
          nome: "Fazenda Rancho",
          dataEntrada: "2023-01-04",
          dataSaida: "2023-03-28",
          latitude: "",
          longitude: "",
        },
        subdivisao: {
          nome: "sub 01",
          dataEntrada: "2023-01-04",
          dataSaida: "2023-03-28",
        },
        idSistemaGestao: "55159",
        idLocalizacaoBlockchain: "a07b5d6a-42b2-4423-91ba-3e9679323388",
      },
      {
        dtCriacao: "2023-12-18T14:12:16",
        dtAlteracao: "2023-12-18T14:12:16",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        fazenda: {
          nome: "FAZENDA TRES BARRAS",
          dataEntrada: "2023-03-28",
          dataSaida: "",
          latitude: "",
          longitude: "",
        },
        subdivisao: { nome: "A2", dataEntrada: "2023-03-28", dataSaida: "" },
        idSistemaGestao: "66741",
        idLocalizacaoBlockchain: "f0183161-f1cb-4676-baea-0c103cf088a9",
      },
    ],
    loteAtual: {
      dtCriacao: "2023-12-18T14:11:53",
      dtAlteracao: "2023-12-18T14:11:53",
      idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
      nome: "Garrote",
      dataEntrada: "2023-03-28",
      dataSaida: null,
      idSistemaGestao: "86434",
      idLoteBlockchain: "14253689-9ad6-46d9-85b8-d486cca19450",
    },
    loteHistorico: [
      {
        dtCriacao: "2023-12-18T14:11:53",
        dtAlteracao: "2023-12-18T14:11:53",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        nome: "geral",
        dataEntrada: "2023-02-13",
        dataSaida: "2023-03-28",
        idSistemaGestao: "70253",
        idLoteBlockchain: "c1bc30a8-996f-4279-9e44-b2f9e2c46df9",
      },
      {
        dtCriacao: "2023-12-18T14:11:53",
        dtAlteracao: "2023-12-18T14:11:53",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        nome: "Garrote",
        dataEntrada: "2023-03-28",
        dataSaida: null,
        idSistemaGestao: "86434",
        idLoteBlockchain: "14253689-9ad6-46d9-85b8-d486cca19450",
      },
    ],
    idResponsavel: "2d4c3e5c-db8d-4b4e-9212-b3cc8779559c",
    nomeResponsavel: "teste-safe",
    frigorifico: null,
  },
  "a19dee36-7971-45df-8b18-edf98168cf6a": {
    idLoteSync: "7adfa622-1d0c-41a1-accf-1a4d29fa2e22",
    idBlockchain: "a19dee36-7971-45df-8b18-edf98168cf6a",
    docType: "ANIMAL",
    idSistemaGestao: "2f9904c1-0865-4470-b630-67b5a6e46e5a",
    idSisbov: "105320181010130",
    idICar: "",
    nome: "",
    dataNascimento: "2020-05-10",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "101013",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Bonsmara", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "5ba141f9-74f9-4630-be2f-ceebde517b39",
      nome: "teste-safe",
      documento: "123456789123456",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:21:49",
    dataUltimaAtualizacao: "2023-12-18T18:35:36",
    vendaAtual: {
      idBlockchain: "fcaeffe6-1066-4af0-af3f-bc2f5c3a90a3",
      documentoProprietarioDestino: "222",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "teste-safe", documento: "123456789123456" },
      proprietarioDestino: { nome: "Frigorífico", documento: "222" },
      dataCriacao: "2023-12-18T18:35:36",
      dataUltimaAtualizacao: "2023-12-18T18:35:36",
      status: "NAO_CONFIRMADA",
      qtdAnimais: 50,
    },
    vendaHistorico: [],
    reproducoes: [
      {
        dtCriacao: "2023-12-18T14:12:33",
        dtAlteracao: "2023-12-18T14:12:33",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        idSistemaGestao: "20e89f07-82b4-4579-9b40-c2f27abaa924",
        idResponsavel: "2439a13a-1b44-4d37-b2fa-50cd244308bb",
        nomeResponsavel: "(INSEMINADOR) Aline Neves Queiroz",
        latitude: "",
        longitude: "",
        tipo: "INSEMINACAO_ARTIFICIAL_TEMPO_FIXO",
        descricao: "...",
        dtReproducao: "2021-06-15",
        dtFimReproducao: "2021-06-15",
        idReproducaoBlockchain: "600c1d7f-df9c-4b7a-acee-041d24b60b7f",
      },
      {
        dtCriacao: "2023-12-18T14:12:33",
        dtAlteracao: "2023-12-18T14:12:33",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        idSistemaGestao: "f16e1c4f-f52b-4a17-aab3-fccb35d1a221",
        idResponsavel: "2439a13a-1b44-4d37-b2fa-50cd244308bb",
        nomeResponsavel: "Aline Neves Queiroz",
        latitude: "",
        longitude: "",
        tipo: "MONTA_NATURAL",
        descricao: "...",
        dtReproducao: "2021-02-01",
        dtFimReproducao: "2021-03-31",
        idReproducaoBlockchain: "a9436f66-e52d-4717-bdcb-2541d4e033c6",
      },
    ],
    acoes: [],
    exames: [
      {
        dtCriacao: "2023-12-18T14:12:49",
        dtAlteracao: "2023-12-18T14:12:49",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        idSistemaGestao: "bb5683ac-9f7f-4c23-a904-90da16d8949b",
        idResponsavel: "2439a13a-1b44-4d37-b2fa-50cd244308bb",
        nomeResponsavel: "Aline Neves Queiroz",
        latitude: "",
        longitude: "",
        tipoExame: "DIAGNOSTICO_GESTACAO",
        dtExame: "2021-08-26",
        vrResultado: "PRENHE",
        vrResultadoAol: "",
        vrResultadoEgs: "",
        vrResultadoEgg: "",
        vrResultadoMar: "",
        vrResultadoRatio: "",
        qtDiasPrenhe: null,
        icApresentouCio: null,
        idExameBlockchain: "2062cc3e-bfe7-47a3-8d73-c90b872f5c48",
      },
    ],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:12:16",
      dtAlteracao: "2023-12-18T14:12:16",
      idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
      fazenda: {
        nome: "FAZENDA TRES BARRAS",
        dataEntrada: "2023-03-28",
        dataSaida: "",
        latitude: "",
        longitude: "",
      },
      subdivisao: { nome: "A2", dataEntrada: "2023-03-28", dataSaida: "" },
      idSistemaGestao: "61595",
      idLocalizacaoBlockchain: "88a144e2-73d4-4af1-a826-652a294c89b4",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:12:16",
        dtAlteracao: "2023-12-18T14:12:16",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        fazenda: {
          nome: "Fazenda Rancho",
          dataEntrada: "2023-01-04",
          dataSaida: "2023-03-28",
          latitude: "",
          longitude: "",
        },
        subdivisao: {
          nome: "sub 01",
          dataEntrada: "2023-01-04",
          dataSaida: "2023-03-28",
        },
        idSistemaGestao: "54485",
        idLocalizacaoBlockchain: "7a19608c-64a4-4c5e-a9a9-9d9c3e6cb5ff",
      },
      {
        dtCriacao: "2023-12-18T14:12:16",
        dtAlteracao: "2023-12-18T14:12:16",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        fazenda: {
          nome: "FAZENDA TRES BARRAS",
          dataEntrada: "2023-03-28",
          dataSaida: "",
          latitude: "",
          longitude: "",
        },
        subdivisao: { nome: "A2", dataEntrada: "2023-03-28", dataSaida: "" },
        idSistemaGestao: "61595",
        idLocalizacaoBlockchain: "88a144e2-73d4-4af1-a826-652a294c89b4",
      },
    ],
    loteAtual: {
      dtCriacao: "2023-12-18T14:11:53",
      dtAlteracao: "2023-12-18T14:11:53",
      idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
      nome: "Garrote",
      dataEntrada: "2023-03-28",
      dataSaida: null,
      idSistemaGestao: "81277",
      idLoteBlockchain: "b9dae337-a9dc-4404-8200-914356e1f02f",
    },
    loteHistorico: [
      {
        dtCriacao: "2023-12-18T14:11:53",
        dtAlteracao: "2023-12-18T14:11:53",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        nome: "geral",
        dataEntrada: "2023-02-13",
        dataSaida: "2023-03-28",
        idSistemaGestao: "69595",
        idLoteBlockchain: "59e37ebb-8249-47ee-853c-b400a5cd5298",
      },
      {
        dtCriacao: "2023-12-18T14:11:53",
        dtAlteracao: "2023-12-18T14:11:53",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        nome: "Garrote",
        dataEntrada: "2023-03-28",
        dataSaida: null,
        idSistemaGestao: "81277",
        idLoteBlockchain: "b9dae337-a9dc-4404-8200-914356e1f02f",
      },
    ],
    idResponsavel: "2d4c3e5c-db8d-4b4e-9212-b3cc8779559c",
    nomeResponsavel: "teste-safe",
    frigorifico: null,
  },
  "af75df88-c280-46d3-9784-26f8799532a4": {
    idLoteSync: "7adfa622-1d0c-41a1-accf-1a4d29fa2e22",
    idBlockchain: "af75df88-c280-46d3-9784-26f8799532a4",
    docType: "ANIMAL",
    idSistemaGestao: "3efc2f1d-9a3a-418a-ac78-b5d8c6886e3e",
    idSisbov: "105930404455140",
    idICar: "",
    nome: "",
    dataNascimento: "2022-02-01",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "445514",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "5ba141f9-74f9-4630-be2f-ceebde517b39",
      nome: "teste-safe",
      documento: "123456789123456",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:21:49",
    dataUltimaAtualizacao: "2023-12-18T18:35:36",
    vendaAtual: {
      idBlockchain: "fcaeffe6-1066-4af0-af3f-bc2f5c3a90a3",
      documentoProprietarioDestino: "222",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "teste-safe", documento: "123456789123456" },
      proprietarioDestino: { nome: "Frigorífico", documento: "222" },
      dataCriacao: "2023-12-18T18:35:36",
      dataUltimaAtualizacao: "2023-12-18T18:35:36",
      status: "NAO_CONFIRMADA",
      qtdAnimais: 50,
    },
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "2d4c3e5c-db8d-4b4e-9212-b3cc8779559c",
    nomeResponsavel: "teste-safe",
    frigorifico: null,
  },
  "afd74885-e6b6-4e04-a480-2d233fca50a8": {
    idLoteSync: "7adfa622-1d0c-41a1-accf-1a4d29fa2e22",
    idBlockchain: "afd74885-e6b6-4e04-a480-2d233fca50a8",
    docType: "ANIMAL",
    idSistemaGestao: "6ab89763-4d59-4079-b2b6-b50d4720ea96",
    idSisbov: "105930404455239",
    idICar: "",
    nome: "",
    dataNascimento: "2022-02-01",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "445523",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "5ba141f9-74f9-4630-be2f-ceebde517b39",
      nome: "teste-safe",
      documento: "123456789123456",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:21:49",
    dataUltimaAtualizacao: "2023-12-18T18:35:36",
    vendaAtual: {
      idBlockchain: "fcaeffe6-1066-4af0-af3f-bc2f5c3a90a3",
      documentoProprietarioDestino: "222",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "teste-safe", documento: "123456789123456" },
      proprietarioDestino: { nome: "Frigorífico", documento: "222" },
      dataCriacao: "2023-12-18T18:35:36",
      dataUltimaAtualizacao: "2023-12-18T18:35:36",
      status: "NAO_CONFIRMADA",
      qtdAnimais: 50,
    },
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "2d4c3e5c-db8d-4b4e-9212-b3cc8779559c",
    nomeResponsavel: "teste-safe",
    frigorifico: null,
  },
  "b41b963d-a7da-43ab-9b32-3f195798bad5": {
    idLoteSync: "7adfa622-1d0c-41a1-accf-1a4d29fa2e22",
    idBlockchain: "b41b963d-a7da-43ab-9b32-3f195798bad5",
    docType: "ANIMAL",
    idSistemaGestao: "6d8d6c54-36d6-44ca-a45e-856b42575f58",
    idSisbov: "105930404455026",
    idICar: "",
    nome: "",
    dataNascimento: "2022-02-01",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "445502",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "5ba141f9-74f9-4630-be2f-ceebde517b39",
      nome: "teste-safe",
      documento: "123456789123456",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:21:49",
    dataUltimaAtualizacao: "2023-12-18T18:35:36",
    vendaAtual: {
      idBlockchain: "fcaeffe6-1066-4af0-af3f-bc2f5c3a90a3",
      documentoProprietarioDestino: "222",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "teste-safe", documento: "123456789123456" },
      proprietarioDestino: { nome: "Frigorífico", documento: "222" },
      dataCriacao: "2023-12-18T18:35:36",
      dataUltimaAtualizacao: "2023-12-18T18:35:36",
      status: "NAO_CONFIRMADA",
      qtdAnimais: 50,
    },
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "2d4c3e5c-db8d-4b4e-9212-b3cc8779559c",
    nomeResponsavel: "teste-safe",
    frigorifico: null,
  },
  "b8f42391-39ae-443b-b253-54be2542293f": {
    idLoteSync: "7adfa622-1d0c-41a1-accf-1a4d29fa2e22",
    idBlockchain: "b8f42391-39ae-443b-b253-54be2542293f",
    docType: "ANIMAL",
    idSistemaGestao: "4ce607d2-22af-46ef-91bf-616abfc0ba88",
    idSisbov: "105930404455158",
    idICar: "",
    nome: "",
    dataNascimento: "2022-02-01",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "445515",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "5ba141f9-74f9-4630-be2f-ceebde517b39",
      nome: "teste-safe",
      documento: "123456789123456",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:21:49",
    dataUltimaAtualizacao: "2023-12-18T18:35:36",
    vendaAtual: {
      idBlockchain: "fcaeffe6-1066-4af0-af3f-bc2f5c3a90a3",
      documentoProprietarioDestino: "222",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "teste-safe", documento: "123456789123456" },
      proprietarioDestino: { nome: "Frigorífico", documento: "222" },
      dataCriacao: "2023-12-18T18:35:36",
      dataUltimaAtualizacao: "2023-12-18T18:35:36",
      status: "NAO_CONFIRMADA",
      qtdAnimais: 50,
    },
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "2d4c3e5c-db8d-4b4e-9212-b3cc8779559c",
    nomeResponsavel: "teste-safe",
    frigorifico: null,
  },
  "c17da378-046d-40ac-b24c-632ab660694d": {
    idLoteSync: "7adfa622-1d0c-41a1-accf-1a4d29fa2e22",
    idBlockchain: "c17da378-046d-40ac-b24c-632ab660694d",
    docType: "ANIMAL",
    idSistemaGestao: "c9cfa99c-0ba8-4c54-8ef2-886e0b3fc5e9",
    idSisbov: "105930404455301",
    idICar: "",
    nome: "",
    dataNascimento: "2022-02-01",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "445530",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "5ba141f9-74f9-4630-be2f-ceebde517b39",
      nome: "teste-safe",
      documento: "123456789123456",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:21:49",
    dataUltimaAtualizacao: "2023-12-18T18:35:36",
    vendaAtual: {
      idBlockchain: "fcaeffe6-1066-4af0-af3f-bc2f5c3a90a3",
      documentoProprietarioDestino: "222",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "teste-safe", documento: "123456789123456" },
      proprietarioDestino: { nome: "Frigorífico", documento: "222" },
      dataCriacao: "2023-12-18T18:35:36",
      dataUltimaAtualizacao: "2023-12-18T18:35:36",
      status: "NAO_CONFIRMADA",
      qtdAnimais: 50,
    },
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "2d4c3e5c-db8d-4b4e-9212-b3cc8779559c",
    nomeResponsavel: "teste-safe",
    frigorifico: null,
  },
  "c21a73bd-aac1-4037-9c75-df47f03d921d": {
    idLoteSync: "7adfa622-1d0c-41a1-accf-1a4d29fa2e22",
    idBlockchain: "c21a73bd-aac1-4037-9c75-df47f03d921d",
    docType: "ANIMAL",
    idSistemaGestao: "8a6f4c9b-322e-40f3-b533-b0184f6b037b",
    idSisbov: "105120141021138",
    idICar: "",
    nome: "",
    dataNascimento: "2019-12-12",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "102113",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Aberdeen Angus", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "5ba141f9-74f9-4630-be2f-ceebde517b39",
      nome: "teste-safe",
      documento: "123456789123456",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:21:49",
    dataUltimaAtualizacao: "2023-12-18T18:35:36",
    vendaAtual: {
      idBlockchain: "fcaeffe6-1066-4af0-af3f-bc2f5c3a90a3",
      documentoProprietarioDestino: "222",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "teste-safe", documento: "123456789123456" },
      proprietarioDestino: { nome: "Frigorífico", documento: "222" },
      dataCriacao: "2023-12-18T18:35:36",
      dataUltimaAtualizacao: "2023-12-18T18:35:36",
      status: "NAO_CONFIRMADA",
      qtdAnimais: 50,
    },
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:12:16",
      dtAlteracao: "2023-12-18T14:12:16",
      idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
      fazenda: {
        nome: "FAZENDA TRES BARRAS",
        dataEntrada: "2023-03-28",
        dataSaida: "",
        latitude: "",
        longitude: "",
      },
      subdivisao: { nome: "A2", dataEntrada: "2023-03-28", dataSaida: "" },
      idSistemaGestao: "64510",
      idLocalizacaoBlockchain: "74746e24-bd72-44d9-8f75-475701d2e338",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:12:16",
        dtAlteracao: "2023-12-18T14:12:16",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        fazenda: {
          nome: "Fazenda Rancho",
          dataEntrada: "2023-01-04",
          dataSaida: "2023-03-28",
          latitude: "",
          longitude: "",
        },
        subdivisao: {
          nome: "sub 01",
          dataEntrada: "2023-01-04",
          dataSaida: "2023-03-28",
        },
        idSistemaGestao: "54847",
        idLocalizacaoBlockchain: "d2be937d-5335-405a-8a5c-39af399a041c",
      },
      {
        dtCriacao: "2023-12-18T14:12:16",
        dtAlteracao: "2023-12-18T14:12:16",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        fazenda: {
          nome: "FAZENDA TRES BARRAS",
          dataEntrada: "2023-03-28",
          dataSaida: "",
          latitude: "",
          longitude: "",
        },
        subdivisao: { nome: "A2", dataEntrada: "2023-03-28", dataSaida: "" },
        idSistemaGestao: "64510",
        idLocalizacaoBlockchain: "74746e24-bd72-44d9-8f75-475701d2e338",
      },
    ],
    loteAtual: {
      dtCriacao: "2023-12-18T14:11:53",
      dtAlteracao: "2023-12-18T14:11:53",
      idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
      nome: "Garrote",
      dataEntrada: "2023-03-28",
      dataSaida: null,
      idSistemaGestao: "84199",
      idLoteBlockchain: "d2cf54bf-7d4c-48a8-925c-4fa849b63f34",
    },
    loteHistorico: [
      {
        dtCriacao: "2023-12-18T14:11:53",
        dtAlteracao: "2023-12-18T14:11:53",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        nome: "geral",
        dataEntrada: "2023-02-13",
        dataSaida: "2023-03-28",
        idSistemaGestao: "69946",
        idLoteBlockchain: "dcda34ff-bba7-4333-8ec9-1f84ef13869c",
      },
      {
        dtCriacao: "2023-12-18T14:11:53",
        dtAlteracao: "2023-12-18T14:11:53",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        nome: "Garrote",
        dataEntrada: "2023-03-28",
        dataSaida: null,
        idSistemaGestao: "84199",
        idLoteBlockchain: "d2cf54bf-7d4c-48a8-925c-4fa849b63f34",
      },
    ],
    idResponsavel: "2d4c3e5c-db8d-4b4e-9212-b3cc8779559c",
    nomeResponsavel: "teste-safe",
    frigorifico: null,
  },
  "c2688d61-bf2c-457f-80d7-03bd9ffad710": {
    idLoteSync: "7adfa622-1d0c-41a1-accf-1a4d29fa2e22",
    idBlockchain: "c2688d61-bf2c-457f-80d7-03bd9ffad710",
    docType: "ANIMAL",
    idSistemaGestao: "37ee1a41-2276-46ad-ba66-729733596c47",
    idSisbov: "105320181010105",
    idICar: "",
    nome: "",
    dataNascimento: "2020-01-10",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "101010",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Aberdeen Angus", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "5ba141f9-74f9-4630-be2f-ceebde517b39",
      nome: "teste-safe",
      documento: "123456789123456",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:21:49",
    dataUltimaAtualizacao: "2023-12-18T18:35:36",
    vendaAtual: {
      idBlockchain: "fcaeffe6-1066-4af0-af3f-bc2f5c3a90a3",
      documentoProprietarioDestino: "222",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "teste-safe", documento: "123456789123456" },
      proprietarioDestino: { nome: "Frigorífico", documento: "222" },
      dataCriacao: "2023-12-18T18:35:36",
      dataUltimaAtualizacao: "2023-12-18T18:35:36",
      status: "NAO_CONFIRMADA",
      qtdAnimais: 50,
    },
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:12:16",
      dtAlteracao: "2023-12-18T14:12:16",
      idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
      fazenda: {
        nome: "FAZENDA TRES BARRAS",
        dataEntrada: "2023-03-28",
        dataSaida: "",
        latitude: "",
        longitude: "",
      },
      subdivisao: { nome: "A2", dataEntrada: "2023-03-28", dataSaida: "" },
      idSistemaGestao: "61849",
      idLocalizacaoBlockchain: "99f0dbf7-38e8-407c-8587-1885ee5536fa",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:12:16",
        dtAlteracao: "2023-12-18T14:12:16",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        fazenda: {
          nome: "Fazenda Rancho",
          dataEntrada: "2023-01-04",
          dataSaida: "2023-03-28",
          latitude: "",
          longitude: "",
        },
        subdivisao: {
          nome: "sub 01",
          dataEntrada: "2023-01-04",
          dataSaida: "2023-03-28",
        },
        idSistemaGestao: "54532",
        idLocalizacaoBlockchain: "666b0a68-4700-4f8e-a450-8d676efb6050",
      },
      {
        dtCriacao: "2023-12-18T14:12:16",
        dtAlteracao: "2023-12-18T14:12:16",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        fazenda: {
          nome: "FAZENDA TRES BARRAS",
          dataEntrada: "2023-03-28",
          dataSaida: "",
          latitude: "",
          longitude: "",
        },
        subdivisao: { nome: "A2", dataEntrada: "2023-03-28", dataSaida: "" },
        idSistemaGestao: "61849",
        idLocalizacaoBlockchain: "99f0dbf7-38e8-407c-8587-1885ee5536fa",
      },
    ],
    loteAtual: {
      dtCriacao: "2023-12-18T14:11:53",
      dtAlteracao: "2023-12-18T14:11:53",
      idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
      nome: "Garrote",
      dataEntrada: "2023-03-28",
      dataSaida: null,
      idSistemaGestao: "81533",
      idLoteBlockchain: "6c67a622-e819-4f4c-a76f-318ae0ec0ead",
    },
    loteHistorico: [
      {
        dtCriacao: "2023-12-18T14:11:53",
        dtAlteracao: "2023-12-18T14:11:53",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        nome: "geral",
        dataEntrada: "2023-02-13",
        dataSaida: "2023-03-28",
        idSistemaGestao: "69639",
        idLoteBlockchain: "3a47711e-6874-468e-84b9-dc104fc7ee4d",
      },
      {
        dtCriacao: "2023-12-18T14:11:53",
        dtAlteracao: "2023-12-18T14:11:53",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        nome: "Garrote",
        dataEntrada: "2023-03-28",
        dataSaida: null,
        idSistemaGestao: "81533",
        idLoteBlockchain: "6c67a622-e819-4f4c-a76f-318ae0ec0ead",
      },
    ],
    idResponsavel: "2d4c3e5c-db8d-4b4e-9212-b3cc8779559c",
    nomeResponsavel: "teste-safe",
    frigorifico: null,
  },
  "c3958bcc-81c4-4f56-bfd2-6afd3a91183f": {
    idLoteSync: "7adfa622-1d0c-41a1-accf-1a4d29fa2e22",
    idBlockchain: "c3958bcc-81c4-4f56-bfd2-6afd3a91183f",
    docType: "ANIMAL",
    idSistemaGestao: "0317322f-fc32-46d9-9c8f-27d2e9fc8a4a",
    idSisbov: "105930404455018",
    idICar: "",
    nome: "",
    dataNascimento: "2022-02-01",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "445501",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "5ba141f9-74f9-4630-be2f-ceebde517b39",
      nome: "teste-safe",
      documento: "123456789123456",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:21:49",
    dataUltimaAtualizacao: "2023-12-18T18:35:36",
    vendaAtual: {
      idBlockchain: "fcaeffe6-1066-4af0-af3f-bc2f5c3a90a3",
      documentoProprietarioDestino: "222",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "teste-safe", documento: "123456789123456" },
      proprietarioDestino: { nome: "Frigorífico", documento: "222" },
      dataCriacao: "2023-12-18T18:35:36",
      dataUltimaAtualizacao: "2023-12-18T18:35:36",
      status: "NAO_CONFIRMADA",
      qtdAnimais: 50,
    },
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "2d4c3e5c-db8d-4b4e-9212-b3cc8779559c",
    nomeResponsavel: "teste-safe",
    frigorifico: null,
  },
  "c478e17a-02f0-4bbc-b9be-47d2b7cd8250": {
    idLoteSync: "7adfa622-1d0c-41a1-accf-1a4d29fa2e22",
    idBlockchain: "c478e17a-02f0-4bbc-b9be-47d2b7cd8250",
    docType: "ANIMAL",
    idSistemaGestao: "215b8855-ae27-4400-b0c6-8c5b6478f6ed",
    idSisbov: "105930404455212",
    idICar: "",
    nome: "",
    dataNascimento: "2022-02-01",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "445521",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "5ba141f9-74f9-4630-be2f-ceebde517b39",
      nome: "teste-safe",
      documento: "123456789123456",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:21:49",
    dataUltimaAtualizacao: "2023-12-18T18:35:36",
    vendaAtual: {
      idBlockchain: "fcaeffe6-1066-4af0-af3f-bc2f5c3a90a3",
      documentoProprietarioDestino: "222",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "teste-safe", documento: "123456789123456" },
      proprietarioDestino: { nome: "Frigorífico", documento: "222" },
      dataCriacao: "2023-12-18T18:35:36",
      dataUltimaAtualizacao: "2023-12-18T18:35:36",
      status: "NAO_CONFIRMADA",
      qtdAnimais: 50,
    },
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "2d4c3e5c-db8d-4b4e-9212-b3cc8779559c",
    nomeResponsavel: "teste-safe",
    frigorifico: null,
  },
  "c68bc579-1da0-4e50-a112-c2864d53b0a8": {
    idLoteSync: "7adfa622-1d0c-41a1-accf-1a4d29fa2e22",
    idBlockchain: "c68bc579-1da0-4e50-a112-c2864d53b0a8",
    docType: "ANIMAL",
    idSistemaGestao: "83aff1d6-4dc8-4c7c-8950-5fd3fc9a9552",
    idSisbov: "105930404455115",
    idICar: "",
    nome: "",
    dataNascimento: "2022-02-01",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "445511",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "5ba141f9-74f9-4630-be2f-ceebde517b39",
      nome: "teste-safe",
      documento: "123456789123456",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:21:49",
    dataUltimaAtualizacao: "2023-12-18T18:35:36",
    vendaAtual: {
      idBlockchain: "fcaeffe6-1066-4af0-af3f-bc2f5c3a90a3",
      documentoProprietarioDestino: "222",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "teste-safe", documento: "123456789123456" },
      proprietarioDestino: { nome: "Frigorífico", documento: "222" },
      dataCriacao: "2023-12-18T18:35:36",
      dataUltimaAtualizacao: "2023-12-18T18:35:36",
      status: "NAO_CONFIRMADA",
      qtdAnimais: 50,
    },
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "2d4c3e5c-db8d-4b4e-9212-b3cc8779559c",
    nomeResponsavel: "teste-safe",
    frigorifico: null,
  },
  "c7401143-acdc-40fa-be17-23dfb8fa26dd": {
    idLoteSync: "7adfa622-1d0c-41a1-accf-1a4d29fa2e22",
    idBlockchain: "c7401143-acdc-40fa-be17-23dfb8fa26dd",
    docType: "ANIMAL",
    idSistemaGestao: "f5b274a6-39a5-4b29-b3ae-fe2b53020506",
    idSisbov: "105320181010148",
    idICar: "",
    nome: "",
    dataNascimento: "2020-05-10",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "101014",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Bonsmara", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "5ba141f9-74f9-4630-be2f-ceebde517b39",
      nome: "teste-safe",
      documento: "123456789123456",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:21:49",
    dataUltimaAtualizacao: "2023-12-18T18:35:36",
    vendaAtual: {
      idBlockchain: "fcaeffe6-1066-4af0-af3f-bc2f5c3a90a3",
      documentoProprietarioDestino: "222",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "teste-safe", documento: "123456789123456" },
      proprietarioDestino: { nome: "Frigorífico", documento: "222" },
      dataCriacao: "2023-12-18T18:35:36",
      dataUltimaAtualizacao: "2023-12-18T18:35:36",
      status: "NAO_CONFIRMADA",
      qtdAnimais: 50,
    },
    vendaHistorico: [],
    reproducoes: [
      {
        dtCriacao: "2023-12-18T14:12:33",
        dtAlteracao: "2023-12-18T14:12:33",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        idSistemaGestao: "dbdc4d70-71bf-48b7-afbb-bb01ca5b62fa",
        idResponsavel: "2439a13a-1b44-4d37-b2fa-50cd244308bb",
        nomeResponsavel: "(INSEMINADOR) Aline Neves Queiroz",
        latitude: "",
        longitude: "",
        tipo: "INSEMINACAO_ARTIFICIAL",
        descricao: "...",
        dtReproducao: "2021-05-01",
        dtFimReproducao: "2021-05-01",
        idReproducaoBlockchain: "978442f6-31cb-4c32-a317-3bb0e1b2cfbb",
      },
    ],
    acoes: [],
    exames: [
      {
        dtCriacao: "2023-12-18T14:12:49",
        dtAlteracao: "2023-12-18T14:12:49",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        idSistemaGestao: "74b532ac-85c3-4ddb-8a55-2538198f83c8",
        idResponsavel: "2439a13a-1b44-4d37-b2fa-50cd244308bb",
        nomeResponsavel: "Aline Neves Queiroz",
        latitude: "",
        longitude: "",
        tipoExame: "DIAGNOSTICO_GESTACAO",
        dtExame: "2021-08-11",
        vrResultado: "VAZIA",
        vrResultadoAol: "",
        vrResultadoEgs: "",
        vrResultadoEgg: "",
        vrResultadoMar: "",
        vrResultadoRatio: "",
        qtDiasPrenhe: null,
        icApresentouCio: null,
        idExameBlockchain: "1304c28a-2c7c-48ba-adbe-b43c4cb1e968",
      },
      {
        dtCriacao: "2023-12-18T14:12:49",
        dtAlteracao: "2023-12-18T14:12:49",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        idSistemaGestao: "96fc27ba-4f89-4772-9f86-29bbfeb310b8",
        idResponsavel: "2439a13a-1b44-4d37-b2fa-50cd244308bb",
        nomeResponsavel: "Aline Neves Queiroz",
        latitude: "",
        longitude: "",
        tipoExame: "DIAGNOSTICO_GESTACAO",
        dtExame: "2021-08-11",
        vrResultado: "ABSORCAO",
        vrResultadoAol: "",
        vrResultadoEgs: "",
        vrResultadoEgg: "",
        vrResultadoMar: "",
        vrResultadoRatio: "",
        qtDiasPrenhe: null,
        icApresentouCio: null,
        idExameBlockchain: "421290df-0d2a-4503-92f7-1ca168d574f6",
      },
    ],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:12:16",
      dtAlteracao: "2023-12-18T14:12:16",
      idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
      fazenda: {
        nome: "FAZENDA TRES BARRAS",
        dataEntrada: "2023-03-28",
        dataSaida: "",
        latitude: "",
        longitude: "",
      },
      subdivisao: { nome: "A2", dataEntrada: "2023-03-28", dataSaida: "" },
      idSistemaGestao: "67800",
      idLocalizacaoBlockchain: "710149ff-5d98-4d96-8b77-52f19fb991fb",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:12:16",
        dtAlteracao: "2023-12-18T14:12:16",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        fazenda: {
          nome: "Fazenda Rancho",
          dataEntrada: "2023-01-04",
          dataSaida: "2023-03-28",
          latitude: "",
          longitude: "",
        },
        subdivisao: {
          nome: "sub 01",
          dataEntrada: "2023-01-04",
          dataSaida: "2023-03-28",
        },
        idSistemaGestao: "55295",
        idLocalizacaoBlockchain: "7482cc56-8c63-48de-bf83-c6fe5193987f",
      },
      {
        dtCriacao: "2023-12-18T14:12:16",
        dtAlteracao: "2023-12-18T14:12:16",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        fazenda: {
          nome: "FAZENDA TRES BARRAS",
          dataEntrada: "2023-03-28",
          dataSaida: "",
          latitude: "",
          longitude: "",
        },
        subdivisao: { nome: "A2", dataEntrada: "2023-03-28", dataSaida: "" },
        idSistemaGestao: "67800",
        idLocalizacaoBlockchain: "710149ff-5d98-4d96-8b77-52f19fb991fb",
      },
    ],
    loteAtual: {
      dtCriacao: "2023-12-18T14:11:53",
      dtAlteracao: "2023-12-18T14:11:53",
      idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
      nome: "Garrote",
      dataEntrada: "2023-03-28",
      dataSaida: null,
      idSistemaGestao: "87494",
      idLoteBlockchain: "95bde439-fa04-494c-ac39-4a65d21f667e",
    },
    loteHistorico: [
      {
        dtCriacao: "2023-12-18T14:11:53",
        dtAlteracao: "2023-12-18T14:11:53",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        nome: "geral",
        dataEntrada: "2023-02-13",
        dataSaida: "2023-03-28",
        idSistemaGestao: "70387",
        idLoteBlockchain: "0ea12bd5-2cff-43a7-98a3-d85e96722818",
      },
      {
        dtCriacao: "2023-12-18T14:11:53",
        dtAlteracao: "2023-12-18T14:11:53",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        nome: "Garrote",
        dataEntrada: "2023-03-28",
        dataSaida: null,
        idSistemaGestao: "87494",
        idLoteBlockchain: "95bde439-fa04-494c-ac39-4a65d21f667e",
      },
    ],
    idResponsavel: "2d4c3e5c-db8d-4b4e-9212-b3cc8779559c",
    nomeResponsavel: "teste-safe",
    frigorifico: null,
  },
  "c9b54991-54a0-4821-b608-ba2ac597fd6e": {
    idLoteSync: "7adfa622-1d0c-41a1-accf-1a4d29fa2e22",
    idBlockchain: "c9b54991-54a0-4821-b608-ba2ac597fd6e",
    docType: "ANIMAL",
    idSistemaGestao: "3890479c-9fde-471c-b7eb-b1fe65774d3a",
    idSisbov: "105930404455131",
    idICar: "",
    nome: "",
    dataNascimento: "2022-02-01",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "445513",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "5ba141f9-74f9-4630-be2f-ceebde517b39",
      nome: "teste-safe",
      documento: "123456789123456",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:21:49",
    dataUltimaAtualizacao: "2023-12-18T18:35:36",
    vendaAtual: {
      idBlockchain: "fcaeffe6-1066-4af0-af3f-bc2f5c3a90a3",
      documentoProprietarioDestino: "222",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "teste-safe", documento: "123456789123456" },
      proprietarioDestino: { nome: "Frigorífico", documento: "222" },
      dataCriacao: "2023-12-18T18:35:36",
      dataUltimaAtualizacao: "2023-12-18T18:35:36",
      status: "NAO_CONFIRMADA",
      qtdAnimais: 50,
    },
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "2d4c3e5c-db8d-4b4e-9212-b3cc8779559c",
    nomeResponsavel: "teste-safe",
    frigorifico: null,
  },
  "d19358f0-61dd-45d9-9f6b-6347ab7a7784": {
    idLoteSync: "7adfa622-1d0c-41a1-accf-1a4d29fa2e22",
    idBlockchain: "d19358f0-61dd-45d9-9f6b-6347ab7a7784",
    docType: "ANIMAL",
    idSistemaGestao: "ff6eafe4-5eec-430d-a4ab-3d7ad4dbaf62",
    idSisbov: "105930404455247",
    idICar: "",
    nome: "",
    dataNascimento: "2022-02-01",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "445524",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "5ba141f9-74f9-4630-be2f-ceebde517b39",
      nome: "teste-safe",
      documento: "123456789123456",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:21:49",
    dataUltimaAtualizacao: "2023-12-18T18:35:36",
    vendaAtual: {
      idBlockchain: "fcaeffe6-1066-4af0-af3f-bc2f5c3a90a3",
      documentoProprietarioDestino: "222",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "teste-safe", documento: "123456789123456" },
      proprietarioDestino: { nome: "Frigorífico", documento: "222" },
      dataCriacao: "2023-12-18T18:35:36",
      dataUltimaAtualizacao: "2023-12-18T18:35:36",
      status: "NAO_CONFIRMADA",
      qtdAnimais: 50,
    },
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "2d4c3e5c-db8d-4b4e-9212-b3cc8779559c",
    nomeResponsavel: "teste-safe",
    frigorifico: null,
  },
  "d9495866-4ada-49aa-80ad-eb8f14954c05": {
    idLoteSync: "7adfa622-1d0c-41a1-accf-1a4d29fa2e22",
    idBlockchain: "d9495866-4ada-49aa-80ad-eb8f14954c05",
    docType: "ANIMAL",
    idSistemaGestao: "4d0977a7-6131-4f78-82dd-751fdf2099f6",
    idSisbov: "105930404455328",
    idICar: "",
    nome: "",
    dataNascimento: "2022-02-01",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "445532",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "5ba141f9-74f9-4630-be2f-ceebde517b39",
      nome: "teste-safe",
      documento: "123456789123456",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:21:49",
    dataUltimaAtualizacao: "2023-12-18T18:35:36",
    vendaAtual: {
      idBlockchain: "fcaeffe6-1066-4af0-af3f-bc2f5c3a90a3",
      documentoProprietarioDestino: "222",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "teste-safe", documento: "123456789123456" },
      proprietarioDestino: { nome: "Frigorífico", documento: "222" },
      dataCriacao: "2023-12-18T18:35:36",
      dataUltimaAtualizacao: "2023-12-18T18:35:36",
      status: "NAO_CONFIRMADA",
      qtdAnimais: 50,
    },
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "2d4c3e5c-db8d-4b4e-9212-b3cc8779559c",
    nomeResponsavel: "teste-safe",
    frigorifico: null,
  },
  "db21e8bf-4dab-43cc-aa53-1420f6f70693": {
    idLoteSync: "7adfa622-1d0c-41a1-accf-1a4d29fa2e22",
    idBlockchain: "db21e8bf-4dab-43cc-aa53-1420f6f70693",
    docType: "ANIMAL",
    idSistemaGestao: "e24a03bf-a0d4-4cdd-b3a0-a305c846f853",
    idSisbov: "105320181010083",
    idICar: "",
    nome: "",
    dataNascimento: "2020-01-10",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "101008",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Aberdeen Angus", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "5ba141f9-74f9-4630-be2f-ceebde517b39",
      nome: "teste-safe",
      documento: "123456789123456",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:21:49",
    dataUltimaAtualizacao: "2023-12-18T18:35:36",
    vendaAtual: {
      idBlockchain: "fcaeffe6-1066-4af0-af3f-bc2f5c3a90a3",
      documentoProprietarioDestino: "222",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "teste-safe", documento: "123456789123456" },
      proprietarioDestino: { nome: "Frigorífico", documento: "222" },
      dataCriacao: "2023-12-18T18:35:36",
      dataUltimaAtualizacao: "2023-12-18T18:35:36",
      status: "NAO_CONFIRMADA",
      qtdAnimais: 50,
    },
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:12:16",
      dtAlteracao: "2023-12-18T14:12:16",
      idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
      fazenda: {
        nome: "FAZENDA TRES BARRAS",
        dataEntrada: "2023-03-28",
        dataSaida: "",
        latitude: "",
        longitude: "",
      },
      subdivisao: { nome: "A2", dataEntrada: "2023-03-28", dataSaida: "" },
      idSistemaGestao: "67223",
      idLocalizacaoBlockchain: "a17daf3a-f065-4ebe-a4d5-7589d691bf5c",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:12:16",
        dtAlteracao: "2023-12-18T14:12:16",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        fazenda: {
          nome: "Fazenda Rancho",
          dataEntrada: "2023-01-04",
          dataSaida: "2023-03-28",
          latitude: "",
          longitude: "",
        },
        subdivisao: {
          nome: "sub 01",
          dataEntrada: "2023-01-04",
          dataSaida: "2023-03-28",
        },
        idSistemaGestao: "55221",
        idLocalizacaoBlockchain: "294b4e76-684f-46c8-85d8-bde9a60c7dfc",
      },
      {
        dtCriacao: "2023-12-18T14:12:16",
        dtAlteracao: "2023-12-18T14:12:16",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        fazenda: {
          nome: "FAZENDA TRES BARRAS",
          dataEntrada: "2023-03-28",
          dataSaida: "",
          latitude: "",
          longitude: "",
        },
        subdivisao: { nome: "A2", dataEntrada: "2023-03-28", dataSaida: "" },
        idSistemaGestao: "67223",
        idLocalizacaoBlockchain: "a17daf3a-f065-4ebe-a4d5-7589d691bf5c",
      },
    ],
    loteAtual: {
      dtCriacao: "2023-12-18T14:11:53",
      dtAlteracao: "2023-12-18T14:11:53",
      idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
      nome: "Garrote",
      dataEntrada: "2023-03-28",
      dataSaida: null,
      idSistemaGestao: "86917",
      idLoteBlockchain: "b5186336-3ee8-4f15-8617-bce13eb316f3",
    },
    loteHistorico: [
      {
        dtCriacao: "2023-12-18T14:11:53",
        dtAlteracao: "2023-12-18T14:11:53",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        nome: "geral",
        dataEntrada: "2023-02-13",
        dataSaida: "2023-03-28",
        idSistemaGestao: "70315",
        idLoteBlockchain: "57814758-a549-4fad-abf4-308aea747f20",
      },
      {
        dtCriacao: "2023-12-18T14:11:53",
        dtAlteracao: "2023-12-18T14:11:53",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        nome: "Garrote",
        dataEntrada: "2023-03-28",
        dataSaida: null,
        idSistemaGestao: "86917",
        idLoteBlockchain: "b5186336-3ee8-4f15-8617-bce13eb316f3",
      },
    ],
    idResponsavel: "2d4c3e5c-db8d-4b4e-9212-b3cc8779559c",
    nomeResponsavel: "teste-safe",
    frigorifico: null,
  },
  "e1591143-fe2a-45f3-883f-686e6e146082": {
    idLoteSync: "7adfa622-1d0c-41a1-accf-1a4d29fa2e22",
    idBlockchain: "e1591143-fe2a-45f3-883f-686e6e146082",
    docType: "ANIMAL",
    idSistemaGestao: "f1f0ccbd-1bea-40e0-a10c-fb9e44ff2596",
    idSisbov: "105320181010067",
    idICar: "",
    nome: "",
    dataNascimento: "2020-01-10",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "101006",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Aberdeen Angus", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "5ba141f9-74f9-4630-be2f-ceebde517b39",
      nome: "teste-safe",
      documento: "123456789123456",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:21:49",
    dataUltimaAtualizacao: "2023-12-18T18:35:37",
    vendaAtual: {
      idBlockchain: "fcaeffe6-1066-4af0-af3f-bc2f5c3a90a3",
      documentoProprietarioDestino: "222",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "teste-safe", documento: "123456789123456" },
      proprietarioDestino: { nome: "Frigorífico", documento: "222" },
      dataCriacao: "2023-12-18T18:35:36",
      dataUltimaAtualizacao: "2023-12-18T18:35:36",
      status: "NAO_CONFIRMADA",
      qtdAnimais: 50,
    },
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:12:16",
      dtAlteracao: "2023-12-18T14:12:16",
      idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
      fazenda: {
        nome: "FAZENDA TRES BARRAS",
        dataEntrada: "2023-03-28",
        dataSaida: "",
        latitude: "",
        longitude: "",
      },
      subdivisao: { nome: "A2", dataEntrada: "2023-03-28", dataSaida: "" },
      idSistemaGestao: "67685",
      idLocalizacaoBlockchain: "e48a152d-94b5-4b22-8d17-34e88fd2ebb2",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:12:16",
        dtAlteracao: "2023-12-18T14:12:16",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        fazenda: {
          nome: "Fazenda Rancho",
          dataEntrada: "2023-01-04",
          dataSaida: "2023-03-28",
          latitude: "",
          longitude: "",
        },
        subdivisao: {
          nome: "sub 01",
          dataEntrada: "2023-01-04",
          dataSaida: "2023-03-28",
        },
        idSistemaGestao: "55283",
        idLocalizacaoBlockchain: "995e3a84-2fe3-415f-b604-66dad708b891",
      },
      {
        dtCriacao: "2023-12-18T14:12:16",
        dtAlteracao: "2023-12-18T14:12:16",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        fazenda: {
          nome: "FAZENDA TRES BARRAS",
          dataEntrada: "2023-03-28",
          dataSaida: "",
          latitude: "",
          longitude: "",
        },
        subdivisao: { nome: "A2", dataEntrada: "2023-03-28", dataSaida: "" },
        idSistemaGestao: "67685",
        idLocalizacaoBlockchain: "e48a152d-94b5-4b22-8d17-34e88fd2ebb2",
      },
    ],
    loteAtual: {
      dtCriacao: "2023-12-18T14:11:53",
      dtAlteracao: "2023-12-18T14:11:53",
      idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
      nome: "Garrote",
      dataEntrada: "2023-03-28",
      dataSaida: null,
      idSistemaGestao: "87379",
      idLoteBlockchain: "8f16d836-10e6-41cd-9876-44094a071725",
    },
    loteHistorico: [
      {
        dtCriacao: "2023-12-18T14:11:53",
        dtAlteracao: "2023-12-18T14:11:53",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        nome: "geral",
        dataEntrada: "2023-02-13",
        dataSaida: "2023-03-28",
        idSistemaGestao: "70375",
        idLoteBlockchain: "1760ac9e-f7c3-4ad5-9bb5-36a4d71bb75b",
      },
      {
        dtCriacao: "2023-12-18T14:11:53",
        dtAlteracao: "2023-12-18T14:11:53",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        nome: "Garrote",
        dataEntrada: "2023-03-28",
        dataSaida: null,
        idSistemaGestao: "87379",
        idLoteBlockchain: "8f16d836-10e6-41cd-9876-44094a071725",
      },
    ],
    idResponsavel: "2d4c3e5c-db8d-4b4e-9212-b3cc8779559c",
    nomeResponsavel: "teste-safe",
    frigorifico: null,
  },
  "e420fbf4-83c0-4014-86f0-1c8e7a9174b1": {
    idLoteSync: "7adfa622-1d0c-41a1-accf-1a4d29fa2e22",
    idBlockchain: "e420fbf4-83c0-4014-86f0-1c8e7a9174b1",
    docType: "ANIMAL",
    idSistemaGestao: "207fec2e-6be3-45fd-bd46-edee61972d18",
    idSisbov: "105930404455042",
    idICar: "",
    nome: "",
    dataNascimento: "2022-02-01",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "445504",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "5ba141f9-74f9-4630-be2f-ceebde517b39",
      nome: "teste-safe",
      documento: "123456789123456",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:21:49",
    dataUltimaAtualizacao: "2023-12-18T18:35:36",
    vendaAtual: {
      idBlockchain: "fcaeffe6-1066-4af0-af3f-bc2f5c3a90a3",
      documentoProprietarioDestino: "222",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "teste-safe", documento: "123456789123456" },
      proprietarioDestino: { nome: "Frigorífico", documento: "222" },
      dataCriacao: "2023-12-18T18:35:36",
      dataUltimaAtualizacao: "2023-12-18T18:35:36",
      status: "NAO_CONFIRMADA",
      qtdAnimais: 50,
    },
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "2d4c3e5c-db8d-4b4e-9212-b3cc8779559c",
    nomeResponsavel: "teste-safe",
    frigorifico: null,
  },
  "eac0d08d-e3f0-4ac7-a37c-975b22d59bd7": {
    idLoteSync: "7adfa622-1d0c-41a1-accf-1a4d29fa2e22",
    idBlockchain: "eac0d08d-e3f0-4ac7-a37c-975b22d59bd7",
    docType: "ANIMAL",
    idSistemaGestao: "16196965-99eb-4079-b017-2e32fc5c363a",
    idSisbov: "105930404455255",
    idICar: "",
    nome: "",
    dataNascimento: "2022-02-01",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "445525",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "5ba141f9-74f9-4630-be2f-ceebde517b39",
      nome: "teste-safe",
      documento: "123456789123456",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:21:49",
    dataUltimaAtualizacao: "2023-12-18T18:35:36",
    vendaAtual: {
      idBlockchain: "fcaeffe6-1066-4af0-af3f-bc2f5c3a90a3",
      documentoProprietarioDestino: "222",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "teste-safe", documento: "123456789123456" },
      proprietarioDestino: { nome: "Frigorífico", documento: "222" },
      dataCriacao: "2023-12-18T18:35:36",
      dataUltimaAtualizacao: "2023-12-18T18:35:36",
      status: "NAO_CONFIRMADA",
      qtdAnimais: 50,
    },
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "2d4c3e5c-db8d-4b4e-9212-b3cc8779559c",
    nomeResponsavel: "teste-safe",
    frigorifico: null,
  },
  "f09718eb-c277-434d-b016-30c6a85aef9b": {
    idLoteSync: "7adfa622-1d0c-41a1-accf-1a4d29fa2e22",
    idBlockchain: "f09718eb-c277-434d-b016-30c6a85aef9b",
    docType: "ANIMAL",
    idSistemaGestao: "21756b53-8f91-42c6-aa6f-ab453f9ca0ba",
    idSisbov: "105930404455190",
    idICar: "",
    nome: "",
    dataNascimento: "2022-02-01",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "445519",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "5ba141f9-74f9-4630-be2f-ceebde517b39",
      nome: "teste-safe",
      documento: "123456789123456",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:21:49",
    dataUltimaAtualizacao: "2023-12-18T18:35:36",
    vendaAtual: {
      idBlockchain: "fcaeffe6-1066-4af0-af3f-bc2f5c3a90a3",
      documentoProprietarioDestino: "222",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "teste-safe", documento: "123456789123456" },
      proprietarioDestino: { nome: "Frigorífico", documento: "222" },
      dataCriacao: "2023-12-18T18:35:36",
      dataUltimaAtualizacao: "2023-12-18T18:35:36",
      status: "NAO_CONFIRMADA",
      qtdAnimais: 50,
    },
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "2d4c3e5c-db8d-4b4e-9212-b3cc8779559c",
    nomeResponsavel: "teste-safe",
    frigorifico: null,
  },
  "f7a922ff-23b1-470f-a7de-6956abd19337": {
    idLoteSync: "7adfa622-1d0c-41a1-accf-1a4d29fa2e22",
    idBlockchain: "f7a922ff-23b1-470f-a7de-6956abd19337",
    docType: "ANIMAL",
    idSistemaGestao: "f8eae290-93e8-4fac-94c1-94df62727d05",
    idSisbov: "105930404455000",
    idICar: "",
    nome: "",
    dataNascimento: "2022-02-01",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "445500",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "5ba141f9-74f9-4630-be2f-ceebde517b39",
      nome: "teste-safe",
      documento: "123456789123456",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:21:49",
    dataUltimaAtualizacao: "2023-12-18T18:35:36",
    vendaAtual: {
      idBlockchain: "fcaeffe6-1066-4af0-af3f-bc2f5c3a90a3",
      documentoProprietarioDestino: "222",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "teste-safe", documento: "123456789123456" },
      proprietarioDestino: { nome: "Frigorífico", documento: "222" },
      dataCriacao: "2023-12-18T18:35:36",
      dataUltimaAtualizacao: "2023-12-18T18:35:36",
      status: "NAO_CONFIRMADA",
      qtdAnimais: 50,
    },
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "2d4c3e5c-db8d-4b4e-9212-b3cc8779559c",
    nomeResponsavel: "teste-safe",
    frigorifico: null,
  },
  "fa7387c1-6cc7-4104-88f2-a5d9e0287783": {
    idLoteSync: "7adfa622-1d0c-41a1-accf-1a4d29fa2e22",
    idBlockchain: "fa7387c1-6cc7-4104-88f2-a5d9e0287783",
    docType: "ANIMAL",
    idSistemaGestao: "88ad131d-4227-4350-9564-cf792005002a",
    idSisbov: "105930404455220",
    idICar: "",
    nome: "",
    dataNascimento: "2022-02-01",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "445522",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "5ba141f9-74f9-4630-be2f-ceebde517b39",
      nome: "teste-safe",
      documento: "123456789123456",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:21:49",
    dataUltimaAtualizacao: "2023-12-18T18:35:37",
    vendaAtual: {
      idBlockchain: "fcaeffe6-1066-4af0-af3f-bc2f5c3a90a3",
      documentoProprietarioDestino: "222",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "teste-safe", documento: "123456789123456" },
      proprietarioDestino: { nome: "Frigorífico", documento: "222" },
      dataCriacao: "2023-12-18T18:35:36",
      dataUltimaAtualizacao: "2023-12-18T18:35:36",
      status: "NAO_CONFIRMADA",
      qtdAnimais: 50,
    },
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "2d4c3e5c-db8d-4b4e-9212-b3cc8779559c",
    nomeResponsavel: "teste-safe",
    frigorifico: null,
  },
  "fabe8c79-9f5d-40d7-a698-3582c56ad484": {
    idLoteSync: "7adfa622-1d0c-41a1-accf-1a4d29fa2e22",
    idBlockchain: "fabe8c79-9f5d-40d7-a698-3582c56ad484",
    docType: "ANIMAL",
    idSistemaGestao: "857a5374-316e-4dd2-87a3-a8c9ee2de391",
    idSisbov: "105320181010075",
    idICar: "",
    nome: "",
    dataNascimento: "2020-01-10",
    sexo: "MACHO",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "101007",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Aberdeen Angus", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "5ba141f9-74f9-4630-be2f-ceebde517b39",
      nome: "teste-safe",
      documento: "123456789123456",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:21:49",
    dataUltimaAtualizacao: "2023-12-18T18:35:36",
    vendaAtual: {
      idBlockchain: "fcaeffe6-1066-4af0-af3f-bc2f5c3a90a3",
      documentoProprietarioDestino: "222",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "teste-safe", documento: "123456789123456" },
      proprietarioDestino: { nome: "Frigorífico", documento: "222" },
      dataCriacao: "2023-12-18T18:35:36",
      dataUltimaAtualizacao: "2023-12-18T18:35:36",
      status: "NAO_CONFIRMADA",
      qtdAnimais: 50,
    },
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T14:12:16",
      dtAlteracao: "2023-12-18T14:12:16",
      idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
      fazenda: {
        nome: "FAZENDA TRES BARRAS",
        dataEntrada: "2023-03-28",
        dataSaida: "",
        latitude: "",
        longitude: "",
      },
      subdivisao: { nome: "A2", dataEntrada: "2023-03-28", dataSaida: "" },
      idSistemaGestao: "64375",
      idLocalizacaoBlockchain: "7ae26f4a-54c3-48c3-815a-93c21a730b1a",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T14:12:16",
        dtAlteracao: "2023-12-18T14:12:16",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        fazenda: {
          nome: "Fazenda Rancho",
          dataEntrada: "2023-01-04",
          dataSaida: "2023-03-28",
          latitude: "",
          longitude: "",
        },
        subdivisao: {
          nome: "sub 01",
          dataEntrada: "2023-01-04",
          dataSaida: "2023-03-28",
        },
        idSistemaGestao: "54831",
        idLocalizacaoBlockchain: "f4c5ac8d-32b0-4912-adda-aa8159bd6004",
      },
      {
        dtCriacao: "2023-12-18T14:12:16",
        dtAlteracao: "2023-12-18T14:12:16",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        fazenda: {
          nome: "FAZENDA TRES BARRAS",
          dataEntrada: "2023-03-28",
          dataSaida: "",
          latitude: "",
          longitude: "",
        },
        subdivisao: { nome: "A2", dataEntrada: "2023-03-28", dataSaida: "" },
        idSistemaGestao: "64375",
        idLocalizacaoBlockchain: "7ae26f4a-54c3-48c3-815a-93c21a730b1a",
      },
    ],
    loteAtual: {
      dtCriacao: "2023-12-18T14:11:53",
      dtAlteracao: "2023-12-18T14:11:53",
      idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
      nome: "Garrote",
      dataEntrada: "2023-03-28",
      dataSaida: null,
      idSistemaGestao: "84062",
      idLoteBlockchain: "5f9c3fd4-d355-421e-ba9a-fb6ce7a76e82",
    },
    loteHistorico: [
      {
        dtCriacao: "2023-12-18T14:11:53",
        dtAlteracao: "2023-12-18T14:11:53",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        nome: "geral",
        dataEntrada: "2023-02-13",
        dataSaida: "2023-03-28",
        idSistemaGestao: "69932",
        idLoteBlockchain: "523314fa-a6ad-45e7-bc20-d622408e33b3",
      },
      {
        dtCriacao: "2023-12-18T14:11:53",
        dtAlteracao: "2023-12-18T14:11:53",
        idLoteSync: "cb117b3b-1680-4574-ae9c-109b070a31b9",
        nome: "Garrote",
        dataEntrada: "2023-03-28",
        dataSaida: null,
        idSistemaGestao: "84062",
        idLoteBlockchain: "5f9c3fd4-d355-421e-ba9a-fb6ce7a76e82",
      },
    ],
    idResponsavel: "2d4c3e5c-db8d-4b4e-9212-b3cc8779559c",
    nomeResponsavel: "teste-safe",
    frigorifico: null,
  },
  "ff1bca3c-c9c5-41b2-aba6-7e4857fa03c4": {
    idLoteSync: "7adfa622-1d0c-41a1-accf-1a4d29fa2e22",
    idBlockchain: "ff1bca3c-c9c5-41b2-aba6-7e4857fa03c4",
    docType: "ANIMAL",
    idSistemaGestao: "4547c59f-57d8-4e07-af92-b4c47463f1c5",
    idSisbov: "105930404455050",
    idICar: "",
    nome: "",
    dataNascimento: "2022-02-01",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "",
    idAnimalFazenda: "445505",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 100, racaPrincipal: true },
    ],
    pesoHistorico: [],
    produtorAtual: {
      idBlockchain: "5ba141f9-74f9-4630-be2f-ceebde517b39",
      nome: "teste-safe",
      documento: "123456789123456",
    },
    status: "VIVO",
    dataCriacao: "2023-12-18T13:21:49",
    dataUltimaAtualizacao: "2023-12-18T18:35:36",
    vendaAtual: {
      idBlockchain: "fcaeffe6-1066-4af0-af3f-bc2f5c3a90a3",
      documentoProprietarioDestino: "222",
      tipo: "INDUSTRIA",
      proprietarioOrigem: { nome: "teste-safe", documento: "123456789123456" },
      proprietarioDestino: { nome: "Frigorífico", documento: "222" },
      dataCriacao: "2023-12-18T18:35:36",
      dataUltimaAtualizacao: "2023-12-18T18:35:36",
      status: "NAO_CONFIRMADA",
      qtdAnimais: 50,
    },
    vendaHistorico: [],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [],
    nutricoesAplicadas: [],
    localizacaoHistorico: [],
    loteHistorico: [],
    idResponsavel: "2d4c3e5c-db8d-4b4e-9212-b3cc8779559c",
    nomeResponsavel: "teste-safe",
    frigorifico: null,
  },
  "7e53af00-7dd4-4aeb-b449-a126a80a5edc": {
    idLoteSync: "77698299-6fa3-4374-86a8-a197ab2e0e94",
    idBlockchain: "7e53af00-7dd4-4aeb-b449-a126a80a5edc",
    docType: "ANIMAL",
    idSistemaGestao: "0ce642e0-4543-4b44-b4cb-9dcf98e6171b",
    idSisbov: "105500434757147",
    idICar: "",
    nome: "A05",
    dataNascimento: "2020-06-23",
    sexo: "FEMEA",
    idPaiBlockchain: "",
    idMaeBlockchain: "",
    idBrincoEletronico: "982000442443813",
    idAnimalFazenda: "A05",
    idRgn: "",
    idRgd: "",
    pelagem: "",
    composicaoRacial: [
      { nome: "Nelore", porcentagemRaca: 50, racaPrincipal: true },
      { nome: "Angus", porcentagemRaca: 50, racaPrincipal: false },
    ],
    pesoAtual: {
      dtCriacao: "2023-12-18T13:52:01",
      idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
      idSistemaGestao: "0ce642e0-4543-4b44-b4cb-9dcf98e6171b",
      idResponsavel: "09af30c7-3ae2-4016-8659-0da0fb248818",
      nomeResponsavel: "Natalino Cavalli Junior",
      latitude: "",
      longitude: "",
      tipo: "Saída",
      peso: 498,
      dtPesagem: "2022-03-01T01:01:01",
      idPesagemBlockchain: "b511bdac-d9d2-4ff8-8717-98fd51c081d1",
    },
    pesoHistorico: [
      {
        dtCriacao: "2023-12-18T13:52:01",
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        idSistemaGestao: "0ce642e0-4543-4b44-b4cb-9dcf98e6171b",
        idResponsavel: "09af30c7-3ae2-4016-8659-0da0fb248818",
        nomeResponsavel: "Natalino Cavalli Junior",
        latitude: "",
        longitude: "",
        tipo: "Nascimento",
        peso: 35,
        dtPesagem: "2020-06-23T01:01:01",
        idPesagemBlockchain: "4e576449-41ed-4a71-ac2b-4bea30eb6b86",
      },
      {
        dtCriacao: "2023-12-18T13:52:01",
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        idSistemaGestao: "0ce642e0-4543-4b44-b4cb-9dcf98e6171b",
        idResponsavel: "09af30c7-3ae2-4016-8659-0da0fb248818",
        nomeResponsavel: "Natalino Cavalli Junior",
        latitude: "",
        longitude: "",
        tipo: "Desmame",
        peso: 291,
        dtPesagem: "2021-03-30T01:01:01",
        idPesagemBlockchain: "b7048d06-794a-4080-8b9b-69d8f45910bb",
      },
      {
        dtCriacao: "2023-12-18T13:52:01",
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        idSistemaGestao: "0ce642e0-4543-4b44-b4cb-9dcf98e6171b",
        idResponsavel: "09af30c7-3ae2-4016-8659-0da0fb248818",
        nomeResponsavel: "Natalino Cavalli Junior",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 316,
        dtPesagem: "2021-05-17T01:01:01",
        idPesagemBlockchain: "3de4379d-92de-4938-9220-e8c4f41bf1a9",
      },
      {
        dtCriacao: "2023-12-18T13:52:01",
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        idSistemaGestao: "0ce642e0-4543-4b44-b4cb-9dcf98e6171b",
        idResponsavel: "09af30c7-3ae2-4016-8659-0da0fb248818",
        nomeResponsavel: "Natalino Cavalli Junior",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 291,
        dtPesagem: "2021-07-21T01:01:01",
        idPesagemBlockchain: "33cbade4-7fa9-4fcd-9716-b463c482a114",
      },
      {
        dtCriacao: "2023-12-18T13:52:01",
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        idSistemaGestao: "0ce642e0-4543-4b44-b4cb-9dcf98e6171b",
        idResponsavel: "09af30c7-3ae2-4016-8659-0da0fb248818",
        nomeResponsavel: "Natalino Cavalli Junior",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 333,
        dtPesagem: "2021-11-15T01:01:01",
        idPesagemBlockchain: "8491a9cb-3e74-4e04-b01b-aa6ecd495ba3",
      },
      {
        dtCriacao: "2023-12-18T13:52:01",
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        idSistemaGestao: "0ce642e0-4543-4b44-b4cb-9dcf98e6171b",
        idResponsavel: "09af30c7-3ae2-4016-8659-0da0fb248818",
        nomeResponsavel: "Natalino Cavalli Junior",
        latitude: "",
        longitude: "",
        tipo: "Pesagem Simples",
        peso: 393,
        dtPesagem: "2021-12-15T01:01:01",
        idPesagemBlockchain: "ce1f7e52-7147-429d-95e8-3b34a394f01d",
      },
      {
        dtCriacao: "2023-12-18T13:52:01",
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        idSistemaGestao: "0ce642e0-4543-4b44-b4cb-9dcf98e6171b",
        idResponsavel: "09af30c7-3ae2-4016-8659-0da0fb248818",
        nomeResponsavel: "Natalino Cavalli Junior",
        latitude: "",
        longitude: "",
        tipo: "Saída",
        peso: 498,
        dtPesagem: "2022-03-01T01:01:01",
        idPesagemBlockchain: "b511bdac-d9d2-4ff8-8717-98fd51c081d1",
      },
    ],
    produtorAtual: {
      idBlockchain: "ea8dd8f2-89e2-4a74-b33f-963c03cc6b3e",
      nome: "Natalino Cavalli Junior",
      documento: "21424412897",
    },
    status: "ABATIDO",
    dataCriacao: "2023-12-18T13:26:28",
    dataUltimaAtualizacao: "2023-12-18T18:45:15",
    vendaAtual: {
      idBlockchain: "e6d5f995-be9f-4525-825f-b8e8c55d7fd3",
      documentoProprietarioDestino: "07162028000417",
      tipo: "INDUSTRIA",
      proprietarioOrigem: {
        nome: "Natalino Cavalli Junior",
        documento: "21424412897",
      },
      proprietarioDestino: {
        nome: "VPJ Alimentos",
        documento: "07162028000417",
      },
      dataCriacao: "2023-12-18T18:17:48",
      dataUltimaAtualizacao: "2023-12-18T18:25:31",
      status: "EFETIVADA",
      qtdAnimais: 1,
    },
    vendaHistorico: [
      {
        idBlockchain: "e6d5f995-be9f-4525-825f-b8e8c55d7fd3",
        documentoProprietarioDestino: "07162028000417",
        tipo: "INDUSTRIA",
        proprietarioOrigem: {
          nome: "Natalino Cavalli Junior",
          documento: "21424412897",
        },
        proprietarioDestino: {
          nome: "VPJ Alimentos",
          documento: "07162028000417",
        },
        dataCriacao: "2023-12-18T18:17:48",
        dataUltimaAtualizacao: "2023-12-18T18:25:31",
        status: "EFETIVADA",
        qtdAnimais: 1,
      },
    ],
    reproducoes: [],
    acoes: [],
    exames: [],
    medicamentosAplicados: [
      {
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        dtCriacao: "2023-12-18T13:54:23",
        dtAlteracao: "2023-12-18T13:54:23",
        idSistemaGestao: "0ce642e0-4543-4b44-b4cb-9dcf98e6171b",
        idOriginalMedicamentoBlockchain: "1c71dcd4-0bf2-4351-935b-5ddde5987566",
        idResponsavel: "09af30c7-3ae2-4016-8659-0da0fb248818",
        nomeResponsavel: "Natalino Cavalli Junior",
        latitude: "-21.4216111",
        longitude: "-52.8631389",
        dtMedicacao: "2021-11-15",
        unidadeMedida: "MILILITRO",
        quantidade: 5,
        idMedicamentoBlockchain: "106d19d4-21f5-4d1c-8431-cd90880b2dc4",
        medicamento: {
          idBlockchain: "1c71dcd4-0bf2-4351-935b-5ddde5987566",
          nome: "FORTRESS 7",
          categoria: "FARMACIA",
          subCategoria: "VACINA",
          fabricante: "Zoets",
          unidade: "MILILITRO",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
      {
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        dtCriacao: "2023-12-18T13:54:23",
        dtAlteracao: "2023-12-18T13:54:23",
        idSistemaGestao: "0ce642e0-4543-4b44-b4cb-9dcf98e6171b",
        idOriginalMedicamentoBlockchain: "6198fbc1-e8f5-4472-978c-ea96122fb069",
        idResponsavel: "09af30c7-3ae2-4016-8659-0da0fb248818",
        nomeResponsavel: "Natalino Cavalli Junior",
        latitude: "-21.4216111",
        longitude: "-52.8631389",
        dtMedicacao: "2021-05-17",
        unidadeMedida: "MILILITRO",
        quantidade: 25,
        idMedicamentoBlockchain: "4202ddfd-5928-4029-b1ce-a7f7c0e5ff46",
        medicamento: {
          idBlockchain: "6198fbc1-e8f5-4472-978c-ea96122fb069",
          nome: "ACTYL POUR-ON",
          categoria: "FARMACIA",
          subCategoria: "ANTIPARASITARIO",
          fabricante: "BIMEDA",
          unidade: "MILILITRO",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:30",
          dataUltimaAtualizacao: "2023-12-13T13:25:30",
        },
      },
      {
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        dtCriacao: "2023-12-18T13:54:23",
        dtAlteracao: "2023-12-18T13:54:23",
        idSistemaGestao: "0ce642e0-4543-4b44-b4cb-9dcf98e6171b",
        idOriginalMedicamentoBlockchain: "0adfff4c-40aa-4732-be5c-d0a92d729229",
        idResponsavel: "09af30c7-3ae2-4016-8659-0da0fb248818",
        nomeResponsavel: "Natalino Cavalli Junior",
        latitude: "-21.4216111",
        longitude: "-52.8631389",
        dtMedicacao: "2021-03-30",
        unidadeMedida: "MILILITRO",
        quantidade: 2,
        idMedicamentoBlockchain: "6950d6d1-ee2d-4e5a-ab00-5aaacfb48d74",
        medicamento: {
          idBlockchain: "0adfff4c-40aa-4732-be5c-d0a92d729229",
          nome: "ABORVAC BRUCELOSE",
          categoria: "FARMACIA",
          subCategoria: "VACINA",
          fabricante: "Zoets",
          unidade: "MILILITRO",
          sexo: "FEMEA",
          dataCriacao: "2023-12-13T13:25:30",
          dataUltimaAtualizacao: "2023-12-13T13:25:30",
        },
      },
      {
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        dtCriacao: "2023-12-18T13:54:23",
        dtAlteracao: "2023-12-18T13:54:23",
        idSistemaGestao: "0ce642e0-4543-4b44-b4cb-9dcf98e6171b",
        idOriginalMedicamentoBlockchain: "748e6766-3bf2-45d9-8b70-4c0226b4461f",
        idResponsavel: "09af30c7-3ae2-4016-8659-0da0fb248818",
        nomeResponsavel: "Natalino Cavalli Junior",
        latitude: "-21.4216111",
        longitude: "-52.8631389",
        dtMedicacao: "2021-11-15",
        unidadeMedida: "MILILITRO",
        quantidade: 6,
        idMedicamentoBlockchain: "41fa87aa-3b42-4dbb-877c-842796bd6e8c",
        medicamento: {
          idBlockchain: "748e6766-3bf2-45d9-8b70-4c0226b4461f",
          nome: "TREO",
          categoria: "FARMACIA",
          subCategoria: "VERMIFUGO",
          fabricante: "Zoets",
          unidade: "MILILITRO",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:30",
          dataUltimaAtualizacao: "2023-12-13T13:25:30",
        },
      },
      {
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        dtCriacao: "2023-12-18T13:54:23",
        dtAlteracao: "2023-12-18T13:54:23",
        idSistemaGestao: "0ce642e0-4543-4b44-b4cb-9dcf98e6171b",
        idOriginalMedicamentoBlockchain: "748e6766-3bf2-45d9-8b70-4c0226b4461f",
        idResponsavel: "09af30c7-3ae2-4016-8659-0da0fb248818",
        nomeResponsavel: "Natalino Cavalli Junior",
        latitude: "-21.4216111",
        longitude: "-52.8631389",
        dtMedicacao: "2021-03-30",
        unidadeMedida: "MILILITRO",
        quantidade: 6,
        idMedicamentoBlockchain: "d4cba26c-be89-44a1-b56e-ec6c03add721",
        medicamento: {
          idBlockchain: "748e6766-3bf2-45d9-8b70-4c0226b4461f",
          nome: "TREO",
          categoria: "FARMACIA",
          subCategoria: "VERMIFUGO",
          fabricante: "Zoets",
          unidade: "MILILITRO",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:30",
          dataUltimaAtualizacao: "2023-12-13T13:25:30",
        },
      },
      {
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        dtCriacao: "2023-12-18T13:54:23",
        dtAlteracao: "2023-12-18T13:54:23",
        idSistemaGestao: "0ce642e0-4543-4b44-b4cb-9dcf98e6171b",
        idOriginalMedicamentoBlockchain: "106ea23c-a7f0-450e-8d8b-90d9e9fa5aff",
        idResponsavel: "09af30c7-3ae2-4016-8659-0da0fb248818",
        nomeResponsavel: "Natalino Cavalli Junior",
        latitude: "-21.4216111",
        longitude: "-52.8631389",
        dtMedicacao: "2021-11-15",
        unidadeMedida: "MILILITRO",
        quantidade: 30,
        idMedicamentoBlockchain: "d54b9927-2f01-4f79-8295-8bf8869a703a",
        medicamento: {
          idBlockchain: "106ea23c-a7f0-450e-8d8b-90d9e9fa5aff",
          nome: "ACATAK POUR ON",
          categoria: "FARMACIA",
          subCategoria: "ECTOCIDA",
          unidade: "MILILITRO",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
      {
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        dtCriacao: "2023-12-18T13:54:23",
        dtAlteracao: "2023-12-18T13:54:23",
        idSistemaGestao: "0ce642e0-4543-4b44-b4cb-9dcf98e6171b",
        idOriginalMedicamentoBlockchain: "19ab7e80-6ab4-4c09-a49f-f2f77bc0e171",
        idResponsavel: "09af30c7-3ae2-4016-8659-0da0fb248818",
        nomeResponsavel: "Natalino Cavalli Junior",
        latitude: "-21.4216111",
        longitude: "-52.8631389",
        dtMedicacao: "2021-03-30",
        unidadeMedida: "MILILITRO",
        quantidade: 5,
        idMedicamentoBlockchain: "7d26ddcf-a1b2-4309-9a4c-87dd5d313ed8",
        medicamento: {
          idBlockchain: "19ab7e80-6ab4-4c09-a49f-f2f77bc0e171",
          nome: "Botulinomax",
          categoria: "FARMACIA",
          subCategoria: "VACINA",
          unidade: "MILILITRO",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
      {
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        dtCriacao: "2023-12-18T13:54:23",
        dtAlteracao: "2023-12-18T13:54:23",
        idSistemaGestao: "0ce642e0-4543-4b44-b4cb-9dcf98e6171b",
        idOriginalMedicamentoBlockchain: "afce3234-5025-4597-b8cd-764e05425c77",
        idResponsavel: "09af30c7-3ae2-4016-8659-0da0fb248818",
        nomeResponsavel: "Natalino Cavalli Junior",
        latitude: "-21.4216111",
        longitude: "-52.8631389",
        dtMedicacao: "2021-05-17",
        unidadeMedida: "MILILITRO",
        quantidade: 2,
        idMedicamentoBlockchain: "24827c64-405b-4f42-a0e3-0b941ce35220",
        medicamento: {
          idBlockchain: "afce3234-5025-4597-b8cd-764e05425c77",
          nome: "AFTOSA",
          categoria: "FARMACIA",
          subCategoria: "VACINA",
          unidade: "MILILITRO",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:30",
          dataUltimaAtualizacao: "2023-12-13T13:25:30",
        },
      },
      {
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        dtCriacao: "2023-12-18T13:54:23",
        dtAlteracao: "2023-12-18T13:54:23",
        idSistemaGestao: "0ce642e0-4543-4b44-b4cb-9dcf98e6171b",
        idOriginalMedicamentoBlockchain: "17b95f67-046e-4f7f-ac59-98b258d231bc",
        idResponsavel: "09af30c7-3ae2-4016-8659-0da0fb248818",
        nomeResponsavel: "Natalino Cavalli Junior",
        latitude: "-21.4216111",
        longitude: "-52.8631389",
        dtMedicacao: "2021-07-21",
        unidadeMedida: "MILILITRO",
        quantidade: 6,
        idMedicamentoBlockchain: "17a3c2e9-e944-4167-8f02-e5dacaa61dd6",
        medicamento: {
          idBlockchain: "17b95f67-046e-4f7f-ac59-98b258d231bc",
          nome: "DUOTIN ABAMECTINA",
          categoria: "FARMACIA",
          subCategoria: "VERMIFUGO",
          unidade: "MILILITRO",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
      {
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        dtCriacao: "2023-12-18T13:54:23",
        dtAlteracao: "2023-12-18T13:54:23",
        idSistemaGestao: "0ce642e0-4543-4b44-b4cb-9dcf98e6171b",
        idOriginalMedicamentoBlockchain: "d38d09bf-ed6e-4ee8-a9cc-73cf4495b441",
        idResponsavel: "09af30c7-3ae2-4016-8659-0da0fb248818",
        nomeResponsavel: "Natalino Cavalli Junior",
        latitude: "-21.4216111",
        longitude: "-52.8631389",
        dtMedicacao: "2021-07-21",
        unidadeMedida: "MILILITRO",
        quantidade: 26,
        idMedicamentoBlockchain: "5ca6902f-c648-462b-a2d9-93257404601d",
        medicamento: {
          idBlockchain: "d38d09bf-ed6e-4ee8-a9cc-73cf4495b441",
          nome: "EFFIPRO BOVIS POUR ON",
          categoria: "FARMACIA",
          subCategoria: "ANTIPARASITARIO",
          fabricante: "VIRBAC",
          unidade: "MILILITRO",
          sexo: "AMBOS",
          dataCriacao: "2023-12-13T13:25:31",
          dataUltimaAtualizacao: "2023-12-13T13:25:31",
        },
      },
    ],
    nutricoesAplicadas: [
      {
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        dtCriacao: "2023-12-18T14:00:56",
        dtAlteracao: "2023-12-18T14:00:56",
        idSistemaGestao: "0ce642e0-4543-4b44-b4cb-9dcf98e6171b",
        idOriginalNutricaoBlockchain: "5a05d5cd-46db-4b66-8168-cd54b1ed8773",
        idResponsavel: "09af30c7-3ae2-4016-8659-0da0fb248818",
        nomeResponsavel: "Natalino Cavalli Junior",
        descricao: "BAIA 01 - RACAO 2% TIP FARELO DE SOJA",
        latitude: "-21.4216111",
        longitude: "-52.8631389",
        dtNutricao: "2021-12-19",
        unidadeMedida: "QUILOGRAMA",
        quantidade: 5,
        idNutricaoBlockchain: "edec10ec-3d70-46bf-9bb2-cebe13a23b39",
        nutricao: {
          idBlockchain: "5a05d5cd-46db-4b66-8168-cd54b1ed8773",
          nome: "RACAO 2% TIP FARELO DE SOJA",
          categoria: "NUTRICIONAL",
          subCategoria: "RACAO_CONFINAMENTO",
          unidade: "QUILOGRAMA",
          dataCriacao: "2023-12-13T13:26:37",
          dataUltimaAtualizacao: "2023-12-13T13:26:37",
        },
      },
      {
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        dtCriacao: "2023-12-18T14:00:56",
        dtAlteracao: "2023-12-18T14:00:56",
        idSistemaGestao: "0ce642e0-4543-4b44-b4cb-9dcf98e6171b",
        idOriginalNutricaoBlockchain: "5a05d5cd-46db-4b66-8168-cd54b1ed8773",
        idResponsavel: "09af30c7-3ae2-4016-8659-0da0fb248818",
        nomeResponsavel: "Natalino Cavalli Junior",
        descricao: "BAIA 01 - RACAO 2% TIP FARELO DE SOJA",
        latitude: "-21.4216111",
        longitude: "-52.8631389",
        dtNutricao: "2021-12-24",
        unidadeMedida: "QUILOGRAMA",
        quantidade: 5,
        idNutricaoBlockchain: "bcb693fa-8054-43f3-baed-04e82196b98c",
        nutricao: {
          idBlockchain: "5a05d5cd-46db-4b66-8168-cd54b1ed8773",
          nome: "RACAO 2% TIP FARELO DE SOJA",
          categoria: "NUTRICIONAL",
          subCategoria: "RACAO_CONFINAMENTO",
          unidade: "QUILOGRAMA",
          dataCriacao: "2023-12-13T13:26:37",
          dataUltimaAtualizacao: "2023-12-13T13:26:37",
        },
      },
      {
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        dtCriacao: "2023-12-18T14:00:56",
        dtAlteracao: "2023-12-18T14:00:56",
        idSistemaGestao: "0ce642e0-4543-4b44-b4cb-9dcf98e6171b",
        idOriginalNutricaoBlockchain: "5a05d5cd-46db-4b66-8168-cd54b1ed8773",
        idResponsavel: "09af30c7-3ae2-4016-8659-0da0fb248818",
        nomeResponsavel: "Natalino Cavalli Junior",
        descricao: "BAIA 01 - RACAO 2% TIP FARELO DE SOJA",
        latitude: "-21.4216111",
        longitude: "-52.8631389",
        dtNutricao: "2021-12-23",
        unidadeMedida: "QUILOGRAMA",
        quantidade: 5,
        idNutricaoBlockchain: "4d084c77-fd7d-4d86-aa20-45f8c1833f30",
        nutricao: {
          idBlockchain: "5a05d5cd-46db-4b66-8168-cd54b1ed8773",
          nome: "RACAO 2% TIP FARELO DE SOJA",
          categoria: "NUTRICIONAL",
          subCategoria: "RACAO_CONFINAMENTO",
          unidade: "QUILOGRAMA",
          dataCriacao: "2023-12-13T13:26:37",
          dataUltimaAtualizacao: "2023-12-13T13:26:37",
        },
      },
      {
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        dtCriacao: "2023-12-18T14:00:56",
        dtAlteracao: "2023-12-18T14:00:56",
        idSistemaGestao: "0ce642e0-4543-4b44-b4cb-9dcf98e6171b",
        idOriginalNutricaoBlockchain: "5a05d5cd-46db-4b66-8168-cd54b1ed8773",
        idResponsavel: "09af30c7-3ae2-4016-8659-0da0fb248818",
        nomeResponsavel: "Natalino Cavalli Junior",
        descricao: "BAIA 01 - RACAO 2% TIP FARELO DE SOJA",
        latitude: "-21.4216111",
        longitude: "-52.8631389",
        dtNutricao: "2021-12-21",
        unidadeMedida: "QUILOGRAMA",
        quantidade: 5,
        idNutricaoBlockchain: "db7aa2fd-da72-49be-aa36-1db1588f6eda",
        nutricao: {
          idBlockchain: "5a05d5cd-46db-4b66-8168-cd54b1ed8773",
          nome: "RACAO 2% TIP FARELO DE SOJA",
          categoria: "NUTRICIONAL",
          subCategoria: "RACAO_CONFINAMENTO",
          unidade: "QUILOGRAMA",
          dataCriacao: "2023-12-13T13:26:37",
          dataUltimaAtualizacao: "2023-12-13T13:26:37",
        },
      },
      {
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        dtCriacao: "2023-12-18T14:00:56",
        dtAlteracao: "2023-12-18T14:00:56",
        idSistemaGestao: "0ce642e0-4543-4b44-b4cb-9dcf98e6171b",
        idOriginalNutricaoBlockchain: "5a05d5cd-46db-4b66-8168-cd54b1ed8773",
        idResponsavel: "09af30c7-3ae2-4016-8659-0da0fb248818",
        nomeResponsavel: "Natalino Cavalli Junior",
        descricao: "BAIA 01 - RACAO 2% TIP FARELO DE SOJA",
        latitude: "-21.4216111",
        longitude: "-52.8631389",
        dtNutricao: "2021-12-17",
        unidadeMedida: "QUILOGRAMA",
        quantidade: 5,
        idNutricaoBlockchain: "e6e4f55f-9c2d-4a18-94ab-e68e1ba9e9d4",
        nutricao: {
          idBlockchain: "5a05d5cd-46db-4b66-8168-cd54b1ed8773",
          nome: "RACAO 2% TIP FARELO DE SOJA",
          categoria: "NUTRICIONAL",
          subCategoria: "RACAO_CONFINAMENTO",
          unidade: "QUILOGRAMA",
          dataCriacao: "2023-12-13T13:26:37",
          dataUltimaAtualizacao: "2023-12-13T13:26:37",
        },
      },
      {
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        dtCriacao: "2023-12-18T14:00:56",
        dtAlteracao: "2023-12-18T14:00:56",
        idSistemaGestao: "0ce642e0-4543-4b44-b4cb-9dcf98e6171b",
        idOriginalNutricaoBlockchain: "5a05d5cd-46db-4b66-8168-cd54b1ed8773",
        idResponsavel: "09af30c7-3ae2-4016-8659-0da0fb248818",
        nomeResponsavel: "Natalino Cavalli Junior",
        descricao: "BAIA 01 - RACAO 2% TIP FARELO DE SOJA",
        latitude: "-21.4216111",
        longitude: "-52.8631389",
        dtNutricao: "2021-12-25",
        unidadeMedida: "QUILOGRAMA",
        quantidade: 5,
        idNutricaoBlockchain: "a3b6ad74-0afb-4b56-91e6-858bbefc84ea",
        nutricao: {
          idBlockchain: "5a05d5cd-46db-4b66-8168-cd54b1ed8773",
          nome: "RACAO 2% TIP FARELO DE SOJA",
          categoria: "NUTRICIONAL",
          subCategoria: "RACAO_CONFINAMENTO",
          unidade: "QUILOGRAMA",
          dataCriacao: "2023-12-13T13:26:37",
          dataUltimaAtualizacao: "2023-12-13T13:26:37",
        },
      },
      {
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        dtCriacao: "2023-12-18T14:00:57",
        dtAlteracao: "2023-12-18T14:00:57",
        idSistemaGestao: "0ce642e0-4543-4b44-b4cb-9dcf98e6171b",
        idOriginalNutricaoBlockchain: "5a05d5cd-46db-4b66-8168-cd54b1ed8773",
        idResponsavel: "09af30c7-3ae2-4016-8659-0da0fb248818",
        nomeResponsavel: "Natalino Cavalli Junior",
        descricao: "BAIA 01 - RACAO 2% TIP FARELO DE SOJA",
        latitude: "-21.4216111",
        longitude: "-52.8631389",
        dtNutricao: "2021-12-29",
        unidadeMedida: "QUILOGRAMA",
        quantidade: 5,
        idNutricaoBlockchain: "86fddd43-2045-4d0c-8025-2b08307f254e",
        nutricao: {
          idBlockchain: "5a05d5cd-46db-4b66-8168-cd54b1ed8773",
          nome: "RACAO 2% TIP FARELO DE SOJA",
          categoria: "NUTRICIONAL",
          subCategoria: "RACAO_CONFINAMENTO",
          unidade: "QUILOGRAMA",
          dataCriacao: "2023-12-13T13:26:37",
          dataUltimaAtualizacao: "2023-12-13T13:26:37",
        },
      },
      {
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        dtCriacao: "2023-12-18T14:00:57",
        dtAlteracao: "2023-12-18T14:00:57",
        idSistemaGestao: "0ce642e0-4543-4b44-b4cb-9dcf98e6171b",
        idOriginalNutricaoBlockchain: "5a05d5cd-46db-4b66-8168-cd54b1ed8773",
        idResponsavel: "09af30c7-3ae2-4016-8659-0da0fb248818",
        nomeResponsavel: "Natalino Cavalli Junior",
        descricao: "BAIA 01 - RACAO 2% TIP FARELO DE SOJA",
        latitude: "-21.4216111",
        longitude: "-52.8631389",
        dtNutricao: "2021-12-26",
        unidadeMedida: "QUILOGRAMA",
        quantidade: 5,
        idNutricaoBlockchain: "681350ce-4269-4d74-ab1b-007efecbcf45",
        nutricao: {
          idBlockchain: "5a05d5cd-46db-4b66-8168-cd54b1ed8773",
          nome: "RACAO 2% TIP FARELO DE SOJA",
          categoria: "NUTRICIONAL",
          subCategoria: "RACAO_CONFINAMENTO",
          unidade: "QUILOGRAMA",
          dataCriacao: "2023-12-13T13:26:37",
          dataUltimaAtualizacao: "2023-12-13T13:26:37",
        },
      },
      {
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        dtCriacao: "2023-12-18T14:00:57",
        dtAlteracao: "2023-12-18T14:00:57",
        idSistemaGestao: "0ce642e0-4543-4b44-b4cb-9dcf98e6171b",
        idOriginalNutricaoBlockchain: "5a05d5cd-46db-4b66-8168-cd54b1ed8773",
        idResponsavel: "09af30c7-3ae2-4016-8659-0da0fb248818",
        nomeResponsavel: "Natalino Cavalli Junior",
        descricao: "BAIA 01 - RACAO 2% TIP FARELO DE SOJA",
        latitude: "-21.4216111",
        longitude: "-52.8631389",
        dtNutricao: "2021-12-28",
        unidadeMedida: "QUILOGRAMA",
        quantidade: 5,
        idNutricaoBlockchain: "dc71a13a-ede6-4e04-95d2-0c7af8f0567b",
        nutricao: {
          idBlockchain: "5a05d5cd-46db-4b66-8168-cd54b1ed8773",
          nome: "RACAO 2% TIP FARELO DE SOJA",
          categoria: "NUTRICIONAL",
          subCategoria: "RACAO_CONFINAMENTO",
          unidade: "QUILOGRAMA",
          dataCriacao: "2023-12-13T13:26:37",
          dataUltimaAtualizacao: "2023-12-13T13:26:37",
        },
      },
      {
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        dtCriacao: "2023-12-18T14:00:57",
        dtAlteracao: "2023-12-18T14:00:57",
        idSistemaGestao: "0ce642e0-4543-4b44-b4cb-9dcf98e6171b",
        idOriginalNutricaoBlockchain: "5a05d5cd-46db-4b66-8168-cd54b1ed8773",
        idResponsavel: "09af30c7-3ae2-4016-8659-0da0fb248818",
        nomeResponsavel: "Natalino Cavalli Junior",
        descricao: "BAIA 01 - RACAO 2% TIP FARELO DE SOJA",
        latitude: "-21.4216111",
        longitude: "-52.8631389",
        dtNutricao: "2021-12-20",
        unidadeMedida: "QUILOGRAMA",
        quantidade: 5,
        idNutricaoBlockchain: "30c43bda-e7c1-495a-bac3-809a1fc8cc5a",
        nutricao: {
          idBlockchain: "5a05d5cd-46db-4b66-8168-cd54b1ed8773",
          nome: "RACAO 2% TIP FARELO DE SOJA",
          categoria: "NUTRICIONAL",
          subCategoria: "RACAO_CONFINAMENTO",
          unidade: "QUILOGRAMA",
          dataCriacao: "2023-12-13T13:26:37",
          dataUltimaAtualizacao: "2023-12-13T13:26:37",
        },
      },
      {
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        dtCriacao: "2023-12-18T14:00:57",
        dtAlteracao: "2023-12-18T14:00:57",
        idSistemaGestao: "0ce642e0-4543-4b44-b4cb-9dcf98e6171b",
        idOriginalNutricaoBlockchain: "5a05d5cd-46db-4b66-8168-cd54b1ed8773",
        idResponsavel: "09af30c7-3ae2-4016-8659-0da0fb248818",
        nomeResponsavel: "Natalino Cavalli Junior",
        descricao: "BAIA 01 - RACAO 2% TIP FARELO DE SOJA",
        latitude: "-21.4216111",
        longitude: "-52.8631389",
        dtNutricao: "2021-12-16",
        unidadeMedida: "QUILOGRAMA",
        quantidade: 5,
        idNutricaoBlockchain: "93c402f5-8d4f-4f93-b38c-8461c9442167",
        nutricao: {
          idBlockchain: "5a05d5cd-46db-4b66-8168-cd54b1ed8773",
          nome: "RACAO 2% TIP FARELO DE SOJA",
          categoria: "NUTRICIONAL",
          subCategoria: "RACAO_CONFINAMENTO",
          unidade: "QUILOGRAMA",
          dataCriacao: "2023-12-13T13:26:37",
          dataUltimaAtualizacao: "2023-12-13T13:26:37",
        },
      },
      {
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        dtCriacao: "2023-12-18T14:00:57",
        dtAlteracao: "2023-12-18T14:00:57",
        idSistemaGestao: "0ce642e0-4543-4b44-b4cb-9dcf98e6171b",
        idOriginalNutricaoBlockchain: "5a05d5cd-46db-4b66-8168-cd54b1ed8773",
        idResponsavel: "09af30c7-3ae2-4016-8659-0da0fb248818",
        nomeResponsavel: "Natalino Cavalli Junior",
        descricao: "BAIA 01 - RACAO 2% TIP FARELO DE SOJA",
        latitude: "-21.4216111",
        longitude: "-52.8631389",
        dtNutricao: "2021-12-18",
        unidadeMedida: "QUILOGRAMA",
        quantidade: 5,
        idNutricaoBlockchain: "53c1d101-2adb-420f-ba3f-16c8c5a02a1d",
        nutricao: {
          idBlockchain: "5a05d5cd-46db-4b66-8168-cd54b1ed8773",
          nome: "RACAO 2% TIP FARELO DE SOJA",
          categoria: "NUTRICIONAL",
          subCategoria: "RACAO_CONFINAMENTO",
          unidade: "QUILOGRAMA",
          dataCriacao: "2023-12-13T13:26:37",
          dataUltimaAtualizacao: "2023-12-13T13:26:37",
        },
      },
      {
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        dtCriacao: "2023-12-18T14:00:57",
        dtAlteracao: "2023-12-18T14:00:57",
        idSistemaGestao: "0ce642e0-4543-4b44-b4cb-9dcf98e6171b",
        idOriginalNutricaoBlockchain: "5a05d5cd-46db-4b66-8168-cd54b1ed8773",
        idResponsavel: "09af30c7-3ae2-4016-8659-0da0fb248818",
        nomeResponsavel: "Natalino Cavalli Junior",
        descricao: "BAIA 01 - RACAO 2% TIP FARELO DE SOJA",
        latitude: "-21.4216111",
        longitude: "-52.8631389",
        dtNutricao: "2021-12-22",
        unidadeMedida: "QUILOGRAMA",
        quantidade: 5,
        idNutricaoBlockchain: "2b8d8bef-879c-478b-acc8-eb5c4b957e66",
        nutricao: {
          idBlockchain: "5a05d5cd-46db-4b66-8168-cd54b1ed8773",
          nome: "RACAO 2% TIP FARELO DE SOJA",
          categoria: "NUTRICIONAL",
          subCategoria: "RACAO_CONFINAMENTO",
          unidade: "QUILOGRAMA",
          dataCriacao: "2023-12-13T13:26:37",
          dataUltimaAtualizacao: "2023-12-13T13:26:37",
        },
      },
      {
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        dtCriacao: "2023-12-18T14:00:57",
        dtAlteracao: "2023-12-18T14:00:57",
        idSistemaGestao: "0ce642e0-4543-4b44-b4cb-9dcf98e6171b",
        idOriginalNutricaoBlockchain: "5a05d5cd-46db-4b66-8168-cd54b1ed8773",
        idResponsavel: "09af30c7-3ae2-4016-8659-0da0fb248818",
        nomeResponsavel: "Natalino Cavalli Junior",
        descricao: "BAIA 01 - RACAO 2% TIP FARELO DE SOJA",
        latitude: "-21.4216111",
        longitude: "-52.8631389",
        dtNutricao: "2021-12-31",
        unidadeMedida: "QUILOGRAMA",
        quantidade: 5,
        idNutricaoBlockchain: "4d6da3b8-7e1a-4173-8d9c-7267f1713ce9",
        nutricao: {
          idBlockchain: "5a05d5cd-46db-4b66-8168-cd54b1ed8773",
          nome: "RACAO 2% TIP FARELO DE SOJA",
          categoria: "NUTRICIONAL",
          subCategoria: "RACAO_CONFINAMENTO",
          unidade: "QUILOGRAMA",
          dataCriacao: "2023-12-13T13:26:37",
          dataUltimaAtualizacao: "2023-12-13T13:26:37",
        },
      },
      {
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        dtCriacao: "2023-12-18T14:00:57",
        dtAlteracao: "2023-12-18T14:00:57",
        idSistemaGestao: "0ce642e0-4543-4b44-b4cb-9dcf98e6171b",
        idOriginalNutricaoBlockchain: "5a05d5cd-46db-4b66-8168-cd54b1ed8773",
        idResponsavel: "09af30c7-3ae2-4016-8659-0da0fb248818",
        nomeResponsavel: "Natalino Cavalli Junior",
        descricao: "BAIA 01 - RACAO 2% TIP FARELO DE SOJA",
        latitude: "-21.4216111",
        longitude: "-52.8631389",
        dtNutricao: "2021-12-15",
        unidadeMedida: "QUILOGRAMA",
        quantidade: 5,
        idNutricaoBlockchain: "27c31365-840a-4059-a8d8-b67ea614f9a9",
        nutricao: {
          idBlockchain: "5a05d5cd-46db-4b66-8168-cd54b1ed8773",
          nome: "RACAO 2% TIP FARELO DE SOJA",
          categoria: "NUTRICIONAL",
          subCategoria: "RACAO_CONFINAMENTO",
          unidade: "QUILOGRAMA",
          dataCriacao: "2023-12-13T13:26:37",
          dataUltimaAtualizacao: "2023-12-13T13:26:37",
        },
      },
      {
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        dtCriacao: "2023-12-18T14:00:57",
        dtAlteracao: "2023-12-18T14:00:57",
        idSistemaGestao: "0ce642e0-4543-4b44-b4cb-9dcf98e6171b",
        idOriginalNutricaoBlockchain: "5a05d5cd-46db-4b66-8168-cd54b1ed8773",
        idResponsavel: "09af30c7-3ae2-4016-8659-0da0fb248818",
        nomeResponsavel: "Natalino Cavalli Junior",
        descricao: "BAIA 01 - RACAO 2% TIP FARELO DE SOJA",
        latitude: "-21.4216111",
        longitude: "-52.8631389",
        dtNutricao: "2021-12-30",
        unidadeMedida: "QUILOGRAMA",
        quantidade: 5,
        idNutricaoBlockchain: "fc698e19-98a9-4276-b7c7-82d9167bc9b6",
        nutricao: {
          idBlockchain: "5a05d5cd-46db-4b66-8168-cd54b1ed8773",
          nome: "RACAO 2% TIP FARELO DE SOJA",
          categoria: "NUTRICIONAL",
          subCategoria: "RACAO_CONFINAMENTO",
          unidade: "QUILOGRAMA",
          dataCriacao: "2023-12-13T13:26:37",
          dataUltimaAtualizacao: "2023-12-13T13:26:37",
        },
      },
      {
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        dtCriacao: "2023-12-18T14:00:57",
        dtAlteracao: "2023-12-18T14:00:57",
        idSistemaGestao: "0ce642e0-4543-4b44-b4cb-9dcf98e6171b",
        idOriginalNutricaoBlockchain: "5a05d5cd-46db-4b66-8168-cd54b1ed8773",
        idResponsavel: "09af30c7-3ae2-4016-8659-0da0fb248818",
        nomeResponsavel: "Natalino Cavalli Junior",
        descricao: "BAIA 01 - RACAO 2% TIP FARELO DE SOJA",
        latitude: "-21.4216111",
        longitude: "-52.8631389",
        dtNutricao: "2021-12-27",
        unidadeMedida: "QUILOGRAMA",
        quantidade: 5,
        idNutricaoBlockchain: "5e5431cf-7ab9-4ca8-bc16-b0866b736f72",
        nutricao: {
          idBlockchain: "5a05d5cd-46db-4b66-8168-cd54b1ed8773",
          nome: "RACAO 2% TIP FARELO DE SOJA",
          categoria: "NUTRICIONAL",
          subCategoria: "RACAO_CONFINAMENTO",
          unidade: "QUILOGRAMA",
          dataCriacao: "2023-12-13T13:26:37",
          dataUltimaAtualizacao: "2023-12-13T13:26:37",
        },
      },
      {
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        dtCriacao: "2023-12-18T14:00:57",
        dtAlteracao: "2023-12-18T14:00:57",
        idSistemaGestao: "0ce642e0-4543-4b44-b4cb-9dcf98e6171b",
        idOriginalNutricaoBlockchain: "bcb28e9e-c700-4caa-9a57-04d776f6749a",
        idResponsavel: "09af30c7-3ae2-4016-8659-0da0fb248818",
        nomeResponsavel: "Natalino Cavalli Junior",
        descricao: "Ronda 13 - PROTEICO 0,2% SECA",
        latitude: "-21.4216111",
        longitude: "-52.8631389",
        dtNutricao: "2021-07-30",
        unidadeMedida: "QUILOGRAMA",
        quantidade: 5,
        idNutricaoBlockchain: "862b2225-5d11-43cf-b2ef-b7761b0ed18a",
        nutricao: {
          idBlockchain: "bcb28e9e-c700-4caa-9a57-04d776f6749a",
          nome: "PROTEICO 0,2% SECA",
          categoria: "NUTRICIONAL",
          subCategoria: "SUPLEMENTO_MINERAL_RECRIA_ENGORDA",
          unidade: "QUILOGRAMA",
          dataCriacao: "2023-12-13T13:26:37",
          dataUltimaAtualizacao: "2023-12-13T13:26:37",
        },
      },
      {
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        dtCriacao: "2023-12-18T14:00:57",
        dtAlteracao: "2023-12-18T14:00:57",
        idSistemaGestao: "0ce642e0-4543-4b44-b4cb-9dcf98e6171b",
        idOriginalNutricaoBlockchain: "bcb28e9e-c700-4caa-9a57-04d776f6749a",
        idResponsavel: "09af30c7-3ae2-4016-8659-0da0fb248818",
        nomeResponsavel: "Natalino Cavalli Junior",
        descricao: "Ronda 13 - PROTEICO 0,2% SECA",
        latitude: "-21.4216111",
        longitude: "-52.8631389",
        dtNutricao: "2021-07-26",
        unidadeMedida: "QUILOGRAMA",
        quantidade: 5,
        idNutricaoBlockchain: "cf9f5c8f-4327-459d-8d4e-096ee6d107e8",
        nutricao: {
          idBlockchain: "bcb28e9e-c700-4caa-9a57-04d776f6749a",
          nome: "PROTEICO 0,2% SECA",
          categoria: "NUTRICIONAL",
          subCategoria: "SUPLEMENTO_MINERAL_RECRIA_ENGORDA",
          unidade: "QUILOGRAMA",
          dataCriacao: "2023-12-13T13:26:37",
          dataUltimaAtualizacao: "2023-12-13T13:26:37",
        },
      },
      {
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        dtCriacao: "2023-12-18T14:00:57",
        dtAlteracao: "2023-12-18T14:00:57",
        idSistemaGestao: "0ce642e0-4543-4b44-b4cb-9dcf98e6171b",
        idOriginalNutricaoBlockchain: "bcb28e9e-c700-4caa-9a57-04d776f6749a",
        idResponsavel: "09af30c7-3ae2-4016-8659-0da0fb248818",
        nomeResponsavel: "Natalino Cavalli Junior",
        descricao: "Ronda 13 - PROTEICO 0,2% SECA",
        latitude: "-21.4216111",
        longitude: "-52.8631389",
        dtNutricao: "2021-07-14",
        unidadeMedida: "QUILOGRAMA",
        quantidade: 5,
        idNutricaoBlockchain: "5c8f579d-09d9-42bd-a5fd-295a6e149630",
        nutricao: {
          idBlockchain: "bcb28e9e-c700-4caa-9a57-04d776f6749a",
          nome: "PROTEICO 0,2% SECA",
          categoria: "NUTRICIONAL",
          subCategoria: "SUPLEMENTO_MINERAL_RECRIA_ENGORDA",
          unidade: "QUILOGRAMA",
          dataCriacao: "2023-12-13T13:26:37",
          dataUltimaAtualizacao: "2023-12-13T13:26:37",
        },
      },
      {
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        dtCriacao: "2023-12-18T14:00:57",
        dtAlteracao: "2023-12-18T14:00:57",
        idSistemaGestao: "0ce642e0-4543-4b44-b4cb-9dcf98e6171b",
        idOriginalNutricaoBlockchain: "bcb28e9e-c700-4caa-9a57-04d776f6749a",
        idResponsavel: "09af30c7-3ae2-4016-8659-0da0fb248818",
        nomeResponsavel: "Natalino Cavalli Junior",
        descricao: "Ronda 13 - PROTEICO 0,2% SECA",
        latitude: "-21.4216111",
        longitude: "-52.8631389",
        dtNutricao: "2021-07-22",
        unidadeMedida: "QUILOGRAMA",
        quantidade: 5,
        idNutricaoBlockchain: "0e850312-94f8-404c-b560-9774c44ec6d8",
        nutricao: {
          idBlockchain: "bcb28e9e-c700-4caa-9a57-04d776f6749a",
          nome: "PROTEICO 0,2% SECA",
          categoria: "NUTRICIONAL",
          subCategoria: "SUPLEMENTO_MINERAL_RECRIA_ENGORDA",
          unidade: "QUILOGRAMA",
          dataCriacao: "2023-12-13T13:26:37",
          dataUltimaAtualizacao: "2023-12-13T13:26:37",
        },
      },
      {
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        dtCriacao: "2023-12-18T14:00:57",
        dtAlteracao: "2023-12-18T14:00:57",
        idSistemaGestao: "0ce642e0-4543-4b44-b4cb-9dcf98e6171b",
        idOriginalNutricaoBlockchain: "bcb28e9e-c700-4caa-9a57-04d776f6749a",
        idResponsavel: "09af30c7-3ae2-4016-8659-0da0fb248818",
        nomeResponsavel: "Natalino Cavalli Junior",
        descricao: "Ronda 13 - PROTEICO 0,2% SECA",
        latitude: "-21.4216111",
        longitude: "-52.8631389",
        dtNutricao: "2021-07-23",
        unidadeMedida: "QUILOGRAMA",
        quantidade: 5,
        idNutricaoBlockchain: "ce5fde47-f00b-4cc3-bd95-172595978c99",
        nutricao: {
          idBlockchain: "bcb28e9e-c700-4caa-9a57-04d776f6749a",
          nome: "PROTEICO 0,2% SECA",
          categoria: "NUTRICIONAL",
          subCategoria: "SUPLEMENTO_MINERAL_RECRIA_ENGORDA",
          unidade: "QUILOGRAMA",
          dataCriacao: "2023-12-13T13:26:37",
          dataUltimaAtualizacao: "2023-12-13T13:26:37",
        },
      },
      {
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        dtCriacao: "2023-12-18T14:00:57",
        dtAlteracao: "2023-12-18T14:00:57",
        idSistemaGestao: "0ce642e0-4543-4b44-b4cb-9dcf98e6171b",
        idOriginalNutricaoBlockchain: "bcb28e9e-c700-4caa-9a57-04d776f6749a",
        idResponsavel: "09af30c7-3ae2-4016-8659-0da0fb248818",
        nomeResponsavel: "Natalino Cavalli Junior",
        descricao: "Ronda 13 - PROTEICO 0,2% SECA",
        latitude: "-21.4216111",
        longitude: "-52.8631389",
        dtNutricao: "2021-07-16",
        unidadeMedida: "QUILOGRAMA",
        quantidade: 5,
        idNutricaoBlockchain: "08b5d172-05a9-44b5-adc1-57bf94ecfdde",
        nutricao: {
          idBlockchain: "bcb28e9e-c700-4caa-9a57-04d776f6749a",
          nome: "PROTEICO 0,2% SECA",
          categoria: "NUTRICIONAL",
          subCategoria: "SUPLEMENTO_MINERAL_RECRIA_ENGORDA",
          unidade: "QUILOGRAMA",
          dataCriacao: "2023-12-13T13:26:37",
          dataUltimaAtualizacao: "2023-12-13T13:26:37",
        },
      },
      {
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        dtCriacao: "2023-12-18T14:00:57",
        dtAlteracao: "2023-12-18T14:00:57",
        idSistemaGestao: "0ce642e0-4543-4b44-b4cb-9dcf98e6171b",
        idOriginalNutricaoBlockchain: "bcb28e9e-c700-4caa-9a57-04d776f6749a",
        idResponsavel: "09af30c7-3ae2-4016-8659-0da0fb248818",
        nomeResponsavel: "Natalino Cavalli Junior",
        descricao: "Ronda 13 - PROTEICO 0,2% SECA",
        latitude: "-21.4216111",
        longitude: "-52.8631389",
        dtNutricao: "2021-07-17",
        unidadeMedida: "QUILOGRAMA",
        quantidade: 5,
        idNutricaoBlockchain: "4125e4a7-f99a-42b0-a79f-d05008cc084d",
        nutricao: {
          idBlockchain: "bcb28e9e-c700-4caa-9a57-04d776f6749a",
          nome: "PROTEICO 0,2% SECA",
          categoria: "NUTRICIONAL",
          subCategoria: "SUPLEMENTO_MINERAL_RECRIA_ENGORDA",
          unidade: "QUILOGRAMA",
          dataCriacao: "2023-12-13T13:26:37",
          dataUltimaAtualizacao: "2023-12-13T13:26:37",
        },
      },
      {
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        dtCriacao: "2023-12-18T14:00:57",
        dtAlteracao: "2023-12-18T14:00:57",
        idSistemaGestao: "0ce642e0-4543-4b44-b4cb-9dcf98e6171b",
        idOriginalNutricaoBlockchain: "bcb28e9e-c700-4caa-9a57-04d776f6749a",
        idResponsavel: "09af30c7-3ae2-4016-8659-0da0fb248818",
        nomeResponsavel: "Natalino Cavalli Junior",
        descricao: "Ronda 13 - PROTEICO 0,2% SECA",
        latitude: "-21.4216111",
        longitude: "-52.8631389",
        dtNutricao: "2021-07-24",
        unidadeMedida: "QUILOGRAMA",
        quantidade: 5,
        idNutricaoBlockchain: "1a457095-325b-428b-bd5d-f80b8f698545",
        nutricao: {
          idBlockchain: "bcb28e9e-c700-4caa-9a57-04d776f6749a",
          nome: "PROTEICO 0,2% SECA",
          categoria: "NUTRICIONAL",
          subCategoria: "SUPLEMENTO_MINERAL_RECRIA_ENGORDA",
          unidade: "QUILOGRAMA",
          dataCriacao: "2023-12-13T13:26:37",
          dataUltimaAtualizacao: "2023-12-13T13:26:37",
        },
      },
      {
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        dtCriacao: "2023-12-18T14:00:57",
        dtAlteracao: "2023-12-18T14:00:57",
        idSistemaGestao: "0ce642e0-4543-4b44-b4cb-9dcf98e6171b",
        idOriginalNutricaoBlockchain: "bcb28e9e-c700-4caa-9a57-04d776f6749a",
        idResponsavel: "09af30c7-3ae2-4016-8659-0da0fb248818",
        nomeResponsavel: "Natalino Cavalli Junior",
        descricao: "Ronda 13 - PROTEICO 0,2% SECA",
        latitude: "-21.4216111",
        longitude: "-52.8631389",
        dtNutricao: "2021-07-29",
        unidadeMedida: "QUILOGRAMA",
        quantidade: 5,
        idNutricaoBlockchain: "d0f773c8-36f4-47bd-9b34-cb92b4f670e0",
        nutricao: {
          idBlockchain: "bcb28e9e-c700-4caa-9a57-04d776f6749a",
          nome: "PROTEICO 0,2% SECA",
          categoria: "NUTRICIONAL",
          subCategoria: "SUPLEMENTO_MINERAL_RECRIA_ENGORDA",
          unidade: "QUILOGRAMA",
          dataCriacao: "2023-12-13T13:26:37",
          dataUltimaAtualizacao: "2023-12-13T13:26:37",
        },
      },
      {
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        dtCriacao: "2023-12-18T14:00:57",
        dtAlteracao: "2023-12-18T14:00:57",
        idSistemaGestao: "0ce642e0-4543-4b44-b4cb-9dcf98e6171b",
        idOriginalNutricaoBlockchain: "bcb28e9e-c700-4caa-9a57-04d776f6749a",
        idResponsavel: "09af30c7-3ae2-4016-8659-0da0fb248818",
        nomeResponsavel: "Natalino Cavalli Junior",
        descricao: "Ronda 13 - PROTEICO 0,2% SECA",
        latitude: "-21.4216111",
        longitude: "-52.8631389",
        dtNutricao: "2021-07-15",
        unidadeMedida: "QUILOGRAMA",
        quantidade: 5,
        idNutricaoBlockchain: "6000478a-f6ac-4e5f-a48f-4e8b0821a3c7",
        nutricao: {
          idBlockchain: "bcb28e9e-c700-4caa-9a57-04d776f6749a",
          nome: "PROTEICO 0,2% SECA",
          categoria: "NUTRICIONAL",
          subCategoria: "SUPLEMENTO_MINERAL_RECRIA_ENGORDA",
          unidade: "QUILOGRAMA",
          dataCriacao: "2023-12-13T13:26:37",
          dataUltimaAtualizacao: "2023-12-13T13:26:37",
        },
      },
      {
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        dtCriacao: "2023-12-18T14:00:57",
        dtAlteracao: "2023-12-18T14:00:57",
        idSistemaGestao: "0ce642e0-4543-4b44-b4cb-9dcf98e6171b",
        idOriginalNutricaoBlockchain: "bcb28e9e-c700-4caa-9a57-04d776f6749a",
        idResponsavel: "09af30c7-3ae2-4016-8659-0da0fb248818",
        nomeResponsavel: "Natalino Cavalli Junior",
        descricao: "Ronda 13 - PROTEICO 0,2% SECA",
        latitude: "-21.4216111",
        longitude: "-52.8631389",
        dtNutricao: "2021-07-28",
        unidadeMedida: "QUILOGRAMA",
        quantidade: 5,
        idNutricaoBlockchain: "68b2c987-eaf7-49d0-9a9e-1657db7941b2",
        nutricao: {
          idBlockchain: "bcb28e9e-c700-4caa-9a57-04d776f6749a",
          nome: "PROTEICO 0,2% SECA",
          categoria: "NUTRICIONAL",
          subCategoria: "SUPLEMENTO_MINERAL_RECRIA_ENGORDA",
          unidade: "QUILOGRAMA",
          dataCriacao: "2023-12-13T13:26:37",
          dataUltimaAtualizacao: "2023-12-13T13:26:37",
        },
      },
    ],
    localizacaoAtual: {
      dtCriacao: "2023-12-18T13:53:25",
      dtAlteracao: "2023-12-18T13:53:25",
      idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
      fazenda: {
        nome: "PUREZA",
        dataEntrada: "2021-12-15",
        dataSaida: "2022-03-11",
        latitude: "-21.4216111",
        longitude: "-52.8631389",
      },
      subdivisao: {
        nome: "BAIA 01",
        dataEntrada: "2021-12-15",
        dataSaida: "2022-03-11",
      },
      idSistemaGestao: "0ce642e0-4543-4b44-b4cb-9dcf98e6171b",
      idLocalizacaoBlockchain: "64205eb5-3091-4e6d-9932-9cc083dd5e6f",
    },
    localizacaoHistorico: [
      {
        dtCriacao: "2023-12-18T13:53:25",
        dtAlteracao: "2023-12-18T13:53:25",
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        fazenda: {
          nome: "PUREZA",
          dataEntrada: "2020-07-11",
          dataSaida: "2020-10-26",
          latitude: "-21.4216111",
          longitude: "-52.8631389",
        },
        subdivisao: {
          nome: "Pasto 9",
          dataEntrada: "2020-07-11",
          dataSaida: "2020-10-26",
        },
        idSistemaGestao: "0ce642e0-4543-4b44-b4cb-9dcf98e6171b",
        idLocalizacaoBlockchain: "d6288f60-57f2-4917-a9f5-3d7dafbfc6cf",
      },
      {
        dtCriacao: "2023-12-18T13:53:25",
        dtAlteracao: "2023-12-18T13:53:25",
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        fazenda: {
          nome: "PUREZA",
          dataEntrada: "2020-10-26",
          dataSaida: "2021-03-30",
          latitude: "-21.4216111",
          longitude: "-52.8631389",
        },
        subdivisao: {
          nome: "Indaia 1",
          dataEntrada: "2020-10-26",
          dataSaida: "2021-03-30",
        },
        idSistemaGestao: "0ce642e0-4543-4b44-b4cb-9dcf98e6171b",
        idLocalizacaoBlockchain: "90de3437-8255-47d8-b0f9-a04185f0c6b3",
      },
      {
        dtCriacao: "2023-12-18T13:53:25",
        dtAlteracao: "2023-12-18T13:53:25",
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        fazenda: {
          nome: "PUREZA",
          dataEntrada: "2021-03-30",
          dataSaida: "2021-01-04",
          latitude: "-21.4216111",
          longitude: "-52.8631389",
        },
        subdivisao: {
          nome: "Pasto 11",
          dataEntrada: "2021-03-30",
          dataSaida: "2021-01-04",
        },
        idSistemaGestao: "0ce642e0-4543-4b44-b4cb-9dcf98e6171b",
        idLocalizacaoBlockchain: "5186fcfd-1513-45ec-b8c9-4cfae67d6893",
      },
      {
        dtCriacao: "2023-12-18T13:53:25",
        dtAlteracao: "2023-12-18T13:53:25",
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        fazenda: {
          nome: "SANTO EXPEDITO",
          dataEntrada: "2021-01-04",
          dataSaida: "2021-05-17",
          latitude: "-21.5611389",
          longitude: "-52.7810278",
        },
        subdivisao: {
          nome: "Ronda 1",
          dataEntrada: "2021-01-04",
          dataSaida: "2021-05-17",
        },
        idSistemaGestao: "0ce642e0-4543-4b44-b4cb-9dcf98e6171b",
        idLocalizacaoBlockchain: "ea5a5cc4-4ed7-4592-8f3c-b06db976de17",
      },
      {
        dtCriacao: "2023-12-18T13:53:25",
        dtAlteracao: "2023-12-18T13:53:25",
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        fazenda: {
          nome: "SANTO EXPEDITO",
          dataEntrada: "2021-05-17",
          dataSaida: "2021-07-21T00:00:01",
          latitude: "-21.5611389",
          longitude: "-52.7810278",
        },
        subdivisao: {
          nome: "Ronda 13",
          dataEntrada: "2021-05-17",
          dataSaida: "2021-07-21T00:00:01",
        },
        idSistemaGestao: "0ce642e0-4543-4b44-b4cb-9dcf98e6171b",
        idLocalizacaoBlockchain: "a9d7246a-2187-4419-87c2-a302b3b685a4",
      },
      {
        dtCriacao: "2023-12-18T13:53:25",
        dtAlteracao: "2023-12-18T13:53:25",
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        fazenda: {
          nome: "SANTO EXPEDITO",
          dataEntrada: "2021-07-21T00:00:01",
          dataSaida: "2021-07-21T00:00:02",
          latitude: "-21.5611389",
          longitude: "-52.7810278",
        },
        subdivisao: {
          nome: "Ronda 14",
          dataEntrada: "2021-07-21T00:00:01",
          dataSaida: "2021-07-21T00:00:02",
        },
        idSistemaGestao: "0ce642e0-4543-4b44-b4cb-9dcf98e6171b",
        idLocalizacaoBlockchain: "96c80775-b90c-48a5-86b2-b7dc0a61f00f",
      },
      {
        dtCriacao: "2023-12-18T13:53:25",
        dtAlteracao: "2023-12-18T13:53:25",
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        fazenda: {
          nome: "SANTO EXPEDITO",
          dataEntrada: "2021-07-21T00:00:02",
          dataSaida: "2021-09-01T00:00:00",
          latitude: "-21.5611389",
          longitude: "-52.7810278",
        },
        subdivisao: {
          nome: "Ronda 13",
          dataEntrada: "2021-07-21T00:00:02",
          dataSaida: "2021-09-01T00:00:00",
        },
        idSistemaGestao: "0ce642e0-4543-4b44-b4cb-9dcf98e6171b",
        idLocalizacaoBlockchain: "7e266e72-c839-447d-bff6-fe2b7e9af203",
      },
      {
        dtCriacao: "2023-12-18T13:53:25",
        dtAlteracao: "2023-12-18T13:53:25",
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        fazenda: {
          nome: "SANTO EXPEDITO",
          dataEntrada: "2021-09-01T00:00:01",
          dataSaida: "2021-09-01T00:00:02",
          latitude: "-21.5611389",
          longitude: "-52.7810278",
        },
        subdivisao: {
          nome: "Ronda 14",
          dataEntrada: "2021-09-01T00:00:01",
          dataSaida: "2021-09-01T00:00:02",
        },
        idSistemaGestao: "0ce642e0-4543-4b44-b4cb-9dcf98e6171b",
        idLocalizacaoBlockchain: "727b30fb-a74e-497a-a0fa-424fba200adb",
      },
      {
        dtCriacao: "2023-12-18T13:53:25",
        dtAlteracao: "2023-12-18T13:53:25",
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        fazenda: {
          nome: "SANTO EXPEDITO",
          dataEntrada: "2021-09-01T00:00:02",
          dataSaida: "2021-11-13",
          latitude: "-21.5611389",
          longitude: "-52.7810278",
        },
        subdivisao: {
          nome: "Ronda 13",
          dataEntrada: "2021-09-01T00:00:02",
          dataSaida: "2021-11-13",
        },
        idSistemaGestao: "0ce642e0-4543-4b44-b4cb-9dcf98e6171b",
        idLocalizacaoBlockchain: "d8621edf-14d9-4a8e-a878-e297411c05ad",
      },
      {
        dtCriacao: "2023-12-18T13:53:25",
        dtAlteracao: "2023-12-18T13:53:25",
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        fazenda: {
          nome: "PUREZA",
          dataEntrada: "2021-11-13",
          dataSaida: "2021-12-15",
          latitude: "-21.4216111",
          longitude: "-52.8631389",
        },
        subdivisao: {
          nome: "TIP 01",
          dataEntrada: "2021-11-13",
          dataSaida: "2021-12-15",
        },
        idSistemaGestao: "0ce642e0-4543-4b44-b4cb-9dcf98e6171b",
        idLocalizacaoBlockchain: "5f06731e-9ccb-4b04-a3a0-985306f60a10",
      },
      {
        dtCriacao: "2023-12-18T13:53:25",
        dtAlteracao: "2023-12-18T13:53:25",
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        fazenda: {
          nome: "PUREZA",
          dataEntrada: "2021-12-15",
          dataSaida: "2022-03-11",
          latitude: "-21.4216111",
          longitude: "-52.8631389",
        },
        subdivisao: {
          nome: "BAIA 01",
          dataEntrada: "2021-12-15",
          dataSaida: "2022-03-11",
        },
        idSistemaGestao: "0ce642e0-4543-4b44-b4cb-9dcf98e6171b",
        idLocalizacaoBlockchain: "64205eb5-3091-4e6d-9932-9cc083dd5e6f",
      },
    ],
    loteAtual: {
      dtCriacao: "2023-12-18T13:52:58",
      dtAlteracao: "2023-12-18T13:52:58",
      idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
      nome: "LOT 01 CONFI",
      dataEntrada: "2021-12-15",
      dataSaida: null,
      idSistemaGestao: null,
      idLoteBlockchain: "739924de-a192-4f9a-8afb-50e9a7754941",
    },
    loteHistorico: [
      {
        dtCriacao: "2023-12-18T13:52:58",
        dtAlteracao: "2023-12-18T13:52:58",
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        nome: "LOT 02 VACAS PRENHE",
        dataEntrada: "2020-06-03",
        dataSaida: "2020-07-01",
        idSistemaGestao: null,
        idLoteBlockchain: "4d747c65-f60b-4c0f-aa87-9d3b70fac102",
      },
      {
        dtCriacao: "2023-12-18T13:52:58",
        dtAlteracao: "2023-12-18T13:52:58",
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        nome: "LOT 7.1",
        dataEntrada: "2020-07-01",
        dataSaida: "2020-10-26",
        idSistemaGestao: null,
        idLoteBlockchain: "4c9d844b-3d5a-4cbe-a073-9f905a5d1e51",
      },
      {
        dtCriacao: "2023-12-18T13:52:58",
        dtAlteracao: "2023-12-18T13:52:58",
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        nome: "7.1 PRENHE",
        dataEntrada: "2020-10-26",
        dataSaida: "2021-03-30",
        idSistemaGestao: null,
        idLoteBlockchain: "1b9337e8-bf10-49d9-b973-a28462038589",
      },
      {
        dtCriacao: "2023-12-18T13:52:58",
        dtAlteracao: "2023-12-18T13:52:58",
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        nome: "LOT N1 RECRIA F2",
        dataEntrada: "2021-03-30",
        dataSaida: "2021-04-06",
        idSistemaGestao: null,
        idLoteBlockchain: "9436d1d9-3c51-4389-b476-de6520596703",
      },
      {
        dtCriacao: "2023-12-18T13:52:58",
        dtAlteracao: "2023-12-18T13:52:58",
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        nome: "LOT N1 RECRIA F2",
        dataEntrada: "2021-04-06",
        dataSaida: "2021-05-17",
        idSistemaGestao: null,
        idLoteBlockchain: "f463f9ff-deb4-4452-9d3e-ba7f297b4845",
      },
      {
        dtCriacao: "2023-12-18T13:52:58",
        dtAlteracao: "2023-12-18T13:52:58",
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        nome: "LOT 52 N1 ANGUS F2",
        dataEntrada: "2021-05-17",
        dataSaida: "2021-07-21",
        idSistemaGestao: null,
        idLoteBlockchain: "f4dc9845-fc20-4b70-82ca-38c099b3b7b2",
      },
      {
        dtCriacao: "2023-12-18T13:52:58",
        dtAlteracao: "2023-12-18T13:52:58",
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        nome: "LOT 52 N1 ANGUS F2",
        dataEntrada: "2021-07-21",
        dataSaida: "2021-11-15",
        idSistemaGestao: null,
        idLoteBlockchain: "65ed5884-7748-49df-9496-41619fa5ea9b",
      },
      {
        dtCriacao: "2023-12-18T13:52:58",
        dtAlteracao: "2023-12-18T13:52:58",
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        nome: "LOT 51 N1 ANGUS F1",
        dataEntrada: "2021-11-15",
        dataSaida: "2021-12-15",
        idSistemaGestao: null,
        idLoteBlockchain: "312d148b-636e-4568-9865-552447e7b768",
      },
      {
        dtCriacao: "2023-12-18T13:52:58",
        dtAlteracao: "2023-12-18T13:52:58",
        idLoteSync: "feefc4df-ab36-4ada-94f8-ee4dc8be8cba",
        nome: "LOT 01 CONFI",
        dataEntrada: "2021-12-15",
        dataSaida: null,
        idSistemaGestao: null,
        idLoteBlockchain: "739924de-a192-4f9a-8afb-50e9a7754941",
      },
    ],
    idResponsavel: "09af30c7-3ae2-4016-8659-0da0fb248818",
    nomeResponsavel: "Natalino Cavalli Junior",
    frigorifico: {
      nome: "VPJ",
      maturidade: "50%",
      acabamento: "",
      pesoBanda1: "",
      pesoBanda2: "",
      obs1: "",
      obs2: "",
      dtMorte: "2022-03-01",
      porcentagemCarcaca: "55.62",
      dtCriacao: "2023-12-18T18:45:15",
      dtAlteracao: "2023-12-18T18:45:15",
    },
  },
};

export default animals;
