<template>
  <div class="container">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">Nome Lote</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <template v-for="lote in animal.loteHistorico">
          <tr>
            <td>{{lote.nome}}</td>
            <td>
              <img alt="+" src="../assets/plus.png" class="plusCollapse" data-bs-toggle="collapse" 
              :href="'#collapseExample'+lote.idLoteBlockchain" role="button" aria-expanded="false" :aria-controls="'collapseExample'+lote.idLoteBlockchain"></td>
          </tr>
          <tr>
            <td class="hiddenRow" colspan="2">
              <div class="collapse" :id="'collapseExample'+lote.idLoteBlockchain">
                <div class="card card-body">
                  <div class="container">
                    <div class="row align-items-start">
                      <div class="col text-start">
                        <b>ID Lote:</b>
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        {{lote.idLoteBlockchain}}
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        <b>Nome Lote:</b>
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        {{lote.nome}}
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        <b>Data Entrada:</b>
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        {{formataData(lote.dataEntrada)}}
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        <b>Data Saída:</b>
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        {{formataData(lote.dataSaida)}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>

let vm

export default {
  name: 'Lotes',
  components: {
  },
  async created() {
    vm = this
    if(!vm.$route.params || !vm.$route.params.id)
      return vm.$router.push({ name: 'Index'})

    if(vm.$store.getters.getAnimal == null) {
      let response = null
      try {
        response = await vm.$http.get('/animal-aberto/'+vm.$route.params.id)
        if(response.data.error)
          throw response.data.message
      } catch(e) {
        return vm.$router.push({ name: 'Index'}) 
      }
      
      vm.$store.commit('setAnimal', response.data)
      vm.$emitter.emit('setAnimal', vm.$store.getters.getAnimal)
    }

    vm.animal = vm.$store.getters.getAnimal
  },
  data() {
    return {
      animal: {}
    }
  },
  methods: {
    formataData(data) {
      if(!data || data.length < 10) return '-'
      return vm.$dayjs(data).format('DD/MM/YYYY')
    }
  }
}

</script>