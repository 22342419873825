<template>
  <div>
    <!-- <div class="modal show" tabindex="-1" id="modalPanfleto">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Fechar"></button>
          </div>
          <div class="modal-body">
            <p>Tirando a carne do panfleto,</p>
            <p><b>todas as informações são reais.</b></p>
            <br>
            <br>
            <img alt="SafeBeef Selo" src="../assets/selo.png" style="width: 200px; heigth: 100%">
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
          </div>
        </div>
      </div>
    </div> -->
    <div class="accordion" id="accordionPanelsStayOpenExample">
      <div class="accordion-item">
        <h2 class="accordion-header" id="panelsStayOpen-headingOne">
          <button class="accordion-button collapsed accordion-color" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="false" aria-controls="panelsStayOpen-collapseOne">
            <b>Último Produtor</b>
          </button>
        </h2>
        <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingOne">
          <div class="accordion-body">
            <div class="container">
              <div class="row align-items-start">
                <div class="col text-start">
                  <b>Produtor:</b>
                </div>
              </div>
              <div class="row align-items-start">
                <div class="col text-start">
                  {{animal.produtorAtual ? animal.produtorAtual.nome : '-'}}
                </div>
              </div>
              <div class="row align-items-start">
                <div class="col text-start">
                  <b>ID Produtor:</b>
                </div>
              </div>
              <div class="row align-items-start">
                <div class="col text-start">
                  {{animal.produtorAtual ? animal.produtorAtual.idBlockchain : '-'}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
          <button class="accordion-button collapsed accordion-color" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
            <b>Informações</b>
          </button>
        </h2>
        <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
          <div class="accordion-body">
            <div class="container">
              <div class="row align-items-start">
                <div class="col text-start">
                  <b>ID Sisbov:</b>
                </div>
              </div>
              <div class="row align-items-start">
                <div class="col text-start">
                  {{animal.idSisbov}}
                </div>
              </div>
              <div class="row align-items-start">
                <div class="col text-start">
                  <b>ID Icar:</b>
                </div>
              </div>
              <div class="row align-items-start">
                <div class="col text-start">
                  {{animal.idICar ? animal.idICar : '-'}}
                </div>
              </div>
              <div class="row align-items-start">
                <div class="col text-start">
                  <b>ID Animal Fazenda:</b>
                </div>
              </div>
              <div class="row align-items-start">
                <div class="col text-start">
                  {{animal.idAnimalFazenda ? animal.idAnimalFazenda : '-'}}
                </div>
              </div>
              <div class="row align-items-start">
                <div class="col text-start">
                  <b>Data Nascimento:</b>
                </div>
              </div>
              <div class="row align-items-start">
                <div class="col text-start">
                  {{animal.dataNascimento ? formataData(animal.dataNascimento) : '-'}}
                </div>
              </div>
              <div class="row align-items-start">
                <div class="col text-start">
                  <b>Sexo:</b>
                </div>
              </div>
              <div class="row align-items-start">
                <div class="col text-start">
                  {{animal.sexo}}
                </div>
              </div>
              <div class="row align-items-start">
                <div class="col text-start">
                  <b>ID Brinco Eletrônico:</b>
                </div>
              </div>
              <div class="row align-items-start">
                <div class="col text-start">
                  {{animal.idBrincoEletronico ? animal.idBrincoEletronico : '-'}}
                </div>
              </div>
              <div class="row align-items-start">
                <div class="col text-start">
                  <b>ID RGN:</b>
                </div>
              </div>
              <div class="row align-items-start">
                <div class="col text-start">
                  {{animal.idRgn ? animal.idRgn : '-'}}
                </div>
              </div>
              <div class="row align-items-start">
                <div class="col text-start">
                  <b>ID RGD:</b>
                </div>
              </div>
              <div class="row align-items-start">
                <div class="col text-start">
                  {{animal.idRgd ? animal.idRgd : '-'}}
                </div>
              </div>
              <div class="row align-items-start">
                <div class="col text-start">
                  <b>Pelagem:</b>
                </div>
              </div>
              <div class="row align-items-start">
                <div class="col text-start">
                  {{animal.pelagem ? animal.pelagem : '-'}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="panelsStayOpen-headingThree">
          <button class="accordion-button collapsed accordion-color" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
            <b>Composição racial</b>
          </button>
        </h2>
        <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
          <div class="accordion-body">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Nome</th>
                  <th scope="col">Porcent.</th>
                  <th scope="col">Principal</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="raca in animal.composicaoRacial" :key="raca.nome">
                  <td>{{raca.nome}}</td>
                  <td>{{raca.porcentagemRaca}}%</td>
                  <td>{{raca.racaPrincipal ? 'Sim' : 'Não'}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="googlemaps">
      <iframe v-if="validaLocalizacao(animal)"
        width="100%"
        height="100%"
        style="border:0"
        loading="lazy"
        allowfullscreen
        referrerpolicy="no-referrer-when-downgrade"
        :src=getMapa(animal)>
      </iframe>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap';

let vm

export default {
  name: 'Informacoes',
  components: {
  },
  async created() {
    vm = this

    if(!vm.$route.params || !vm.$route.params.id)
      return vm.$router.push({ name: 'Index'}) 
    
    let response = null
    try {
      response = await vm.$http.get('/animal-aberto/'+vm.$route.params.id)
      if(response.data.error)
        throw response.data.message
    } catch(e) {
      return vm.$router.push({ name: 'Index'}) 
    }
    
    vm.$store.commit('setAnimal', response.data)
    vm.$emitter.emit('setAnimal', vm.$store.getters.getAnimal)
    vm.animal = vm.$store.getters.getAnimal
  },
  mounted() {
    // CÓDIGO REFERENTE AO MODAL PROMOCIONAL, DEIXADO AQUI PRA CASO DESEJE UTILIZAR DE NOVO

    // let modalPanfleto = new Modal(document.getElementById('modalPanfleto'))

    // modalPanfleto.show(true)
  },
  data() {
    return {
      animal: {}
    }
  },
  methods: {
    validaLocalizacao(animal) {
      return (animal && animal.localizacaoAtual && animal.localizacaoAtual && animal.localizacaoAtual.fazenda 
              && animal.localizacaoAtual.fazenda.latitude && animal.localizacaoAtual.fazenda.longitude)
    },
    getMapa(animal) {
      return `https://www.google.com/maps/embed/v1/view?key=AIzaSyBSrRxNTQJmCHcLTBv8IVoehzySq-atChk&center=\
      ${animal.localizacaoAtual.fazenda.latitude},${animal.localizacaoAtual.fazenda.longitude}&maptype=satellite&zoom=15`
    },
    formataData(data) {
      if(!data || data.length < 10) return '-'
      return vm.$dayjs(data).format('DD/MM/YYYY')
    }
  }
}

</script>

<style>

.googlemaps {
  margin-top: 10px;
}

</style>