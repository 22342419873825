<template>
  <div class="container">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">Data</th>
          <th scope="col">Tipo</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <template v-for="reproducao in animal.reproducoes">
          <tr>
            <td>{{formataData(reproducao.dtReproducao)}}</td>
            <td>{{reproducao.tipo}}</td>
            <td>
              <img alt="+" src="../assets/plus.png" class="plusCollapse" data-bs-toggle="collapse" 
              :href="'#collapseExample'+reproducao.idReproducaoBlockchain" role="button" aria-expanded="false" :aria-controls="'collapseExample'+reproducao.idReproducaoBlockchain"></td>
          </tr>
          <tr>
            <td class="hiddenRow" colspan="3">
              <div class="collapse" :id="'collapseExample'+reproducao.idReproducaoBlockchain">
                <div class="card card-body">
                  <div class="container">
                    <div class="row align-items-start">
                      <div class="col text-start">
                        <b>ID Reprodução:</b>
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        {{reproducao.idReproducaoBlockchain}}
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        <b>Data da Reprodução:</b>
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        {{formataData(reproducao.dtReproducao)}}
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        <b>Tipo da Reprodução:</b>
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        {{reproducao.tipo}}
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        <b>Responsável:</b>
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        {{reproducao.nomeResponsavel}}
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        <b>Localidade:</b>
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        <iframe v-if="validaLocalizacao(reproducao.idReproducaoBlockchain)"
                          width="100%"
                          height="100%"
                          style="border:0"
                          loading="lazy"
                          allowfullscreen
                          referrerpolicy="no-referrer-when-downgrade"
                          :src=getMapa(reproducao.idReproducaoBlockchain)>
                        </iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>

let vm

export default {
  name: 'Reproducoes',
  components: {
  },
  async created() {
    vm = this
    if(!vm.$route.params || !vm.$route.params.id)
      return vm.$router.push({ name: 'Index'})

    if(vm.$store.getters.getAnimal == null) {
      let response = null
      try {
        response = await vm.$http.get('/animal-aberto/'+vm.$route.params.id)
        if(response.data.error)
          throw response.data.message
      } catch(e) {
        return vm.$router.push({ name: 'Index'}) 
      }
      
      vm.$store.commit('setAnimal', response.data)
      vm.$emitter.emit('setAnimal', vm.$store.getters.getAnimal)
    }

    vm.animal = vm.$store.getters.getAnimal
  },
  data() {
    return {
      animal: {}
    }
  },
  methods: {
    validaLocalizacao(idReproducao) {
      if (!vm.animal || !vm.animal.reproducoes) return false

      const reproducao = vm.animal.reproducoes.find(el => el.idReproducaoBlockchain == idReproducao)

      if (!reproducao) return false

      return (reproducao.latitude && reproducao.longitude)
    },
    getMapa(idReproducao) {      
      const reproducao = vm.animal.reproducoes.find(el => el.idReproducaoBlockchain == idReproducao)
      
      return `https://www.google.com/maps/embed/v1/view?key=AIzaSyBSrRxNTQJmCHcLTBv8IVoehzySq-atChk&center=\
      ${reproducao.latitude},${reproducao.longitude}&maptype=satellite&zoom=15`
    },
    formataData(data) {
      if(!data || data.length < 10) return '-'
      return vm.$dayjs(data).format('DD/MM/YYYY')
    }
  }
}

</script>