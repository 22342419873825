import { createRouter, createWebHistory } from 'vue-router'
import Index from '../views/Index.vue'
import Informacoes from '../views/Informacoes.vue'
import Pesagem from '../views/Pesagem.vue'
import Nutricao from '../views/Nutricao.vue'
import Medicamentos from '../views/Medicamentos.vue'
import Exames from '../views/Exames.vue'
import Reproducoes from '../views/Reproducoes.vue'
import Localizacoes from '../views/Localizacoes.vue'
import Lotes from '../views/Lotes.vue'
import AcoesDeManejo from '../views/AcoesDeManejo.vue'
import Vendas from '../views/Vendas.vue'
import Frigorifico from '../views/Frigorifico.vue'

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path: '/animal/:id',
    name: 'Informacoes',
    component: Informacoes
  },
  {
    path: '/animal/:id/pesagem',
    name: 'Pesagem',
    component: Pesagem
  },
  {
    path: '/animal/:id/nutricao',
    name: 'Nutricao',
    component: Nutricao
  },
  {
    path: '/animal/:id/medicamentos',
    name: 'Medicamentos',
    component: Medicamentos
  },
  {
    path: '/animal/:id/exames',
    name: 'Exames',
    component: Exames
  },
  {
    path: '/animal/:id/reproducoes',
    name: 'Reproducoes',
    component: Reproducoes
  },
  {
    path: '/animal/:id/localizacoes',
    name: 'Localizacoes',
    component: Localizacoes
  },
  {
    path: '/animal/:id/lotes',
    name: 'Lotes',
    component: Lotes
  },
  {
    path: '/animal/:id/acoes-de-manejo',
    name: 'AcoesDeManejo',
    component: AcoesDeManejo
  },
  {
    path: '/animal/:id/vendas',
    name: 'Vendas',
    component: Vendas
  },
  {
    path: '/animal/:id/frigorifico',
    name: 'Frigorifico',
    component: Frigorifico
  }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
