<template>
  <div class="container">
    <table class="table" v-if="animal.medicamentosAplicados">
      <thead>
        <tr>
          <th scope="col">Data</th>
          <th scope="col">Medicamento</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <template v-for="medicamento in animal.medicamentosAplicados">
          <tr>
            <td>{{formataData(medicamento.dtMedicacao)}}</td>
            <td>{{medicamento.medicamento.nome}}</td>
            <td>
              <img alt="+" src="../assets/plus.png" class="plusCollapse" data-bs-toggle="collapse" 
              :href="'#collapseExample'+medicamento.idMedicamentoBlockchain" role="button" aria-expanded="false" :aria-controls="'collapseExample'+medicamento.idMedicamentoBlockchain"></td>
          </tr>
          <tr>
            <td class="hiddenRow" colspan="3">
              <div class="collapse" :id="'collapseExample'+medicamento.idMedicamentoBlockchain">
                <div class="card card-body">
                  <div class="container">
                    <div class="row align-items-start">
                      <div class="col text-start">
                        <b>ID Aplicação:</b>
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        {{medicamento.idMedicamentoBlockchain}}
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        <b>Data Medicação:</b>
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        {{formataData(medicamento.dtMedicacao)}}
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        <b>Nome Medicamento:</b>
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        {{medicamento.medicamento.nome}} <img alt="Info" src="../assets/info.png" class="info">
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        <b>Quantidade:</b>
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        {{medicamento.quantidade}} ml
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        <b>Responsável:</b>
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        {{medicamento.nomeResponsavel}}
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        <b>Localidade:</b>
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        <iframe v-if="validaLocalizacao(medicamento.idMedicamentoBlockchain)"
                          width="100%"
                          height="100%"
                          style="border:0"
                          loading="lazy"
                          allowfullscreen
                          referrerpolicy="no-referrer-when-downgrade"
                          :src=getMapa(medicamento.idMedicamentoBlockchain)>
                        </iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>

let vm

export default {
  name: 'Medicamentos',
  components: {
  },
  async created() {
    vm = this
    if(!vm.$route.params || !vm.$route.params.id)
      return vm.$router.push({ name: 'Index'})

    if(vm.$store.getters.getAnimal == null) {
      let response = null
      try {
        response = await vm.$http.get('/animal-aberto/'+vm.$route.params.id)
        if(response.data.error)
          throw response.data.message
      } catch(e) {
        return vm.$router.push({ name: 'Index'}) 
      }
      
      vm.$store.commit('setAnimal', response.data)
      vm.$emitter.emit('setAnimal', vm.$store.getters.getAnimal)
    }

    vm.animal = vm.$store.getters.getAnimal
  },
  data() {
    return {
      animal: {}
    }
  },
  methods: {
    validaLocalizacao(idMedicamento) {
      if (!vm.animal || !vm.animal.medicamentosAplicados) return false

      const medicamento = vm.animal.medicamentosAplicados.find(el => el.idMedicamentoBlockchain == idMedicamento)

      if (!medicamento) return false

      return (medicamento.latitude && medicamento.longitude)
    },
    getMapa(idMedicamento) {      
      const medicamento = vm.animal.medicamentosAplicados.find(el => el.idMedicamentoBlockchain == idMedicamento)
      
      return `https://www.google.com/maps/embed/v1/view?key=AIzaSyBSrRxNTQJmCHcLTBv8IVoehzySq-atChk&center=\
      ${medicamento.latitude},${medicamento.longitude}&maptype=satellite&zoom=15`
    },
    formataData(data) {
      if(!data || data.length < 10) return '-'
      return vm.$dayjs(data).format('DD/MM/YYYY')
    }
  }
}

</script>