<template>
  <div class="container">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">Data</th>
          <th scope="col">Tipo</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <template v-if="animal.vendaAtual">
          <tr>
            <td>{{ formataData(animal.vendaAtual.dataCriacao) }}</td>
            <td>{{ animal.vendaAtual.tipo }}</td>
            <td>
              <img
                alt="+"
                src="../assets/plus.png"
                class="plusCollapse"
                data-bs-toggle="collapse"
                :href="'#collapseExample' + animal.vendaAtual.idBlockchain"
                role="button"
                aria-expanded="false"
                :aria-controls="
                  'collapseExample' + animal.vendaAtual.idBlockchain
                "
              />
            </td>
          </tr>
          <tr>
            <td class="hiddenRow" colspan="3">
              <div
                class="collapse"
                :id="'collapseExample' + animal.vendaAtual.idBlockchain"
              >
                <div class="card card-body">
                  <div class="container">
                    <div class="row align-items-start">
                      <div class="col text-start">
                        <b>ID Venda:</b>
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        {{ animal.vendaAtual.idBlockchain }}
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        <b>Tipo Venda:</b>
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        {{ animal.vendaAtual.tipo }}
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        <b>Status:</b>
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        {{ animal.vendaAtual.status }}
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        <b>Proprietário Origem:</b>
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        {{ animal.vendaAtual.proprietarioOrigem.nome }}
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        <b>Proprietário Destino:</b>
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        {{ animal.vendaAtual.proprietarioDestino.nome }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
let vm;

export default {
  name: "Vendas",
  components: {},
  async created() {
    vm = this;
    if (!vm.$route.params || !vm.$route.params.id)
      return vm.$router.push({ name: "Index" });

    if (vm.$store.getters.getAnimal == null) {
      let response = null;
      try {
        response = await vm.$http.get("/animal-aberto/" + vm.$route.params.id);
        if (response.data.error) throw response.data.message;
      } catch (e) {
        return vm.$router.push({ name: "Index" });
      }

      vm.$store.commit("setAnimal", response.data);
      vm.$emitter.emit("setAnimal", vm.$store.getters.getAnimal);
    }

    vm.animal = vm.$store.getters.getAnimal;
  },
  data() {
    return {
      animal: {},
    };
  },
  methods: {
    formataData(data) {
      if (!data || data.length < 10) return "-";
      return vm.$dayjs(data).format("DD/MM/YYYY");
    },
  },
};
</script>
