<template>
  <div class="container">
    <table class="table" v-if="animal.nutricoesAplicadas">
      <thead>
        <tr>
          <th scope="col">Data</th>
          <th scope="col">Nome</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <template v-for="nutricao in animal.nutricoesAplicadas">
          <tr>
            <td>{{formataData(nutricao.dtNutricao)}}</td>
            <td>{{nutricao.nutricao.nome}}</td>
            <td>
              <img alt="+" src="../assets/plus.png" class="plusCollapse" data-bs-toggle="collapse" 
              :href="'#collapseExample'+nutricao.idNutricaoBlockchain" role="button" aria-expanded="false" :aria-controls="'collapseExample'+nutricao.idNutricaoBlockchain"></td>
          </tr>
          <tr>
            <td class="hiddenRow" colspan="3">
              <div class="collapse" :id="'collapseExample'+nutricao.idNutricaoBlockchain">
                <div class="card card-body">
                  <div class="container">
                    <div class="row align-items-start">
                      <div class="col text-start">
                        <b>ID Nutrição:</b>
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        {{nutricao.idNutricaoBlockchain}}
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        <b>Data Nutrição:</b>
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        {{formataData(nutricao.dtNutricao)}}
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        <b>Nome:</b>
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        {{nutricao.nutricao.nome}}
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        <b>Descrição:</b>
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        {{nutricao.descricao}}
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        <b>Quantidade:</b>
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        {{nutricao.quantidade}} kg
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        <b>Responsável:</b>
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        {{nutricao.nomeResponsavel}}
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        <b>Localidade:</b>
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        <iframe v-if="validaLocalizacao(nutricao.idNutricaoBlockchain)"
                          width="100%"
                          height="100%"
                          style="border:0"
                          loading="lazy"
                          allowfullscreen
                          referrerpolicy="no-referrer-when-downgrade"
                          :src=getMapa(nutricao.idNutricaoBlockchain)>
                        </iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>

let vm

export default {
  name: 'Nutricao',
  components: {
  },
  async created() {
    vm = this
    if(!vm.$route.params || !vm.$route.params.id)
      return vm.$router.push({ name: 'Index'})

    if(vm.$store.getters.getAnimal == null) {
      let response = null
      try {
        response = await vm.$http.get('/animal-aberto/'+vm.$route.params.id)
        if(response.data.error)
          throw response.data.message
      } catch(e) {
        return vm.$router.push({ name: 'Index'}) 
      }
      
      vm.$store.commit('setAnimal', response.data)
      vm.$emitter.emit('setAnimal', vm.$store.getters.getAnimal)
    }

    vm.animal = vm.$store.getters.getAnimal
  },
  data() {
    return {
      animal: {}
    }
  },
  methods: {
    validaLocalizacao(idNutricao) {
      if (!vm.animal || !vm.animal.nutricoesAplicadas) return false

      const nutricao = vm.animal.nutricoesAplicadas.find(el => el.idNutricaoBlockchain == idNutricao)

      if (!nutricao) return false

      return (nutricao.latitude && nutricao.longitude)
    },
    getMapa(idNutricao) {      
      const nutricao = vm.animal.nutricoesAplicadas.find(el => el.idNutricaoBlockchain == idNutricao)
      
      return `https://www.google.com/maps/embed/v1/view?key=AIzaSyBSrRxNTQJmCHcLTBv8IVoehzySq-atChk&center=\
      ${nutricao.latitude},${nutricao.longitude}&maptype=satellite&zoom=15`
    },
    formataData(data) {
      if(!data || data.length < 10) return '-'
      return vm.$dayjs(data).format('DD/MM/YYYY')
    }
  }
}

</script>