<template>
  <div class="container">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">Data</th>
          <th scope="col">Tipo</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <template v-for="exame in animal.exames">
          <tr>
            <td>{{formataData(exame.dtExame)}}</td>
            <td>{{exame.tipoExame}}</td>
            <td>
              <img alt="+" src="../assets/plus.png" class="plusCollapse" data-bs-toggle="collapse" 
              :href="'#collapseExample'+exame.idExameBlockchain" role="button" aria-expanded="false" :aria-controls="'collapseExample'+exame.idExameBlockchain"></td>
          </tr>
          <tr>
            <td class="hiddenRow" colspan="3">
              <div class="collapse" :id="'collapseExample'+exame.idExameBlockchain">
                <div class="card card-body">
                  <div class="container">
                    <div class="row align-items-start">
                      <div class="col text-start">
                        <b>ID Exame:</b>
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        {{exame.idExameBlockchain}}
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        <b>Data do Exame:</b>
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        {{formataData(exame.dtExame)}}
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        <b>Tipo do Exame:</b>
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        {{exame.tipoExame}}
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        <b>Responsável:</b>
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        {{exame.nomeResponsavel}}
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        <b>Resultados:</b>
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start" style="white-space: pre-wrap">
                        {{getResultadosExame(exame.idExameBlockchain)}}
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        <b>Localidade:</b>
                      </div>
                    </div>
                    <div class="row align-items-start">
                      <div class="col text-start">
                        <iframe v-if="validaLocalizacao(exame.idExameBlockchain)"
                          width="100%"
                          height="100%"
                          style="border:0"
                          loading="lazy"
                          allowfullscreen
                          referrerpolicy="no-referrer-when-downgrade"
                          :src=getMapa(exame.idExameBlockchain)>
                        </iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>

let vm

export default {
  name: 'Exames',
  components: {
  },
  async created() {
    vm = this
    if(!vm.$route.params || !vm.$route.params.id)
      return vm.$router.push({ name: 'Index'})

    if(vm.$store.getters.getAnimal == null) {
      let response = null
      try {
        response = await vm.$http.get('/animal-aberto/'+vm.$route.params.id)
        if(response.data.error)
          throw response.data.message
      } catch(e) {
        return vm.$router.push({ name: 'Index'}) 
      }
      
      vm.$store.commit('setAnimal', response.data)
      vm.$emitter.emit('setAnimal', vm.$store.getters.getAnimal)
    }

    vm.animal = vm.$store.getters.getAnimal
  },
  data() {
    return {
      animal: {},

    }
  },
  methods: {
    validaLocalizacao(idExame) {
      if (!vm.animal || !vm.animal.exames) return false

      const exame = vm.animal.exames.find(el => el.idExameBlockchain == idExame)

      if (!exame) return false

      return (exame.latitude && exame.longitude)
    },
    getMapa(idExame) {      
      const exame = vm.animal.exames.find(el => el.idExameBlockchain == idExame)
      
      return `https://www.google.com/maps/embed/v1/view?key=AIzaSyBSrRxNTQJmCHcLTBv8IVoehzySq-atChk&center=\
      ${exame.latitude},${exame.longitude}&maptype=satellite&zoom=15`
    },
    getResultadosExame(idExame) {
      const exame = vm.animal.exames.find(el => el.idExameBlockchain == idExame)
      const keys = Object.keys(exame).filter(key => key.includes('vrResultado'))
      const resultadosExame = {}
      keys.forEach(key => {
        if(exame[key]) resultadosExame[key] = exame[key]
      })
      return JSON.stringify(resultadosExame)
                 .replaceAll(',','\n')
                 .replaceAll('vrResultado','Resultado ')
                 .replaceAll('\"','')
                 .replaceAll('{','')
                 .replaceAll('}','')
                 .replaceAll(':',':\t')
                 .replaceAll(' :',':')
    },
    formataData(data) {
      if(!data || data.length < 10) return '-'
      return vm.$dayjs(data).format('DD/MM/YYYY')
    }
  }
}

</script>